import React, { useEffect, useRef, useState } from 'react';
import { DMSearchIcon, DMCloseThinIcon } from '@/components/Icons';
import { BaseButton } from '@/components/UI-kit/Button';
import AutocompleteInput from '@/components/UI-kit/Inputs/AutocompleteInput';
import { useFetchSites } from '@/NewUI/hooks';
import { useAppFilters } from '@/NewUI/hooks/_common';
import { useFetchAssets } from '@/NewUI/hooks/AssetsPreviewPage';
import { ASSETS_TYPE } from '@/types/_common/navigationTabs.enums';

export const HeaderSearchField = () => {
  const [showSearchInput, setShowSearchInput] = useState(false);
  const { setLocalSearch, search, assetsPreview, assetsType } = useAppFilters();
  const inputRef = useRef<HTMLInputElement>(null);
  const { sites } = useFetchSites();
  const { assets } = useFetchAssets();

  const options = assetsPreview
    ? assetsType === ASSETS_TYPE.MOBILE
      ? assets?.map((asset) => asset.asset_ui_name).concat(assets?.map((asset) => asset.asset_name))
      : assets?.map((asset) => asset.asset_ui_name)
    : sites?.map((site) => site.site_name);

  useEffect(() => {
    if (showSearchInput && inputRef.current) {
      inputRef.current.focus(); // Autofocus when `showSearchInput` is true
    }
  }, [showSearchInput]);

  return showSearchInput ? (
    <AutocompleteInput
      ref={inputRef}
      onChange={(value: string) => {
        setLocalSearch(value);
      }}
      options={options}
      placeholder="Search..."
      value={search || ''}
      onClose={() => {
        setShowSearchInput(false);
        setLocalSearch(null);
      }}
      rightButton={{
        element: DMCloseThinIcon,
        onClick: () => {
          setShowSearchInput(false);
          setLocalSearch(null);
        },
      }}
    />
  ) : (
    <div className="flex whitespace-nowrap items-center hover:cursor-pointer">
      <BaseButton
        variant={'ghost'}
        className="text-default items-center h-fit gap-0.5 flex"
        onClick={() => setShowSearchInput(true)}
        leftIcon={
          <div className="scale-150 mt-[-2px]">
            <DMSearchIcon />
          </div>
        }
      >
        <span className="hidden xl:block">Search</span>
      </BaseButton>
    </div>
  );
};
