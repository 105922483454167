import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import React, { ChangeEvent, HTMLInputTypeAttribute, KeyboardEventHandler, ReactNode } from 'react';
import { BaseIconButton } from '../Button';
import { ComponentWithAs, IconProps } from '@chakra-ui/react';
import { cn } from '@/lib/utils';

export type ButtonVariant = 'link' | 'outline' | 'default' | 'destructive' | 'secondary' | 'ghost' | null | undefined;
export type InputVariant = 'default' | 'outline' | 'ghost' | 'underline';

const inputStyles = {
  default: 'px-4 py-2 rounded bg-gray-100 border border-gray-300 text-sm',
  outline: 'px-4 py-2 rounded border-2 border-blue-500 bg-white text-sm',
  underline: 'px-4 py-2 border-b-2 border-t-0 border-x-0 rounded-none focus:border-primary-500',
  ghost: 'px-4 py-2 bg-transparent border-none text-sm',
};

interface BaseInputProps {
  leftButton?: {
    element: ReactNode | ComponentWithAs<'svg', IconProps>;
    onClick?: () => void;
    variant?: ButtonVariant;
  };
  rightButton?: {
    element: ReactNode | ComponentWithAs<'svg', IconProps>;
    onClick?: () => void;
    variant?: ButtonVariant;
  };
  ref?: React.RefObject<HTMLInputElement>;
  variant?: InputVariant;
  placeholder?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  type: HTMLInputTypeAttribute | undefined;
  value: any;
}

const BaseInput = React.forwardRef<HTMLInputElement, BaseInputProps>(
  (
    {
      leftButton,
      rightButton,
      onChange,
      placeholder = 'Enter text here',
      type,
      value,
      variant = 'underline',
      onKeyDown,
      // className,
    },
    ref // Forwarded ref
  ) => {
    return (
      <div className="relative flex items-center">
        {leftButton && (
          <div className="absolute left-1 top-1/2 transform -translate-y-1/2">
            <BaseIconButton
              variant="ghost"
              icon={leftButton?.element}
              onClick={leftButton?.onClick}
              classNames={{ button: 'min-w-8 min-h-8' }}
            />
          </div>
        )}
        <Input
          ref={ref}
          type={type}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={value}
          placeholder={placeholder}
          className={cn(
            inputStyles[variant as InputVariant],
            `focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 ${leftButton ? 'pl-12' : ''} ${
              rightButton ? 'pr-12' : ''
            }`
          )}
        />
        {rightButton && (
          <div className="absolute right-1 top-1/2 t ansform -translate-y-1/2">
            <BaseIconButton
              variant="ghost"
              icon={rightButton?.element}
              onClick={rightButton?.onClick}
              classNames={{ button: 'min-w-8 min-h-8' }}
            />
          </div>
        )}
      </div>
    );
  }
);

BaseInput.displayName = 'BaseInput';
export default BaseInput;
