import React from 'react';
import { MachineFailureRow } from 'src/pages/machines/components/failure-row/MachineFailureRow';
import { VStack, Box } from '@chakra-ui/react';
import { HEALTH_STATUS } from 'src/const';

export const ComponentFailuresList = ({ selectedDiagnosticsFiltered }: { selectedDiagnosticsFiltered: any }) => {
  const importOrder = [
    HEALTH_STATUS.CRITICAL,
    HEALTH_STATUS.ALARM,
    HEALTH_STATUS.MONITOR,
    HEALTH_STATUS.HEALTHY,
    HEALTH_STATUS.NOT_AVAILABLE,
  ];

  const sortByObject = importOrder.reduce((obj: any, item, index) => {
    return {
      ...obj,
      [item]: index,
    };
  }, {});
  selectedDiagnosticsFiltered &&
    selectedDiagnosticsFiltered.sort((a: any, b: any) => sortByObject[a.health_status] - sortByObject[b.health_status]);
  return selectedDiagnosticsFiltered?.length ? (
    <Box position="relative" flex="1" w="full">
      <VStack
        w="full"
        spacing={2}
        p="6px"
        position="absolute"
        maxH="100%"
        overflowY="auto"
        bgColor="#E6E8EC"
        border="2px solid #E6E8EC"
        borderRadius="8px"
      >
        {selectedDiagnosticsFiltered?.map((item: any, index: number) => (
          <MachineFailureRow key={index} item={item} isComponentView />
        ))}
      </VStack>
    </Box>
  ) : null;
};
