import React from 'react';
import { FILTER_PARAMS, ASSET_STUDIO } from '@/types/_common/navigationTabs.enums';
import { HEALTH_STATUS, SENSOR_TYPE, SENSORS_DATA_TYPE } from '@/const';
import { isAfter, parseISO } from 'date-fns';
import { useDispatch } from 'react-redux';
import { setVisualAIActiveItemIndex } from '@/app/slices/assetStudioSlice';
import { useParams } from 'react-router-dom';
import { useQueryNavigation } from '@/NewUI/hooks';

interface navigateEvidenceProps {
  id: string;
  sourceType: string;
  diagnosticDate: string;
  healthStatus: string;
  assetId?: string;
}

export const useNavigateEvidence = ({
  id,
  sourceType,
  diagnosticDate,
  healthStatus,
  assetId,
}: navigateEvidenceProps) => {
  const { navigateWithQuery, pathname, currentParams } = useQueryNavigation();
  const { id: urlAssetId, siteId } = useParams();

  const disaptch = useDispatch();

  //video evidence are avaliable only from 4th of Apr 2024 onwards
  const videoSupportedFrom = parseISO('2024-04-08');
  const isCamera = sourceType?.toLowerCase() === SENSOR_TYPE.Camera;
  const isHealthy = healthStatus?.toLowerCase() === HEALTH_STATUS.HEALTHY;
  const isVideoSupported = isAfter(parseISO(diagnosticDate), videoSupportedFrom);
  const isAssetStudio = currentParams[FILTER_PARAMS.ASSET_STUDIO] && urlAssetId;

  //@TODO: make it generic
  const targetPath = isAssetStudio
    ? assetId !== urlAssetId
      ? `/sites/${siteId}/machines/${assetId}/components`
      : pathname
    : `${assetId}/components`;

  const linkText =
    isVideoSupported && isCamera
      ? 'Visual AI'
      : (isCamera && !isVideoSupported) || (!isCamera && !isHealthy)
      ? 'View'
      : isHealthy && 'Sensor Data';

  const navigateEvidence = () => {
    if (!isAssetStudio) {
      disaptch(setVisualAIActiveItemIndex(0)) &&
        navigateWithQuery(targetPath, {
          replaceParams: {
            [FILTER_PARAMS.SELECTED_COMPONENT]: id,
            [FILTER_PARAMS.ASSET_STUDIO]: ASSET_STUDIO.VISUAL_AI,
          },
        });
    }
    if (isCamera) {
      isVideoSupported &&
        disaptch(setVisualAIActiveItemIndex(0)) &&
        navigateWithQuery(targetPath, {
          replaceParams: {
            [FILTER_PARAMS.SELECTED_COMPONENT]: id,
            [FILTER_PARAMS.ASSET_STUDIO]: ASSET_STUDIO.VISUAL_AI,
          },
        });
    } else {
      if (isHealthy) {
        navigateWithQuery(pathname, {
          replaceParams: {
            [FILTER_PARAMS.SELECTED_COMPONENT]: id,
            [FILTER_PARAMS.ASSET_STUDIO]: ASSET_STUDIO.SENSOR_DATA,
          },
          addParams: {
            group: SENSORS_DATA_TYPE.PI,
          },
        });
      }
    }
  };
  if (!(id && sourceType && diagnosticDate && healthStatus))
    return {
      navigateEvidence: () =>
        console.error('Missing props to create navigation', { id, sourceType, diagnosticDate, healthStatus, assetId }),
      isCamera: false,
      isVideoSupported: false,
      isHealthy: false,
      linkText: '',
    };
  return { navigateEvidence, isCamera, isVideoSupported, isHealthy, linkText };
};
