import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { format, sub } from 'date-fns';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Fade, Hide, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, useToast } from '@chakra-ui/react';
import { Asset, AssetsView } from 'src/types/assets';
import {
  DIAGNOSTICS_FROM_DATE_FORMAT,
  DIAGNOSTICS_TO_DATE_FORMAT,
  INITIAL_FILTERS,
  STATUS_CHANGED,
  TOAST_STATUS,
} from 'src/const';
import { getSiteById } from 'src/app/queries';
import { analytics } from 'src/analytics';
import { useLazyGetAssetsQuery, useLazyGetDiagnosticsQuery } from 'src/app/api/diagnosticApi';
import { setSearchValue, setActiveAssetsCategory, setDisabledAssetsCategories } from 'src/app/slices/applicationSlice';
import { MachinesList } from 'src/pages/machines/components/MachinesList';
import { NoResultsFound } from 'src/pages/machines/components/NoResultsFound';
import { Toolbar } from 'src/components/Toolbar';
import { searchValueSelector, activeAssetsCategory } from 'src/app/store';
import { Header } from 'src/components/Header';
import Fallback from 'src/components/Fallback';
import { Loading } from 'src/components/Loading';
import {
  getAmountByCamera,
  getAmountByStatus,
  getSensorsOverallStatus,
  getTotalAssetsWithStatusChanged,
  getFilterAssetsByStatusAndSensors,
  getAllSensorsCount,
  getCriticalSensorsCount,
  getHealthySensorsCount,
} from './utils';
import { css } from '@emotion/react';
import { SiteBusinessInsightsStatistics } from 'src/pages/machines/business-insights/SiteBusinessInsightsStatistics';
import { AssetsViewDropdown } from 'src/pages/machines/components/AssetsViewDropdown';
import { setCenter } from 'src/app/slices/siteMapSlice';
import { SiteMap } from '@/NewUI/components/AssetsPreviewPage/SiteMap';

const sensorsAmountInitial = {
  all: 0,
  critical: 0,
  healthy: 0,
};

export const MachinesPage = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const { siteId } = useParams();
  const dispatch = useDispatch();
  const { site } = getSiteById(siteId);
  const siteLastVerifiedFixed = site?.assets_categories?.fixed?.latest_diagnostic_date;
  const siteLastVerifiedMobile = site?.assets_categories?.mobile?.latest_diagnostic_date;

  const activeAssetsCategoryId = useSelector(activeAssetsCategory);

  const [selectedFixedStatuses, setSelectedFixedStatuses] = useState<Record<string, boolean>>(INITIAL_FILTERS);
  const [selectedFixedStatusChanged, setSelectedFixedStatusChanged] = useState<Record<string, boolean>>(STATUS_CHANGED);
  const [selectedFixedSensors, setSelectedFixedSensors] = useState<string>('all');

  const [selectedMobileStatuses, setSelectedMobileStatuses] = useState<Record<string, boolean>>(INITIAL_FILTERS);
  const [selectedMobileStatusChanged, setSelectedMobileStatusChanged] =
    useState<Record<string, boolean>>(STATUS_CHANGED);
  const [selectedMobileSensors, setSelectedMobileSensors] = useState<string>('all');

  const [totalFixedAssetsSensors, setTotalFixedAssetsSensors] = useState(sensorsAmountInitial);
  const [totalMobileAssetsSensors, setTotalMobileAssetsSensors] = useState(sensorsAmountInitial);

  const selectedFixedStatusesKeys = Object.keys(selectedFixedStatuses).filter((key) => selectedFixedStatuses[key]);
  const selectedMobileStatusesKeys = Object.keys(selectedMobileStatuses).filter((key) => selectedMobileStatuses[key]);

  const [fixedAssets, setFixedAssets] = useState<any>();
  const [mobileAssets, setMobileAssets] = useState<any>();
  const [fetchAssets, { data: assets, isLoading }] = useLazyGetAssetsQuery();

  const fixedAssetsAmountByStatus = useMemo(() => fixedAssets && getAmountByStatus(fixedAssets), [fixedAssets]);
  const fixedAssetsAmountByCamera = useMemo(() => fixedAssets && getAmountByCamera(fixedAssets), [fixedAssets]);

  const mobileAssetsAmountByStatus = useMemo(() => mobileAssets && getAmountByStatus(mobileAssets), [mobileAssets]);
  const mobileAssetsAmountByCamera = useMemo(() => mobileAssets && getAmountByCamera(mobileAssets), [mobileAssets]);

  const fixedAssetsAmountByStatusChanged = useMemo(
    () => fixedAssets && getTotalAssetsWithStatusChanged(fixedAssets, siteLastVerifiedFixed),
    [fixedAssets]
  );
  const mobileAssetsAmountByStatusChanged = useMemo(
    () => mobileAssets && getTotalAssetsWithStatusChanged(mobileAssets, siteLastVerifiedMobile),
    [mobileAssets]
  );

  const [fetchDiagnostics] = useLazyGetDiagnosticsQuery();
  const searchValue = useSelector(searchValueSelector);
  const [fixedSearchResults, setFixedSearchResults] = useState<any>([]);
  const [mobileSearchResults, setMobileSearchResults] = useState<any>([]);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const [selectedAssetsView, setSelectedAssetsView] = useState<AssetsView>('grid');

  const [defaultCategorySelected, setDefaultCategorySelected] = useState(false);

  useEffect(() => {
    dispatch(setDisabledAssetsCategories({ all: true }));
    return () => {
      dispatch(setDisabledAssetsCategories({ all: false, mobile: false, fixed: false }));
    };
  }, []);

  useEffect(() => {
    if (
      (activeAssetsCategoryId !== 'mobile' && activeTabIndex === 2) ||
      (activeAssetsCategoryId !== 'fixed' && activeTabIndex === 1)
    )
      setActiveTabIndex(0);
  }, [activeAssetsCategoryId]);

  useEffect(() => {
    if (assets && !defaultCategorySelected) {
      setDefaultCategorySelected(true);

      const hasMobileAssets = assets.some((asset) => asset.asset_category === 'mobile');
      const hasFixedAssets = assets.some((asset) => asset.asset_category !== 'mobile');

      if (activeAssetsCategoryId === 'all') {
        if (hasFixedAssets) {
          dispatch(setActiveAssetsCategory('fixed'));
        } else if (hasMobileAssets) {
          dispatch(setActiveAssetsCategory('mobile'));
        } else {
          dispatch(setActiveAssetsCategory(''));
        }
      }

      if (activeAssetsCategoryId === 'fixed' && !hasFixedAssets) {
        if (hasMobileAssets) {
          dispatch(setActiveAssetsCategory('mobile'));
        } else {
          dispatch(setActiveAssetsCategory(''));
        }
      }

      if (activeAssetsCategoryId === 'mobile' && !hasMobileAssets) {
        if (hasFixedAssets) {
          dispatch(setActiveAssetsCategory('fixed'));
        } else {
          dispatch(setActiveAssetsCategory(''));
        }
      }

      if (!hasFixedAssets) {
        dispatch(setDisabledAssetsCategories({ fixed: true }));
      }

      if (!hasMobileAssets) {
        dispatch(setDisabledAssetsCategories({ mobile: true }));
      }
    }
  }, [assets]);

  useEffect(() => {
    if (fixedAssets) {
      setTotalFixedAssetsSensors({
        all: getAllSensorsCount(fixedAssets),
        critical: getCriticalSensorsCount(fixedAssets),
        healthy: getHealthySensorsCount(fixedAssets),
      });
    }
  }, [fixedAssets]);

  useEffect(() => {
    if (mobileAssets) {
      setTotalMobileAssetsSensors({
        all: getAllSensorsCount(mobileAssets),
        critical: getCriticalSensorsCount(mobileAssets),
        healthy: getHealthySensorsCount(mobileAssets),
      });
    }
  }, [mobileAssets]);

  const filteredFixedAssetsByStatusAndSensors = getFilterAssetsByStatusAndSensors(
    fixedAssets,
    selectedFixedStatuses,
    selectedFixedStatusesKeys,
    selectedFixedStatusChanged,
    selectedFixedSensors,
    siteLastVerifiedFixed
  );

  const filteredMobileAssetsByStatusAndSensors = getFilterAssetsByStatusAndSensors(
    mobileAssets,
    selectedMobileStatuses,
    selectedMobileStatusesKeys,
    selectedMobileStatusChanged,
    'all',
    siteLastVerifiedMobile
  );

  const handleSearch = (value: string, assets: any) => {
    const valueInLowerCase = value.toLowerCase();
    const filteredResults = assets
      ? assets.filter(
          (item: any) =>
            item.asset_name.toLowerCase().includes(valueInLowerCase) ||
            item.asset_ui_name.toLowerCase().includes(valueInLowerCase)
        )
      : [];

    return filteredResults;
  };

  useEffect(() => {
    setFixedSearchResults(handleSearch(searchValue, filteredFixedAssetsByStatusAndSensors));
  }, [searchValue, fixedAssets, selectedFixedStatuses, selectedFixedStatusChanged]);

  useEffect(() => {
    setMobileSearchResults(handleSearch(searchValue, filteredMobileAssetsByStatusAndSensors));
  }, [searchValue, mobileAssets, selectedMobileStatuses, selectedMobileStatusChanged]);

  useEffect(() => {
    dispatch(setSearchValue(''));

    analytics.page({
      name: `Machines`,
      title: 'DataMind Dashboard 2.0 - Machines',
      properties: {
        siteId,
      },
    });

    if (siteId) {
      fetchAssets(
        {
          site_id: siteId,
        },
        true
      )
        .unwrap()
        .catch((error) => {
          if (!toast.isActive(siteId)) {
            toast({
              id: siteId,
              status: TOAST_STATUS.Error,
              title: `Failed to load assets for site: ${siteId}`,
              description: t('general.errors.communication'),
            });
          }
          Sentry.captureException(
            error?.data?.message || error?.error || error?.message || error?.originalError || error
          );
        });
    }
  }, [siteId]);

  useEffect(() => {
    if (assets) {
      const assetsWithSensorsOverallStatus = assets.map((asset: Asset) => {
        return {
          ...asset,
          isSensorsOverallStatusCritical: getSensorsOverallStatus(asset.sensors_health_status),
        };
      });

      setFixedAssets(assetsWithSensorsOverallStatus.filter((asset) => asset.asset_category !== 'mobile'));
      setMobileAssets(assetsWithSensorsOverallStatus.filter((asset) => asset.asset_category === 'mobile'));
    }
  }, [assets]);

  useEffect(() => {
    if (assets) {
      for (const asset of assets) {
        if (siteId) {
          const fromDate = asset.show_diagnostics_day
            ? sub(new Date(asset.latest_diagnostic_created_at), { days: asset.show_diagnostics_day })
            : sub(new Date(asset.latest_diagnostic_created_at), { months: 6 });
          const toDate = new Date(asset.latest_diagnostic_created_at);

          fetchDiagnostics(
            {
              site_id: siteId,
              assets_ids: asset.asset_id,
              from_date: format(fromDate, DIAGNOSTICS_FROM_DATE_FORMAT),
              to_date: format(toDate, DIAGNOSTICS_TO_DATE_FORMAT),
              asset_category: asset.asset_category || 'fixed',
            },
            true
          );
        }
      }
    }
  }, [assets]);

  useEffect(() => {
    dispatch(
      setCenter({
        lat: site?.latitude ? +site?.latitude : null,
        lng: site?.longitude ? +site?.longitude : null,
      })
    );
  }, [site?.latitude, site?.longitude]);
  const onTabChange = (index: number) => setActiveTabIndex(index);
  const siteAssetsUINames = assets
    ?.map((asset: any) => asset.asset_ui_name)
    .sort((a: any, b: any) => a.localeCompare(b));

  return (
    <Suspense>
      <Header>
        <ErrorBoundary FallbackComponent={Fallback}>
          {site && (
            <>
              {activeAssetsCategoryId === 'fixed' && (
                <Toolbar
                  siteName={site?.site_name}
                  amountByStatus={fixedAssetsAmountByStatus}
                  selectedStatuses={selectedFixedStatuses}
                  setSelectedStatuses={setSelectedFixedStatuses}
                  totalAssetsSensors={totalFixedAssetsSensors}
                  selectedSensors={selectedFixedSensors}
                  setSelectedSensors={setSelectedFixedSensors}
                  isTopFixed
                  amountByStatusChanged={fixedAssetsAmountByStatusChanged}
                  selectedStatusChanged={selectedFixedStatusChanged}
                  setSelectedStatusChanged={setSelectedFixedStatusChanged}
                  tabIndex={activeTabIndex}
                  amountByCamera={fixedAssetsAmountByCamera}
                />
              )}
              {activeAssetsCategoryId === 'mobile' && (
                <Toolbar
                  siteName={site?.site_name}
                  amountByStatus={mobileAssetsAmountByStatus}
                  selectedStatuses={selectedMobileStatuses}
                  setSelectedStatuses={setSelectedMobileStatuses}
                  totalAssetsSensors={totalMobileAssetsSensors}
                  selectedSensors={selectedMobileSensors}
                  setSelectedSensors={setSelectedMobileSensors}
                  isTopFixed
                  amountByStatusChanged={mobileAssetsAmountByStatusChanged}
                  selectedStatusChanged={selectedMobileStatusChanged}
                  setSelectedStatusChanged={setSelectedMobileStatusChanged}
                  tabIndex={activeTabIndex}
                  amountByCamera={mobileAssetsAmountByCamera}
                  hideSensors
                />
              )}
            </>
          )}
        </ErrorBoundary>
      </Header>

      <Box {...containerProps}>
        {!isLoading && assets ? (
          <Fade in={!isLoading} transition={{ enter: { delay: 0 }, exit: { delay: 0 } }}>
            <Tabs index={activeTabIndex} onChange={onTabChange} variant="unstyled" isLazy>
              <HStack justifyContent="space-between">
                <TabList
                  bgColor="#ffffff"
                  borderRadius="8px"
                  w="auto"
                  border="1px solid #e2e8f0"
                  boxShadow="sm"
                  zIndex={2}
                >
                  <Tab
                    bg="#ffffff"
                    borderTopLeftRadius="8px"
                    borderBottomLeftRadius="8px"
                    borderTopRightRadius="0"
                    borderBottomRightRadius="0"
                    fontWeight={500}
                    _selected={{ color: '#12ffe2', bg: '#021d3d', fontWeight: 600 }}
                    {...tabProps}
                  >
                    Assets
                  </Tab>
                  <Tab
                    isDisabled={activeAssetsCategoryId !== 'fixed'}
                    bg="#ffffff"
                    fontWeight={500}
                    _selected={{ color: '#12ffe2', bg: '#021d3d', fontWeight: 600 }}
                    {...tabProps}
                  >
                    Business Insights
                  </Tab>
                  <Tab
                    isDisabled={activeAssetsCategoryId !== 'mobile'}
                    bg="#ffffff"
                    borderTopRightRadius="8px"
                    borderBottomRightRadius="8px"
                    borderTopLeftRadius="0"
                    borderBottomLeftRadius="0"
                    fontWeight={500}
                    _selected={{ color: '#12ffe2', bg: '#021d3d', fontWeight: 600 }}
                    {...tabProps}
                  >
                    Site Map
                  </Tab>
                </TabList>
                <Hide below="w-1024">
                  {activeTabIndex === 0 && (
                    <AssetsViewDropdown
                      selectedAssetsView={selectedAssetsView}
                      setSelectedAssetsView={setSelectedAssetsView}
                    />
                  )}
                </Hide>
              </HStack>

              <TabPanels h="full" w="full" bgColor="transparent">
                <Fade in={!activeTabIndex} transition={{ enter: { delay: 0 }, exit: { delay: 0 } }} unmountOnExit>
                  <TabPanel css={tabPanelStyle}>
                    <ErrorBoundary FallbackComponent={Fallback}>
                      {activeAssetsCategoryId === 'fixed' && !!fixedSearchResults.length && (
                        <MachinesList assets={fixedSearchResults} site={site} selectedAssetsView={selectedAssetsView} />
                      )}
                      {activeAssetsCategoryId === 'mobile' && !!mobileSearchResults.length && (
                        <MachinesList
                          assets={mobileSearchResults}
                          site={site}
                          selectedAssetsView={selectedAssetsView}
                        />
                      )}
                      {((activeAssetsCategoryId === 'fixed' && !fixedSearchResults.length) ||
                        (activeAssetsCategoryId === 'mobile' && !mobileSearchResults.length)) && <NoResultsFound />}
                    </ErrorBoundary>
                  </TabPanel>
                </Fade>

                <Fade in={!!activeTabIndex} transition={{ enter: { delay: 0 }, exit: { delay: 0 } }} unmountOnExit>
                  <TabPanel css={tabPanelStyle}>
                    <ErrorBoundary FallbackComponent={Fallback}>
                      <SiteBusinessInsightsStatistics siteAssetsUINames={siteAssetsUINames} />
                    </ErrorBoundary>
                  </TabPanel>
                </Fade>

                <Fade in={!!activeTabIndex} transition={{ enter: { delay: 0 }, exit: { delay: 0 } }} unmountOnExit>
                  <TabPanel
                    css={tabPanelStyle}
                    sx={{
                      position: 'absolute',
                      top: '33.5px',
                      left: '72px',
                      height: '100%',
                      width: 'calc(100% - 72px)',
                    }}
                  >
                    <ErrorBoundary FallbackComponent={Fallback}>
                      <SiteMap />
                    </ErrorBoundary>
                  </TabPanel>
                </Fade>
              </TabPanels>
            </Tabs>
          </Fade>
        ) : (
          <Loading />
        )}
      </Box>
    </Suspense>
  );
};

const tabProps = {
  fontSize: {
    base: '0.875rem',
    xl: '0.875rem',
    '1.5xl': '0.875rem',
  },
  px: {
    base: 6,
    xl: 4,
    '1.5xl': 6,
  },
  py: {
    base: 3,
    xl: 3,
    '1.5xl': 3,
  },
};

const containerProps = {
  w: 'full',
  p: { base: 0, xl: 8 },
  pt: { base: '5.5rem', xl: '5.5rem' },
  pr: 4,
};

const tabPanelStyle = css`
  padding-left: 0;
  padding-right: 0;
  padding-top: 1.5rem;
  background-color: transparent;
`;
