import React from 'react';
import { DMNoAssetResults } from '@/components/Icons';

export const SectionEmptyState = () => (
  <div className="flex items-center justify-center min-h-[200px] bg-white flex-col">
    <DMNoAssetResults boxSize={16} />
    <div className="text-sm">No Assets Found</div>
  </div>
);

export const AllSectionEmptyState = () => (
  <div className="flex items-center justify-center min-h-[80vh] flex-col">
    <DMNoAssetResults boxSize={16} />
    <div className="text-sm">No Assets Found</div>
  </div>
);
