import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  VStack,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/app/store';
import { DMArrowDownIcon } from 'src/components/Icons';
import { mobileFleetApi } from 'src/app/api/mobileFleetApi';

export const MobileFleetSensorsFilterPopover: React.FC<{ queryParams: any }> = ({ queryParams }) => {
  const mobileFleetSensorsData = useSelector(
    (state: RootState) => mobileFleetApi.endpoints.getMobileFleetSensors.select(queryParams)(state)?.data
  );
  const [draftMobileFleetSensorsData, setDraftMobileFleetSensorsData] = useState<any>(mobileFleetSensorsData);
  const visibleCount = draftMobileFleetSensorsData?.filter((item: any) => item.visible).length || '...';

  const onApplyClick = () => {
    dispatch(
      mobileFleetApi.util.updateQueryData('getMobileFleetSensors', queryParams, (draft) => {
        draft.forEach((sensor: any, index: number) => {
          sensor.visible = draftMobileFleetSensorsData[index].visible;
        });
      })
    );
  };

  const onCancelClick = () => {
    setDraftMobileFleetSensorsData(mobileFleetSensorsData);
  };

  const onChange = (id: number) => {
    const draft = draftMobileFleetSensorsData?.map((item: any) => {
      if (id === item.id) {
        return {
          ...item,
          visible: !item.visible,
        };
      } else {
        return item;
      }
    });

    setDraftMobileFleetSensorsData(draft);
  };

  useEffect(() => {
    setDraftMobileFleetSensorsData(mobileFleetSensorsData);
  }, [mobileFleetSensorsData]);

  return (
    <Popover>
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              variant="naked"
              size="sm"
              isDisabled={!mobileFleetSensorsData}
              rightIcon={<DMArrowDownIcon />}
              bgColor={isOpen ? 'rgba(112, 113, 243, 1)' : 'none'}
              color={isOpen ? 'white' : 'rgb(26, 32, 44)'}
            >
              <HStack>
                <Box>Trends & Sensors</Box>
                <Box
                  textAlign="center"
                  py={1}
                  px={2}
                  borderRadius="lg"
                  bgColor={isOpen ? 'white' : 'rgb(26, 32, 44)'}
                  color={isOpen ? '#6667DD' : '#12FFE2'}
                >
                  {visibleCount}
                </Box>
              </HStack>
            </Button>
          </PopoverTrigger>

          <PopoverContent borderRadius="2xl">
            <PopoverHeader px={4} py={4} pb={2} border="0">
              <HStack fontSize="0.875rem" fontWeight="600" w="full" justifyContent="space-between">
                <Box>Operational Sensors</Box>
                <Box>{mobileFleetSensorsData?.length}</Box>
              </HStack>
            </PopoverHeader>
            <PopoverBody py={4} px={4}>
              <VStack w="full" alignItems="flex-start" spacing={6}>
                {draftMobileFleetSensorsData?.map((item: any, index: number) => (
                  <Checkbox
                    key={index}
                    isChecked={item.visible}
                    fontWeight="500"
                    colorScheme="DMCheckboxColorScheme"
                    onChange={() => onChange(item.id)}
                  >
                    <Box fontSize="0.875rem">{item.name}</Box>
                  </Checkbox>
                ))}
              </VStack>
            </PopoverBody>

            <PopoverFooter border="0" px={4} py={4} pt={2}>
              <HStack w="full" justifyContent="flex-end" spacing={5}>
                <Button
                  size="sm"
                  variant="unstyled"
                  fontWeight={500}
                  onClick={() => {
                    onCancelClick();
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  colorScheme="DMButtonColorScheme"
                  fontWeight={500}
                  onClick={() => {
                    onApplyClick();
                    onClose();
                  }}
                >
                  Apply
                </Button>
              </HStack>
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
