import React from 'react';
import { AuthError } from 'firebase/auth';
import { NavLink, useNavigate } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { TOAST_STATUS, PASSWORD_PATTERN } from 'src/const';
import { mapAuthCodeToMessage } from 'src/utils';
import { locations } from 'src/app/locations';
import { FormattedText } from 'src/components/FormattedText';
import { useAuth } from 'src/providers/AuthProvider';
import { useDispatch } from 'react-redux';
import { useLazyGetUsersQuery } from 'src/app/api/userApi';
import { analytics } from 'src/analytics';
import { setIsFirebaseRequesting, setMfaExpired } from 'src/app/slices/applicationSlice';
import { useLazyGetStatusQuery } from 'src/app/api/authApi';
import * as Sentry from '@sentry/react';
import { Eye, EyeClosed } from 'lucide-react';

type LoginFormData = {
  email: string;
  password: string;
};

export const LoginForm = () => {
  const [fetchUsers, { isLoading: isLoadingUsers, isFetching: isFetchingUsers }] = useLazyGetUsersQuery();
  const [fetchAuthStatus, { isLoading: isLoadingAuthStatus, isFetching: isFetchingAuthStatus }] =
    useLazyGetStatusQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { signIn } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm<LoginFormData>();
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const onSubmit = async (values: FieldValues) => {
    const { email, password } = values;

    if (isValid) {
      dispatch(setIsFirebaseRequesting(true));
      dispatch(setMfaExpired(false));

      try {
        const { user } = await signIn(email.trim(), password.trim());

        if (user) {
          const isStrongPassword = PASSWORD_PATTERN.test(password.trim());
          localStorage.removeItem('userMustChangePassword');
          localStorage.removeItem('afterMfaNavigateToPasswordResetPage');
          await fetchAuthStatus({})
            .unwrap()
            .then((data) => {
              if (data.run_mfa) {
                if (!isStrongPassword) {
                  localStorage.setItem('afterMfaNavigateToPasswordResetPage', 'true');
                }
                navigate(locations.public.secondFactorMethod);
              } else {
                if (isStrongPassword) {
                  fetchUsers({ uids: user.uid })
                    .unwrap()
                    .then(async (data) => {
                      const userData = data[0];
                      if (userData) {
                        analytics.identify(userData?.email, {
                          name: userData?.displayName,
                          firstName: userData?.first_name,
                          lastName: userData?.last_name,
                          email: userData?.email,
                          id: userData?.user_id,
                          role: userData?.role,
                        });
                      }

                      navigate(locations.protected.sites.index);
                    });
                } else {
                  localStorage.setItem('userMustChangePassword', 'true');
                  navigate(locations.public.change);
                }
              }
            });
        }
      } catch (error: unknown) {
        const authError = error as AuthError;
        Sentry.captureException(authError);

        toast({
          status: TOAST_STATUS.Error,
          title: mapAuthCodeToMessage(authError.code),
        });
      }
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={8}>
          <FormControl variant="floating" isInvalid={!!errors.email}>
            <Input
              type="text"
              variant="data-mind-auth"
              placeholder=" "
              {...register('email', {
                required: 'Email is required',
              })}
            />
            <FormErrorMessage fontWeight="medium">{errors.email && errors.email.message}</FormErrorMessage>
            <FormLabel>
              <FormattedText label="auth.login.form.email.label" />
            </FormLabel>
          </FormControl>

          <FormControl variant="floating" isInvalid={!!errors.password}>
            <InputGroup>
              <Input
                type={show ? 'text' : 'password'}
                variant="data-mind-auth"
                placeholder=" "
                {...register('password', {
                  required: 'Password is required',
                })}
              />
              <InputRightElement width="4.5rem" height={'full'}>
                <Button h="1.75rem" size="sm" onClick={handleClick} bg={'transparent'}>
                  {show ? <Eye /> : <EyeClosed />}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage fontWeight="medium">{errors.password && errors.password.message}</FormErrorMessage>
            <FormLabel>
              <FormattedText label="auth.login.form.password.label" />
            </FormLabel>
          </FormControl>
        </VStack>

        <Flex flexDirection={['column-reverse', 'column-reverse', 'row']} mt={8} justifyContent="space-between">
          <Box w="full" textAlign="center">
            <NavLink to="/reset-password">
              <Button variant="data-mind-naked">
                <FormattedText label="auth.login.form.resetPassword" />
              </Button>
            </NavLink>
          </Box>

          <Box w="full" textAlign="center" mb={[5, 5, 0]}>
            <Button
              w={['80%', '80%', 'full']}
              type="submit"
              variant="data-mind"
              size="lg"
              borderRadius="full"
              isLoading={
                isSubmitting || isLoadingUsers || isLoadingAuthStatus || isFetchingAuthStatus || isFetchingUsers
              }
            >
              <FormattedText label="auth.login.form.submit" />
            </Button>
          </Box>
        </Flex>
      </form>
    </Box>
  );
};
