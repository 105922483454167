import React from 'react';
import { useAppNavigation } from '../Sidebar/useAppNavigation';
import { Breadcrumb } from '@/components/UI-kit/Breadcrumbs/BaseBreadcrumbs';
import { DMIndustryWindowsIcon, DMSettingsIcon } from '@/components/Icons';
import { getAssetById, getAssets, getSiteById } from '@/app/queries';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';

import { Asset } from '@/NewUI/types/_common/asset.types';
import { AssetsDropdown, AssetsSelector } from '@/NewUI/components/Header';

const breadcrumbConfig = {
  sites: {
    id: 'sites',
    label: 'Sites',
    href: '/sites',
    element: (
      <>
        <DMIndustryWindowsIcon boxSize={7} /> sites
      </>
    ),
  },
  site: (siteId: string) => {
    const siteData = getSiteById(siteId);
    return {
      id: 'site',
      label: siteData?.site?.site_name,
      href: `/sites/${siteId}/machines`,
    };
  },
  machine: (machineId: string, siteId: string) => {
    const { assets } = getAssets(siteId);
    const { asset } = getAssetById(siteId, machineId);
    const { asset_ui_name } = asset || {};
    return {
      id: 'site',
      label: asset_ui_name,
      // href: `/sites/${siteId}/machines/${machineId}/components`,
      element: <AssetsDropdown asset={asset as Asset} assets={assets as Asset[]} siteId={siteId} />,
    };
  },
  components: (machineId: string, siteId: string, selectedComponents: any) => {
    const { asset } = getAssetById(siteId, machineId);
    const { asset_ui_name: assetName, asset_supplier, asset_model } = asset || {};
    return {
      id: 'site',
      label: asset_supplier ? `${assetName} - ${asset_supplier} ${asset_model}` : assetName,
      // href: `/sites/${siteId}/machines/${machineId}/components`,
      element: <AssetsSelector selectedComponents={selectedComponents} asset={asset} />,
    };
  },
  settings: {
    id: 'settings',
    label: 'Settings',
    href: '/settings',
    element: (
      <>
        <DMSettingsIcon boxSize={7} /> settings
      </>
    ),
  },
};

function generateBreadcumbs({
  pathname,
  pathNameAsArray,
  searchParams,
  siteId,
  machineId,
  appFilters,
  selectedComponents,
}: {
  pathname: string;
  pathNameAsArray: string[];
  searchParams: URLSearchParams;
  siteId?: string;
  machineId?: string;
  appFilters: any;
  selectedComponents: any;
}) {
  const breadcrumbs: Breadcrumb[] = [];
  if (pathNameAsArray.includes('sites')) breadcrumbs[0] = breadcrumbConfig.sites;
  if (pathNameAsArray.includes('settings')) breadcrumbs[0] = breadcrumbConfig.settings;

  if (siteId) {
    breadcrumbs[1] = breadcrumbConfig.site(siteId);
  }
  if (siteId && machineId) {
    breadcrumbs[2] = breadcrumbConfig.machine(machineId, siteId);
  }
  // if (siteId && machineId && selectedComponents) {
  //   breadcrumbs[3] = breadcrumbConfig.components(machineId, siteId, selectedComponents);
  // }

  return breadcrumbs;
}

export const useAppBreadcrumbs = () => {
  const { pathname, pathNameAsArray, searchParams, siteId, machineId, appFilters } = useAppNavigation();
  const { selectedComponents } = useSelector((state: RootState) => state.assetsStudio);
  return {
    breadcrumbs: generateBreadcumbs({
      pathname,
      pathNameAsArray,
      searchParams,
      siteId,
      machineId,
      appFilters,
      selectedComponents,
    }),
  };
};
