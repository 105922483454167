import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';

interface BaseAvatarProps {
  classNames?: { avatar?: string; image?: string; fallback?: string };
  imageSrc?: string;
  initials: string;
}

export const BaseAvatar = ({
  classNames,
  imageSrc = 'https://github.com/shadcn.png',
  initials = 'DM',
}: BaseAvatarProps) => {
  return (
    <Avatar className={classNames?.avatar}>
      <AvatarImage className={classNames?.image} src={imageSrc} alt="user avatar" />
      <AvatarFallback className={classNames?.fallback}>{initials}</AvatarFallback>
    </Avatar>
  );
};
