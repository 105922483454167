import React from 'react';
import { AccordionButton, AccordionIcon, Box, HStack } from '@chakra-ui/react';

export const SchemesMenuItemHead = (props: { statusColor: string; statusLabel: string; label: string }) => {
  const { statusColor, statusLabel, label } = props;

  return (
    <AccordionButton bg="#F0F2F7" py={2.5} sx={{ _hover: { bg: '#F0F2F7' } }}>
      <HStack justifyContent="space-between" w="full">
        <HStack>
          <Box fontWeight="600" fontSize="0.875rem" textAlign="left">
            {label}
          </Box>
          <Box color={`health.${statusColor}`} fontWeight="600" fontSize="0.75rem" textTransform="capitalize">
            {statusLabel === 'na' ? 'NA' : statusLabel}
          </Box>
        </HStack>

        <AccordionIcon />
      </HStack>
    </AccordionButton>
  );
};
