import { useLazyGetSitesQuery } from '@/app/api/diagnosticApi';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useToast } from '@/NewUI/hooks';

export const useFetchSites = () => {
  const [fetchSites, { isLoading, data: sites, isError }] = useLazyGetSitesQuery();
  const { toast } = useToast();
  useEffect(() => {
    fetchSites({}, true)
      .unwrap()
      .catch((error) => {
        toast({
          variant: 'destructive',
          title: t('general.errors.title'),
          description: t('general.errors.communication'),
        });
        // Sentry.captureException(
        //   error?.data?.message || error?.error || error?.message || error?.originalError || error
        // );
      });
  }, []);

  return { sites, isLoading, isError };
};
