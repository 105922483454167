import { Card } from '@/components/ui/card';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel';
import { flexRender, Table } from '@tanstack/react-table';
import React from 'react';

interface BaseTableCarouselProps<T> {
  table: Table<T>;
  rowClickHandler?: (row: any) => void;
}

export function BaseTableMobileCarousel<T>({ table, rowClickHandler }: BaseTableCarouselProps<T>) {
  return (
    <Carousel className="w-full lg:hidden">
      <CarouselContent>
        {table.getRowModel().rows.map((row, index) => (
          <CarouselItem key={index} className="relative">
            {table.getRowModel().rows.length > 1 && (
              <div className="w-full relative z-50">
                <CarouselPrevious
                  className="left-6 top-12 h-10 w-10 hover:text-white hover:bg-primary-500"
                  variant={'ghost'}
                />
                <CarouselNext
                  className="right-6 top-12  h-10 w-10 hover:text-white hover:bg-primary-500"
                  variant={'ghost'}
                />
              </div>
            )}
            <Card
              key={row.id}
              className=" flex flex-col gap-6 p-2 mb-4 hover:cursor-pointer shadow-inner bg-gray-200 lg:hidden"
              onClick={() => rowClickHandler && rowClickHandler(row.original)}
            >
              <div className=" rounded-md p-4  bg-white">
                {row.getVisibleCells().map((cell: any, index) => {
                  if (index === 0)
                    return (
                      <div className="w-full text-center font-bold pb-1 text-lg " key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </div>
                    );
                  else
                    return (
                      <div
                        key={cell.id}
                        className={`flex justify-between py-1.5 items-center ${
                          table.getRowModel().rows.length - 1 > index ? '' : 'border-b-[1.5px]'
                        }`}
                      >
                        <span className="font-medium">
                          {flexRender(cell.column.columnDef.header, cell.getContext())}
                        </span>
                        <span>{flexRender(cell.column.columnDef.cell, cell.getContext())}</span>
                      </div>
                    );
                })}
              </div>
            </Card>
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  );
}
