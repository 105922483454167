import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Avatar, Box, HStack, Spinner, Tag, useToast } from '@chakra-ui/react';
import { locations } from 'src/app/locations';
import { UserRowMenu } from 'src/pages/settings/pages/user-management/components/UserRowMenu';
import { useDeleteUserMutation } from 'src/app/api/userApi';
import { useTranslation } from 'react-i18next';
import { TOAST_STATUS, userRoleLabel } from 'src/const';
import { colors } from 'src/theme/foundations/colors';

interface UserRowProps {
  user?: any;
}

export const UserRow = ({ user }: UserRowProps) => {
  const navigate = useNavigate();
  const { user_id: userId } = user;
  const [deleteUser, { isLoading }] = useDeleteUserMutation();
  const toast = useToast();
  const { t } = useTranslation();

  const onEditMenuItemClick = () => navigate(generatePath(locations.protected.settings.user, { userId }));

  const onDeleteMenuItemClick = async () => {
    try {
      await deleteUser(userId);
      toast({
        status: TOAST_STATUS.Success,
        title: 'User successfully deleted',
      });
    } catch (e) {
      toast({
        status: TOAST_STATUS.Error,
        title: `Failed to delete user`,
        description: t('general.errors.communication'),
      });
    }
  };

  return (
    <HStack
      boxShadow="sm"
      bgColor="white"
      borderRadius="md"
      w="full"
      alignItems="center"
      justifyContent="space-between"
      cursor="pointer"
      _hover={{
        boxShadow: 'md',
      }}
      fontSize="0.875rem"
    >
      <HStack w="20%" spacing={4} paddingLeft="1.5rem" py="1.25rem">
        <Avatar size="sm" name="John Doe" src={'data:image/png;base64,' + user.photo} zIndex={1} />
        <Box fontSize="0.875rem" fontWeight={700}>
          {user?.first_name} {user?.last_name}
        </Box>
      </HStack>

      <Box w="20%" py="1.25rem">
        {user.email}
      </Box>

      <Box w="20%" py="1.25rem">
        {user.phone_number}
      </Box>

      <Box w="20%" py="1.25rem">
        <Tag size="lg" fontSize="0.875rem" css={{ textTransform: 'capitalize', background: colors.DMTagBackground }}>
          {userRoleLabel[user.role]}
        </Tag>
      </Box>

      <HStack w="20%" justifyContent="end">
        {isLoading ? (
          <Spinner mr="1.35rem" />
        ) : (
          <UserRowMenu onEditMenuItemClick={onEditMenuItemClick} onDeleteMenuItemClick={onDeleteMenuItemClick} />
        )}
      </HStack>
    </HStack>
  );
};
