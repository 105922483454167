import { Badge } from '@/components/ui/badge';
import React, { useEffect, useState } from 'react';
import { Input } from '@/components/ui/input';
import BaseInput from '../Inputs/BaseInput';
import { DMCloseThinIcon, DMSearchIcon } from '@/components/Icons';
import { setSearchValue } from '@/app/slices/applicationSlice';

interface SearchByHeaderPanelProps {
  headerNames: string[];
  searchAnnotation: string;
  suggestions: string[];
  handleSuggestionClick: (suggestion: string) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSearchAnnotation: (value: string) => void;
  handleClearInput: () => void;
  searchInputRef: React.RefObject<HTMLInputElement>;
  columns: any;
}

export const SearchByHeaderPanel = ({
  headerNames,
  handleSuggestionClick,
  searchInputRef,
  searchAnnotation,
  handleInputChange,
  handleClearInput,
  suggestions,
  columns,
}: SearchByHeaderPanelProps) => {
  const [selectedBadge, setSelectedBadge] = useState<string | null>(null);

  useEffect(() => {
    if (searchAnnotation)
      setSelectedBadge(columns.find((el: any) => searchAnnotation.startsWith(el.accessorKey + ':'))?.header);
  }, [searchAnnotation]);

  return (
    <div className="space-y-4 pb-1">
      <div className="flex flex-wrap mt-2 gap-2">
        Search By:
        {headerNames.map((field) => (
          <Badge
            key={field}
            variant="secondary"
            className={`cursor-pointer hover:bg-primary-500 hover:text-white flex ${
              selectedBadge === field ? 'bg-primary-500 text-white' : ''
            }`}
            onClick={() => {
              handleSuggestionClick(field);
            }}
          >
            {field}
          </Badge>
        ))}
      </div>
      <div className="relative bg-white rounded-lg">
        <BaseInput
          ref={searchInputRef}
          placeholder="Search (e.g., firstName:John or globally)"
          value={searchAnnotation}
          onChange={handleInputChange}
          // className="w-full focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 "
          aria-label="Search input"
          type={'text'}
          rightButton={{
            element: searchAnnotation && searchAnnotation?.length > 0 ? DMCloseThinIcon : DMSearchIcon,
            onClick: () => handleClearInput(),
          }}
        />
        {suggestions.length > 0 && (
          <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg">
            {suggestions.map((suggestion, index) => (
              <div
                key={index}
                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                onClick={() => handleSuggestionClick(suggestion)}
                role="option"
                aria-selected="false"
              >
                {suggestion}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
