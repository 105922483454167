import { Tooltip, Text } from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';

export const CustomTooltip = ({ label, children, textStyles, placement, ...rest }: any) => {
  const ref = useRef<HTMLParagraphElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);

  const compareSize = () => {
    const node = ref.current!;
    setIsOverflown(node.scrollWidth > node.clientWidth);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);

    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, []);

  return (
    <Tooltip label={label} placement={placement} isDisabled={!isOverflown} {...rest}>
      <Text sx={textStyles} ref={ref} isTruncated>
        {children}
      </Text>
    </Tooltip>
  );
};