import { Card } from '@/components/ui/card';
import { flexRender, Table } from '@tanstack/react-table';
import React from 'react';

interface BaseTableCarouselProps<T> {
  table: Table<T>;
  rowClickHandler?: (row: any) => void;
}

export function BaseTableMobileCard<T>({ table, rowClickHandler }: BaseTableCarouselProps<T>) {
  return (
    <>
      {table.getRowModel().rows.map((row, index) => (
        <Card
          key={row.id}
          className=" flex flex-col gap-6 p-2 mb-4 hover:cursor-pointer shadow-inner bg-gray-200 lg:hidden overflow-auto max-h-screen"
          onClick={() => rowClickHandler && rowClickHandler(row.original)}
        >
          <div className=" rounded-md p-4 bg-white">
            {row.getVisibleCells().map((cell: any, index) => {
              if (index === 0)
                return (
                  <div className="w-full text-center font-bold pb-2 text-lg ">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                );
              else
                return (
                  <div key={cell.id} className="flex justify-between py-2 border-b-2 items-center">
                    <span className="font-medium">{flexRender(cell.column.columnDef.header, cell.getContext())}</span>
                    <span>{flexRender(cell.column.columnDef.cell, cell.getContext())}</span>
                  </div>
                );
            })}
          </div>
        </Card>
      ))}
    </>
  );
}
