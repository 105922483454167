import { REGULAR_DATE_FORMAT } from '@/const';
import { format } from 'date-fns';
import React from 'react';

interface AssetLastVerifiedProps {
  lastVerified: string;
}

export const AssetLastVerified = ({ lastVerified }: AssetLastVerifiedProps) => {
  return (
    <span className="text-xs font-medium text-[#55687D]">
      Last verified: {format(new Date(lastVerified), REGULAR_DATE_FORMAT)}
    </span>
  );
};
