import React from 'react';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';

export interface PieChartIndexProps {
  chartIndexData: {
    data: { label: string; value: number; color: string }[];
    metaData?: { label: string; value: number }[];
  };
  classNames?: { wrapper?: string };
}

export const PieChartBottomIndex = ({ chartIndexData, classNames }: PieChartIndexProps) => {
  const { data } = chartIndexData;
  return (
    <div className={cn('flex flex-col h-full w-full lg:p-2 pr-6 lg:px-4', classNames?.wrapper)}>
      {data.map((el, index) => (
        <React.Fragment key={el.label}>
          <div className="flex w-full justify-between sm:h-9 h-8 p-2 items-center">
            <div className="text-xs capitalize font-medium">{el.label}</div>
            <div className={`${el.color} font-semibold text-md`}>{el.value}</div>
          </div>
          {index !== data.length - 1 && <Separator className="bg-gray-300" />}
        </React.Fragment>
      ))}
    </div>
  );
};
