import React, { useEffect } from 'react';
import { IconButton, Input, InputGroup, InputGroupProps, InputRightElement } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DMCloseIcon, DMSearchIcon } from 'src/components/Icons';
import { setSearchValue } from 'src/app/slices/applicationSlice';
import { searchValueSelector } from 'src/app/store';

interface ToolbarSearchProps extends InputGroupProps {
  isTopFixed?: boolean;
  sticky?: boolean;
  bgColor?: string;
  onSearchClose?: () => void;
}

export const ToolbarSearch = ({ isTopFixed, sticky, bgColor, onSearchClose, ...rest }: ToolbarSearchProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const searchValue = useSelector(searchValueSelector);

  useEffect(() => {
    return () => {
      dispatch(setSearchValue(''));
    };
  }, []);

  const onChange = (e: any) => {
    const value = e.target.value;
    dispatch(setSearchValue(value));
  };

  const handleSearchClose = () => {
    if (onSearchClose) {
      dispatch(setSearchValue(''));
      onSearchClose();
    }
  };

  return (
    <InputGroup size="sm" variant="data-mind-search-toolbar" w={{ base: 'full', xl: '20rem' }} mr={4} {...rest}>
      <Input
        type="text"
        onChange={onChange}
        fontSize={{ base: '1rem', xl: '0.75rem' }}
        borderBottom={searchValue ? '2px solid #6667DD' : '1px solid #B1B9C3'}
        placeholder={t('general.toolbarSearch.placeholder').toString()}
        _placeholder={{ color: '#55687D', fontWeight: 500 }}
      />
      {onSearchClose && searchValue ? (
        <InputRightElement mr="8px">
          <IconButton
            variant="naked"
            aria-label="search close"
            icon={<DMCloseIcon fontSize= "20px" />}
            onClick={handleSearchClose}
          />
        </InputRightElement>
      ) : null}

      {!searchValue && (
        <InputRightElement
          pointerEvents="none"
          mr="8px"
          children={
            <DMSearchIcon fontSize={{ base: '24px' }} color={{ base: '#01152B' }} />
          }
        />
      )}
    </InputGroup>
  );
};
