import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { diagnosticApi } from 'src/app/api/diagnosticApi';
import { userApi } from 'src/app/api/userApi';
import { notificationApi } from 'src/app/api/notificationApi';
import { sensorsApi } from 'src/app/api/sensorsApi';
import { statisticsApi } from 'src/app/api/statisticsApi';
import { appSlice } from 'src/app/slices/applicationSlice';
import { authApi } from 'src/app/api/authApi';
import { businessInsightsApi } from './api/businessInsightsApi';
import { maintenanceApi } from 'src/app/api/maintenanceApi';
import { AssetsPreviewSliceReducer } from './slices/assetsPreviewSlice/assetsPreviewSlice';
import { siteMapSliceReducer } from './slices/siteMapSlice';
import { mapApi } from './api/mapApi';
import { reportsApi } from './api/reportsApi';
import { AssetsStudioSliceReducer } from './slices/assetStudioSlice';
import { mobileFleetSensorDataSlice } from 'src/features/mobile-fleet-sensor-data/store/mobileFleetSensorData.slice';
import { mobileFleetApi } from 'src/app/api/mobileFleetApi';

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer: {
      [diagnosticApi.reducerPath]: diagnosticApi.reducer,
      [userApi.reducerPath]: userApi.reducer,
      [notificationApi.reducerPath]: notificationApi.reducer,
      [sensorsApi.reducerPath]: sensorsApi.reducer,
      [statisticsApi.reducerPath]: statisticsApi.reducer,
      [authApi.reducerPath]: authApi.reducer,
      [businessInsightsApi.reducerPath]: businessInsightsApi.reducer,
      [maintenanceApi.reducerPath]: maintenanceApi.reducer,
      [mapApi.reducerPath]: mapApi.reducer,
      [reportsApi.reducerPath]: reportsApi.reducer,
      [mobileFleetApi.reducerPath]: mobileFleetApi.reducer,
      siteMap: siteMapSliceReducer,
      application: appSlice.reducer,
      assetsPreview: AssetsPreviewSliceReducer,
      assetsStudio: AssetsStudioSliceReducer,
      mobileFleetSensorData: mobileFleetSensorDataSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        diagnosticApi.middleware,
        userApi.middleware,
        notificationApi.middleware,
        sensorsApi.middleware,
        statisticsApi.middleware,
        authApi.middleware,
        mapApi.middleware,
        businessInsightsApi.middleware,
        maintenanceApi.middleware,
        reportsApi.middleware,
        mobileFleetApi.middleware
      ),
    ...options,
  });

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const store = createStore();
export const rootState = store.getState();
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const dispatch = store.dispatch;

export const firebaseUserRequestingSelector = (state: RootState) => state.application.isFirebaseRequesting;
export const searchValueSelector = (state: RootState) => state.application.searchValue;
export const notificationsFeatureFlagSelector = (state: RootState) => state.application.notificationsFeatureFlag;
export const mfaExpiredSelector = (state: RootState) => state.application.isMfaExpired;
export const maintenanceChartVisible = (state: RootState) => state.application.isMaintenanceChartVisible;
export const activeAssetsCategory = (state: RootState) => state.application.activeAssetsCategory;
export const disabledAssetsCategories = (state: RootState) => state.application.disabledAssetsCategories;
export const fullPullModeActivatedSelector = (state: RootState) => state.application.isFullPullModeActivated;
export const mobileFleetSchemeSensorsCurrentSelectionSelector = (state: RootState) =>
  state.application.mobileFleetSchemeSensorsCurrentSelection;
export const mobileFleetSchemeSensorsPinSelectionSelector = (state: RootState) =>
  state.application.mobileFleetSchemeSensorsPinSelection;
export const mobileFleetSensorFusionSelectionSelector = (state: RootState) =>
  state.application.mobileFleetSensorFusionSelection;
export const mobileFleetSensorTracesSelector = (state: RootState) => state.application.mobileFleetSensorTraces;
export const dateRangePickerSelector = (state: RootState) => state.application.dateRangePickerDate;
export const schemesMenuActivatedSelector = (state: RootState) => state.application.isSchemesMenuActivated;
export const updatedFailuresFeedbacksSelector = (state: RootState) => state.application.updatedFailuresFeedbacks;
