import { BaseTooltip } from '@/components/UI-kit';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import React from 'react';

interface FaultReasonsProps {
  reasons?: string[];
  limit?: number;
  showReasons?: boolean;
  counterOnly?: boolean;
  size?: 'sm' | 'md';
}
//@TODO: Move to upper _common
export const FaultReasons = ({
  reasons = [],
  limit = 1,
  showReasons = true,
  counterOnly = false,
  size = 'md',
}: FaultReasonsProps) => {
  if (!(reasons?.length > 0 && showReasons)) return null;
  const hidden = reasons.slice(counterOnly ? 0 : limit);
  return (
    <div className="flex gap-1 items-center h-2 pb-0.5">
      {reasons && reasons.length > hidden.length && (
        <Badge className={cn('bg-neutral-50 items-center capitalized ', `${size === 'sm' ? 'text-[10px]' : ''}`)}>
          {reasons[0]}
        </Badge>
      )}
      {hidden && hidden.length > 0 && (
        <BaseTooltip
          content={hidden.map((el, index) => (
            <span key={el} className="capitalized">{`${el} ${index < hidden.length - 1 ? ', ' : ''}`}</span>
          ))}
        >
          <Badge
            className={cn(
              'bg-neutral-50 items-center capitalized flex ',
              `${size === 'sm' ? 'py-[0.0625rem] mt-0.5' : 'pt-1'}`
            )}
          >
            +{hidden.length}
          </Badge>
        </BaseTooltip>
      )}
    </div>
  );
};
