import React, { useState, useEffect, useRef, ReactNode } from 'react';
import { useReactTable, getCoreRowModel, ColumnDef, flexRender, Row } from '@tanstack/react-table';
import { BaseCard } from '@/components/UI-kit/Cards/BaseCard';
import { useMousedownScroll } from '@/NewUI/hooks/_common/useMousedownScroll';

// Utility to scroll to a column
const scrollToColumn = (columnId: string, columnRefs: { [key: string]: HTMLTableCellElement | null }) => {
  const columnElement = columnRefs[columnId];
  if (columnElement) {
    columnElement.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center', // Adjusts the scroll to align the column
    });
  }
};

const tableRowStyle = 'group hover:bg-gray-100 hover:cursor-pointer whitespace-nowrap max-w-screen truncate';
const tableCellStyle = `text-sm font-medium  first-of-type:text-left bg-white  group-hover:bg-inherit first-of-type:w-[250px] max-w-fit min-w-max border-[1px] whitespace-nowrap overflow-hidden text-ellipsis min-w-[200px] p-0`;

interface CollapsableTableProps<T, N> {
  mainData: any[];
  renderNestedRowCell: (row: Row<any>, column: any, index: number) => ReactNode;
  mainColumns: ColumnDef<T, any>[];
  expandedRows: Set<string>;
  setExpandedRows: React.Dispatch<React.SetStateAction<Set<string>>>;
  nestedDataKey: string;
  scrollToColumnId?: string; // Optional prop to scroll to a specific column
}

export const CollapsableTable = <T, N>({
  mainData,
  renderNestedRowCell,
  mainColumns,
  setExpandedRows,
  expandedRows,
  nestedDataKey,
  scrollToColumnId,
}: CollapsableTableProps<T, N>) => {
  const [columnWidths, setColumnWidths] = useState<{ [key: string]: number }>({});
  const headerRefs = useRef<{ [key: string]: HTMLTableCellElement | null }>({});
  const cellRefs = useRef<{ [key: string]: HTMLTableCellElement | null }>({});
  const { scrollContainerRef, handleMouseDown } = useMousedownScroll();

  const mainTable = useReactTable({
    data: mainData,
    columns: mainColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  // Calculate column widths after initial render (to match tables columns width)
  useEffect(() => {
    const calculateWidths = () => {
      const newWidths: { [key: string]: number } = {};

      mainTable.getHeaderGroups()[0].headers.forEach((header) => {
        if (header.column.id !== 'expander') {
          const headerWidth = headerRefs.current[header.id]?.offsetWidth || 0;
          let maxWidth = headerWidth;

          mainTable.getRowModel().rows.forEach((row) => {
            const cell = row.getVisibleCells().find((cell) => cell.column.id === header.column.id);
            if (cell) {
              const cellWidth = cellRefs.current[cell.id]?.offsetWidth || 0;
              maxWidth = Math.max(maxWidth, cellWidth);
            }
          });

          newWidths[header.id] = maxWidth;
        }
      });

      setColumnWidths(newWidths);
    };

    calculateWidths();
  }, [mainData, mainColumns]);

  useEffect(() => {
    if (scrollToColumnId) {
      scrollToColumn(scrollToColumnId, headerRefs.current); // Scroll to column if the prop is set
    }
  }, [scrollToColumnId]); // Trigger scrolling whenever this prop changes

  return (
    <BaseCard
      variant="wrapper"
      classNames={{
        card: 'rounded-lg min-h-[calc(100vh-18rem)] overflow-hidden px-3 py-0 min-w-[calc(100vw-29.5rem)]',
        content:
          'min-h-[calc(100vh-18rem)] max-h-[calc(100vh-20)] max-w-100 overflow-x-auto overflow-y-hidden p-0 pb-2 bg-gray-200 ',
      }}
    >
      <div ref={scrollContainerRef} className="overflow-auto max-h-[calc(100vh-18.5rem)]" onMouseDown={handleMouseDown}>
        <table className="table-auto w-full border-collapse border-spacing-y-0.5 select-none ml-[-1px]">
          {/* Header */}
          <thead className="min-w-fit sticky top-0 z-[50] bg-gray-200 ">
            <tr>
              {mainTable.getHeaderGroups()[0].headers.map((header, index) =>
                header.column.id !== 'expander' && !header.isPlaceholder ? (
                  <th
                    key={header.id}
                    ref={(el) => (headerRefs.current[header.id] = el)} // Store refs for scrolling
                    className={`py-4 text-left text-sm font-medium ${
                      index === 0 ? 'sticky left-0  z-10 bg-gray-200 ' : ''
                    }`}
                    style={{ width: columnWidths[header.id] ? `${columnWidths[header.id]}px` : 'auto' }}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ) : null
              )}
            </tr>
          </thead>
          <tbody className="ml-[-1px]">
            {mainTable.getRowModel().rows.map((row, rowIndex) => (
              <React.Fragment key={row.id}>
                {/* Trigger Row */}
                <tr>
                  <td className="py-2 text-sm text-gray-700 h-6 sticky left-0 z-10 border-none font-semibold bg-gray-200">
                    {flexRender(row.getVisibleCells()[1].column.columnDef.cell, row.getVisibleCells()[1].getContext())}
                  </td>
                  <td
                    colSpan={mainTable.getHeaderGroups()[0].headers.length - 2}
                    className="text-sm text-gray-700 h-6 bg-gray-200 "
                  >
                    <div
                      className="flex items-center justify-between hover:cursor-pointer bg-white rounded-r-xl h-12 px-4 border-none "
                      onClick={() =>
                        setExpandedRows((prev) => {
                          const newSet = new Set(prev);
                          newSet.has(row.original.id) ? newSet.delete(row.original.id) : newSet.add(row.original.id);
                          return newSet;
                        })
                      }
                    >
                      <div className="flex items-center justify-start w-full h-full gap-2 px-2 "></div>
                    </div>
                  </td>
                </tr>
                {/* Expanded Rows */}
                {expandedRows.has(row.original.id) &&
                  row.original?.[nestedDataKey].map((nestedRow: any, index: number) => (
                    <tr key={nestedRow.id}>
                      {mainColumns
                        .filter((col) => col.id !== 'expander')
                        .map((column: any, colunIndex: number) => {
                          return (
                            <td
                              key={`${nestedRow.id}-${column.accessorKey}`}
                              ref={(el) => (cellRefs.current[column.id] = el)}
                              className={`${tableCellStyle}  capitalize ${
                                colunIndex === 0 ? 'sticky left-0 z-10 border-0 bg-gray-400' : ''
                              }`}
                              style={{ width: columnWidths[column.id] ? `${columnWidths[column.id]}px` : 'auto' }}
                            >
                              {renderNestedRowCell(nestedRow, column, colunIndex)}
                            </td>
                          );
                        })}
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </BaseCard>
  );
};
