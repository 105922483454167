import React from 'react';
import { Button, HStack, Tooltip } from '@chakra-ui/react';
import {
  DMArrowsIcon,
  DMCameraIcon,
  DMHouseBlankIcon,
  DMMinusCircleBlankIcon,
  DMPlusCircleBlankIcon,
  DMSquareDashedIcon,
} from 'src/components/Icons';

enum TOOLBAR_ACTIONS {
  Download = 'Download plot as a png',
  Zoom = 'Zoom',
  Pan = 'Pan',
  ZoomIn = 'Zoom in',
  ZoomOut = 'Zoom out',
  Reset = 'Reset axes',
}
interface MultipleSubplotsToolbarProps {
  plotlyContainerClassName: string;
}
export const MultipleSubplotsToolbar: React.FC<MultipleSubplotsToolbarProps> = ({ plotlyContainerClassName }) => {
  const triggerToolbarAction = (actionTitle: string) => {
    const toolbarButton = document.querySelector(
      `.${plotlyContainerClassName} .modebar-btn[data-title="${actionTitle}"]`
    ) as HTMLElement | null;

    if (toolbarButton) {
      toolbarButton.click();
    }
  };

  const triggerDownload = () => triggerToolbarAction(TOOLBAR_ACTIONS.Download);
  const triggerZoom = () => triggerToolbarAction(TOOLBAR_ACTIONS.Zoom);
  const triggerPan = () => triggerToolbarAction(TOOLBAR_ACTIONS.Pan);
  const triggerZoomIn = () => triggerToolbarAction(TOOLBAR_ACTIONS.ZoomIn);
  const triggerZoomOut = () => triggerToolbarAction(TOOLBAR_ACTIONS.ZoomOut);
  const triggerReset = () => triggerToolbarAction(TOOLBAR_ACTIONS.Reset);

  return (
    <HStack spacing={1} justifyContent="right">
      <Tooltip label={TOOLBAR_ACTIONS.Download} borderRadius="md">
        <Button variant="unstyled" onClick={triggerDownload}>
          <DMCameraIcon fontSize="2xl" />
        </Button>
      </Tooltip>

      <Tooltip label={TOOLBAR_ACTIONS.Zoom} borderRadius="md">
        <Button variant="unstyled" onClick={triggerZoom}>
          <DMSquareDashedIcon fontSize="2xl" />
        </Button>
      </Tooltip>

      <Tooltip label={TOOLBAR_ACTIONS.Pan} borderRadius="md">
        <Button variant="unstyled" onClick={triggerPan}>
          <DMArrowsIcon fontSize="2xl" />
        </Button>
      </Tooltip>

      <Tooltip label={TOOLBAR_ACTIONS.ZoomIn} borderRadius="md">
        <Button variant="unstyled" onClick={triggerZoomIn}>
          <DMPlusCircleBlankIcon fontSize="2xl" />
        </Button>
      </Tooltip>

      <Tooltip label={TOOLBAR_ACTIONS.ZoomOut} borderRadius="md">
        <Button variant="unstyled" onClick={triggerZoomOut}>
          <DMMinusCircleBlankIcon fontSize="2xl" />
        </Button>
      </Tooltip>

      <Tooltip label={TOOLBAR_ACTIONS.Reset} borderRadius="md">
        <Button variant="unstyled" onClick={triggerReset}>
          <DMHouseBlankIcon fontSize="2xl" />
        </Button>
      </Tooltip>
    </HStack>
  );
};
