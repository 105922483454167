import { useSticky } from 'src/hooks/useSticky';
import { Box, HStack } from '@chakra-ui/react';
import { css } from '@emotion/react';
import React from 'react';

export const UserManagementHeader = () => {
  const [sticky] = useSticky();

  const style = {
    ...userManagementHeaderBaseStyle,
    ...(sticky ? userManagementHeaderStickyStyle : null),
  };

  return (
    <HStack alignItems="flex-start" justifyContent="space-between" textTransform="uppercase" css={style} zIndex={2}>
      <HStack spacing={4} alignItems="flex-start" textAlign="left" w="100%" fontWeight="bold">
        <Box w="20%" pl={2}>
          Full name
        </Box>
        <Box w="20%">Email</Box>
        <Box w="20%">Phone</Box>
        <Box w="20%">Role</Box>
        <Box w="20%"></Box>
      </HStack>
    </HStack>
  );
};

const userManagementHeaderBaseStyle = css`
  width: 100%;
  font-size: 0.625rem;
  font-weight: 600;
  padding: 1rem;
  background-color: #f0f1f2;
  position: sticky;
  top: 0;
  border-top: 10px;
`;

const userManagementHeaderStickyStyle = css`
  position: fixed;
  top: 3.5rem;
  z-index: 1;
  margin-left: 1.5rem;
  margin-right: 1rem;
  width: calc(100% - 24.8rem);
  font-size: 0.625rem;
  font-weight: 600;
  padding: 0.5rem 4rem;
  background-color: #eaebee;
`;
