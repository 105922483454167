import React, { useMemo, useState } from 'react';
import { Separator } from '@/components/ui/separator';
import { HeaderSearchField } from '../../components/Header/HeaderSearchField';
import { DMHamburgerIcon } from '@/components/Icons';
import MobileSidebar from '../Sidebar/MobileSidebar';
import { useAppBreadcrumbs } from '@/NewUI/hooks/Header/useAppBreadcrumbs';
import { useViewportWidth } from '@/NewUI/hooks/_common';
import { sitesPageTabs } from '@/NewUI/pages/SitesPage';
import { useAppFilters } from '@/NewUI/hooks/_common/useAppFilters';
import { AppLogo } from '@/components/Logo';
import { ASSETS_TYPE } from '@/types/_common/navigationTabs.enums';
import { useLocation } from 'react-router-dom';
import { BaseIconButton, TabType, ToggleTabs } from '@/components/UI-kit';
import { BaseBreadcrumb } from '@/components/UI-kit/Breadcrumbs';
import { HeaderLogo } from '@/NewUI/components/Header';

export const Header = () => {
  const { breadcrumbs } = useAppBreadcrumbs();
  const { isMobile, isTablet } = useViewportWidth();
  const filters = useAppFilters();
  const { pathname } = useLocation();
  const isSites = pathname.includes('sites');
  const tabs = useMemo(
    () =>
      sitesPageTabs.map((el: TabType) => {
        return {
          ...el,
          disabled:
            el.disabled ||
            (el.id === ASSETS_TYPE.ALL && !!filters.assetsPreview) ||
            (filters.assetsType !== el.id && !!filters.assetStudio) ||
            false,
        };
      }),
    [filters]
  );
  return (
    <div className="w-screen shadow-none sm:shadow-md flex items-center justify-between px-2 md:px-4 xl:px-6 fixed bg-white top-0 left-0 h-header z-[50] ">
      <BaseIconButton
        classNames={{ button: 'text-default xl:hidden flex' }}
        icon={DMHamburgerIcon}
        variant={'ghost'}
        drawer={
          isMobile
            ? {
                element: <MobileSidebar />,
                direction: 'bottom',
              }
            : {
                className: 'bg-accent border-0 text-white',
                element: <MobileSidebar />,
                direction: 'left',
              }
        }
      />
      <div className="flex items-center gap-4 xl:w-full justify-center xl:justify-start">
        <HeaderLogo />
        <Separator orientation="vertical" className="h-5 w-0.5 hidden xl:block" />
        {!isTablet || filters.assetsPreview ? (
          <div className="flex gap-4 items-center w-full justify-start ">
            <BaseBreadcrumb breadcrumbs={breadcrumbs} className="hidden xl:flex items-center w-fit" />
          </div>
        ) : (
          <AppLogo size={''} />
        )}
      </div>
      <div className="flex gap-4 font-medium text-sm items-center ">
        {isSites && <HeaderSearchField />}
        {isSites && (
          <ToggleTabs tabs={tabs} defaultValue={filters.assetsType || sitesPageTabs[0].id} paramAlias="assetsType" />
        )}
      </div>
    </div>
  );
};
