import React, { useEffect, useState } from 'react';
import { Box, Card, CardBody, Center, FormControl, HStack, Select, Spinner } from '@chakra-ui/react';
import Plot from 'react-plotly.js';
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';
import useScreenSize from 'src/hooks/useScreenSize';

interface CoupledPlotsProps {
  title: string;
  noDataDescription: string;
  errorMessage?: string;
  dataAcc: any;
  dataVel: any;
  isFetching?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  revision: number;
  lastUpdateComponent?: React.ReactNode;
}

export const SpectrumPlots = ({
  title,
  noDataDescription,
  errorMessage,
  dataAcc,
  dataVel,
  isLoading,
  isFetching,
  isError,
  revision,
  lastUpdateComponent,
}: CoupledPlotsProps) => {
  const screenSize = useScreenSize();
  const { machineId } = useParams<string>();
  const [dataSourceCoupled, setDataSourceCoupled] = useState<any>(null);
  const [isUpdated, setUpdated] = useState<boolean>(false);
  const [selectedDataSource, setSelectedDataSource] = useState(dataVel?.[0]?.name || null);

  const onChangeDataSource = (event: any) => {
    const value = event.target.value;
    setSelectedDataSource(value);
    const selectedDataSourceAcc: any = dataAcc.filter((item: any) => item.name === value);
    const selectedDataSourceVel: any = dataVel.filter((item: any) => item.name === value);

    setDataSourceCoupled({
      measurements: [...selectedDataSourceAcc[0].measurements, ...selectedDataSourceVel[0].measurements],
      acc: selectedDataSourceAcc[0],
      vel: selectedDataSourceVel[0],
    });
  };

  useEffect(() => {
    if (dataVel?.length && dataAcc?.length) {
      const selectedDataSourceAcc: any = dataAcc.filter((item: any) => item.name === dataAcc[0].name);
      const selectedDataSourceVel: any = dataVel.filter((item: any) => item.name === dataVel[0].name);

      setDataSourceCoupled({
        measurements: [...selectedDataSourceAcc[0].measurements, ...selectedDataSourceVel[0].measurements],
        acc: selectedDataSourceAcc[0],
        vel: selectedDataSourceVel[0],
      });
    } else {
      setDataSourceCoupled(null);
    }
    setTimeout(() => setUpdated(true), 600);
  }, [dataVel, dataAcc]);

  useEffect(() => setUpdated(false), [machineId]);

  return (
    <Box bgColor="#E6E8EC" p={2} pt={0} borderRadius="2xl">
      <HStack py={4} pb={3} px={4} justifyContent="space-between">
        <HStack spacing={4}>
          <Box fontWeight={600}>{title}</Box>
          {dataAcc.length && dataVel.length && dataSourceCoupled?.measurements?.length > 0 ? (
            <HStack spacing={4} alignItems="flex-end" mb={1}>
              <FormControl maxW="16rem" h="28px">
                <Select
                  onChange={onChangeDataSource}
                  borderRadius="md"
                  size="sm"
                  minW="6rem"
                  bg="#021d3d"
                  borderColor="#021d3d"
                  color="#ffffff"
                  css={selectStyle}
                  _hover={{
                    bg: '#021d3d',
                  }}
                  _focus={{
                    bg: '#021d3d',
                  }}
                  variant="filled"
                  value={selectedDataSource}
                >
                  {dataVel.map((item: any, index: number) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </HStack>
          ) : null}
        </HStack>
        {lastUpdateComponent}
      </HStack>
      <Card w="full" boxShadow={0}>
        <CardBody p={1}>
          {!isFetching && dataSourceCoupled?.measurements?.length > 0 ? (
            <Plot
              data={JSON.parse(JSON.stringify(dataSourceCoupled.measurements))}
              layout={{
                showlegend: true,
                height: screenSize.height - 294,
                hovermode: 'closest',
                hoverlabel: { align: 'left' },
                margin: { t: 16 },
                autosize: true,
                xaxis: {
                  title: dataSourceCoupled.acc.x_units,
                  anchor: 'y2',
                  domain: [0.0, 1.0],
                },
                yaxis: {
                  title: dataSourceCoupled.acc.y_units,
                  anchor: 'free',
                  domain: [0.575, 1.0],
                  position: 0.0,
                },
                yaxis2: {
                  title: dataSourceCoupled.vel.y_units,
                  anchor: 'x',
                  domain: [0.0, 0.425],
                },
              }}
              useResizeHandler={true}
              config={{
                displayModeBar: true,
                displaylogo: false,
                modeBarButtonsToRemove: ['lasso2d', 'select2d', 'autoScale2d'],
              }}
              style={{ width: 'auto' }}
              revision={revision}
            />
          ) : (
            <Box h="700px">
              <Center py="325px">
                {isError ? (
                  errorMessage
                ) : isFetching || isLoading || !isUpdated ? (
                  <Spinner size="lg" />
                ) : !dataAcc || !dataVel || !dataAcc?.length || !dataVel?.length ? (
                  noDataDescription
                ) : (
                  <Spinner size="lg" />
                )}
              </Center>
            </Box>
          )}
        </CardBody>
      </Card>
    </Box>
  );
};

const selectStyle = css`
  > option {
    background: #ffffff;
    color: #021d3d;
  }
`;
