import React from 'react';
import { css } from '@emotion/react';
import { Box } from '@chakra-ui/react';
import { summary } from 'src/data/summary';
import { SummaryChart } from 'src/pages/sites/components/summary/components/SummaryChart';
import { SummaryHeader } from 'src/pages/sites/components/summary/components/SummaryHeader';
import { SummaryLegend } from 'src/pages/sites/components/summary/components/SummaryLegend';
import { SummaryTotalAssets } from './SummaryTotalAssets';
import { useSelector } from 'react-redux';
import { activeAssetsCategory } from 'src/app/store';
import { ASSETS_CATEGORY } from 'src/const';

interface SummaryProps {
  sites: any[];
}

export const Summary = ({ sites }: SummaryProps) => {
  const activeAssetsCategoryId = useSelector(activeAssetsCategory);
  const isActiveAllAssetsTab = activeAssetsCategoryId === ASSETS_CATEGORY.All;
  const isActiveFixedAssetsTab = activeAssetsCategoryId === ASSETS_CATEGORY.Fixed;
  const isActiveMobileAssetsTab = activeAssetsCategoryId === ASSETS_CATEGORY.Mobile;

  const initialAssetsHealthState: Record<string, number> = {
    critical: 0,
    alarm: 0,
    monitor: 0,
    healthy: 0,
    na: 0,
  };

  sites.forEach((item) => {
    const assetsHealthStatus: Record<string, number> = item.assets_categories.all.assets_health_statuses_counts;
    for (const key in initialAssetsHealthState) {
      initialAssetsHealthState[key] = initialAssetsHealthState[key] + assetsHealthStatus[key];
    }
  });

  const calculateStatuses = (data: any, activeTab: any) => {
    return data.reduce((acc: any, site: any) => {
      const tabData = site.assets_categories[activeTab];
      if (tabData && tabData.assets_health_statuses_counts) {
        for (const [key, value] of Object.entries(tabData.assets_health_statuses_counts)) {
          acc[key] = (acc[key] || 0) + value;
        }
      }
      return acc;
    }, {});
  };
  const overallStatuses = calculateStatuses(sites, activeAssetsCategoryId);

  const assetsHealthStatusSummary = summary.map((item) => {
    if (overallStatuses[item.id]) {
      return {
        ...item,
        value: overallStatuses[item.id],
      };
    } else {
      return item;
    }
  });

  const totals = sites.reduce(
    (acc: any, site: any) => {
      acc.all += site.assets_categories.all.total_assets || 0;
      acc.fixed += site.assets_categories.fixed.total_assets || 0;
      acc.mobile += site.assets_categories.mobile.total_assets || 0;

      return acc;
    },
    { all: 0, fixed: 0, mobile: 0 }
  );

  return (
    <Box
      w={{ base: '280px' }}
      px={{ base: 4, lg: '1rem' }}
      pl={{ base: 4, lg: '0' }}
      pt={{ base: 0, lg: '5.5rem' }}
      pb={{ base: 4, lg: 6 }}
      top={{ base: 0, lg: 0 }}
      css={style}
    >
      <Box bgColor="#E6E8EC" p={2} borderRadius="lg" h="100%">
        <Box bgColor="#ffffff" borderRadius="lg" h="100%" p={4} overflowY="auto" textTransform="capitalize">
          <SummaryHeader />

          {isActiveAllAssetsTab ? <SummaryChart data={assetsHealthStatusSummary} total={totals.all} /> : null}
          {isActiveMobileAssetsTab ? <SummaryChart data={assetsHealthStatusSummary} total={totals.mobile} /> : null}
          {isActiveFixedAssetsTab ? <SummaryChart data={assetsHealthStatusSummary} total={totals.fixed} /> : null}

          {isActiveAllAssetsTab ? (
            <SummaryTotalAssets totalFixedAssets={totals.fixed} totalMobileAssets={totals.mobile} />
          ) : null}

          <SummaryLegend data={assetsHealthStatusSummary} />
        </Box>
      </Box>
    </Box>
  );
};

const style = css`
  background-color: white;
  position: fixed;
  right: 0;
  height: 100vh;
  z-index: 3;
`;
