import React, { useState } from 'react';
import { Box, Button, HStack, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup } from '@chakra-ui/react';
import { DMArrowDownFilled, DMArrowUpFilled, DMChevronLeftIcon } from 'src/components/Icons';
import { css } from '@emotion/react';

interface DropdownOptionGroupMenuProps {
  defaultValue: string | string[];
  options: string[];
  onChange: (nextValue: string | string[]) => void;
  label?: string;
}

export const DropdownOptionGroupMenu = ({ defaultValue, options, onChange, label }: DropdownOptionGroupMenuProps) => {
  const [state, setState] = useState<string | string[]>(defaultValue);
  const handleChange = (nextValue: string | string[]) => {
    setState(nextValue);
    onChange(nextValue);
  };

  const rangeFormat = (value: string | string[]) => {
    const range = ['last', '60', '90', '180', '365'];
    if (value === '365') {
      return '1 year';
    } else if (value === 'last') {
      return '1 year';
    } else if (Array.isArray(value)) {
      return value.map((val) => (val === '365' ? '1 year' : val === 'last' ? '1 year' : `${val} days`)).join(', ');
    }
    return range.includes(value) ? (value === 'last' ? '1 year' : `${value} days`) : value;
  };

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={isOpen}
            as={Button}
            variant="unstyled"
            color={isOpen ? '#7071f3' : '#021d3d'}
            css={menuButtonCSS}
          >
            <HStack spacing={1}>
              <Box>
                {rangeFormat(state)} {label}
              </Box>
              <Box>
                {!isOpen ? (
                  <div className="rotate-[270deg]">
                    <DMChevronLeftIcon fontSize="1.2rem" />
                  </div>
                ) : (
                  <div className="rotate-90">
                    <DMChevronLeftIcon fontSize="1.2rem" />
                  </div>
                )}
              </Box>
            </HStack>
          </MenuButton>

          <MenuList minW="8.7rem" css={menuListCSS}>
            <MenuOptionGroup defaultValue={defaultValue} onChange={handleChange}>
              {options.map((value: string, index: number) => (
                <MenuItemOption key={index} value={value} iconSpacing={4} css={menuItemOptionCSS}>
                  {rangeFormat(value)} {label}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

const menuButtonCSS = css`
  font-size: 0.8125rem;
  font-weight: 500;
  height: 1.5rem;

  &:hover {
    color: #7071f3;
  }
`;

const menuItemOptionCSS = css`
  font-size: 0.875rem;
  font-weight: 600;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  &:hover,
  &:active,
  &:focus {
    background-color: rgba(112, 113, 243, 0.1);
  }
`;

const menuListCSS = css`
  max-height: 200px;
  overflow-y: auto;
`;
