export enum MENU_LABLES {
  ASSETS_HEALTH_STATUS = 'Assets Health Status',
  ASSETS_STATUS = 'Assets Status',
  ASSETS_CONDITION = 'Assets Condition',
  DATA_COVERAEGE = 'Data Coverage',
  SENSORS_TYPE = 'Sensors Type',
}

export enum SENSOR_TYPE_ID {
  CAMERA = 'camera',
  NON_CAMERA = 'non-camera',
}

export enum SENSOR_TYPE_LABEL {
  CAMERA = 'camera',
  NON_CAMERA = 'non-camera',
}

export enum ASSETS_HEALTH_STATUS_ID {
  CRITICAL = 'critical',
  ALARM = 'alarm',
  MONITOR = 'monitor',
  HEALTHY = 'healthy',
  NA = 'na',
}

export enum ASSETS_HEALTH_STATUS_LABEL {
  CRITICAL = 'critical',
  ALARM = 'alarm',
  MONITOR = 'monitor',
  HEALTHY = 'healthy',
  NA = 'N/A',
}

export enum ASSETS_STATUS_ID {
  NA = 'na',
  ONLINE = 'online',
}

export enum ASSETS_STATUS_LABEL {
  NA = 'N/A',
  ONLINE = 'online',
}

export enum ASSETS_CONDITION_ID {
  STATUS_CHANGED = 'status-changed',
  NO_STATUS_CHANGE = 'no-change',
}

export enum ASSETS_CONDITION_LABEL {
  STATUS_CHANGED = 'status changed',
  NO_STATUS_CHANGE = 'no status change',
}

export enum DATA_COVERAEGE_ID {
  ONLINE = 'online-sensors',
  OFFLINE = 'offline-sensors',
  SEMI = 'semi-online-sensors',
}

export enum DATA_COVERAEGE_LABEL {
  OFFLINE = '0%-50%',
  ONLINE = '81%-100%',
  SEMI = '51%-80%',
}
