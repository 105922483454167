import { Dash, Layout, Shape } from 'plotly.js';

export const getSelectionLineShape = (selection: any, dash: Dash): Partial<Shape> & { shapeCategory: string } => ({
  type: 'line',
  x0: selection[0].x,
  x1: selection[0].x,
  y0: 0,
  y1: 1,
  xref: 'x',
  yref: 'paper',
  line: {
    color: 'rgba(2, 29, 61, 1)',
    width: 2,
    dash,
  },
  shapeCategory: 'selection',
});

export const splitTraces = (traces: any, numSubplots: number) => {
  const tracesPerSubplot = Math.ceil(traces.length / numSubplots);
  const subplotTraces = Array.from({ length: numSubplots }, (_, i) =>
    traces.slice(i * tracesPerSubplot, (i + 1) * tracesPerSubplot)
  );

  return subplotTraces.flatMap((traceGroup, subplotIndex) =>
    traceGroup.map((trace: any) => ({
      ...trace,
      xaxis: 'x',
      yaxis: `y${trace.sensor_group}`,
    }))
  );
};

export const changeTraceType = (traces: any, isFullPullEnabled: boolean) =>
  traces.map((trace: any) => ({
    ...trace,
    type: isFullPullEnabled ? 'bar' : 'scatter',
  }));

export const transformData = (selectedComponent: any, selectedScheme: any) => {
  const statusOrder = ['critical', 'alarm', 'monitor', 'healthy', 'na'];

  return selectedComponent?.subcomponents
    .map((subcomponent: any) => ({
      label: subcomponent.subcomponent_ui_name,
      statusColor: subcomponent.health_status,
      statusLabel: subcomponent.health_status,
      schemes: subcomponent.diagnostics
        .map((diagnostic: any) => ({
          id: diagnostic.formula_id,
          label: diagnostic.fault_type,
          status: diagnostic.health_status,
          isSelected: selectedScheme && selectedScheme.id === diagnostic.formula_id,
        }))
        .sort((a: any, b: any) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)),
    }))
    .sort((a: any, b: any) => statusOrder.indexOf(a.statusLabel) - statusOrder.indexOf(b.statusLabel));
};

export const getInitialLayout = (
  numSubplots: number,
  plotContainerHeight: number,
  range: string[]
): Partial<Layout> | any => ({
  hovermode: 'x unified',
  hoversubplots: 'axis',
  margin: { t: 36, l: 48, r: 16, b: 0 },
  grid: { rows: numSubplots, columns: 1, pattern: 'independent' },
  height: numSubplots <= 5 ? plotContainerHeight : numSubplots * 160,
  showlegend: false,
  xaxis: {
    autorange: false,
    range,
    showspikes: false,
    domain: [0, 1],
    showgrid: true,
    gridcolor: '#F1F1FE',
    gridwidth: 1,
    zeroline: true,
    zerolinecolor: '#B1B9C3',
    zerolinewidth: 1,
  },
  annotations: [],
  shapes: [],
});
