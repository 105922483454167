import React, { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Highlight } from '@/components/UI-kit/Highlight/Highlight';
import { useAppFilters, useViewportWidth } from '@/NewUI/hooks/_common';
import { BaseUIKitWrapper } from '@/components/UI-kit/_common/BaseUIKitWrapper';
import { cn } from '@/lib/utils';
import { ASSETS_TYPE } from '@/types/_common/navigationTabs.enums';
import { Asset, FaultyComponent } from '@/types/assets';

import {
  AssetLastVerified,
  DataNotRecivedBadge,
  AssetActionsBar,
  AssetHealthStatusIndicator,
  AssetPreviewItemTitle,
  AssetsFaultIndicator,
  FaultyComponents,
  SensorsNewIndicator,
  StatusChanged,
  // AssetStatusChanged,
} from '@/NewUI/components/AssetsPreviewPage/AssetsOverview/AssetsPreviewItems/_common';

export const useAssetsPreviewTable = () => {
  const { assetsType } = useAppFilters();
  const { isMobile, isTablet } = useViewportWidth();
  const blackList = ['extra_info_1', 'extra_info_2', 'extra_info_3'];

  const assets_preview_table: ColumnDef<Asset>[] = useMemo(() => {
    const maxItems = isTablet ? 1 : 3;
    return [
      {
        accessorKey: 'asset_info',
        header: '1',
        cell: (info: any) => {
          const asset = info.row.original;
          return (
            <div className={cn('flex flex-col justify-center min-w-[400px] h-14')}>
              <div className="flex gap-2 items-center">
                <AssetPreviewItemTitle
                  title={
                    <div className="flex items-center gap-2">
                      <Highlight
                        text={`${asset.asset_ui_name} ${
                          assetsType === ASSETS_TYPE.MOBILE ? ` - ${asset.asset_supplier} ${asset.asset_model}` : ''
                        }`}
                      />
                    </div>
                  }
                />
                {/* {asset.health_status_changed && <StatusChanged />} */}
                {asset.not_monitored && <DataNotRecivedBadge variant="table" />}
              </div>
              <div className="flex gap-2 items-center pt-1">
                <AssetHealthStatusIndicator
                  healthStatus={asset.health_status}
                  showReasons={asset.health_status === 'alarm' || asset.health_status === 'critical'}
                  reasons={asset.health_status_changed ? ['Status Change'] : undefined}
                />
                <AssetLastVerified lastVerified={asset.latest_diagnostic_created_at} />
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: 'extra_info_1',
        header: '2',
        cell: (info: any) => (
          <AssetPreviewItemTitle
            className={cn('items-start')}
            title={<Highlight text={info.row.original.asset_model || ''} />}
            subTitle={<Highlight text={info.row.original.asset_supplier || ''} />}
          />
        ),
      },
      {
        accessorKey: 'extra_info_2',
        header: '3',
        cell: (info: any) => {
          return (
            <div className="flex flex-col justify-start py-1 gap-0.5 w-full">
              <AssetHealthStatusIndicator healthStatus={info.row.original.health_status} />
              {/* {info.row.original.health_status_changed && <AssetStatusChanged />} */}
            </div>
          );
        },
      },
      {
        accessorKey: 'extra_info_3',
        header: '4',
        cell: (info: any) => {
          const item: FaultyComponent[] = info.row.original.faulty_components || [];
          return (
            <div className="flex gap-2 justify-start items-center">
              {item
                .slice(0, maxItems)
                .map(
                  (el, index) =>
                    el.component_ui_name && (
                      <AssetsFaultIndicator
                        key={index}
                        healthStatus={el.health_status}
                        faultType={el.component_ui_name}
                        className={`w-fit text-xs p-2 px-3 ${
                          el.health_status === 'critical' || el.health_status === 'alarm' ? 'opacity-100' : 'opacity-0'
                        }`}
                      />
                    )
                )}
              {item?.slice(maxItems).length > 0 && (
                <BaseUIKitWrapper
                  dialog={{
                    title: 'Require Attention Part(s)',
                    className: 'w-fit ',
                    element: isMobile && (
                      <div className="flex flex-col gap-2">
                        {item.map((el) => (
                          <AssetsFaultIndicator
                            className={`w-fit text-xs px-2 ${
                              el.health_status === 'critical' || el.health_status === 'alarm'
                                ? 'opacity-100'
                                : 'opacity-0'
                            }`}
                            key={el.component_ui_name}
                            healthStatus={el.health_status}
                            faultType={el.component_ui_name}
                          />
                        ))}
                      </div>
                    ),
                  }}
                  tooltip={{
                    element: (
                      <div className="flex flex-col gap-2 max-w-fit">
                        {item.slice(maxItems).map((el: any) => (
                          <AssetsFaultIndicator
                            key={el.fault_type}
                            healthStatus={el.health_status}
                            faultType={el.fault_type || el.component_name}
                          />
                        ))}
                      </div>
                    ),
                  }}
                >
                  <div
                    className={cn(
                      'rounded-lg h-8 w-10 text-sm flex justify-center font-semibold text-medium items-center bg-accent text-accent-foreground',
                      `${
                        item
                          .slice(maxItems)
                          .filter((el) => el.health_status === 'critical' || el.health_status === 'alarm').length > 0
                          ? 'opacity-100'
                          : 'opacity-0'
                      }`
                    )}
                  >
                    +{item.slice(maxItems).length}
                  </div>
                </BaseUIKitWrapper>
              )}
            </div>
          );
        },
      },
      {
        accessorKey: 'extra_info_4',
        header: '5',
        cell: (info: any) => {
          const asset = info.row.original;
          return (
            <div
              className={cn('flex justify-end items-center gap-4 min-w-fit', `${asset.not_monitored && 'grayscale'}`)}
            >
              <FaultyComponents faulty_components={asset.faulty_components} asset_id={asset.asset_id} />
              <AssetActionsBar asset={asset} />
              <SensorsNewIndicator asset={asset} />
            </div>
          );
        },
      },
    ].filter((el) => {
      return !blackList.includes(el.accessorKey);
    });
  }, [assetsType, isTablet]);

  return { tableStructure: assets_preview_table };
};
