import { useViewportWidth } from '@/NewUI/hooks/_common';
import { PaginationState } from '@tanstack/react-table';
import React, { useEffect } from 'react';

export const usePagination = (pageSize: number | undefined) => {
  const { isTablet } = useViewportWidth();
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize || 999999999,
  });

  useEffect(() => {
    if (isTablet) {
      setPagination((prev) => {
        return { pageIndex: 0, pageSize: 999999999 };
      });
    } else if (pageSize)
      setPagination((prev) => {
        return { pageIndex: 0, pageSize };
      });
  }, [isTablet]);

  return { pagination, setPagination };
};
