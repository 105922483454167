import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { MaintenanceChartPopover } from 'src/features/maintenance-data/maintenance-chart/components/MaintenanceChartPopover';
import {
  enrichTasks,
  extractTasks,
  getOption,
  organizeGanttTasks,
  updateTaskColors,
} from 'src/features/maintenance-data/maintenance-chart/utils';
import { Pagination } from 'src/features/maintenance-data/maintenance-chart/components/Pagination';
import { MaintenanceTask, MaintenanceTasks } from 'src/features/maintenance-data/maintenance-chart/types';
import { TASK_RECTANGLE_COLOR } from 'src/features/maintenance-data/maintenance-chart/const';
import { Legend } from 'src/features/maintenance-data/maintenance-chart/components/Legend';
import { useLazyGetMaintenanceDataQuery } from 'src/app/api/maintenanceApi';
import { useParams } from 'react-router-dom';
import { getAssetById } from 'src/app/queries';
import { Loading } from 'src/components/Loading';

enum POPOVER_HORIZONTAL_OFFSET {
  onDiagnostics = 300,
  onSensorFusion = 315,
}

enum POPOVER_VERTICAL_OFFSET {
  onDiagnostics = 140,
  onSensorFusion = 130,
}

interface MaintenanceChartProps {
  selectedComponent: any;
  xAxisMin?: string | undefined;
  xAxisMax?: string | undefined;
  context?: MAINTENANCE_CHART_CONTEXT;
  wrapperRef: RefObject<HTMLDivElement>;
  diagnosticsChartRef?: React.RefObject<ReactECharts>;
  maintenanceChartRef?: React.RefObject<ReactECharts>;
  hideLegend?: boolean;
}

export enum MAINTENANCE_CHART_CONTEXT {
  Diagnostics = 'diagnostics',
  SensorFusion = 'sensorFusion',
  SchemeSensors = 'schemeSensors',
}

export const MaintenanceChart = ({
  selectedComponent,
  xAxisMin,
  xAxisMax,
  context = MAINTENANCE_CHART_CONTEXT.Diagnostics,
  wrapperRef,
  diagnosticsChartRef,
  maintenanceChartRef,
  hideLegend = false,
}: MaintenanceChartProps) => {
  const { siteId, machineId } = useParams<string>();
  const { asset } = getAssetById(siteId, machineId);
  const { asset_ui_name: assetName } = asset || {};
  const [tasks, setTasks] = useState<MaintenanceTasks>([]);
  const [taskPages, setTaskPages] = useState<Array<Array<MaintenanceTask>>>([]);
  const [activeTaskPage, setActiveTaskPage] = useState<number>(0);
  const [selectedTask, setSelectedTask] = useState<MaintenanceTask>();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState({ x: 0, y: 0 });
  const [option, setOption] = useState<any>();
  const [dataZoom, setDataZoom] = useState<Record<string, number>>({ start: 0, end: 100 });
  const isDiagnosticsContext = useMemo(() => context === MAINTENANCE_CHART_CONTEXT.Diagnostics, []);
  const [fetchMaintenanceData, { data: maintenanceData, isLoading: isLoadingMaintenanceData }] =
    useLazyGetMaintenanceDataQuery();

  const onEventsHandlers = {
    click: (params: any) => {
      const clickedTaskIndex = params.dataIndex;
      const wrapperBoundingRect = wrapperRef.current!.getBoundingClientRect();

      const x = isDiagnosticsContext
        ? params.event.event.clientX - wrapperBoundingRect.left - POPOVER_HORIZONTAL_OFFSET.onDiagnostics
        : params.event.event.clientX - wrapperBoundingRect.left - POPOVER_HORIZONTAL_OFFSET.onSensorFusion;

      const y = isDiagnosticsContext
        ? params.event.event.clientY -
          wrapperBoundingRect.top +
          wrapperBoundingRect.top -
          POPOVER_VERTICAL_OFFSET.onDiagnostics
        : params.event.event.clientY - wrapperBoundingRect.top - POPOVER_VERTICAL_OFFSET.onSensorFusion;

      setPopoverPosition({ x, y });
      setIsPopoverOpen(true);

      setSelectedTask(params.data);
      setTasks(updateTaskColors(tasks, clickedTaskIndex));
    },
    datazoom: (e: any) => {
      if (e.batch?.[0]) {
        const { start, end, startValue, endValue } = e.batch[0];
        const updatedZoom: Record<string, number> = {};

        if (start !== undefined && end !== undefined) {
          updatedZoom.start = start;
          updatedZoom.end = end;
        }

        if (startValue !== undefined && endValue !== undefined) {
          updatedZoom.startValue = startValue;
          updatedZoom.endValue = endValue;
        }

        if (Object.keys(updatedZoom).length) {
          setDataZoom(updatedZoom);
        }
      }
    },
    finished: () => {
      if (diagnosticsChartRef && maintenanceChartRef) {
        if (maintenanceChartRef.current && diagnosticsChartRef.current) {
          const maintenanceChartInstance = maintenanceChartRef.current.getEchartsInstance();
          const diagnosticsChartInstance = diagnosticsChartRef.current.getEchartsInstance();

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          echarts.connect([maintenanceChartInstance, diagnosticsChartInstance]);

          diagnosticsChartRef.current.getEchartsInstance().on('restore', (e: any) => {
            diagnosticsChartRef.current?.getEchartsInstance().dispatchAction({
              type: 'dataZoom',
              start: 0,
              end: 100,
            });

            setDataZoom({ start: 0, end: 100 });
          });
        }
      }
    },
    restore: () => setDataZoom({ start: 0, end: 100 }),
  };

  const onPopoverClose = () => {
    const updatedTasks = tasks.map((task: MaintenanceTask) => ({
      ...task,
      color: TASK_RECTANGLE_COLOR,
    }));

    setTasks(updatedTasks);
    setIsPopoverOpen(false);
    setSelectedTask(undefined);
  };

  const onPrevClick = () => {
    if (taskPages && activeTaskPage > 0 && taskPages[activeTaskPage - 1].length) {
      setTasks(taskPages[activeTaskPage - 1]);
      setActiveTaskPage((prevPage) => prevPage - 1);
    }
  };

  const onNextClick = () => {
    if (taskPages && activeTaskPage < taskPages.length - 1 && taskPages[activeTaskPage + 1].length) {
      setTasks(taskPages[activeTaskPage + 1]);
      setActiveTaskPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (maintenanceData) {
      const { data } = maintenanceData;
      const getAssetData = () => (assetName && assetName in data ? { [assetName]: data[assetName] } : {});
      const processedTasks: MaintenanceTasks = enrichTasks(extractTasks(getAssetData()));

      if (xAxisMin && xAxisMax) {
        const organizedTasks = organizeGanttTasks(processedTasks, xAxisMin, xAxisMax);

        setTaskPages(organizedTasks);
        setTasks(organizedTasks.length ? organizedTasks[activeTaskPage] : []);
      }
    }
  }, [selectedComponent, maintenanceData, activeTaskPage, xAxisMin, xAxisMax]);

  useEffect(
    () => setOption(getOption(tasks, dataZoom, isDiagnosticsContext, xAxisMin, xAxisMax)),
    [tasks, dataZoom, xAxisMin, xAxisMax]
  );

  useEffect(() => {
    fetchMaintenanceData({ site_id: siteId }, true);
  }, []);

  const chartElement = useMemo(() => {
    return (
      <ReactECharts
        ref={maintenanceChartRef}
        className="diagnostics-maintenance-chart"
        echarts={echarts}
        option={option}
        onEvents={onEventsHandlers}
      />
    );
  }, [option]);

  const noDataPlaceholderElement = useMemo(() => {
    return (
      <Box fontSize="0.875rem" color="#55687D" py={4}>
        No maintenance data available. Please upload data in order to see it.
      </Box>
    );
  }, []);

  return (
    <Box px={4}>
      <Box bgColor="rgba(230, 232, 236, 1)" borderRadius="lg">
        {isLoadingMaintenanceData ? (
          <Loading py={2.5} />
        ) : (
          <HStack spacing={0} justifyContent={tasks?.length ? 'stretch' : 'center'}>
            {tasks?.length && option ? (
              <>
                {chartElement}

                <MaintenanceChartPopover
                  isPopoverOpen={isPopoverOpen}
                  onPopoverClose={onPopoverClose}
                  popoverPosition={popoverPosition}
                  selectedTask={selectedTask}
                />

                {taskPages.length > 1 ? (
                  <Pagination
                    onPrevClick={onPrevClick}
                    onNextClick={onNextClick}
                    taskPages={taskPages}
                    activeTaskPageIndex={activeTaskPage}
                    lastTaskPageIndex={taskPages.length}
                  />
                ) : null}
              </>
            ) : (
              noDataPlaceholderElement
            )}
          </HStack>
        )}
      </Box>

      {hideLegend ? null : <Legend items={[{ color: '#01152B', label: 'Maintenance Task' }]} />}
    </Box>
  );
};
