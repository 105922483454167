import React from 'react';
import { useHighlight } from '@/NewUI/hooks/_common/useHightlight';

export interface HighlightProps {
  text: string;
  searchTerm?: string;
  Wrapper?: React.ComponentType<{ children: React.ReactNode }>;
}

export const Highlight: React.FC<HighlightProps> = ({ text, searchTerm, Wrapper }) => {
  const highlightedContent = useHighlight({ text, searchTerm, Wrapper });

  return <>{highlightedContent}</>;
};
