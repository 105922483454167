import { BaseTable } from '@/components/UI-kit';
import { useSensorsTable } from '@/NewUI/hooks/AssetsPreviewPage/_common/useSensorsTable';
import { sortSensors } from '@/pages/machines/utils';
import { AssetSensorsHealthStatus } from '@/types/assets';
import React from 'react';

interface sensorsModalProps {
  sensors: AssetSensorsHealthStatus[];
}

export const SensorModal = ({ sensors }: sensorsModalProps) => {
  const sortedSensors = sortSensors(sensors);
  const { tableStructure } = useSensorsTable();
  return (
    <BaseTable
      classNames={{
        table: {
          container: 'min-h-[520px]',
          td: 'w-full text-left w-fit px-4 py-4 [&>*]:justify-start',
          th: '[&>*]:justify-start px-2 py-2 w-fit',
        },
      }}
      data={sortedSensors || []}
      columns={tableStructure}
      pageSize={10}
      enableSorting={true}
      enableSearch={true}
    />
  );
};
