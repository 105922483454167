import React from 'react';
import { ASSETS_PREVIEW, ASSETS_TYPE } from '@/types/_common/navigationTabs.enums';
import { useMemo } from 'react';
import { BusinessInsights } from '../components/AssetsPreviewPage';
import { AssetsOverview } from '../components/AssetsPreviewPage/AssetsOverview/AssetsOverview';
import { SiteMap } from '../components/AssetsPreviewPage/SiteMap';
import { useAppFilters } from '../hooks/_common';
import { Layout } from '../layouts';
import { BaseTabs } from '@/components/UI-kit';
import { AssetPreviewFilter } from '../components/AssetsPreviewPage/AssetsOverview/AssetsPreviewFilter/AssetPreviewFilter';
import { AssetPreviewViewsSelectors } from '../components/AssetsPreviewPage/AssetsOverview/AssetPreviewViewsSelectors/AssetPreviewViewsSelectors';
import { RootState } from '@/app/store';
import { useSelector } from 'react-redux';

export const AssetsPreviewPage = () => {
  const { assetsType, assetsPreview } = useAppFilters();
  const { selectedView } = useSelector((state: RootState) => state.assetsPreview);

  const sitesPageTabs = useMemo(() => {
    return [
      { id: 'assets', title: 'Assets', element: <AssetsOverview /> },
      {
        id: 'business-insights',
        title: 'Business Insights',
        element: <BusinessInsights />,
        disabled: assetsType !== ASSETS_TYPE.FIXED,
      },
      {
        id: 'map',
        title: 'Site Map',
        // disabled: assetsType !== ASSETS_TYPE.MOBILE,
        disabled: true,
        element: (
          <div className="absolute top-0 left-0 h-full w-full z-0">
            <SiteMap />
          </div>
        ),
      },
      // {
      //   id: 'maintenance-data',
      //   title: 'Maintenance Data',
      //   element: <MaintenancePlaningPage />,
      // },
    ];
  }, [assetsType]);

  return (
    <Layout>
      <div className="flex flex-col md:px-8 w-full h-full gap-4 px-2 py-0  max-h-screen ">
        <BaseTabs
          tabs={sitesPageTabs}
          defaultValue={assetsPreview || sitesPageTabs[0].id}
          paramAlias={'assetsPreview'}
          hideTabTriggers={false}
          renderRightPane={() => (
            <div className="flex gap-6 w-full justify-end">
              <AssetPreviewFilter />
              {assetsPreview === ASSETS_PREVIEW.ASSETS && <AssetPreviewViewsSelectors selectedView={selectedView} />}
            </div>
          )}
        />
      </div>
    </Layout>
  );
};
