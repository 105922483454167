import {
  ASSETS_HEALTH_STATUS_ITEMS,
  ASSETS_STATUS_ITEMS,
  ASSETS_CONDITION_ITEMS,
  DATA_COVERAGE_ITEMS,
  SENSOR_TYPE_ITEMS,
} from './filter-items';
import { MENU_LABLES } from './types/filter.enums';
import { Menu } from './types/filter.types';

interface State {
  filteredItems: null | any; // @TODO: Replace `any` with the correct type
  filter: Menu[];
}

export const initialState: State = {
  filteredItems: null,
  filter: [
    { menuLabel: MENU_LABLES.ASSETS_HEALTH_STATUS, menuItems: ASSETS_HEALTH_STATUS_ITEMS },
    { menuLabel: MENU_LABLES.ASSETS_STATUS, menuItems: ASSETS_STATUS_ITEMS },
    { menuLabel: MENU_LABLES.ASSETS_CONDITION, menuItems: ASSETS_CONDITION_ITEMS },
    { menuLabel: MENU_LABLES.DATA_COVERAEGE, menuItems: DATA_COVERAGE_ITEMS },
    { menuLabel: MENU_LABLES.SENSORS_TYPE, menuItems: SENSOR_TYPE_ITEMS },
  ],
};
