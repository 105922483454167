import React from 'react';
import { css } from '@emotion/react';
import { Box, Divider, HStack } from '@chakra-ui/react';
import { AppLogo } from 'src/components/Logo';
import { NavLink } from 'react-router-dom';
import { NavigationMenuMobile } from 'src/components/NavigationMenuMobile';

interface HeaderProps {
  children?: any;
}

export const Header = ({ children }: HeaderProps) => {
  return (
    <HStack
      pl={{ base: 2, xl: '1.5rem', '1.5xl': '1.5rem' }}
      pr={{ base: 2, xl: '1rem', '1.5xl': '1rem' }}
      boxShadow="md"
      css={style}
    >
      <HStack>
        <NavLink to="/">
          <AppLogo size="140px" minW="140px" />
        </NavLink>
        <Divider orientation="vertical" ml={2} mr={1} height={4} />
      </HStack>

      {children}

      <HStack spacing={4}>
        <NavigationMenuMobile />
      </HStack>
    </HStack>
  );
};

const style = css`
  justify-content: space-between;
  width: 100%;
  height: 56px;
  left: 0;
  top: 0;
  min-height: 56px;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  position: fixed;
  background-color: #ffffff;
  z-index: 4;
`;
