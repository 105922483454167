import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { auth, database } from 'src/firebase';
import {
  Auth,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthCredential,
  EmailAuthProvider,
  reauthenticateWithCredential as reauthenticateUserWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut as signOutUser,
  updatePassword as updateUserPassword,
  updateProfile,
  User,
  UserCredential,
} from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { onValue, ref } from 'firebase/database';
import { setIsFirebaseRequesting, resetAppState } from 'src/app/slices/applicationSlice';

export interface AuthProviderProps {
  children?: ReactNode;
}

export interface UserContextState {
  isAuthenticated: boolean;
  isLoading: boolean;
  id?: string;
}

export const UserStateContext = createContext<UserContextState>({} as UserContextState);

export interface AuthContextModel {
  auth: Auth;
  user: User | null | undefined;
  userRelatedData: any;
  getCredential: (email: string, password: string) => EmailAuthCredential;
  signOut: () => Promise<void>;
  signIn: (email: string, password: string) => Promise<UserCredential>;
  signUp: (email: string, password: string) => Promise<UserCredential>;
  resetPassword: (email: string) => Promise<void>;
  changePassword: (oobCode: string, password: string) => Promise<void>;
  updateUserProfile: (user: User, data: any) => Promise<void>;
  updatePassword: (user: User, password: string) => Promise<void>;
  reauthenticateWithCredential: (user: User, credential: EmailAuthCredential) => Promise<UserCredential>;
  getUserRelatedData: (userId: string) => void;
}

export const AuthContext = React.createContext<AuthContextModel>({} as AuthContextModel);

export function useAuth(): AuthContextModel {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [user, setUser] = useState<User | null | undefined>(undefined);
  const [userRelatedData, setUserRelatedData] = useState(null);
  const dispatch = useDispatch();

  const signUp = (email: string, password: string): Promise<UserCredential> => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const signIn = (email: string, password: string): Promise<UserCredential> => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const signOut = async (): Promise<void> => {
    await signOutUser(auth);
    dispatch(resetAppState())
  };

  const resetPassword = (email: string): Promise<void> => {
    return sendPasswordResetEmail(auth, email);
  };

  const changePassword = (oobCode: string, password: string): Promise<void> => {
    return confirmPasswordReset(auth, oobCode, password);
  };

  const updateUserProfile = (user: User, data: any): Promise<void> => {
    return updateProfile(user, data);
  };

  const updatePassword = (user: User, password: string): Promise<void> => {
    return updateUserPassword(user, password);
  };

  const reauthenticateWithCredential = (user: User, credential: EmailAuthCredential): Promise<UserCredential> => {
    return reauthenticateUserWithCredential(user, credential);
  };

  const getUserRelatedData = (userId: string) => {
    return onValue(
      ref(database, '/users/dm_users/' + userId),
      (snapshot) => {
        setUserRelatedData(snapshot.val());
      },
      {
        onlyOnce: true,
      }
    );
  };

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        getUserRelatedData(user.uid);
      } else {
        setUser(null);
      }
    });
  }, []);

  useEffect(() => {
    if (user !== undefined) {
      dispatch(setIsFirebaseRequesting(false));
    }
  }, [user]);

  const values = {
    signUp,
    signIn,
    signOut,
    resetPassword,
    changePassword,
    updateUserProfile,
    updatePassword,
    reauthenticateWithCredential,
    getUserRelatedData,
    getCredential: EmailAuthProvider.credential,
    user,
    userRelatedData,
    auth,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useUserContext = (): UserContextState => {
  return useContext(UserStateContext);
};
