import { VStack } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { ComponentSidebarUnit } from './ComponentSidebarUnit';

export const ComponentsSidebarContent = (props: {
  components: any;
  element: (component: any, index: number) => JSX.Element;
}) => {
  const assetUnits = useMemo(() => {
    const units: any[] = [];

    props.components.forEach((component: any) => {
      if (component.unit_ui_name) {
        const relatedUnit = units.find((unit: any) => unit.unit_ui_name === component.unit_ui_name);
        if (relatedUnit) {
          relatedUnit.components.push(component);
        } else {
          units.push({
            unit_ui_name: component.unit_ui_name,
            components: [component],
          });
        }
      }
    });

    return units;
  }, [props.components]);

  return (
    <>
      {!!assetUnits.length && (
        <VStack spacing={2} mx={2} alignItems="stretch" pb="0.75rem" mb="0.75rem">
          {assetUnits.map((unit: any, idx: number) => (
            <ComponentSidebarUnit
              unitData={unit}
              key={unit.unit_ui_name}
              element={props.element}
              isOpenedByDefault={idx === 0}
            />
          ))}
        </VStack>
      )}
      <VStack spacing={2} px={2} alignItems="stretch">
        {props.components && props.components.filter((component: any) => !component.unit_ui_name).map(props.element)}
      </VStack>
    </>
  );
};
