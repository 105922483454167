import { Site } from '@/types/sites';
import React from 'react';

interface ChartIndexData {
  data: { label: string; value: number; color: string; fill: string }[];
  metaData?: { label: string; value: number }[];
}

export const useHealthStatusPieChart = (data: { label: string; value: number; name: string }[] | undefined) => {
  if (!data) return { chartData: { metaData: { total: 0 }, data: [] } };
  const chartData = {
    metaData: {
      total: data.map((el) => el.value).reduce((value: number, a: number) => value + a, 0),
    },
    data: [
      {
        label: 'critical',
        value: data.find((el) => el.name === 'critical')?.value || 0,
        color: 'text-critical',
        fill: 'hsl(var(--bg-critical))',
      },
      {
        label: 'alarm',
        value: data.find((el) => el.name === 'alarm')?.value || 0,
        color: 'text-alarm',
        fill: 'hsl(var(--bg-alarm)',
      },
      {
        label: 'monitor',
        value: data.find((el) => el.name === 'monitor')?.value || 0,
        color: 'text-monitor',
        fill: 'hsl(var(--bg-monitor))',
      },
      {
        label: 'healthy',
        value: data.find((el) => el.name === 'healthy')?.value || 0,
        color: 'text-healthy',
        fill: 'hsl(var(--bg-healthy))',
      },
      {
        label: 'N/A',
        value: data.find((el) => el.name === 'na')?.value || 0,
        color: 'text-notMonitored',
        fill: 'hsl(var(--bg-not-monitored))',
      },
    ],
  };

  const chartIndexData: ChartIndexData = {
    data: chartData.data,
  };

  return { chartData, chartIndexData };
};
