import { AppLogo } from '@/components/Logo';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const HeaderLogo = () => {
  const navigate = useNavigate();
  return (
    <div className="min-w-[140px] xl:block hidden" onClick={() => navigate('/sites')}>
      <AppLogo size="140px" />
    </div>
  );
};
