import { DMSensorsIcon } from '@/components/Icons';
import { BaseUIKitWrapper } from '@/components/UI-kit/_common/BaseUIKitWrapper';
import { cn } from '@/lib/utils';
import { SensorModal } from '@/NewUI/components/AssetsPreviewPage/_common';
import { Asset } from '@/types/assets';
import React from 'react';

const getRatioStyle = (a: number, b: number) => {
  const ratio = a / b;
  if (ratio <= 0.5) return 'text-critical bg-[#CC00001F]';
  else if (ratio <= 0.8 && ratio > 0.5) return 'text-alarm bg-[#FF891B1F]';
  else if (ratio > 0.8) return 'text-healthy bg-[#11975D1F]';
};

interface SensorsIndicatorProps {
  asset: Asset;
}
export const SensorsNewIndicator = ({ asset }: SensorsIndicatorProps) => {
  const { asset_ui_name, sensors_health_status, sensors_count_active, sensors_count_total } = asset || {};
  const sensorStyle =
    sensors_count_active !== undefined &&
    sensors_count_total !== undefined &&
    getRatioStyle(sensors_count_active, sensors_count_total);
  return (
    <BaseUIKitWrapper
      dialog={{
        title: asset_ui_name,
        element: <SensorModal sensors={sensors_health_status} />,
        className: 'max-w-screen md:max-w-fit md:min-w-[1200px] max-h-screen overflow-auto md:overflow-hidden',
      }}
    >
      <div className="flex flex-2 items-center gap-2 text-xs font-semibold pr-2 hover:bg-[#F0F2F7] hover:rounded-full">
        <div
          className={cn(
            'flex items-center justify-center h-[2rem] w-[2rem] bg-primary-400 rounded-full text-white',
            sensorStyle
          )}
        >
          <DMSensorsIcon boxSize={'18px'} />
        </div>
        <div className="flex items-center whitespace-nowrap  text-xs ">
          {sensors_count_active + ' / ' + sensors_count_total}
        </div>
      </div>
    </BaseUIKitWrapper>
  );
};
