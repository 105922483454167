import {
  ASSETS_HEALTH_STATUS_ID,
  ASSETS_HEALTH_STATUS_LABEL,
  ASSETS_STATUS_ID,
  ASSETS_STATUS_LABEL,
  ASSETS_CONDITION_ID,
  ASSETS_CONDITION_LABEL,
  DATA_COVERAEGE_ID,
  DATA_COVERAEGE_LABEL,
  SENSOR_TYPE_ID,
  SENSOR_TYPE_LABEL,
} from './types/filter.enums';
import { MenuItem } from './types/filter.types';

export const ASSETS_HEALTH_STATUS_ITEMS: MenuItem[] = [
  {
    id: ASSETS_HEALTH_STATUS_ID.CRITICAL,
    label: ASSETS_HEALTH_STATUS_LABEL.CRITICAL,
    counter: 0,
    className: 'text-critical',
  },
  { id: ASSETS_HEALTH_STATUS_ID.ALARM, label: ASSETS_HEALTH_STATUS_LABEL.ALARM, counter: 0, className: 'text-alarm' },
  {
    id: ASSETS_HEALTH_STATUS_ID.MONITOR,
    label: ASSETS_HEALTH_STATUS_LABEL.MONITOR,
    counter: 0,
    className: 'text-monitor',
  },
  {
    id: ASSETS_HEALTH_STATUS_ID.HEALTHY,
    label: ASSETS_HEALTH_STATUS_LABEL.HEALTHY,
    counter: 0,
    className: 'text-healthy',
  },
  { id: ASSETS_HEALTH_STATUS_ID.NA, label: ASSETS_HEALTH_STATUS_LABEL.NA, counter: 0, className: 'text-notMonitored' },
];

export const ASSETS_STATUS_ITEMS: MenuItem[] = [
  { id: ASSETS_STATUS_ID.NA, label: ASSETS_STATUS_LABEL.NA, counter: 0, className: '' },
  { id: ASSETS_STATUS_ID.ONLINE, label: ASSETS_STATUS_LABEL.ONLINE, counter: 0, className: '' },
];

export const ASSETS_CONDITION_ITEMS: MenuItem[] = [
  { id: ASSETS_CONDITION_ID.STATUS_CHANGED, label: ASSETS_CONDITION_LABEL.STATUS_CHANGED, counter: 0, className: '' },
  {
    id: ASSETS_CONDITION_ID.NO_STATUS_CHANGE,
    label: ASSETS_CONDITION_LABEL.NO_STATUS_CHANGE,
    counter: 0,
    className: '',
  },
];

export const DATA_COVERAGE_ITEMS: MenuItem[] = [
  { id: DATA_COVERAEGE_ID.OFFLINE, label: DATA_COVERAEGE_LABEL.OFFLINE, counter: 0, className: '' },
  { id: DATA_COVERAEGE_ID.SEMI, label: DATA_COVERAEGE_LABEL.SEMI, counter: 0, className: '' },
  { id: DATA_COVERAEGE_ID.ONLINE, label: DATA_COVERAEGE_LABEL.ONLINE, counter: 0, className: '' },
];

export const SENSOR_TYPE_ITEMS: MenuItem[] = [
  { id: SENSOR_TYPE_ID.CAMERA, label: SENSOR_TYPE_LABEL.CAMERA, counter: 0, className: '' },
  { id: SENSOR_TYPE_ID.NON_CAMERA, label: SENSOR_TYPE_LABEL.NON_CAMERA, counter: 0, className: '' },
];
