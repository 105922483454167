import { HEALTH_STATUS, NOT_MONITORED_DAYS, SENSOR_STATUS, SENSOR_TYPE } from 'src/const';
import { AssetSensorsHealthStatus } from 'src/types/assets';

const FAULTY_COMPONENTS_GAP = 10;
const FAULTY_COMPONENTS_PLUS_BOX_WIDTH_SM = 42;
const FAULTY_COMPONENTS_PLUS_BOX_WIDTH_MD = 52;
const FAULTY_COMPONENTS_PLUS_BOX_WIDTH_LG = 62;

const getFaultyComponentsAvailableSpace = (
  wrapperWidth: number,
  childrenCount: number,
  showChildrenCount: number
): number => {
  const plusBoxWidth =
    childrenCount - showChildrenCount > 99
      ? FAULTY_COMPONENTS_PLUS_BOX_WIDTH_LG
      : childrenCount - showChildrenCount > 9
      ? FAULTY_COMPONENTS_PLUS_BOX_WIDTH_MD
      : FAULTY_COMPONENTS_PLUS_BOX_WIDTH_SM;

  if (childrenCount > 3) {
    return wrapperWidth - plusBoxWidth - FAULTY_COMPONENTS_GAP * showChildrenCount;
  } else {
    if (childrenCount === showChildrenCount) {
      return wrapperWidth - FAULTY_COMPONENTS_GAP * (showChildrenCount - 1);
    } else {
      return wrapperWidth - plusBoxWidth - FAULTY_COMPONENTS_GAP * showChildrenCount;
    }
  }
};

export const getVisibleFaultyComponentsCount = (wrapper: HTMLDivElement) => {
  const children = wrapper.children;
  const wrapperWidth = wrapper.getBoundingClientRect().width;
  if (
    children[2] &&
    getFaultyComponentsAvailableSpace(wrapperWidth, children.length, 3) -
      children[0].getBoundingClientRect().width -
      children[1].getBoundingClientRect().width -
      children[2].getBoundingClientRect().width >
      0
  ) {
    return 3;
  } else if (
    children[1] &&
    getFaultyComponentsAvailableSpace(wrapperWidth, children.length, 2) -
      children[0].getBoundingClientRect().width -
      children[1].getBoundingClientRect().width >
      0
  ) {
    return 2;
  } else if (
    children[0] &&
    getFaultyComponentsAvailableSpace(wrapperWidth, children.length, 1) - children[0].getBoundingClientRect().width > 0
  ) {
    return 1;
  } else {
    return 0;
  }
};

const cutoffDate = new Date();
cutoffDate.setDate(cutoffDate.getDate() - NOT_MONITORED_DAYS);

export const isNotMonitored = (asset: any, siteLastVerified: any) => {
  return (
    siteLastVerified &&
    (new Date(asset.latest_diagnostic_created_at) < new Date(siteLastVerified) ||
      new Date(siteLastVerified) < cutoffDate)
  );
};

const isNotMonitoredComponent = (siteLastVerified?: any, asset?: any) => {
  return (
    siteLastVerified &&
    (new Date(asset.latest_diagnostic_created_at) < new Date(siteLastVerified) ||
      new Date(siteLastVerified) < cutoffDate ||
      asset.health_status?.toLowerCase() === HEALTH_STATUS.NOT_AVAILABLE)
  );
};

export const getAmountByStatus = (items: any) => {
  return {
    all: items.length,
    [HEALTH_STATUS.CRITICAL]: items.filter((item: any) => item.health_status === HEALTH_STATUS.CRITICAL).length,
    [HEALTH_STATUS.ALARM]: items.filter((item: any) => item.health_status === HEALTH_STATUS.ALARM).length,
    [HEALTH_STATUS.MONITOR]: items.filter((item: any) => item.health_status === HEALTH_STATUS.MONITOR).length,
    [HEALTH_STATUS.HEALTHY]: items.filter((item: any) => item.health_status === HEALTH_STATUS.HEALTHY).length,
    [HEALTH_STATUS.NOT_AVAILABLE]: items.filter((item: any) => item.health_status === HEALTH_STATUS.NOT_AVAILABLE)
      .length,
  };
};

export const getAmountByCamera = (items: any) => {
  return {
    all: items.filter((item: any) =>
      item.sensors_health_status.some((sensor: any) => sensor.sensor_type.toLowerCase() === SENSOR_TYPE.Camera)
    ).length,
  };
};

export const getComponentAmountByCamera = (components: any) => {
  return {
    all: components.filter((component: any) =>
      !component.diagnostics
        ? false
        : component.diagnostics.some((diagnostic: any) =>
            diagnostic.sources_info.some((source: any) => source.source_type.toLowerCase() === SENSOR_TYPE.Camera)
          )
    ).length,
  };
};

export const getTotalAssetsWithStatusChanged = (assets: any, siteLastVerified?: any) => {
  return {
    all: assets.length,
    changed: assets.filter((asset: any) => asset.health_status_changed === true).length,
    not_monitored: assets.filter((item: any) => isNotMonitored(item, siteLastVerified)).length,
  };
};

export const getTotalComponentWithStatusChanged = (components: any, siteLastVerified?: any, asset?: any) => {
  return {
    all: components.length,
    changed: components.filter((component: any) => component.health_status_changed === true).length,
    not_monitored: components.filter((component: any) => isNotMonitoredComponent(siteLastVerified, asset)).length,
  };
};

export const getSensorsOverallStatus = (sensors: AssetSensorsHealthStatus[]) => {
  if (sensors) {
    for (const sensor of sensors) {
      if (sensor.health_status === SENSOR_STATUS.OFFLINE || sensor.health_status === SENSOR_STATUS.DATA_NOT_RECEIVED) {
        return true;
      }
    }
  }

  return false;
};

export enum SENSORS_OVERALL_STATUS {
  Healthy = 'healthy',
  Critical = 'critical',
  Warning = 'warning',
}

export const getSensorsOverallStatusUpdated = (sensors: AssetSensorsHealthStatus[]) => {
  const offlineSensors = [];
  const onlineSensors = [];

  if (sensors) {
    for (const sensor of sensors) {
      if (sensor.health_status === SENSOR_STATUS.OFFLINE || sensor.health_status === SENSOR_STATUS.DATA_NOT_RECEIVED) {
        offlineSensors.push(sensor);
      }
      if (sensor.health_status === SENSOR_STATUS.ONLINE || sensor.health_status === SENSOR_STATUS.DATA_RECEIVED) {
        onlineSensors.push(sensor);
      }
    }
  }

  if (offlineSensors.length === 0 && onlineSensors.length > 0) {
    return SENSORS_OVERALL_STATUS.Healthy;
  }

  if (offlineSensors.length >= 1 && onlineSensors.length > 0) {
    return SENSORS_OVERALL_STATUS.Warning;
  }

  return SENSORS_OVERALL_STATUS.Critical;
};

export const sortSensors = (sensors: AssetSensorsHealthStatus[]) => {
  return sensors
    ? [...sensors].sort((a, b) => {
        if (a.last_uptime > b.last_uptime) return -1;
        if (a.last_uptime < b.last_uptime) return 1;
        if (a.sensor_name < b.sensor_name) return -1;
        if (a.sensor_name > b.sensor_name) return 1;
        return 0;
      })
    : [];
};

export const getComponentDiagnostics = (data: any, selectedComponent: any) => {
  return data.map((item: any) => {
    return [
      item[0],
      [
        ...item[1].assets
          .map((asset: any) => {
            return asset.components
              .filter((component: any) => {
                if (selectedComponent) {
                  if (component.component_id === selectedComponent.component_id) {
                    return component;
                  }
                } else {
                  return component;
                }
              })
              .map((component: any) => {
                if (component.diagnostics) {
                  return component.diagnostics
                    .map((diagnostic: any) => {
                      return {
                        component_name: component.component_name,
                        component_id: component.component_id,
                        component_health_status: component.health_status,
                        ...diagnostic,
                      };
                    })
                    .flat();
                } else {
                  return component.subcomponents
                    .map((subcomponent: any) => {
                      return subcomponent.diagnostics
                        .map((diagnostic: any) => {
                          return {
                            component_name: component.component_name,
                            component_id: component.component_id,
                            component_ui_name: component.component_ui_name,
                            component_health_status: component.health_status,
                            subcomponent_id: subcomponent.subcomponent_id,
                            subcomponent_name: subcomponent.subcomponent_name,
                            subcomponent_ui_name: subcomponent.subcomponent_ui_name,
                            subcomponent_health_status: subcomponent.health_status,
                            ...diagnostic,
                          };
                        })
                        .flat();
                    })
                    .flat();
                }
              })
              .flat();
          })
          .flat(),
      ],
    ];
  });
};

export const getFilterAssetsByStatusAndSensors = (
  assets: any,
  selectedStatuses: any,
  selectedStatusesKeys: any,
  selectedStatusChanged: any,
  selectedSensors: any,
  siteLastVerified: any
) => {
  if (!selectedStatuses.all) {
    return assets?.filter((item: any) => item.health_status === selectedStatusesKeys[0]);
  }

  if (selectedStatusChanged.health_status_changed) {
    return assets?.filter((item: any) => item.health_status_changed === true);
  }
  if (selectedStatusChanged.not_monitored) {
    return assets?.filter((item: any) => isNotMonitored(item, siteLastVerified));
  }

  if (selectedSensors !== 'all') {
    if (selectedSensors === SENSOR_STATUS.OFFLINE) {
      return assets?.filter((item: any) => item.isSensorsOverallStatusCritical && item.sensors_health_status.length);
    }

    if (selectedSensors === SENSOR_STATUS.ONLINE) {
      return assets?.filter((item: any) => !item.isSensorsOverallStatusCritical && item.sensors_health_status.length);
    }
    if (selectedSensors === SENSOR_TYPE.Camera) {
      return assets?.filter(
        (item: any) =>
          item.sensors_health_status.filter((sensor: any) => sensor.sensor_type === SENSOR_TYPE.Camera).length
      );
    }
  } else {
    return assets;
  }
};

export const getAllSensorsCount = (assets: any) =>
  assets.reduce((total: number, item: any) => {
    return total + item?.sensors_health_status?.length;
  }, 0);

export const getCriticalSensorsCount = (assets: any) =>
  assets.reduce((total: number, item: any) => {
    return (
      total +
      item.sensors_health_status.filter((item: any) => {
        return item.health_status === SENSOR_STATUS.OFFLINE || item.health_status === SENSOR_STATUS.DATA_NOT_RECEIVED;
      }).length
    );
  }, 0);

export const getHealthySensorsCount = (assets: any) =>
  assets.reduce((total: number, item: any) => {
    return (
      total +
      item.sensors_health_status.filter((item: any) => {
        return item.health_status === SENSOR_STATUS.ONLINE || item.health_status === SENSOR_STATUS.DATA_RECEIVED;
      }).length
    );
  }, 0);
