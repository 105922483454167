import React from 'react';
import { DMFixedAssetsIcon, DMGridIcon, DMMobileAssetsIcon } from '@/components/Icons';
import { useViewportWidth } from '@/NewUI/hooks/_common';
import { ASSETS_TYPE } from '@/types/_common/navigationTabs.enums';
import { Layout } from '../layouts';
import { TabType, BaseTabs } from '@/components/UI-kit';
import { Sites } from '../components/SitesPage/SitesContent/Sites';

export const sitesPageTabs: TabType[] = [
  { id: ASSETS_TYPE.ALL, title: 'All Assets', icon: <DMGridIcon boxSize={6} /> },
  {
    id: ASSETS_TYPE.MOBILE,
    title: 'Mobile Fleet',
    tooltip: 'Mobile Fleet Assets',
    icon: <DMMobileAssetsIcon boxSize={6} />,
  },
  { id: ASSETS_TYPE.FIXED, title: 'Fixed Assets', icon: <DMFixedAssetsIcon boxSize={6} /> },
];

export const SitesPage = () => {
  const { isTablet } = useViewportWidth();
  return (
    <Layout>
      <div className="flex flex-col md:px-8 w-full h-full gap-4 px-2 py-0 max-h-screen">
        <BaseTabs
          tabs={sitesPageTabs}
          updateUrl={true}
          defaultValue={ASSETS_TYPE.ALL}
          paramAlias="assetsType"
          hideTabTriggers={!isTablet}
          renderFixedElement={() => <Sites />}
        />
      </div>
    </Layout>
  );
};
