import React from 'react';
import {
  VStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Text,
  ModalHeader,
  useMediaQuery,
} from '@chakra-ui/react';
import { DMUserGuideIcon } from './Icons';
import { SIDEBAR_MENU_BUTTON_SIZE } from 'src/const';
import pdfFile from 'src/assets/files/UserManualDataMindAIOct2024.pdf';
import { theme } from 'src/theme';

export const UserGuide = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isNotMobileResolution] = useMediaQuery(`(min-width: ${theme.breakpoints.xl})`);

  return (
    <>
      <VStack spacing="4px" cursor="pointer" position="relative" onClick={onOpen}>
        <IconButton
          aria-label="Guide"
          size="lg"
          background={isOpen ? '#12FFE2' : 'transparent'}
          color={isOpen ? '#021d3d' : '#fff'}
          borderRadius="16px"
          height="2rem"
          icon={<DMUserGuideIcon boxSize={SIDEBAR_MENU_BUTTON_SIZE} mb={0.5} />}
          variant="unstyled"
        />

        <Text fontSize="10px" lineHeight="14px" fontWeight={600}>
          Guide
        </Text>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'full', xl: '6xl' }} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="2xl">
          <ModalCloseButton top={3} right={1} />
          <ModalBody pb={{ base: 0, xl: 2 }} px={{ base: 0, xl: 2 }} pt={0}>
            <ModalHeader py={7} />
            <iframe
              src={pdfFile}
              style={{
                width: '100%',
                height: isNotMobileResolution ? '80vh' : 'calc(100vh - 3.5rem)',
                borderRadius: isNotMobileResolution ? '0.6rem' : 0,
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
