import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { DMChartIcon } from 'src/components/Icons';
import { MAINTENANCE_CHART_CONTEXT } from 'src/features/maintenance-data/maintenance-chart/MaintenanceChart';
import { PreferencesPopover } from 'src/features/maintenance-data/maintenance-chart/components/PreferencesPopover';
import { MobileFleetSensorsFilterPopover } from 'src/features/mobile-fleet-sensor-data/components/MobileFleetSensorsFilterPopover';

interface MobileFleetSensorDataHeaderProps {
  scheme?: any;
  queryParams: any;
  context: any;
}

export const MobileFleetSensorDataHeader: React.FC<MobileFleetSensorDataHeaderProps> = ({
  scheme,
  queryParams,
  context,
}) => {
  return (
    <HStack justifyContent="space-between" p={2} w="full">
      <HStack h={8}>
        <DMChartIcon fontSize="1.5rem" />
        <Box
          fontWeight={600}
          fontSize="0.875rem"
          w="full"
          maxW={{ base: '5rem', xl: '5rem', '1.5xl': '15rem', '2xl': '20rem', '4xl': '35rem' }}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {scheme ? (
            <>
              Scheme for{' '}
              <span
                style={{
                  textTransform: 'capitalize',
                }}
              >
                {scheme.label}
              </span>
            </>
          ) : (
            'Sensor Fusion'
          )}
        </Box>
      </HStack>

      <HStack>
        <PreferencesPopover context={context} mb={0} />
        <MobileFleetSensorsFilterPopover queryParams={queryParams} />
      </HStack>
    </HStack>
  );
};
