import React from 'react';
import { AssetsPreviewItem } from './AssetPreviewCardItem/AssetsPreviewItem';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { useAppFilters } from '@/NewUI/hooks/_common';
import { ASSETS_TYPE } from '@/types/_common/navigationTabs.enums';

interface AssetsPreviewItemsProps {
  items: any;
  isRequiredAttention?: boolean;
}

const assetsPreviewLayout = {
  grid: 'grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 4xl:grid-cols-4 5xl:grid-cols-4 grid-auto-flow-column h-full',
  list: 'flex flex-col',
};

export const AssetsPreviewItems = ({ items, isRequiredAttention = false }: AssetsPreviewItemsProps) => {
  const { selectedView } = useSelector((state: RootState) => state.assetsPreview);
  const filters = useAppFilters();
  return (
    <div className={`${assetsPreviewLayout[selectedView]} gap-2`}>
      {items?.map((item: any, index: number) => (
        <AssetsPreviewItem
          key={item?.id || index}
          item={item}
          isRequireAttention={isRequiredAttention}
          view={selectedView}
          assetsType={filters.assetsType as ASSETS_TYPE.MOBILE | ASSETS_TYPE.FIXED}
        />
      ))}
    </div>
  );
};
