import { Config, Layout } from 'plotly.js';

export const config: Partial<Config> = {
  displayModeBar: true,
  displaylogo: false,
  modeBarButtonsToRemove: ['lasso2d', 'select2d', 'autoScale2d'],
};

const tickFont = {
  color: '#021d3d',
  size: 12,
  family: 'Montserrat',
};

const yAxisBase = {
  zeroline: false,
  linecolor: 'rgba(2, 29, 61, 0.3)',
  showline: true,
  showgrid: false,
  tickfont: tickFont,
};

const xAxisBase = {
  zeroline: false,
  linecolor: 'rgba(2, 29, 61, 0.3)',
  showspikes: false,
  tickfont: tickFont,
  showticklabels: false,
};

export const layoutInitialConfig: Partial<Layout & { hoversubplots: any; selections: any }> = {
  hovermode: 'x unified',
  hoversubplots: 'axis',
  margin: { t: 32, l: 48, r: 16, b: 0 },
  showlegend: false,
  autosize: false,
  annotations: [],
  legend: { orientation: 'h', traceorder: 'reversed', itemwidth: 10, font: { size: 11 } },
  images: [],
  xaxis: {
    ...xAxisBase,
    domain: [0, 1],
  },
  yaxis: {
    ...yAxisBase,
    domain: [0, 0.3],
  },
  yaxis2: {
    ...yAxisBase,
    domain: [0.35, 0.65],
  },
  yaxis3: {
    ...yAxisBase,
    domain: [0.7, 1],
  },
  yaxis4: {
    ...yAxisBase,
    domain: [0.8, 1],
  },
};
