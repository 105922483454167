import { DateRange } from 'react-day-picker';
import { createSlice } from '@reduxjs/toolkit';
import { sub } from 'date-fns';
import { Asset } from '@/types/assets';
import { ComponentDiagnostics, DiagnosticsAssetComponent } from '@/types/diagnostics';
import { ASSET_STUDIO } from '@/types/_common/navigationTabs.enums';

interface assetsStudioState {
  dateRange: {
    from: string;
    to: string;
  };
  selectedComponents: {
    id: string;
    data: DiagnosticsAssetComponent[];
  };
  selectedComponentObj?: any;
  selectedDiagnostic?: any;
  visualAIActiveItemIndex: number;
  visualAIEvidenceItems: any[];
  visualAIPlaylistItems: any;
}
const initialState: assetsStudioState = {
  dateRange: {
    from: sub(new Date(), { months: 2 }).toISOString(),
    to: new Date().toISOString(),
  },
  selectedComponents: { id: ASSET_STUDIO.ALL_COMPONENTS, data: [] },
  visualAIActiveItemIndex: 0,
  visualAIPlaylistItems: [],
  visualAIEvidenceItems: [],
};

const assetsStudioSlice = createSlice({
  initialState,
  name: 'assetsStudio',
  reducers: {
    setDateRange(state, action) {
      const { from, to } = action.payload;
      if (from && to) state.dateRange = { from, to };
    },
    setSelectedComponents(state, action) {
      state.selectedComponents = { ...action.payload };
    },
    setSelectedDiagnostic(state, action) {
      state.selectedDiagnostic = action.payload;
    },
    setSelectedComponentObj(state, action) {
      state.selectedComponentObj = action.payload;
    },
    setVisualAIActiveItemIndex(state, action) {
      state.visualAIActiveItemIndex = action.payload;
    },
    setVisualAIPlaylistItems(state, action) {
      state.visualAIPlaylistItems = action.payload;
    },
  },
});

export const AssetsStudioSliceReducer = assetsStudioSlice.reducer;
export const {
  setDateRange,
  setSelectedComponents,
  setSelectedDiagnostic,
  setSelectedComponentObj,
  setVisualAIActiveItemIndex,
  setVisualAIPlaylistItems,
} = assetsStudioSlice.actions;
