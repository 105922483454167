import React, { useState, useEffect } from 'react';

export function useViewportWidth(): { isMobile: boolean; isTablet: boolean } {
  // const BP = 768;
  const MobileBP = 768;
  const TabletBP = 1280;
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < MobileBP);
  const [isTablet, setIsTablet] = useState<boolean>(window.innerWidth < TabletBP);

  useEffect(() => {
    // Define a media query for mobile screen sizes
    const mediaQueryMobile = window.matchMedia(`(max-width: ${MobileBP}px)`);
    const mediaQueryTablet = window.matchMedia(`(max-width: ${TabletBP}px)`);

    // Update state based on the media query's match status
    const handleMobileChange = (e: MediaQueryListEvent) => {
      setIsMobile(e.matches);
    };

    const handelTabletChange = (e: MediaQueryListEvent) => {
      setIsTablet(e.matches);
    };

    // Add an event listener for media query changes
    mediaQueryMobile.addEventListener('change', handleMobileChange);
    mediaQueryTablet.addEventListener('change', handelTabletChange);

    // Clean up the event listener when the component unmounts
    return () => {
      mediaQueryMobile.removeEventListener('change', handleMobileChange);
      mediaQueryTablet.removeEventListener('change', handelTabletChange);
    };
  }, []);

  return { isMobile, isTablet };
}
