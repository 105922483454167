import { RootState } from '@/app/store';
import { DMCirclePlay, DMSensorsIcon } from '@/components/Icons';
import { Counter } from '@/components/UI-kit/Counter/Counter';
import { BaseDropdownFilterMenu } from '@/components/UI-kit/Dropdown/BaseDropdownFilterMenu';
import { useAppFilters } from '@/NewUI/hooks/_common';
import { ASSETS_PREVIEW, ASSETS_TYPE } from '@/types/_common/navigationTabs.enums';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAssetsPreviewFilterItems } from '@/app/slices/assetsPreviewSlice';
import { cn } from '@/lib/utils';

interface HeaderFilterProps {
  disabled?: boolean;
}

const renderSensorOption = (item: any) => {
  const sensorIconStyle = (() => {
    if (item.id?.toString().includes('offline')) return 'text-critical bg-[#CC00001F]';
    else if (item.id?.toString().includes('semi')) return 'text-alarm bg-[#FF891B1F]';
    else if (item.id?.toString().includes('online')) return 'text-healthy bg-[#11975D1F]';
  })();

  return (
    <div className={`${item.label?.toString().includes('online') ? 'text-healthy' : 'text-critical'} scale-125 pb-0.5`}>
      {/* {item.id?.toString().includes('semi') ? <DMSensorsWarningIcon boxSize={12} /> : <DMSensorsIcon boxSize={10} />} */}
      <div
        className={cn(
          'flex items-center justify-center h-6 w-6 bg-primary-400 rounded-full text-white mx-2 ',
          sensorIconStyle
        )}
      >
        <DMSensorsIcon className="scale-[80%]" />
      </div>
    </div>
  );
};
// @TODO: move from here
export const sensorsIcon = {
  camera: (
    <div className="flex items-center justify-center h-7 w-7 rounded-full pt-0.5 mx-0.5">
      <DMCirclePlay />
    </div>
  ),
  vibrations: <DMSensorsIcon />,
  current: <DMSensorsIcon />,
  temperature: <DMSensorsIcon />,
  speed: <DMSensorsIcon />,
  'oil-condition': <DMSensorsIcon />,
  'oil-temperature': <DMSensorsIcon />,
  'oil-quality': <DMSensorsIcon />,
  pressure: <DMSensorsIcon />,
};

export const AssetPreviewFilter = ({ disabled }: HeaderFilterProps) => {
  const { assetsType, assetsPreview } = useAppFilters();
  const dispatch = useDispatch();
  const { filter } = useSelector((state: RootState) => state.assetsPreview);
  const sensorsTypes = useMemo(() => Object.keys(sensorsIcon), []);

  const isSensorExists = (item: any, sensor: string) => {
    return item.label?.toString().toLowerCase().startsWith(sensor);
  };

  const renderFilterItem = (item: any) => (
    <div className={`${item.className} flex items-center justify-between w-[calc(100%-50px)] gap-4`}>
      <div className="w-full text-sm">{item.label}</div>
      {item.id?.toString().includes('sensor') && renderSensorOption(item)}
      {sensorsTypes.map((sensor, index) => {
        return (
          isSensorExists(item, sensor) && (
            <div className="scale-150 pb-0.5" key={index}>
              {sensorsIcon[sensor as 'camera']}
            </div>
          )
        );
      })}
    </div>
  );

  if (assetsPreview !== ASSETS_PREVIEW.ASSETS) return null;
  return (
    <BaseDropdownFilterMenu
      disabled={disabled}
      onFilterChange={(filters) => {
        dispatch(setAssetsPreviewFilterItems(filters));
      }}
      filterLable="Filter"
      type="filter"
      variant="outline"
      size="lg"
      data={
        assetsType === ASSETS_TYPE.MOBILE
          ? filter.filter((menuItem: any) => !menuItem.menuLabel.toLowerCase().includes('sensors'))
          : filter
      }
      showCounters={true}
      showAll={false}
      checkmarkPlacement="left"
      menuItemCounterRender={(count) => <Counter value={count} variant={'tab'} size="lg" className="text-center" />}
      menuItemRender={renderFilterItem}
    />
  );
};
