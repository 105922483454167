import { Asset } from '@/NewUI/types/_common/asset.types';
import {
  SENSOR_TYPE_ID,
  ASSETS_STATUS_ID,
  ASSETS_CONDITION_ID,
  DATA_COVERAEGE_ID,
  MENU_LABLES,
} from './types/filter.enums';
import { Menu } from './types/filter.types';
import { initialState } from './filter-initial-state';

export function applyAssetsFilter(
  assets: Asset[],
  filterMenu: Record<string, string[] | null> | null,
  options: { isMobile?: boolean } = {}
): Asset[] {
  if (!filterMenu) return assets;

  return assets.filter((asset) => {
    const assetRatio = asset.sensors_count_active / asset.sensors_count_total;
    return Object.entries(filterMenu).every(([menuLabel, filterItems]) => {
      if (!filterItems || filterItems.length === 0) return false; // Skip empty or null filters

      switch (menuLabel) {
        case MENU_LABLES.ASSETS_HEALTH_STATUS:
          return filterItems.includes(asset.health_status);

        case MENU_LABLES.ASSETS_CONDITION:
          if (filterItems.includes(ASSETS_CONDITION_ID.STATUS_CHANGED) && asset?.health_status_changed) return true;
          if (filterItems.includes(ASSETS_CONDITION_ID.NO_STATUS_CHANGE) && !asset?.health_status_changed) return true;
          return false;

        case MENU_LABLES.DATA_COVERAEGE:
          if (filterItems.includes(DATA_COVERAEGE_ID.OFFLINE) && assetRatio <= 0.5) return true;
          if (filterItems.includes(DATA_COVERAEGE_ID.SEMI) && assetRatio > 0.5 && assetRatio <= 0.8) return true;
          if (filterItems.includes(DATA_COVERAEGE_ID.ONLINE) && assetRatio > 0.8) return true;
          return false;

        case MENU_LABLES.ASSETS_STATUS:
          if (filterItems.includes(ASSETS_STATUS_ID.NA) && asset.not_monitored) return true;
          if (filterItems.includes(ASSETS_STATUS_ID.ONLINE) && !asset.not_monitored) return true;
          return false;

        case MENU_LABLES.SENSORS_TYPE:
          if (!options.isMobile) {
            return asset.sensors_health_status.some((sensor) => {
              if (
                filterItems.includes(SENSOR_TYPE_ID.CAMERA) &&
                sensor.sensor_type.toLowerCase() === SENSOR_TYPE_ID.CAMERA
              )
                return true;
              if (filterItems.includes(SENSOR_TYPE_ID.NON_CAMERA))
                return sensor.sensor_type.toLowerCase() !== SENSOR_TYPE_ID.CAMERA;
              return filterItems.includes(sensor.sensor_type.toLowerCase());
            });
          }
          return true;

        default:
          return true; // If the menuLabel is unrecognized, allow the asset
      }
    });
  });
}

export function processAssetsData(assets: Asset[]): Menu[] {
  // Clone initialState to avoid mutation and prepare counters
  const result: Menu[] = initialState.filter.map((menu) => ({
    ...menu,
    menuItems: menu.menuItems.map((item) => ({ ...item, counter: 0 })),
  }));

  // Helper function to increment counters
  const incrementCounter = (menuLabel: string, itemId: string) => {
    const menu = result.find((menu) => menu.menuLabel === menuLabel);
    if (!menu) return;
    const item = menu.menuItems.find((menuItem) => menuItem.id === itemId);
    if (item) item.counter++;
  };

  // Process each asset
  assets.forEach((asset) => {
    const assetRatio = asset.sensors_count_active / asset.sensors_count_total;

    // Update health status counters
    incrementCounter(MENU_LABLES.ASSETS_HEALTH_STATUS, asset.health_status);

    // Update condition counters
    incrementCounter(
      MENU_LABLES.ASSETS_CONDITION,
      asset.health_status_changed ? ASSETS_CONDITION_ID.STATUS_CHANGED : ASSETS_CONDITION_ID.NO_STATUS_CHANGE
    );

    // Update data coverage counters
    if (assetRatio <= 0.5) incrementCounter(MENU_LABLES.DATA_COVERAEGE, DATA_COVERAEGE_ID.OFFLINE);
    else if (assetRatio > 0.5 && assetRatio <= 0.8)
      incrementCounter(MENU_LABLES.DATA_COVERAEGE, DATA_COVERAEGE_ID.SEMI);
    else if (assetRatio > 0.8) incrementCounter(MENU_LABLES.DATA_COVERAEGE, DATA_COVERAEGE_ID.ONLINE);

    // Update sensors type counters
    asset.sensors_health_status.forEach((sensor) => {
      const sensorType = sensor.sensor_type.toLowerCase();
      if (sensorType === SENSOR_TYPE_ID.CAMERA) incrementCounter(MENU_LABLES.SENSORS_TYPE, SENSOR_TYPE_ID.CAMERA);
      else incrementCounter(MENU_LABLES.SENSORS_TYPE, SENSOR_TYPE_ID.NON_CAMERA);
    });

    // Update monitored status counters
    incrementCounter(MENU_LABLES.ASSETS_STATUS, asset.not_monitored ? ASSETS_STATUS_ID.NA : ASSETS_STATUS_ID.ONLINE);
  });

  const ans = result.map((menu) => ({
    ...menu,
    menuItems: menu.menuItems.map((item) => ({
      ...item,
      disabled: item.counter === 0,
    })),
  }));

  // Filter out items with counter 0, mark them as disabled
  return ans;
}
