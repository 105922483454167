import { cn } from '@/lib/utils';
import { VariantType } from '@/types';
import React from 'react';

interface CounterProps {
  value: number;
  prefix?: string;
  variant?: VariantType;
  className?: string;
  disabled?: boolean;
  size?: 'xl' | 'lg' | 'sm' | 'md';
}

const variantOptions = {
  filled: 'bg-white text-primary-500',
  tab: 'bg-neutral-25 text-dark',
};
const sizeOptions = {
  xl: 'py-1 px-2 text-lg font-semibold h-8 w-10',
  lg: 'py-1 px-2 text-sm font-semibold h-7 w-12 min-w-12 max-w-12',
  md: 'py-0.25 px-1.5 text-sm font-bold h-7 w-9',
  sm: 'py-0.25 px-0.5 text-xs font-bold h-6 w-8',
};

export const Counter = ({ value, prefix, variant = 'filled', size = 'sm', disabled, className }: CounterProps) => {
  return (
    <div
      className={cn(
        'rounded-lg h-full self-center w-fit  flex items-center justify-center ',
        variantOptions[variant as 'filled'],
        sizeOptions[size as 'sm'],
        `${disabled && 'text-white bg-neutral-100'}`,
        className
      )}
    >
      {prefix}
      {/* {+value < 10 ? '0' + value : value} */}
      {value}
    </div>
  );
};
