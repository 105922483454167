import React, { useEffect, useState } from 'react';
import { MachineStatusChartNew } from './SiteHealthOverview/MachineStatusChartOld_New';
import { CommonFailures } from './SiteHealthOverview/CommonFailures';
import { useIsDMAdmin } from '@/NewUI/hooks/_common/useIsDMAdmin';
import { MonthlyImpactNew } from './DowntimeImpact';
import { SiteHealthPieChart } from './SiteHealthOverview';
import Image from './image.svg';
import { cn } from '@/lib/utils';
import { BaseCard, GridCards } from '@/components/UI-kit/Cards';
import { useAppFilters } from '@/NewUI/hooks';
import { ASSETS_PREVIEW, ASSETS_TYPE } from '@/types/_common/navigationTabs.enums';

const componentMap = {
  'site-health-pie-chart': {
    title: 'Site Health',
    element: <SiteHealthPieChart />,
  },
  'machine-status-chart': {
    title: 'Machine Status',
    element: <MachineStatusChartNew />,
  },
  'common-failures-1': {
    title: 'Common Failures',
    element: <CommonFailures />,
  },
  'mock-image': {
    title: 'Mock Image',
    element: (
      <div className="font-bold rounded-2xl h-full w-full p-0 m-0 max-h-[500px]">
        <img src={Image} alt="mock" height="100%" width="100%" />
      </div>
    ),
  },
};

const layoutOrderConfig = {
  1: { card: 'col-span-12 lg:col-span-4 xl:col-span-4', content: 'min-h-full w-full' },
  2: { card: 'col-span-12 lg:col-span-8 xl:col-span-8', content: 'h-full w-full' },
  3: { card: 'col-span-12 xl:col-span-12 h-full w-full', content: 'h-full w-full' },
  4: { card: 'col-span-12 xl:col-span-6 h-full w-full', content: 'h-full w-full' },
  5: { card: 'col-span-12 xl:col-span-6 h-full w-full', content: 'h-full w-full' },
};

const layoutConfig = [
  { id: 'site-health-pie-chart', order: 1 },
  { id: 'machine-status-chart', order: 2 },
  { id: 'common-failures-1', order: 3 },
  // { id: 'mock-image', order: 4 },
  // { id: 'common-failures-1', order: 5 },
];

const businessInsightsComponents = layoutConfig.map((item) => {
  return {
    ...componentMap[item.id as keyof typeof componentMap],
    classNames: {
      ...layoutOrderConfig[item.order as keyof typeof layoutOrderConfig],
      card: cn(
        layoutOrderConfig[item.order as keyof typeof layoutOrderConfig].card,
        'transition-all duration-200 ease-in-out hover:shadow-md'
      ),
    },
    order: item.order,
  };
});

export const BusinessInsights = () => {
  const { isDMAdmin } = useIsDMAdmin();
  const [title, setTitle] = useState('downtime');
  const { setFilters, assetsType } = useAppFilters();
  // bi is not avaliable in mobile at this moment
  useEffect(() => {
    if (assetsType === ASSETS_TYPE.MOBILE) setFilters({ assetsPreview: ASSETS_PREVIEW.ASSETS });
  }, [assetsType]);

  return (
    <div className="flex flex-col gap-4 overflow-auto max-h-full">
      {isDMAdmin && (
        <BaseCard
          classNames={{ content: 'px-0' }}
          variant="wrapper"
          title={
            <span className="text-ellipsis max-w-[55%] overflow-hidden whitespace-nowrap text-sm capitalize">
              {`accumulated monthly ${title} impact`}
            </span>
          }
        >
          <MonthlyImpactNew setTitle={setTitle} />
        </BaseCard>
      )}
      <GridCards elements={businessInsightsComponents} title={'Site Health Overview'} enableDrag={false} />
    </div>
  );
};
