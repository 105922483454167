import { Button } from '@/components/ui/button';
import React, { ReactNode } from 'react';
import { DialogType, SizeType, TooltipType, VariantType } from '@/types';
import { DrawerType } from '@/types/UI-Kit/drawer.types';
import { cn } from '@/lib/utils';
import { Counter } from '../Counter/Counter';
import { BaseUIKitWrapper } from '../_common/BaseUIKitWrapper';
export interface BaseButtonProps {
  onClick?: () => void;
  isActive?: boolean;
  tooltip?: TooltipType;
  label?: string;
  content?: ReactNode;
  dialog?: DialogType;
  drawer?: DrawerType;
  href?: string;
  id?: string;
  className?: string;
  variant?: VariantType;
  size?: SizeType;
  showLabel?: boolean;
  children?: ReactNode;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  icon?: any;
  counter?: number;
  disabled?: boolean;
  selected?: boolean;
}

export const BaseButton = ({
  onClick,
  isActive = false,
  tooltip,
  dialog,
  drawer,
  variant,
  label,
  className,
  showLabel,
  content,
  children,
  leftIcon,
  rightIcon,
  counter,
  icon,
  disabled,
  selected,
  size = 'default',
}: BaseButtonProps) => {
  //@TODO - change icon to ReactNode once we migrate from chakra
  const Icon = icon;
  return (
    <BaseUIKitWrapper tooltip={tooltip} drawer={drawer} dialog={dialog}>
      {size === 'icon' ? (
        <Button variant={variant} disabled={disabled} size="icon" onClick={onClick}>
          <div>{icon && icon}</div>
          {showLabel && <div className="whitespace-pre-line">{label}</div>}
        </Button>
      ) : (
        <Button
          disabled={disabled}
          variant={variant}
          size={size}
          onClick={onClick}
          className={cn(`flex items-center justify-center `, className)}
        >
          {leftIcon && <span className="mr-2">{leftIcon}</span>}
          {children}
          {counter === 0 ||
            (counter && (
              <Counter
                value={counter}
                size={size === 'sm' || size === 'xs' ? 'sm' : 'md'}
                variant={variant !== 'filled' ? 'tab' : 'filled'}
              />
            ))}
          {rightIcon && <span className="ml-2">{rightIcon}</span>}
        </Button>
      )}
    </BaseUIKitWrapper>
  );
};
