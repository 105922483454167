import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Card, CardBody, Center, Hide, HStack, Skeleton, Spinner } from '@chakra-ui/react';
import Plot from 'react-plotly.js';
import { Annotations, Data, Layout, Shape } from 'plotly.js';
import { Loading } from 'src/components/Loading';
import {
  calculateDomains,
  getIconByHealthStatus,
  getRestSensorOptions,
  getSelectionLineShape,
  getSubPlotTitleAnnotations,
  getTracesBySensorType,
  getTrendOptions,
  getVibrationSensorOptions,
  isConditionSpeedCurrentSensor,
  isOilSensor,
  isTemperaturePressureSensor,
  isVibrationSensor,
  mergeArrays,
  mergeTraces,
  noDataPlaceholderAnnotation,
  oilReportSubPlotTitleAnnotations,
  orderTraces,
  patchTraces,
  patchTracesByTrend,
} from 'src/features/sensor-fusion/utils';
import { config, layoutInitialConfig } from 'src/features/sensor-fusion/config';
import TrendsSensorsFilterPopover from 'src/features/sensor-fusion/TrendsSensorsFilterPopover';
import useScreenSize from 'src/hooks/useScreenSize';
import { debounce } from 'src/utils';
import { format } from 'date-fns';
import { SensorFusionLegend } from 'src/features/sensor-fusion/SensorFusionLegend';
import { useLazyGetSensorsPiTagsQuery, useLazyGetSensorsRMSQuery } from 'src/app/api/sensorsApi';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getAssetById } from 'src/app/queries';
import {
  DIAGNOSTICS_FROM_DATE_FORMAT,
  DIAGNOSTICS_REGULAR_DATE_FORMAT,
  DIAGNOSTICS_TO_DATE_FORMAT,
  SENSORS_DATA_TYPE,
} from 'src/const';
import {
  MAINTENANCE_CHART_CONTEXT,
  MaintenanceChart,
} from 'src/features/maintenance-data/maintenance-chart/MaintenanceChart';
import { PreferencesPopover } from 'src/features/maintenance-data/maintenance-chart/components/PreferencesPopover';
import { useSelector } from 'react-redux';
import { dateRangePickerSelector, dispatch, maintenanceChartVisible, RootState } from 'src/app/store';
import { DMChartIcon } from 'src/components/Icons';
import * as Sentry from '@sentry/react';
import { setActiveSensorDataInnerTab, setSelectedOilReportMetadata } from '@/app/slices/applicationSlice';
import { MultipleSubplotsToolbar } from '@/features/mobile-fleet-sensor-data/components/MultipleSubplotsModebar';

export const oilReportShape: Partial<Data> | any = {
  type: 'scatter',
  mode: 'markers',
  name: 'Oil Report',
  marker: {
    size: 6,
    color: '#01152b',
    symbol: 'circle',
  },
  text: '\u25B6',
  textposition: 'middle center',
  opacity: 1,
  showlegend: false,
  hoverinfo: 'text',
  hovertext: 'Click to view Oil Report',
  clickmode: 'event',
};

interface SensorFusionPlotProps {
  title: string;
  component: any;
  revision: number;
  selectedComponent: any;
  catchErrorHandler: (toastId: string, sensorsDataType: SENSORS_DATA_TYPE, toastTitle: string) => (error: any) => void;
  assetName?: string;
  oilReportRecap?: any;
  isLoadingOilReportRecap?: boolean;
}

const processOilReportsData = (data: any) => {
  return Object.entries(data).flatMap(([coord, entries]: [string, any]) =>
    entries.map((entry: any) => ({
      coord,
      ...entry.meta,
    }))
  );
};

const SensorFusionPlot = ({
  title,
  component,
  revision,
  selectedComponent,
  catchErrorHandler,
  assetName,
  oilReportRecap,
  isLoadingOilReportRecap,
}: SensorFusionPlotProps) => {
  const dateRangePickerDate = useSelector(dateRangePickerSelector);
  const screenSize = useScreenSize();
  const { t } = useTranslation();
  const { siteId, machineId } = useParams<string>();
  const { asset } = getAssetById(siteId, machineId);
  const [layout, setLayout] = useState<Partial<Layout>>();
  const [oilReportLayout, setOilReportLayout] = useState<Partial<Layout> | any>();
  const [traces, setTraces] = useState<Array<Data>>([]);
  const [visibleTraces, setVisibleTraces] = useState<Array<Data>>([]);
  const [currentSelection, setCurrentSelection] = useState<Array<any>>();
  const [pinSelection, setPinSelection] = useState<Array<any>>();
  const [trendOptions, setTrendOptions] = useState<Array<any>>();
  const [vibrationSensorOptions, setVibrationSensorOptions] = useState<Array<any>>();
  const [operationalSensorOptions, setOperationalSensorOptions] = useState<Array<any>>();
  const [yAxisDomains, setYAxisDomains] = useState<number[][]>();
  const [xAxisMinMaintenance, setXAxisMinMaintenance] = useState<string>();
  const [xAxisMaxMaintenance, setXAxisMaxMaintenance] = useState<string>();
  const isMaintenanceChartVisible = useSelector(maintenanceChartVisible);

  const [isPlotInitialized, setIsPlotInitialized] = useState<boolean>(false);
  const [isOilReportPlotInitialized, setIsOilReportPlotInitialized] = useState<boolean>(false);

  const oilReportLayoutHeight = useMemo(() => {
    return oilReportLayout?.height || 0;
  }, [oilReportLayout]);

  const oilReportMetadata = useMemo(() => {
    return oilReportRecap ? processOilReportsData(oilReportRecap) : [];
  }, [oilReportRecap]);

  const isOilReportEnabled = useSelector((state: RootState) => state.application.isOilReportEnabled);

  const [
    fetchSensorsRMS,
    {
      data: sensorsDataRMS = [],
      isLoading: isSensorsDataRMSLoading,
      isFetching: isSensorsDataRMSFetching,
      isError: isSensorsDataRMSError,
    },
  ] = useLazyGetSensorsRMSQuery({});

  const [
    fetchSensorsPiTags,
    { data: piTagsData, isLoading: isLoadingPiTags, isFetching: isFetchingPiTags, isError: isPiTagsError },
  ] = useLazyGetSensorsPiTagsQuery({});

  const fetchData = (queryParams: Record<string, string | undefined>) => {
    fetchSensorsRMS({ ...queryParams }, true)
      .unwrap()
      .catch(catchErrorHandler(queryParams.asset_name!, SENSORS_DATA_TYPE.RMS, t('plots.rms.error')));

    fetchSensorsPiTags({ ...queryParams }, true)
      .unwrap()
      .catch(catchErrorHandler(queryParams.asset_name!, SENSORS_DATA_TYPE.PI, t('plots.pi.error')));
  };

  const queryParams: Record<string, string | undefined> = {
    site_id: siteId as string,
    asset_id: asset?.asset_id,
    asset_name: assetName || asset?.asset_name,
    component_id: component.component_id,
    component_name: component.component_name,
    to_date: dateRangePickerDate.to ? format(new Date(dateRangePickerDate.to), DIAGNOSTICS_TO_DATE_FORMAT) : undefined,
    from_date: dateRangePickerDate.from
      ? format(new Date(dateRangePickerDate.from), DIAGNOSTICS_FROM_DATE_FORMAT)
      : undefined,
  };

  useEffect(() => {
    fetchData(queryParams);
  }, [component, machineId]);

  useEffect(() => {
    fetchData({
      ...queryParams,
    });

    setPinSelection(undefined);
    setCurrentSelection(undefined);
  }, [dateRangePickerDate.from, dateRangePickerDate.to]);

  const boxRef: any = useRef(null);

  if (boxRef && boxRef.current) {
    const box = boxRef.current;
    let drag = false;

    box.addEventListener('mousedown', () => (drag = false));
    box.addEventListener('mousemove', () => (drag = true));
    box.addEventListener('mouseup', (e: any) => {
      if (drag) {
        e.stopPropagation();
      } else {
        return;
      }
    });
  }

  const handleMouseMove = (event: any) => {
    const boxRect = boxRef.current.getBoundingClientRect();
    const x = event.clientX - boxRect.left;
    const y = event.clientY - boxRect.top;

    boxRef.current.style.setProperty('--x', `${x}px`);
    boxRef.current.style.setProperty('--y', `${y - 8}px`);
  };

  let doubleClickOccurred = false;

  const handleSingleClick = debounce((data: Plotly.PlotMouseEvent) => {
    if (!doubleClickOccurred) {
      if (data.event.button === 0) {
        if (data.event.shiftKey) {
          setPinSelection(data.points);
        } else {
          setCurrentSelection(data.points);
        }
      }

      if (data.event.button === 2) {
        setPinSelection(data.points);
      }
    }
    doubleClickOccurred = false;
  }, 200);

  const handleDoubleClick = () => {
    doubleClickOccurred = true;
    clearTimeout((handleSingleClick as any).timeout);
  };

  const onUnpinSelection = () => setPinSelection(undefined);

  const initializeLayout = (options?: Record<any, any>) => {
    const mergedTraces = orderTraces(mergeTraces(sensorsDataRMS, piTagsData));

    mergedTraces?.forEach((item: any) => {
      if (!isVibrationSensor(item) && !isTemperaturePressureSensor(item) && !isConditionSpeedCurrentSensor(item)) {
        const warningString = `Unknown sensor type in ${siteId} > ${machineId}`;

        Sentry.captureMessage(warningString, {
          level: 'warning',
        });
        console.log(warningString);
      }
    });

    const vibrationSensorTraces = getTracesBySensorType(mergedTraces, isVibrationSensor);
    const temperaturePressureSensorTraces = getTracesBySensorType(mergedTraces, isTemperaturePressureSensor);
    const conditionSpeedCurrentSensorTraces = getTracesBySensorType(mergedTraces, isConditionSpeedCurrentSensor);
    const oilSensorTraces = getTracesBySensorType(mergedTraces, isOilSensor);

    const trendOptionsMerged = getTrendOptions(mergedTraces).map((option: any) => {
      const previousValue = trendOptions?.find((item: any) => option.name === item.name);

      return previousValue
        ? previousValue
        : {
            ...option,
            selected: option.name === 'Velocity RMS',
          };
    });

    setTrendOptions(trendOptionsMerged);

    const vibrationSensorOptionsMerged = getVibrationSensorOptions(mergedTraces).map((option: any) => {
      const previousValue = vibrationSensorOptions?.find((item: any) => option.name === item.name);

      return previousValue ? previousValue : option;
    });
    setVibrationSensorOptions(vibrationSensorOptionsMerged);

    const operationalSensorOptionsMerged = getRestSensorOptions(mergedTraces).map((option: any) => {
      const previousValue = operationalSensorOptions?.find((item: any) => option.name === item.name);

      return previousValue ? previousValue : option;
    });
    setOperationalSensorOptions(operationalSensorOptionsMerged);

    const patchedTraces = patchTraces(
      mergedTraces,
      mergeArrays(vibrationSensorOptionsMerged, operationalSensorOptionsMerged)
    );

    const result = trendOptionsMerged.length
      ? patchTracesByTrend(trendOptionsMerged, patchedTraces)
      : [...patchedTraces];

    setTraces(result);
    setVisibleTraces(result);

    const yAxisDomainsValue = calculateDomains(
      vibrationSensorTraces.length,
      temperaturePressureSensorTraces.length,
      conditionSpeedCurrentSensorTraces.length,
      oilSensorTraces.length
    );

    setYAxisDomains(yAxisDomainsValue);

    const fromDateString = format(new Date(dateRangePickerDate.from), DIAGNOSTICS_FROM_DATE_FORMAT);
    const toDateString = format(new Date(dateRangePickerDate.to), DIAGNOSTICS_TO_DATE_FORMAT);

    const yValues = oilReportMetadata.map((item: any) => item.coord);
    const uniqueCoords = Array.from(new Set(yValues));

    const heightOffset = (coordsNum: any) => {
      if (coordsNum === 1) return 550;
      if (coordsNum === 2) return 550;
      if (coordsNum === 3) return 550;
      if (coordsNum === 4) return 560;

      return 400;
    };

    setLayout({
      ...layoutInitialConfig,
      margin: {
        ...layoutInitialConfig.margin,
        b: isOilReportEnabled && oilReportMetadata?.length ? 0 : 32,
      },
      height:
        options?.screenSize.height -
        heightOffset(uniqueCoords?.length) +
        (isMaintenanceChartVisible.onSensorFusion ? 0 : 90) -
        (isOilReportEnabled && oilReportMetadata?.length ? oilReportLayoutHeight : 0),
      xaxis: {
        ...layoutInitialConfig.xaxis,
        autorange: false,
        range: [fromDateString, toDateString],
        showticklabels: !isOilReportEnabled || !oilReportMetadata?.length,
      },
      yaxis: {
        ...layoutInitialConfig.yaxis,
        domain: yAxisDomainsValue[0] || [],
      },
      yaxis2: {
        ...layoutInitialConfig.yaxis,
        domain: yAxisDomainsValue[1] || [],
      },
      yaxis3: {
        ...layoutInitialConfig.yaxis,
        domain: yAxisDomainsValue[2] || [],
      },
      yaxis4: {
        ...layoutInitialConfig.yaxis,
        domain: yAxisDomainsValue[3] || [],
      },
      shapes: [],
    });
  };

  useEffect(() => {
    if (oilReportMetadata?.length) {
      const fromDateString = format(new Date(dateRangePickerDate.from), DIAGNOSTICS_FROM_DATE_FORMAT);
      const toDateString = format(new Date(dateRangePickerDate.to), DIAGNOSTICS_TO_DATE_FORMAT);

      setXAxisMinMaintenance(fromDateString);
      setXAxisMaxMaintenance(toDateString);

      const yValues = oilReportMetadata.map((item: any) => item.coord);
      const uniqueCoords = Array.from(new Set(yValues));
      const dynamicHeight = uniqueCoords.length === 1 ? 140 : 120 + uniqueCoords.length * 10;

      const getIconSizeY = (rows: number) => {
        if (rows > 4) return 0.75;

        const sizes: Record<number, number> = {
          2: 0.45,
          3: 0.55,
          4: 0.65,
        };

        return sizes[rows] || 0.7;
      };

      const sizeY = getIconSizeY(uniqueCoords.length);

      setOilReportLayout({
        height: dynamicHeight || 100,
        xaxis: {
          autorange: false,
          range: [fromDateString, toDateString],
          showline: true,
          linecolor: 'rgba(2, 29, 61, 0.3)',
          linewidth: 1,
          showgrid: true,
          zeroline: true,
        },
        yaxis: {
          type: 'category',
          categoryorder: 'array',
          showgrid: true,
          zeroline: true,
          showticklabels: false,
          linecolor: 'rgba(2, 29, 61, 0.3)',
          showline: true,
          autorange: true,
        },
        margin: { t: 24, l: 48, r: 16, b: 16 },
        showlegend: false,
        autosize: true,
        annotations: [
          ...oilReportSubPlotTitleAnnotations,
          ...uniqueCoords.map((category: any, index: number) => ({
            x: 0,
            y: category,
            yshift: 18,
            xref: 'paper',
            yref: 'y',
            text: category !== 'null' ? `${category}` : '',
            showarrow: false,
            font: { size: 10, color: 'black' },
            align: 'left',
          })),
        ],
        images: oilReportMetadata?.map((item: any) => ({
          source: 'data:image/svg+xml;base64,' + btoa(getIconByHealthStatus(item.health_status)),
          x: new Date(item.date).toISOString(),
          y: item.coord,
          xref: 'x',
          yref: 'y',
          sizex: 2 * 24 * 60 * 60 * 10000,
          sizey: sizeY,
          xanchor: 'center',
          yanchor: 'middle',
          opacity: 1,
        })),
      });
    }
  }, [oilReportMetadata, isOilReportEnabled]);

  useEffect(() => {
    if (trendOptions?.length) {
      const patchedTraces = patchTraces(traces, vibrationSensorOptions);
      const result = patchTracesByTrend(trendOptions, patchedTraces);

      setTraces(result);
    }
  }, [vibrationSensorOptions, trendOptions]);

  useEffect(() => {
    const patchedTraces = patchTraces(traces, operationalSensorOptions);
    setTraces(patchedTraces);
  }, [operationalSensorOptions]);

  useEffect(() => {
    initializeLayout({
      screenSize: screenSize,
    });
  }, [
    piTagsData,
    sensorsDataRMS,
    revision,
    screenSize,
    isMaintenanceChartVisible.onSensorFusion,
    isOilReportEnabled,
    oilReportLayoutHeight,
    oilReportMetadata,
  ]);

  useEffect(() => {
    setIsPlotInitialized(false);
    setIsOilReportPlotInitialized(false);
  }, [component?.component_id]);

  useEffect(() => {
    const shapes: Partial<Shape>[] = [];

    if (pinSelection?.length) {
      shapes.push(getSelectionLineShape(pinSelection, 'dash'));
    }

    if (currentSelection?.length) {
      shapes.push(getSelectionLineShape(currentSelection, 'solid'));
    }

    setLayout({
      ...layout,
      shapes,
    });
  }, [pinSelection, currentSelection]);

  useEffect(() => {
    const selected = traces.filter((trace: any) => trace.selected);

    setVisibleTraces(selected);
  }, [traces]);

  useEffect(() => {
    if (visibleTraces.length || traces.length) {
      const sensorTypes = [
        {
          type: 'vibration',
          filterFn: isVibrationSensor,
        },
        {
          type: 'temperature',
          filterFn: isTemperaturePressureSensor,
        },
        {
          type: 'condition',
          filterFn: isConditionSpeedCurrentSensor,
        },
        {
          type: 'oil',
          filterFn: isOilSensor,
        },
      ];

      const activeSensors: any = sensorTypes
        .map(({ type, filterFn }) => ({
          type,
          visibleTraces: visibleTraces.filter(filterFn),
          allTraces: getTracesBySensorType(traces, filterFn),
        }))
        .filter(({ allTraces }) => allTraces.length);

      activeSensors.forEach((sensor: any, index: number) => {
        sensor.yRef = `y${activeSensors.length - index}`;
      });

      const annotations: Partial<Annotations | any>[] = [
        ...getSubPlotTitleAnnotations(
          activeSensors.some((sensor: any) => sensor.type === 'vibration'),
          activeSensors.some((sensor: any) => sensor.type === 'temperature'),
          activeSensors.some((sensor: any) => sensor.type === 'condition'),
          activeSensors.some((sensor: any) => sensor.type === 'oil'),
          yAxisDomains
        ),
      ];

      activeSensors.forEach(({ allTraces, visibleTraces, yRef }: any) => {
        if (allTraces.length && !visibleTraces.length) {
          annotations.push({
            ...noDataPlaceholderAnnotation,
            yref: yRef,
          });
        }
      });

      if (activeSensors.length === 1 && !activeSensors[0].visibleTraces.length) {
        annotations.push({
          ...noDataPlaceholderAnnotation,
          yref: activeSensors[0].yRef,
        });
      }

      setLayout({
        ...layout,
        annotations,
      });
    }
  }, [visibleTraces]);

  useEffect(() => {
    initializeLayout();
    setCurrentSelection(undefined);
    setPinSelection(undefined);
    fetchData({
      ...queryParams,
      to_date: dateRangePickerDate.to
        ? format(new Date(dateRangePickerDate.to), DIAGNOSTICS_TO_DATE_FORMAT)
        : undefined,
      from_date: dateRangePickerDate.from
        ? format(new Date(dateRangePickerDate.from), DIAGNOSTICS_FROM_DATE_FORMAT)
        : undefined,
    });
  }, [selectedComponent]);

  useEffect(() => {
    return () => {
      setTraces([]);
      setVisibleTraces([]);
    };
  }, []);

  const onContextMenu = (e: any) => e.preventDefault();

  useEffect(() => {
    if (dateRangePickerDate.from && dateRangePickerDate.to) {
      const fromDateString = format(new Date(dateRangePickerDate.from), DIAGNOSTICS_FROM_DATE_FORMAT);
      const toDateString = format(new Date(dateRangePickerDate.to), DIAGNOSTICS_TO_DATE_FORMAT);

      setXAxisMinMaintenance(fromDateString);
      setXAxisMaxMaintenance(toDateString);
    }
  }, [dateRangePickerDate.from, dateRangePickerDate.to]);

  const handleOilReportPlotClick = (event: any) => {
    const selectedPointDate = event.points?.[0]?.x;
    const selectedPointCoord = event.points?.[0]?.y;
    if (!selectedPointDate) return;

    const selectedOilReportMetadata = oilReportMetadata.find(
      (item: any) => item.date === selectedPointDate && item.coord == selectedPointCoord
    );

    if (selectedOilReportMetadata) {
      dispatch(setSelectedOilReportMetadata(selectedOilReportMetadata));
      dispatch(setActiveSensorDataInnerTab(SENSORS_DATA_TYPE.OilReports));
    }
  };

  const onRelayout = (event: any) => {
    const [xAxisRangeMin, xAxisRangeMax] = [event['xaxis.range[0]'], event['xaxis.range[1]']];

    const fromDateString = format(new Date(dateRangePickerDate.from), DIAGNOSTICS_FROM_DATE_FORMAT);
    const toDateString = format(new Date(dateRangePickerDate.to), DIAGNOSTICS_TO_DATE_FORMAT);

    if (oilReportMetadata?.length && isOilReportEnabled) {
      if (xAxisRangeMin && xAxisRangeMax) {
        setOilReportLayout({
          ...oilReportLayout,
          xaxis: {
            ...oilReportLayout?.xaxis,
            autorange: false,
            range: [xAxisRangeMin, xAxisRangeMax],
          },
        });

        const xAxisRangeMinString = format(new Date(xAxisRangeMin), DIAGNOSTICS_REGULAR_DATE_FORMAT);
        const xAxisRangeMaxString = format(new Date(xAxisRangeMax), DIAGNOSTICS_REGULAR_DATE_FORMAT);

        setXAxisMinMaintenance(xAxisRangeMinString);
        setXAxisMaxMaintenance(xAxisRangeMaxString);

        return;
      } else {
        setXAxisMinMaintenance(fromDateString);
        setXAxisMaxMaintenance(toDateString);

        setOilReportLayout({
          ...oilReportLayout,
          xaxis: {
            ...oilReportLayout?.xaxis,
            autorange: false,
            range: [fromDateString, toDateString],
          },
          yaxis: {
            ...oilReportLayout?.yaxis,
            autorange: true,
          },
        });
      }
    }

    if (!xAxisRangeMin && !xAxisRangeMax) {
      setXAxisMinMaintenance(fromDateString);
      setXAxisMaxMaintenance(toDateString);

      setLayout({
        ...layout,
        xaxis: {
          ...layout?.xaxis,
          autorange: false,
          range: [fromDateString, toDateString],
        },
      });
    }
  };

  const onOilReportPlotRelayout = (event: any) => {
    const [xAxisRangeMin, xAxisRangeMax] = [event['xaxis.range[0]'], event['xaxis.range[1]']];

    const fromDateString = format(new Date(dateRangePickerDate.from), DIAGNOSTICS_FROM_DATE_FORMAT);
    const toDateString = format(new Date(dateRangePickerDate.to), DIAGNOSTICS_TO_DATE_FORMAT);

    if (oilReportMetadata?.length && isOilReportEnabled) {
      if (xAxisRangeMin && xAxisRangeMax) {
        setLayout({
          ...layout,
          xaxis: {
            ...layout?.xaxis,
            autorange: false,
            range: [xAxisRangeMin, xAxisRangeMax],
          },
        });

        setXAxisMinMaintenance(format(new Date(xAxisRangeMin), DIAGNOSTICS_REGULAR_DATE_FORMAT));
        setXAxisMaxMaintenance(format(new Date(xAxisRangeMax), DIAGNOSTICS_REGULAR_DATE_FORMAT));
      }
    }

    if (!xAxisRangeMin && !xAxisRangeMax) {
      setXAxisMinMaintenance(fromDateString);
      setXAxisMaxMaintenance(toDateString);

      setOilReportLayout({
        ...oilReportLayout,
        xaxis: {
          ...oilReportLayout?.xaxis,
          autorange: false,
          range: [fromDateString, toDateString],
        },
      });
    }
  };

  const [oilReportData, setOilReportData] = useState<any>();

  useEffect(() => {
    if (oilReportMetadata?.length) {
      setOilReportData([
        {
          ...oilReportShape,
          x: oilReportMetadata.map((item: any) => new Date(item.date).toISOString()),
          y: oilReportMetadata.map((item: any) => item.coord),
          hovertemplate: oilReportMetadata.map((item: any) => {
            const coordName = item.coord !== 'null' ? `<br />${item.coord}` : '';

            return `Date: ${item.date}${coordName}<br /><span style="text-transform: capitalize">Health Status: ${item.health_status}</span><extra></extra>`;
          }),
          hoverinfo: 'text',
        },
      ]);
    } else {
      setOilReportData([]);
    }
  }, [oilReportMetadata]);

  return (
    <HStack w="full" spacing={4} alignItems="flex-start">
      <Box bgColor="#E6E8EC" w="full" p={2} pt={0} borderRadius="lg">
        <HStack h={12} justifyContent="space-between" px={2}>
          <Hide below="1.5xl">
            <HStack>
              <DMChartIcon fontSize="1.5rem" />
              <Box fontWeight={600} fontSize="0.875rem">
                {title}
              </Box>
            </HStack>
          </Hide>

          <HStack spacing={0}>
            <Hide below="1.5xl">
              <PreferencesPopover context={MAINTENANCE_CHART_CONTEXT.SensorFusion} mb={0} />
            </Hide>

            <TrendsSensorsFilterPopover
              traces={traces}
              trendOptions={trendOptions}
              vibrationSensorOptions={vibrationSensorOptions}
              operationalSensorOptions={operationalSensorOptions}
              setTrendOptions={setTrendOptions}
              setVibrationSensorOptions={setVibrationSensorOptions}
              setOperationalSensorOptions={setOperationalSensorOptions}
              isFetching={isFetchingPiTags || isSensorsDataRMSFetching}
            />
          </HStack>
        </HStack>

        <Card w="full" boxShadow={0}>
          <CardBody p={1} position="relative" opacity={isSensorsDataRMSFetching || isFetchingPiTags ? 0.5 : 1}>
            <Box
              ref={boxRef}
              height="100%"
              className="tooltip-box"
              onMouseMove={handleMouseMove}
              onContextMenu={onContextMenu}
              position="relative"
            >
              {!isPlotInitialized ? (
                <Skeleton
                  w="100%"
                  h="100%"
                  position="absolute"
                  top="0"
                  left="0"
                  startColor="white"
                  endColor="white"
                  opacity={1}
                  zIndex={1}
                  borderRadius="lg"
                />
              ) : null}

              {!isSensorsDataRMSFetching &&
              !isFetchingPiTags &&
              !isSensorsDataRMSLoading &&
              !isLoadingPiTags &&
              !traces.length ? (
                <Center py={screenSize?.height - 870}>No data available for selected time range</Center>
              ) : !isSensorsDataRMSFetching && !isSensorsDataRMSLoading && layout ? (
                <>
                  <Plot
                    useResizeHandler
                    className="sensor-fusion-sensors-plot"
                    revision={revision}
                    data={[...JSON.parse(JSON.stringify(visibleTraces))]}
                    layout={layout}
                    style={{ width: 'auto' }}
                    config={config}
                    onDoubleClick={handleDoubleClick}
                    onClick={handleSingleClick}
                    onRelayout={onRelayout}
                    onAfterPlot={() => {
                      setTimeout(() => {
                        setIsPlotInitialized(true);
                      }, 100);
                    }}
                  />
                </>
              ) : (
                <Loading py="calc(50vh - 215px)" />
              )}

              {(isLoadingPiTags || isFetchingPiTags) && !isSensorsDataRMSFetching ? (
                <Loading position="absolute" w="100%" top="calc(50vh - 250px)" />
              ) : null}
            </Box>

            <Box>
              {!isLoadingOilReportRecap ? (
                <Box position="relative">
                  {!isOilReportPlotInitialized ? (
                    <Skeleton
                      mt={1}
                      w="100%"
                      h="100%"
                      position="absolute"
                      top="0"
                      left="0"
                      startColor="white"
                      endColor="white"
                      opacity={1}
                      zIndex={1}
                      borderRadius="lg"
                    />
                  ) : null}

                  {oilReportData?.length && isOilReportEnabled ? (
                    <Plot
                      useResizeHandler
                      className="sensor-fusion-oil-plot"
                      data={oilReportData}
                      layout={oilReportLayout}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      config={{
                        displayModeBar: true,
                        displaylogo: false,
                        modeBarButtonsToRemove: ['lasso2d', 'select2d', 'autoScale2d'],
                      }}
                      onClick={handleOilReportPlotClick}
                      onHover={(event: any) => {
                        event.event.target.style.cursor = 'pointer';
                      }}
                      onUnhover={(event: any) => {
                        event.event.target.style.cursor = 'crosshair';
                      }}
                      onRelayout={onOilReportPlotRelayout}
                      onAfterPlot={() => {
                        setTimeout(() => {
                          setIsOilReportPlotInitialized(true);
                        }, 100);
                      }}
                    />
                  ) : null}
                </Box>
              ) : (
                <Center>
                  <Spinner size="sm" />
                </Center>
              )}

              <Box pt={4}>
                <MultipleSubplotsToolbar plotlyContainerClassName="sensor-fusion-sensors-plot" />
              </Box>
            </Box>

            {isMaintenanceChartVisible.onSensorFusion ? (
              <Box pt={4} pb={0} pl={7} pr={0}>
                <MaintenanceChart
                  selectedComponent={selectedComponent}
                  xAxisMin={xAxisMinMaintenance}
                  xAxisMax={xAxisMaxMaintenance}
                  context={MAINTENANCE_CHART_CONTEXT.SensorFusion}
                  wrapperRef={boxRef}
                />
              </Box>
            ) : null}
          </CardBody>
        </Card>
      </Box>

      <SensorFusionLegend
        currentSelection={currentSelection}
        pinSelection={pinSelection}
        onUnpinSelection={onUnpinSelection}
      />
    </HStack>
  );
};

export default SensorFusionPlot;
