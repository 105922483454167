import React from 'react';
import MobileSidebar from '../Sidebar/MobileSidebar';
import DrawerSwipeBtn from '@/components/UI-kit/Drawer/DrawerSwipeBtn';
import { useViewportWidth } from '@/NewUI/hooks/_common';
import { BaseDrawer } from '@/components/UI-kit';

export const Footer = () => {
  const { isMobile } = useViewportWidth();

  return (
    <footer className="flex lg:hidden">
      <BaseDrawer content={<MobileSidebar />} direction={isMobile ? 'bottom' : 'left'}>
        <DrawerSwipeBtn variant="bottom" />
      </BaseDrawer>
    </footer>
  );
};
