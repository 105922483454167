import React from 'react';
import { SitesPieChart } from './SitesPieChart';
import { SitesTable } from './SitesTable/SiteTable';
import useSitesPage from '@/NewUI/hooks/SitesPage/useSitesPage';
import { DMNoAssetResults } from '@/components/Icons';

const SitesEmptyFallback = () => {
  return (
    <div className="min-h-[calc(100%-30px)] border-8 flex-1 bg-white my-[-0.5rem] rounded-xl flex flex-col items-center justify-center border-gray-200">
      <DMNoAssetResults boxSize={16} />
      <div className="text-sm">No Sites Found</div>
    </div>
  );
};

export const Sites = () => {
  const { isLoading, columns, sitesIndexData, chartData, data } = useSitesPage();
  const isEmpty = chartData?.metaData?.total === 0;
  return (
    <div
      className={`flex gap-2 sm:gap-4 xl:flex-row  xl:h-full h-fit ${
        isEmpty ? 'h-full sm:mt-10 xl:mt-0' : 'flex-col-reverse '
      }`}
    >
      <div className={`w-full ${isEmpty ? 'bg-gray-200 rounded-lg' : ''}`}>
        <SitesTable isLoading={isLoading} columns={columns} data={isEmpty || !data ? [] : data} />
        {isEmpty && !isLoading && <SitesEmptyFallback />}
      </div>

      <div className="w-full xl:w-[20%] xl:max-w-[400px] 2xl:min-w-[350px] min-h-fit xl:min-h-full">
        <SitesPieChart chartIndexData={sitesIndexData} chartData={chartData} isLoading={isLoading} isEmpty={isEmpty} />
      </div>
    </div>
  );
};
