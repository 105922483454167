import { ASSETS_TYPE } from '@/types/_common/navigationTabs.enums';
import React, { useMemo } from 'react';
import { useFetchSites } from './useFetchSites';
import { useSitesPageTables } from './useSitesPageTables';
import { useSitesData } from './useSitesPieChart';
import { useQueryNavigation } from '../_common';

export default function useSitesPage() {
  const {
    currentParams: { search: searchValue, assetsType },
  } = useQueryNavigation();
  const { sites, isLoading } = useFetchSites();
  const { columns } = useSitesPageTables();

  const assetsTypeMemo = useMemo(() => assetsType, [assetsType]);
  const columnsMemo = useMemo(() => columns, [columns]);

  const { data, sitesIndexData, chartData } = useSitesData(
    searchValue ? sites?.filter((site) => site.site_name.toLowerCase().includes(searchValue.toLowerCase())) : sites,
    assetsTypeMemo?.split('-')[0] as 'all'
  );
  const filteredColumns = useMemo(() => {
    return assetsTypeMemo === ASSETS_TYPE.ALL
      ? columnsMemo
      : assetsTypeMemo === ASSETS_TYPE.FIXED
      ? columnsMemo.filter((el: any) => el.accessorKey !== 'mobile_assets')
      : columnsMemo.filter((el: any) => el.accessorKey !== 'fixed_assets');
  }, [columnsMemo, assetsTypeMemo]);

  const assetsData = data?.map((el) => {
    const assetTypeData = el?.[assetsTypeMemo?.split('-')[0] as 'all'];
    return {
      site_name: el?.site_name,
      site_id: el?.site_id,
      fixed_assets: el?.fixed_assets,
      mobile_assets: el?.mobile_assets,
      latest_diagnostic_date: assetTypeData?.latest_diagnostic_date,
      assets_health_statuses_counts: assetTypeData?.assets_health_statuses_counts,
    };
  });
  return {
    isLoading,
    columns: filteredColumns,
    sitesIndexData,
    chartData,
    data: assetsData,
  };
}
