import { createApi, retry } from '@reduxjs/toolkit/dist/query/react';
import { userTimezone } from 'src/const';
import { BeltMisalignmentDataTransformedResponse, RockSizeDataTransformedResponse } from 'src/app/types/sensors';
import {
  beltMisalignmentResponseTransformer,
  rockSizeResponseTransformer,
  sensorsPiTagsResponseTransformer,
  sensorsRmsResponseTransformer,
  spectralAccelerationResponseTransformer,
  spectralVelocityResponseTransformer,
} from 'src/app/responseTransformers';
import { baseQueryWithStatusCheck } from 'src/app/baseQueryWithStatusCheck';

const baseQuery = retry(baseQueryWithStatusCheck, { maxRetries: 2 });

const getSearchParams = ({
  site_id,
  asset_id,
  asset_name,
  component_id,
  component_name,
  from_date,
  to_date,
}: Record<string, string | undefined>) => {
  const searchParams = new URLSearchParams();

  if (site_id) {
    searchParams.append('site_id', site_id);
  }
  if (asset_id) {
    searchParams.append('asset_id', asset_id);
  }
  if (asset_name) {
    searchParams.append('asset_name', asset_name);
  }
  if (component_id) {
    searchParams.append('component_id', component_id);
  }
  if (component_name) {
    searchParams.append('component_name', component_name);
  }
  if (from_date) {
    searchParams.append('from_date', from_date);
  }
  if (to_date) {
    searchParams.append('to_date', to_date);
  }

  searchParams.append('timezone', userTimezone);

  return searchParams;
};

export const sensorsApi = createApi({
  reducerPath: 'sensorsApi',
  baseQuery,
  tagTypes: ['RMS', 'SpectralAcceleration', 'SpectralVelocity', 'PiTags', 'RockSize', 'BeltMisalignment'],
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getSensorsRMS: builder.query<Array<any>, any>({
      query: ({ site_id, asset_name, component_id, from_date, to_date }) => {
        const searchParams = getSearchParams({ site_id, asset_name, component_id, from_date, to_date });
        return { url: `sensors_data/rms?${searchParams.toString()}` };
      },
      providesTags: (result = []) => [
        ...result.map(({ name }) => ({ type: 'RMS' as const, name })),
        { type: 'RMS', id: 'LIST' },
      ],
      transformResponse: sensorsRmsResponseTransformer,
    }),

    getSpectralAccelerationData: builder.query<Array<any>, any>({
      query: ({ site_id, asset_name, component_id }) => {
        const searchParams = getSearchParams({ site_id, asset_name, component_id });
        return { url: `sensors_data/spectral/acceleration_low_res?${searchParams.toString()}` };
      },
      providesTags: (result = []) => [
        ...result.map(({ name }) => ({ type: 'SpectralAcceleration' as const, name })),
        { type: 'SpectralAcceleration', id: 'LIST' },
      ],
      transformResponse: spectralAccelerationResponseTransformer,
    }),

    getSpectralVelocityData: builder.query<Array<any>, any>({
      query: ({ site_id, asset_name, component_id }) => {
        const searchParams = getSearchParams({ site_id, asset_name, component_id });
        return { url: `sensors_data/spectral/velocity_low_res?${searchParams.toString()}` };
      },
      providesTags: (result = []) => [
        ...result.map(({ name }) => ({ type: 'SpectralVelocity' as const, name })),
        { type: 'SpectralVelocity', id: 'LIST' },
      ],
      transformResponse: spectralVelocityResponseTransformer,
    }),

    getSensorsPiTags: builder.query<Array<any>, any>({
      query: ({ site_id, asset_name, component_id, from_date, to_date }) => {
        const searchParams = getSearchParams({ site_id, asset_name, component_id, from_date, to_date });
        return { url: `sensors_data/pi_tags?${searchParams.toString()}` };
      },
      providesTags: (result = []) => [
        ...result.map(({ name }) => ({ type: 'PiTags' as const, name })),
        { type: 'PiTags', id: 'LIST' },
      ],
      transformResponse: sensorsPiTagsResponseTransformer,
    }),

    getRockSize: builder.query<RockSizeDataTransformedResponse, Record<string, string | undefined>>({
      query: ({ site_id, asset_id, component_id, from_date, to_date }) => {
        const searchParams = getSearchParams({ site_id, asset_id, component_id, from_date, to_date });
        return { url: `sensors_data/rock_size?${searchParams.toString()}` };
      },
      providesTags: (result = []) => [
        ...result.map(({ name }: { name: string }) => ({ type: 'RockSize' as const, name })),
        { type: 'RockSize', id: 'LIST' },
      ],
      transformResponse: rockSizeResponseTransformer,
    }),

    getBeltMisalignment: builder.query<BeltMisalignmentDataTransformedResponse, Record<string, string | undefined>>({
      query: ({ site_id, asset_id, component_id, from_date, to_date }) => {
        const searchParams = getSearchParams({ site_id, asset_id, component_id, from_date, to_date });
        return { url: `sensors_data/belt_alignment?${searchParams.toString()}` };
      },
      providesTags: (result = []) => [
        ...result.map(({ name }: { name: string }) => ({ type: 'BeltMisalignment' as const, name })),
        { type: 'BeltMisalignment', id: 'LIST' },
      ],
      transformResponse: beltMisalignmentResponseTransformer,
    }),
  }),
});

export const {
  useLazyGetSensorsRMSQuery,
  useLazyGetSpectralAccelerationDataQuery,
  useLazyGetSpectralVelocityDataQuery,
  useLazyGetSensorsPiTagsQuery,
  useLazyGetRockSizeQuery,
  useLazyGetBeltMisalignmentQuery,
} = sensorsApi;
