import React from 'react';
import { Box, HStack } from '@chakra-ui/react';

interface LegendItemProps {
  item: Record<string, string>;
}

const LegendItem: React.FC<LegendItemProps> = ({ item }) => {
  return (
    <HStack py={2} spacing={1}>
      <Box w={3} h={1} bgColor={item.color} borderRadius="full" />
      <Box fontSize="0.75rem" fontWeight={500}>
        {item.label}
      </Box>
    </HStack>
  );
};

interface LegendProps {
  items: Array<Record<string, string>>;
}

export const Legend: React.FC<LegendProps> = ({ items }) => {
  return items.length ? (
    <HStack spacing={3}>
      {items.map((item: Record<string, string>, index) => (
        <LegendItem item={item} key={index} />
      ))}
    </HStack>
  ) : null;
};
