import React, { ReactNode } from 'react';
import { BaseDialog } from '../Dialog';
import { BaseDrawer } from '../Drawer';
import { BaseTooltip } from '../Tooltip';

interface BaseButtonWrapperProps {
  dialog?: { title?: string; element: ReactNode | string; className?: string };
  drawer?: {
    title?: string;
    element: ReactNode | string;
    direction?: 'left' | 'right' | 'top' | 'bottom';
    className?: string;
  };
  tooltip?: {
    element: ReactNode | string;
    direction?: 'left' | 'right' | 'top' | 'bottom';
  };
  children: ReactNode;
}

export const BaseUIKitWrapper = ({ drawer, tooltip, dialog, children }: BaseButtonWrapperProps) => {
  return (
    <div
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();
      }}
      className="flex items-center justify-center"
    >
      <BaseTooltip content={tooltip && tooltip.element} side={tooltip ? tooltip.direction : 'bottom'}>
        <BaseDrawer
          title={drawer && drawer.title}
          content={drawer && drawer.element}
          direction={drawer ? drawer.direction : 'left'}
          className={drawer ? drawer.className : ''}
        >
          <BaseDialog
            title={dialog && dialog.title}
            content={dialog && dialog.element}
            className={dialog && dialog.className}
          >
            {children}
          </BaseDialog>
        </BaseDrawer>
      </BaseTooltip>
    </div>
  );
};
