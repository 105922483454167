import { locations } from '@/app/locations';
import { Card } from '@/components/ui/card';
import { Asset } from '@/types/assets';
import React, { version } from 'react';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { ASSETS_TYPE } from '@/types/_common/navigationTabs.enums';
import { Separator } from '@radix-ui/react-separator';
import { BodySection } from './CardSections/BodySection';
import { HeaderSection } from './CardSections/HeaderSection';
import { FooterSection } from './CardSections/FooterSection';
import { cn } from '@/lib/utils';
import { RootState } from '@/app/store';
import { useSelector } from 'react-redux';
import { useQueryNavigation } from '@/NewUI/hooks';

interface MachineItemProps {
  item: Asset;
  isRequireAttention?: boolean;
  isNA?: boolean;
  view?: 'list' | 'grid';
  assetsType?: ASSETS_TYPE;
}

const componentLayout = {
  card: `w-full min-h-[100px] flex-col justify-start p-1 gap-1 flex hover:ring-primary-600 hover:ring-2 hover:cursor-pointer shadow-sm`,
  mainTitle: 'flex justify-between items-center',
  secondaryTitle: 'flex justify-between items-center',
  healthStatusIndicator: 'flex gap-2 items-center',
  assetsFaultIndicator: 'flex items-center gap-3  overflow-hidden  whitespace-nowrap pt-2 w-full',
};

export const AssetsPreviewItem = ({ item, isRequireAttention = false, assetsType }: MachineItemProps) => {
  const { navigateWithQuery } = useQueryNavigation();
  const { siteId } = useParams();
  const onMachineItemClick = () => {
    navigateWithQuery(generatePath(locations.protected.sites.components, { siteId, machineId: item.asset_id }));
  };

  return (
    item && (
      <Card onClick={onMachineItemClick} className={cn(componentLayout.card)}>
        <div className={`p-3 ${!item.not_monitored || 'bg-gray-100 '}`}>
          <HeaderSection className={componentLayout.mainTitle} asset={item} assetsType={assetsType} />
          <BodySection
            asset={item}
            classNames={{ assetsFaultIndicator: componentLayout.assetsFaultIndicator }}
            isRequireAttention={isRequireAttention}
          />
          <Separator orientation="vertical" className="h-0.5 bg-gray-200 mb-3" />
          <FooterSection
            isRequireAttention={isRequireAttention}
            asset={item}
            classNames={{ assetsFaultIndicator: componentLayout.assetsFaultIndicator }}
          />
        </div>
      </Card>
    )
  );
};
