import React from 'react';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { SensorFusionCard } from 'src/features/sensor-fusion/SensorFusionCard';
import { DMSensorDataGraphLegendIcon } from 'src/components/Icons';

interface SensorFusionLegendProps {
  currentSelection: any;
  pinSelection: any;
  onUnpinSelection: () => void;
}

export const SensorFusionLegend = ({ currentSelection, pinSelection, onUnpinSelection }: SensorFusionLegendProps) => {
  return (
    <VStack
      alignItems="flex-start"
      bgColor="#E6E8EC"
      px={2}
      borderRadius="lg"
      minW="22rem"
      maxW="22rem"
      height="calc(100vh - 208px)"
      borderY="8px solid #E6E8EC"
      gap="0.25rem"
    >
      <HStack
        pb={2}
        pt={1.5}
        px={3}
        fontWeight={600}
        fontSize="0.875rem"
        lineHeight="1.5rem"
        bgColor="#E6E8EC"
        gap="0.25rem"
      >
        <DMSensorDataGraphLegendIcon fontSize="1.5rem" />
        <Box>Graph Legend</Box>
      </HStack>
      <VStack flex="1" minHeight="0px" maxW="full">
        <SensorFusionCard selectedData={currentSelection} pinnedData={pinSelection} />
        <SensorFusionCard selectedData={pinSelection} isPinned onUnpinSelection={onUnpinSelection} />
      </VStack>
    </VStack>
  );
};
