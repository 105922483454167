import React from 'react';
import { AssetPreviewTable } from '../AssetsPreviewItems/AssetPreviewTable';

interface ListViewProps {
  requireAttention: any[];
  notRequireAttention: any[];
}
export const ListView = ({ requireAttention, notRequireAttention }: ListViewProps) => {
  return (
    <>
      <AssetPreviewTable
        title={`${requireAttention?.length} Assets Require Attention`}
        data={requireAttention as any}
      />
      <AssetPreviewTable
        title={`${notRequireAttention?.length} Assets Don't Require Attention`}
        data={notRequireAttention as any}
      />
    </>
  );
};
