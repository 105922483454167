import { setSelectedView } from '@/app/slices/assetsPreviewSlice';
import { dispatch } from '@/app/store';
import { BaseIconButton } from '@/components';
import { DMGridViewIcon, DMListViewIcon } from '@/components/Icons';
import { cn } from '@/lib/utils';
import React from 'react';
{
  /* @TODO:replace with toggle btns (typeof tabs)\? */
}

interface AssetPreviewViewsSelectorsProps {
  selectedView: 'grid' | 'list';
}

export const AssetPreviewViewsSelectors = ({ selectedView }: AssetPreviewViewsSelectorsProps) => {
  return (
    <div className="xl:flex rounded-xl hidden ">
      <BaseIconButton
        classNames={{
          button: cn(
            ' border-2  first-of-type:rounded-r-none first-of-type:border-r-none  w-full px-5 py-3 h-100',
            selectedView === 'grid' ? 'bg-accent text-accent-foreground border-accent' : 'hover:bg-neutral-25'
          ),
        }}
        label={'Grid'}
        showLabel={true}
        variant={'tab'}
        icon={DMGridViewIcon}
        onClick={() => dispatch(setSelectedView('grid'))}
      />
      <BaseIconButton
        label={'List'}
        showLabel={true}
        classNames={{
          button: cn(
            'border-2 last-of-type:rounded-l-none w-full px-5 py-5  last-of-type:border-l-none py-3 h-100',
            selectedView === 'list' ? 'bg-accent text-accent-foreground border-accent' : 'hover:bg-neutral-25'
          ),
        }}
        variant={'tab'}
        icon={DMListViewIcon}
        onClick={() => dispatch(setSelectedView('list'))}
      />
    </div>
  );
};
