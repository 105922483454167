import React, { ReactNode } from 'react';
import { Header } from './Header/Header';
import { Toaster } from '@/components/ui/toaster';
import { Footer } from './Footer/Footer';
import { ErrorBoundary } from 'react-error-boundary';
import Fallback from '@/components/Fallback';

interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      {/* @TODO: update </ErrorBoundary> and use a custom components that reffers to diff errors type w dedicated components */}
      <Header />
      <ErrorBoundary FallbackComponent={Fallback}>
        <main className="pl-0 xl:pl-sidebar xl:mt-header xl:pb-header mt-mobile-header max-h-main-mobile xl:max-h-main self-center overflow-hidden xl:pt-3 relative h-main pb-2">
          {children}
        </main>
        <Toaster />
      </ErrorBoundary>
      <Footer />
    </>
  );
};
