import { DMMachineIcon } from '@/components/Icons';
import { BaseCard } from '@/components/UI-kit/Cards/BaseCard';
import { Card, CardContent, CardTitle } from '@/components/ui/card';
import React, { ReactNode } from 'react';

interface AssetsPreviewCardProps {
  children: ReactNode;
  title: string;
}

export const AssetsPreviewCard = ({ children, title }: AssetsPreviewCardProps) => {
  return (
    <BaseCard variant="wrapper" title={title} classNames={{ content: 'p-0 bg-inherit' }}>
      {children}
    </BaseCard>
  );
};
