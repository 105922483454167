import { useLazyGetPotentialSavingsQuery } from '@/app/api/businessInsightsApi';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export default function useFetchPotentialSavings() {
  const { siteId } = useParams();
  const [fetchPotentialSaving, { data: potentialSavingData, isLoading }] = useLazyGetPotentialSavingsQuery({});

  useEffect(() => {
    fetchPotentialSaving({ site_id: siteId });
  }, []);

  const sortedFailures = useMemo(() => {
    const failuresData = potentialSavingData?.failures || {};
    const allFailures = Object.keys(failuresData)
      .flatMap((key) => failuresData[key])
      .sort((a, b) => new Date(b.start_date).getTime() - new Date(a.start_date).getTime());
    return allFailures;
  }, [potentialSavingData]);

  return { failures: sortedFailures, isLoading };
}
