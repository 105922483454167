import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { Button } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
interface BaseTooltipProps {
  children: ReactNode;
  content: ReactNode | string;
  side?: 'top' | 'left' | 'right' | 'bottom';
  className?: string;
}

export const BaseTooltip = ({ children, content, side = 'right', className }: BaseTooltipProps) => {
  if (!content) return <>{children}</>;
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger className={className}>{children}</TooltipTrigger>
        <TooltipContent side={side}>{content}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
