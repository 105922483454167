import React, { useState, useEffect } from 'react';
import { Hide, HStack, IconButton, Text, useMediaQuery, VStack, Box } from '@chakra-ui/react';
import { NavLink, useNavigate } from 'react-router-dom';
import { SIDEBAR_MENU_BUTTON_SIZE } from 'src/const';
import { DMSettingsIcon, DMSitesIcon } from 'src/components/Icons';
import { LegendPopover } from 'src/components/legend/LegendPopover';
import Notifications from 'src/components/Notifications';
import { locations } from 'src/app/locations';
import { useAuth } from 'src/providers/AuthProvider';
import { useDispatch } from 'react-redux';
import { diagnosticApi } from 'src/app/api/diagnosticApi';
import { userApi } from 'src/app/api/userApi';
import { theme } from 'src/theme';
import { LegendModal } from 'src/components/legend/LegendModal';
import { analytics } from 'src/analytics';
import { UserMenu } from 'src/components/UserMenu';
import { UserGuide } from 'src/components/UserGuide';

export const NavigationMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signOut } = useAuth();
  const [isNotMobileResolution] = useMediaQuery(`(min-width: ${theme.breakpoints.xl})`);

  const [startLoggingOut, setStartLoggingOut] = useState(false);

  const logoutUser = async () => {
    try {
      await signOut();
      dispatch(userApi.util.resetApiState());
      dispatch(diagnosticApi.util.resetApiState());
      navigate(locations.public.login);
      analytics.track('User logged out');
    } catch (error: unknown) {
      setStartLoggingOut(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (startLoggingOut) {
      logoutUser();
    }
  }, [startLoggingOut]);

  const onLogoutClick = () => {
    setStartLoggingOut(true);
  };

  return (
    <VStack
      justifyContent="space-between"
      h="full"
      spacing={0}
      color="white"
      alignItems={{ base: 'flex-start', xl: 'center' }}
      py={4}
    >
      <VStack spacing={2} w="100%" alignItems="center" justifyContent={'center'}>
        <NavLink to="/sites">
          {({ isActive }) => (
            <VStack spacing="4px">
              <IconButton
                aria-label="Sites"
                size="lg"
                background={isActive ? '#12FFE2' : 'transparent'}
                color={isActive ? '#021d3d' : '#fff'}
                borderRadius="16px"
                height="2rem"
                icon={<DMSitesIcon boxSize={SIDEBAR_MENU_BUTTON_SIZE} mb={0.5} />}
                variant="unstyled"
              />
              <Text fontSize="10px" lineHeight="14px" fontWeight={600}>
                Sites
              </Text>
            </VStack>
          )}
        </NavLink>

        {isNotMobileResolution ? <LegendPopover /> : <LegendModal />}
        {isNotMobileResolution && !startLoggingOut ? <Notifications /> : null}

        <Box>
          <UserGuide />
        </Box>

        <NavLink
          to="/settings"
          onClick={(e) => {
            if (!isNotMobileResolution) {
              e.preventDefault();
            }
          }}
        >
          {({ isActive }) => (
            <VStack spacing="4px">
              <IconButton
                aria-label="Settings"
                size="lg"
                background={isActive ? '#12FFE2' : 'transparent'}
                color={isActive ? '#021d3d' : '#fff'}
                borderRadius="16px"
                height="2rem"
                icon={<DMSettingsIcon boxSize={SIDEBAR_MENU_BUTTON_SIZE} mb={0.5} />}
                variant="unstyled"
              />
              <Text fontSize="10px" lineHeight="14px" fontWeight={600}>
                Settings
              </Text>
            </VStack>
          )}
        </NavLink>
      </VStack>

      <VStack spacing={4} w="100%" alignItems="center" justifyContent={'center'}>
        <Hide below="xl">
          <HStack justifyContent="center" w="full">
            <UserMenu />
          </HStack>
        </Hide>

        <Text fontSize="10px" lineHeight="14px" fontWeight={600} onClick={onLogoutClick} cursor="pointer">
          Logout
        </Text>
      </VStack>
    </VStack>
  );
};
