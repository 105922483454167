import React from 'react';
import {
  Box,
  BoxProps,
  Button,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from '@chakra-ui/react';
import { format, sub } from 'date-fns';
import { DMArrowDownIcon, DMArrowUpIcon, DMCalendarInfo } from 'src/components/Icons';
import { DateRangeCalendarPopover } from 'src/components/date-range-picker/components/DateRangeCalendarPopover';
import { useSelector } from 'react-redux';
import { dateRangePickerSelector, dispatch } from 'src/app/store';
import { REGULAR_DATE_FORMAT } from 'src/const';
import { resetDateRangePicker } from 'src/app/slices/applicationSlice';

interface DateRangePickerProps extends BoxProps {
  isInline?: boolean | undefined;
  isFetching?: boolean;
  buttonSize?: string;
}

export const DateRangePicker = ({ isInline = false, isFetching, buttonSize = 'lg', ...rest }: DateRangePickerProps) => {
  const { from: fromDateString, to: toDateString } = useSelector(dateRangePickerSelector);

  const hoverStyle = isInline
    ? {
        bgColor: 'transparent',
      }
    : {
        bgColor: 'rgba(112, 113, 243, 0.9)',
        color: 'white',
        border: '1px solid rgba(112, 113, 243, 1)',
      };

  const onResetClick = () => {
    dispatch(resetDateRangePicker());
  };

  return (
    <Box zIndex={9} className="visual-ai-datepicker" {...rest}>
      <Popover>
        {({ isOpen, onClose: onCloseChartDatePickerPopover }) => (
          <>
            <PopoverTrigger>
              <Button
                px={4}
                size={buttonSize}
                bgColor={isInline ? 'transparent' : isOpen ? 'rgba(112, 113, 243, 1)' : 'white'}
                color={isInline ? undefined : isOpen ? 'white' : 'rgb(26, 32, 44)'}
                fontSize="0.875rem"
                border={isInline ? undefined : isOpen ? '1px solid transparent' : '1px solid rgba(177, 185, 195, 1)'}
                leftIcon={<DMCalendarInfo fontSize="1.5rem" />}
                rightIcon={isOpen ? <DMArrowUpIcon fontSize="1.5rem" /> : <DMArrowDownIcon fontSize="1.5rem" />}
                isLoading={isInline ? undefined : isFetching}
                isDisabled={isFetching}
                _hover={hoverStyle}
              >
                {format(new Date(fromDateString), REGULAR_DATE_FORMAT)} -{' '}
                {format(new Date(toDateString), REGULAR_DATE_FORMAT)}
              </Button>
            </PopoverTrigger>

            <PopoverContent w="full" borderColor="rgba(177, 185, 195, 1)" zIndex={99}>
              <PopoverArrow />
              <PopoverBody p={0} w="full">
                <HStack justifyContent="space-between" p={4} fontSize="0.875rem" fontWeight="600">
                  <Box>Filter dates</Box>
                  <Button
                    variant="unstyled"
                    color="rgba(112, 113, 243, 1)"
                    fontSize="0.875rem"
                    size="xs"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                    onClick={onResetClick}
                  >
                    Reset
                  </Button>
                </HStack>

                <Box py={2} pb={8}>
                  <VStack px={4} spacing={4}>
                    <Box w="full">
                      <Box fontSize="0.75rem" pl={3}>
                        From date
                      </Box>
                      <DateRangeCalendarPopover
                        minDate={fromDateString}
                        selectedDate={fromDateString}
                        onCloseChartDatePickerPopover={onCloseChartDatePickerPopover}
                      />
                    </Box>

                    <Box w="full">
                      <Box fontSize="0.75rem" pl={3}>
                        To date
                      </Box>
                      <DateRangeCalendarPopover
                        selectedDate={toDateString}
                        onCloseChartDatePickerPopover={onCloseChartDatePickerPopover}
                        isDisabled
                      />
                    </Box>
                  </VStack>
                </Box>
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
  );
};
