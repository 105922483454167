import { RootState } from '@/app/store';
import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

interface UseHighlightOptions {
  text: string | React.ReactNode; // text can also be JSX now
  searchTerm?: string;
  Wrapper?: React.ComponentType<{ children: React.ReactNode }>;
}

export function useHighlight({ text, searchTerm, Wrapper }: UseHighlightOptions): React.ReactNode {
  const [searchParams] = useSearchParams();
  const searchVal = searchTerm || searchParams.get('search');

  if (!searchVal || typeof text !== 'string') {
    return text; // If the text is not a string, return it unchanged
  }

  // Create a regex to find the search term
  const regex = new RegExp(`(${searchVal})`, 'gi');
  const parts = text.split(regex);

  // Wrap the matching parts with the specified Wrapper or a default span
  return parts.map((part, index) =>
    regex.test(part) ? (
      Wrapper ? (
        <Wrapper key={index}>{part}</Wrapper>
      ) : (
        <span key={index} className="bg-primary-500 text-white">
          {part}
        </span>
      )
    ) : (
      part
    )
  );
}

// export const highlightTextInJSX: any = (
//   jsx: React.ReactNode,
//   searchTerm: string,
//   Wrapper?: React.ComponentType<{ children: React.ReactNode }>
// ) => {
//   // If it's a string, apply highlighting
//   if (typeof jsx === 'string') {
//     return useHighlight({ text: jsx, searchTerm, Wrapper });
//   }

//   // If it's a React component or JSX element, recurse into its children
//   if (React.isValidElement(jsx)) {
//     return React.cloneElement(
//       jsx,
//       {},
//       React.Children.map(jsx.props.children, (child) => highlightTextInJSX(child, searchTerm, Wrapper))
//     );
//   }
//   // If it's not a string or React element, return it unchanged
//   return jsx;
// };
