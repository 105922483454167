import React, { useEffect, useRef } from 'react';

export function useMousedownScroll() {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);
  const wasDragged = useRef(false); // Track if a drag occurred

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!scrollContainerRef.current) return;
    isDragging.current = true;
    wasDragged.current = false; // Reset drag state on mouse down
    startX.current = event.pageX - scrollContainerRef.current.offsetLeft;
    scrollLeft.current = scrollContainerRef.current.scrollLeft;
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!isDragging.current || !scrollContainerRef.current) return;
    event.preventDefault();
    const x = event.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX.current) * 1; // Adjust scrolling speed
    scrollContainerRef.current.scrollLeft = scrollLeft.current - walk;
    wasDragged.current = true; // Mark as dragged
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };

  const handleClick = (event: MouseEvent) => {
    if (wasDragged.current) {
      event.stopPropagation(); // Prevent click event if dragging occurred
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    container.addEventListener('mousemove', handleMouseMove);
    container.addEventListener('mouseup', handleMouseUp);
    container.addEventListener('mouseleave', handleMouseUp);
    container.addEventListener('click', handleClick, true); // Capture phase to stop before bubbling

    return () => {
      container.removeEventListener('mousemove', handleMouseMove);
      container.removeEventListener('mouseup', handleMouseUp);
      container.removeEventListener('mouseleave', handleMouseUp);
      container.removeEventListener('click', handleClick, true);
    };
  }, []);

  return { scrollContainerRef, handleMouseDown };
}
