import { getSiteById } from '@/app/queries';
import { NOT_MONITORED_DAYS } from '@/const';
import React from 'react';
import { useParams } from 'react-router-dom';

export const useIsMonitored = (lastDiagnostic: string) => {
  const { siteId } = useParams();
  const { site } = getSiteById(siteId);
  const siteLastVerified = site?.latest_diagnostic_created_at;

  const cutoffDate = new Date();
  cutoffDate.setDate(cutoffDate.getDate() - NOT_MONITORED_DAYS);

  const isNotMonitored = siteLastVerified
    ? new Date(lastDiagnostic) < new Date(siteLastVerified) || new Date(siteLastVerified) < cutoffDate
    : false;
  return !isNotMonitored;
};
