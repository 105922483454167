import React from 'react';
import { Skeleton } from '@/components/ui/skeleton';
import { useCommonFailuresTable } from '@/NewUI/hooks/AssetsPreviewPage/BusinessInsights/CommonFailures/useCommonFailuresTable';
import useFetchCommonFailures from '@/NewUI/hooks/AssetsPreviewPage/BusinessInsights/CommonFailures/useFetchcommonFailures';
import { useCommonFailuresData } from '@/NewUI/hooks/AssetsPreviewPage/BusinessInsights/CommonFailures/useCommonFailuresData';
import { useIsDMAdmin } from '@/NewUI/hooks/_common/useIsDMAdmin';
import { BaseDropdownFilterMenu } from '@/components/UI-kit/Dropdown';
import { BaseTable } from '@/components/UI-kit';

export const CommonFailures = () => {
  //@TODO: move data manipluation to another custom hook that handles data
  const { isLoading, rawCommonFailuresData } = useFetchCommonFailures();
  const {
    formattedDateRange,
    assetsFilters,
    periodFilters,
    commonFailures,
    selectedRange,
    setSelectedItems,
    handleSelectChange,
  } = useCommonFailuresData(rawCommonFailuresData);

  const { tableStructure } = useCommonFailuresTable();
  const { isDMAdmin } = useIsDMAdmin();

  return isLoading ? (
    <Skeleton className={`${isDMAdmin ? 'h-[200px]' : 'h-[40vh]'} w-full`} />
  ) : (
    <div className="flex flex-col gap-2">
      <div className="flex w-full justify-between items-center">
        <div>
          <div className="font-semibold text-sm capitalize">Most Common Failures</div>
          <div className="text-xs text-gray-500">{formattedDateRange}</div>
        </div>
        <div className="flex gap-2">
          <BaseDropdownFilterMenu
            type="filter"
            multiSelect={true}
            filterLable="All Assets"
            data={[{ menuLabel: 'Assets', menuItems: assetsFilters }]}
            onFilterChange={(filters) => setSelectedItems(filters.Assets)}
          />
          <BaseDropdownFilterMenu
            multiSelect={false}
            closeOnSelect={true}
            filterLable="Period Selection"
            defaultSelected={{ Period: ['1 Year'] }}
            onFilterChange={(filters) => {
              filters?.Period?.length > 0 &&
                handleSelectChange(periodFilters?.find((el) => el.id === filters.Period[0])?.value as '365');
            }}
            data={[{ menuLabel: 'Period', menuItems: periodFilters || [] }]}
          />
        </div>
      </div>
      <BaseTable
        data={commonFailures?.[selectedRange] || []}
        variant="base"
        mobileVariant="carousel"
        columns={tableStructure}
        enableSorting={true}
        // pageSize={3}
        classNames={{
          table: { container: `p-0 ${isDMAdmin ? 'max-h-[130px]' : 'h-full'}` },
          card: {
            container: 'bg-white',
            content: `px-0 overflow-y-auto ${isDMAdmin ? 'min-h-[140px] max-h-[135px]' : 'min-h-[40vh] max-h-[40vh]'}`,
          },
          pagination: { container: 'h-5 pt-2', text: 'text-sm', icons: 'scale-[0.7]' },
        }}
      />
    </div>
  );
};
