import React from 'react';
import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import { DMBackArrowIcon } from 'src/components/Icons';

interface SchemesMenuHeaderProps {
  onBackButtonClick?: () => void;
  selectedComponent: any;
}

export const SchemesMenuHeader: React.FC<SchemesMenuHeaderProps> = ({ onBackButtonClick, selectedComponent }) => {
  const onClick = () => {
    onBackButtonClick && onBackButtonClick();
  };

  return (
    <VStack
      w="full"
      alignItems="flex-start"
      px={4}
      pt={4}
      pb={4}
      spacing="3"
      bgColor="white"
      borderTopRadius="lg"
      borderBottom="1px solid #B1B9C3"
    >
      <Button variant="unstyled" size="xs" fontSize="0.875rem" color="DMPrimaryColor" onClick={onClick}>
        <HStack spacing={1}>
          <DMBackArrowIcon fontSize="1rem" />
          <Box>Back to Components</Box>
        </HStack>
      </Button>

      <HStack alignItems="flex-start" justifyContent="space-between" w="full">
        <Box fontWeight={600} fontSize="0.875rem" maxWidth="10rem">
          {selectedComponent.component_ui_name}
        </Box>
        <Box
          color="white"
          fontWeight={600}
          borderRadius="8px"
          background="#01152B"
          textAlign="center"
          px={2}
          fontSize="10px"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          Not Monitored
        </Box>
      </HStack>

      <HStack>
        <Box
          color={`health.${selectedComponent.health_status}`}
          fontSize="0.75rem"
          fontWeight={600}
          textTransform="capitalize"
        >
          {selectedComponent.health_status === 'na' ? 'NA' : selectedComponent.health_status}
        </Box>
        <Box fontSize="0.625rem" fontWeight={600}>
          {selectedComponent.health_status_changed ? 'Status Change' : null}
        </Box>
      </HStack>
    </VStack>
  );
};
