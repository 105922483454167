import { HEALTH_STATUS } from 'src/const';

export const healthStatusMap: Record<number | string, string> = {
  1: HEALTH_STATUS.CRITICAL,
  2: HEALTH_STATUS.ALARM,
  3: HEALTH_STATUS.MONITOR,
  4: HEALTH_STATUS.HEALTHY,
  5: HEALTH_STATUS.NOT_AVAILABLE,
};

export const healthStatusToNumberMap: Record<string | number, number> = {
  [HEALTH_STATUS.CRITICAL]: 1,
  [HEALTH_STATUS.ALARM]: 2,
  [HEALTH_STATUS.MONITOR]: 3,
  [HEALTH_STATUS.HEALTHY]: 4,
  [HEALTH_STATUS.NOT_AVAILABLE]: 5,
};

export const healthStatusToShadowColorMap: Record<number, string> = {
  1: '#cc000026',
  2: '#ff891b26',
  3: '#f1bf4226',
  4: '#11975d26',
  5: '#01152b26',
};
