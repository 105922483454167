import React, { useEffect } from 'react';
import { Box, useToast, VStack } from '@chakra-ui/react';
import { SENSOR_TYPE, SENSORS_DATA_TYPE, TOAST_STATUS } from 'src/const';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getAssetById } from 'src/app/queries';
import { useLazyGetSpectralAccelerationDataQuery, useLazyGetSpectralVelocityDataQuery } from 'src/app/api/sensorsApi';
import { SpectrumPlots } from 'src/features/spectrum/SpectrumPlots';
import { LastUpdate } from 'src/pages/machines/components/LastUpdate';
import { AssetSensorsHealthStatus } from 'src/types/assets';
import SensorFusionPlot from 'src/features/sensor-fusion/SensorFusionPlot';
import { useSelector } from 'react-redux';
import { dateRangePickerSelector, RootState } from '@/app/store';
import { OilReportTable } from '@/NewUI/components/AssetsStudioPage - WIP/MainContent/ReportsData/OilReportTable';
import { useFetchReports } from '@/NewUI/hooks/AssetsStudioPage/_common/useFetchReports';

interface SensorsTabsProps {
  component: any;
  activeSensorsDataType: string;
  revision: number;
  siteId: string | undefined;
  asset: any;
  componentLastUptime: any;
  selectedComponent: any;
  fromDate?: any;
  toDate?: any;
}

const SensorsTabs = ({
  component,
  activeSensorsDataType,
  revision,
  componentLastUptime,
  selectedComponent,
  fromDate,
  toDate,
  asset: passedAsset,
}: SensorsTabsProps) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { siteId, id: machineId } = useParams<string>();
  const { asset } = getAssetById(siteId, machineId);
  const activeSensorDataInnerTab = useSelector((state: RootState) => state.application.activeSensorDataInnerTab);

  const [
    fetchSpectralAccelerationData,
    {
      data: spectralAccelerationData = [],
      isLoading: isSpectralAccelerationLoading,
      isFetching: isSpectralAccelerationFetching,
      isError: isSpectralAccelerationError,
    },
  ] = useLazyGetSpectralAccelerationDataQuery({});

  const [
    fetchSpectralVelocityData,
    {
      data: spectralVelocityData = [],
      isLoading: isSpectralVelocityLoading,
      isFetching: isSpectralVelocityFetching,
      isError: isSpectralVelocityError,
    },
  ] = useLazyGetSpectralVelocityDataQuery({});

  const { oilReportRecap, oilReport, isLoadingOilReport, isFetchingOilReport } = useFetchReports({
    asset_name: passedAsset.asset_name,
    site_id: siteId,
    component_id: selectedComponent?.component_id,
  });

  const getVibrationSensors = (sensor: AssetSensorsHealthStatus) =>
    sensor.component_id === selectedComponent.component_id &&
    (sensor.sensor_type.toLowerCase() === SENSOR_TYPE.Vibration ||
      sensor.sensor_type.toLowerCase() === SENSOR_TYPE.Vibrations);

  const getNoDataDescription = (
    noDataMessage: string,
    noSensorsMessage: string,
    filterFunction: (sensor: AssetSensorsHealthStatus) => any
  ) => {
    const sensors = asset?.sensors_health_status?.filter(filterFunction);
    return sensors?.length ? noDataMessage : noSensorsMessage;
  };

  const catchErrorHandler = (toastId: string, sensorsDataType: SENSORS_DATA_TYPE, toastTitle: string) => {
    return (error: any) => {
      if (!toast.isActive(toastId)) {
        if (activeSensorsDataType === sensorsDataType) {
          toast({
            id: toastId,
            status: TOAST_STATUS.Error,
            title: toastTitle,
            description: t('general.errors.communication'),
          });
        }
      }
      Sentry.captureException(error?.data?.message || error?.error || error?.message || error?.originalError || error);
    };
  };

  useEffect(() => {
    const queryParams: Record<string, string> = {
      site_id: siteId as string,
      asset_id: asset?.asset_id,
      asset_name: asset?.asset_name,
      component_id: component.component_id,
      component_name: component.component_name,
    };

    fetchData(queryParams);
  }, [component, machineId]);

  const fetchData = (queryParams: Record<string, string | undefined>) => {
    fetchSpectralAccelerationData({ ...queryParams }, true)
      .unwrap()
      .catch(catchErrorHandler(queryParams.asset_name!, SENSORS_DATA_TYPE.FFT, t('plots.fft.error')));

    fetchSpectralVelocityData({ ...queryParams }, true)
      .unwrap()
      .catch(catchErrorHandler(queryParams.asset_name!, SENSORS_DATA_TYPE.FFT, t('plots.fft.error')));
  };

  return (
    <VStack alignItems="stretch" spacing={4}>
      {activeSensorDataInnerTab === SENSORS_DATA_TYPE.PI ? (
        <SensorFusionPlot
          title={t('plots.pi.title')}
          component={component}
          revision={revision}
          selectedComponent={selectedComponent}
          catchErrorHandler={catchErrorHandler}
          oilReportRecap={oilReportRecap}
          isLoadingOilReportRecap={isLoadingOilReport || isFetchingOilReport}
        />
      ) : null}

      {activeSensorDataInnerTab === SENSORS_DATA_TYPE.FFT ? (
        <SpectrumPlots
          title={t('plots.fft.title')}
          noDataDescription={getNoDataDescription(t('plots.fft.noData'), t('plots.fft.noSensors'), getVibrationSensors)}
          errorMessage={t('plots.fft.error')}
          dataAcc={spectralAccelerationData}
          dataVel={spectralVelocityData}
          isFetching={isSpectralVelocityFetching || isSpectralAccelerationFetching}
          isLoading={isSpectralVelocityLoading || isSpectralAccelerationLoading}
          isError={isSpectralAccelerationError || isSpectralVelocityError}
          revision={revision}
          lastUpdateComponent={
            <LastUpdate
              siteId={siteId}
              asset={asset}
              componentLastUptime={componentLastUptime}
              filterFunction={getVibrationSensors}
            />
          }
        />
      ) : null}

      {activeSensorDataInnerTab === SENSORS_DATA_TYPE.OilReports ? (
        <div className="max-w-[calc(100vw-29rem)] w-full flex self-start z-0">
          <OilReportTable
            fromDate={fromDate}
            toDate={toDate}
            oilReportData={oilReport}
            isLoading={isLoadingOilReport || isFetchingOilReport}
          />
        </div>
      ) : null}
    </VStack>
  );
};

export default SensorsTabs;
