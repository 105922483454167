import React, { Suspense, useEffect } from 'react';
import { LoadScript, GoogleMap } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { API_KEY, GMP_OPTIONS, MAP_CONTAINER_STYLE } from './site-map-config/config';
import { MapControllers } from './site-map-controllers/MapControllers';
import { Skeleton } from '@/components/ui/skeleton';
import { useRenderSiteMapData, useSiteMap } from '@/NewUI/hooks/AssetsPreviewPage/SiteMap';
import { MapViewLayerEnum } from '@/types/site-map';
import { useAppFilters } from '@/NewUI/hooks';
import { ASSETS_TYPE, ASSETS_PREVIEW } from '@/types/_common/navigationTabs.enums';

export const SiteMap = () => {
  const { onLoad, isLoading } = useSiteMap();
  // const { renderMulticoloredPolylines, renderHeatMap } = useRenderSiteMapData();
  const { selectedMapViewLayer, center, zoom } = useSelector((state: RootState) => state.siteMap);

  // sitemap is not avaliable in fixed asset at this moment
  const { setFilters, assetsType } = useAppFilters();
  useEffect(() => {
    if (assetsType === ASSETS_TYPE.FIXED) setFilters({ assetsPreview: ASSETS_PREVIEW.ASSETS });
  }, [assetsType]);

  return (
    <LoadScript googleMapsApiKey={`${API_KEY}`} libraries={['visualization']}>
      {isLoading ? (
        <Skeleton className="h-100 w-100" />
      ) : (
        <GoogleMap
          options={GMP_OPTIONS}
          mapContainerStyle={MAP_CONTAINER_STYLE}
          center={center}
          zoom={zoom}
          mapTypeId={'satellite'}
          onLoad={onLoad}
        >
          {/* {selectedMapViewLayer === MapViewLayerEnum.SPEED && renderMulticoloredPolylines()} */}
          {/* {selectedMapViewLayer === MapViewLayerEnum.TIRE_WEAR_HEATMAP && renderHeatMap()} */}
          <MapControllers />
        </GoogleMap>
      )}
    </LoadScript>
  );
};
