import { Badge } from '@/components/ui/badge';
import { HEALTH_STATUS } from '@/const';
import { cn } from '@/lib/utils';
import React, { ReactNode } from 'react';

interface StatusTagProps {
  status: HEALTH_STATUS | string;
  className?: string;
  suffix?: string | ReactNode;
}

export const StatusTag = ({ status, suffix, className }: StatusTagProps) => {
  const statusAlias = status === HEALTH_STATUS.NOT_AVAILABLE ? 'bg-notMonitored' : `bg-${status}`;
  return (
    <Badge
      className={cn(`text-white ${statusAlias} text-xs px-2 h-fit capitalize flex gap-1 items-center`, `${className}`)}
    >
      {status === HEALTH_STATUS.NOT_AVAILABLE ? 'N/A' : status}
      {suffix && <span className="text-xs">{suffix}</span>}
    </Badge>
  );
};
