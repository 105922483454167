import React, { useEffect, useState, useRef } from 'react';
import { Asset, AssetsView } from 'src/types/assets';
import { SENSORS_DATA_TYPE, NOT_MONITORED_DAYS } from 'src/const';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { locations } from 'src/app/locations';
import { MachineGridItem } from 'src/pages/machines/components/MachineGridItem';
import { MachineListItem } from 'src/pages/machines/components/MachineListItem';
import useScreenSize from 'src/hooks/useScreenSize';
import { debounce } from 'lodash';

type MachineItemProps = {
  item: Asset;
  isRequireAttention?: boolean;
  site?: any;
  selectedAssetsView: AssetsView;
};

export const MachineItem = ({ item, isRequireAttention, site, selectedAssetsView }: MachineItemProps) => {
  const navigate = useNavigate();
  const { siteId } = useParams();

  const isFirstRender = useRef(true);

  const [keyValue, setKeyValue] = useState(Math.random());

  const screenSize = useScreenSize();

  const generateNewKeyValue = useRef(
    debounce(() => {
      setKeyValue((prevValue) => prevValue + Math.random());
    }, 150)
  ).current;

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      generateNewKeyValue();
    }
  }, [screenSize.width]);

  const onMachineItemClick = () => {
    navigate(generatePath(locations.protected.sites.components, { siteId, machineId: item.asset_id }));
  };

  const hasCamera: boolean = item.sensors_health_status.some(
    (sensor: any) => sensor.sensor_type.toLowerCase() === SENSORS_DATA_TYPE.Camera
  );

  const cutoffDate = new Date();
  cutoffDate.setDate(cutoffDate.getDate() - NOT_MONITORED_DAYS);

  const siteLastVerified =
    item.asset_category === 'mobile'
      ? site?.assets_categories?.mobile?.latest_diagnostic_date
      : site?.assets_categories?.fixed?.latest_diagnostic_date;

  const isNotMonitored = siteLastVerified
    ? new Date(item.latest_diagnostic_created_at) < new Date(siteLastVerified) ||
      new Date(siteLastVerified) < cutoffDate
    : false;

  return (
    <>
      {selectedAssetsView === 'grid' ? (
        <MachineGridItem
          item={item}
          isRequireAttention={isRequireAttention}
          siteId={siteId}
          onMachineItemClick={onMachineItemClick}
          hasCamera={hasCamera}
          isNotMonitored={isNotMonitored}
          key={`${keyValue}-grid-item`}
        />
      ) : (
        <MachineListItem
          item={item}
          isRequireAttention={isRequireAttention}
          siteId={siteId}
          onMachineItemClick={onMachineItemClick}
          hasCamera={hasCamera}
          isNotMonitored={isNotMonitored}
          key={`${keyValue}-list-item`}
        />
      )}
    </>
  );
};
