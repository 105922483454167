import React from 'react';
import { BasePieChart } from '@/components/UI-kit';
import { Skeleton } from '@/components/ui/skeleton';
import { PieChartTopIndex } from './PieChartTopIndex';
import { useViewportWidth } from '@/NewUI/hooks/_common';
import { BaseCard } from '@/components/UI-kit/Cards/BaseCard';
import { PieChartBottomIndex } from '@/NewUI/components/_common/PieChartBottomIndex';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';
import { ErrorBoundary } from 'react-error-boundary';
import Fallback from '@/components/Fallback';

interface SitesPieChartProps {
  chartIndexData?: { data: any[]; metaData: any[] };
  chartData?: any;
  isLoading?: boolean;
  isEmpty?: boolean;
}

const renderLabel = (el: any, data: any) => {
  const { metaData, index } = data;
  const showSeperator = metaData.length - 1 > index;
  return (
    <>
      <div
        className={cn(
          'font-medium xl:min-h-[80px] h-fit text-xs text-center flex items-center capitalize w-1/2 justify-between min-w-100 xl:max-w-[100px] xl:items-center',
          ` ${
            metaData.length === 1 ? 'px-4 gap-4 w-full text-left whitespace-nowrap min-w-full ' : 'xl:flex-col gap-0'
          }`
        )}
      >
        <div
          className={cn(
            'whitespace-nowrap xl:whitespace-pre-wrap w-fit min-w-1/2 mr-8 xl:mr-0',
            `${metaData.length === 1 ? 'whitespace-nowrap w-full ' : ''}`,
            'text-sm'
          )}
        >
          {el.label}
        </div>
        <div className="font-semibold text-lg">{el.value}</div>
      </div>
      {showSeperator && <Separator className="mx-4 h-6 xl:h-14 bg-gray-300" orientation="vertical" />}
    </>
  );
};

export const SitesPieChart = ({ chartIndexData, isLoading, chartData }: SitesPieChartProps) => {
  const { isMobile, isTablet } = useViewportWidth();

  // TODO: use Suspense (and update all RTK queries and all the other components)
  return isLoading ? (
    <Skeleton className="w-100 h-[40dvh] xl:min-h-full rounded-xl" />
  ) : (
    <ErrorBoundary FallbackComponent={Fallback}>
      <BaseCard variant="wrapper" classNames={{ card: 'h-full', content: 'h-full' }}>
        <div className="w-full text-center font-semibold text-xl lg:text-2xl pt-3">Summary</div>
        <BasePieChart
          orientation={isMobile || isTablet ? 'horizontile' : 'vertical'}
          chartData={chartData}
          chartTopIndex={
            chartIndexData && <PieChartTopIndex chartIndexData={chartIndexData} renderLabel={renderLabel} />
          }
          chartBottomIndex={chartIndexData && <PieChartBottomIndex chartIndexData={chartIndexData} />}
        />
      </BaseCard>
    </ErrorBoundary>
  );
};
