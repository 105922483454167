import { Annotations, Dash, Shape } from 'plotly.js';
import {
  CONDITION_SENSOR_TYPE_KEYWORDS,
  OIL_SENSOR_TYPE_KEYWORDS,
  TEMPERATURE_SENSOR_TYPES_KEYWORDS,
  VIBRATION_SENSOR_TYPES_KEYWORDS,
} from 'src/const';
import { colors } from '@/theme/foundations/colors';

export const noDataPlaceholderAnnotation: Partial<Annotations> = {
  text: 'No sensor selected. Please select a sensor to view data.',
  xref: 'paper',
  showarrow: false,
  arrowhead: 2,
  font: {
    color: '#021d3d',
    family: 'Montserrat',
    size: 16,
  },
  xanchor: 'center',
  yanchor: 'middle',
};

export const oilReportSubPlotTitleAnnotations: Partial<Annotations>[] = [
  {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    xref: 'x domain',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yref: 'y domain',
    x: 0.02,
    y: 1.2,
    xanchor: 'left',
    yanchor: 'top',
    text: '<b>Oil Report</b>',
    showarrow: false,
    font: {
      color: '#01152B',
      family: 'Montserrat',
      size: 14,
    },
  },
];

const baseSubPlotAnnotation = {
  font: {
    color: '#01152B',
    family: 'Montserrat',
    size: 14,
  },
  showarrow: false,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  xref: 'x1 domain',
  x: 0.01,
  y: 1.1,
  xanchor: 'left',
  yanchor: 'top',
};

export const initialSubPlotTitleAnnotations: Partial<Annotations>[] | any = [
  {
    ...baseSubPlotAnnotation,
    text: '<b>Oil Quality</b>',
  },
  {
    ...baseSubPlotAnnotation,
    text: '<b>Current / Speed / Flow</b>',
  },
  {
    ...baseSubPlotAnnotation,
    text: '<b>Temperature / Pressure</b>',
  },
  {
    ...baseSubPlotAnnotation,
    text: '<b>Vibration</b>',
  },
];

export const getSubPlotTitleAnnotations = (
  hasVibrationSensorTraces: boolean,
  hasTemperaturePressureSensorTraces: boolean,
  hasConditionSpeedCurrentSensorTraces: boolean,
  hasOilSensorTraces: boolean,
  yAxisDomains?: Array<any>
) => {
  const annotations: Partial<Annotations>[] = [];
  const subplotsNumber = [
    hasOilSensorTraces,
    hasConditionSpeedCurrentSensorTraces,
    hasTemperaturePressureSensorTraces,
    hasVibrationSensorTraces,
  ].filter(Boolean).length;

  const sensorTraceFlags = [
    { hasTrace: hasOilSensorTraces, index: 0 },
    { hasTrace: hasConditionSpeedCurrentSensorTraces, index: 1 },
    { hasTrace: hasTemperaturePressureSensorTraces, index: 2 },
    { hasTrace: hasVibrationSensorTraces, index: 3 },
  ];

  sensorTraceFlags
    .filter(({ hasTrace }) => hasTrace)
    .forEach(({ index }, idx) => {
      annotations.push({
        ...initialSubPlotTitleAnnotations[index],
        y: subplotsNumber === 1 ? 1.025 : subplotsNumber === 2 ? 1.055 : 1.1,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        yref: `y${idx + 1} domain`,
      });
    });

  return annotations || [];
};

export const getTrendOptions = (traces: any) => {
  return traces
    .filter((trace: any) => trace.trend !== undefined)
    .reduce((acc: any, current: any) => {
      if (!acc.includes(current.trend)) {
        acc.push(current.trend);
      }
      return acc;
    }, [])
    .map((name: any) => {
      return {
        name: name,
        selected: true,
      };
    });
};

export const getVibrationSensorOptions = (traces: any) => {
  return traces
    .filter((trace: any) => trace.trend !== undefined)
    .reduce((acc: any, current: any) => {
      if (!acc.includes(current.name)) {
        acc.push(current.name);
      }
      return acc;
    }, [])
    .map((name: any) => {
      return {
        name: name,
        selected: true,
      };
    });
};

export const getRestSensorOptions = (traces: any) => {
  return traces
    .filter((trace: any) => trace.trend === undefined)
    .map((trace: any) => {
      return {
        name: trace.name,
        selected: true,
      };
    });
};

export const updateOptions = (options: any, value: any) => {
  return options.map((option: any) => {
    if (option.name === value.name) {
      return {
        ...option,
        selected: !value.selected,
      };
    } else {
      return option;
    }
  });
};

export const mergeArrays = (firstArray: any, secondArray: any) => {
  firstArray = firstArray || [];
  secondArray = secondArray || [];

  return [...firstArray, ...secondArray];
};

export const patchTraces = (traces: any, options: any, property = 'name') => {
  return traces.map((trace: any) => {
    const item = options?.find((option: any) => option.name === trace[property]);
    return item ? { ...trace, selected: item.selected } : trace;
  });
};

export const calculateDomains = (a: boolean, b: boolean, c: boolean, d: boolean) => {
  const trueCount = [a, b, c, d].filter(Boolean).length;
  const domains = [];

  if (trueCount === 1) {
    if (a || b || c || d) domains.push([0, 1]);
  } else if (trueCount === 2) {
    if (a && b) {
      domains.push([0, 0.48]);
      domains.push([0.52, 1]);
    }
    if (a && c) {
      domains.push([0, 0.48]);
      domains.push([0.52, 1]);
    }
    if (a && d) {
      domains.push([0, 0.48]);
      domains.push([0.52, 1]);
    }
    if (b && c) {
      domains.push([0, 0.48]);
      domains.push([0.52, 1]);
    }
    if (b && d) {
      domains.push([0, 0.48]);
      domains.push([0.52, 1]);
    }
    if (c && d) {
      domains.push([0, 0.48]);
      domains.push([0.52, 1]);
    }
  } else if (trueCount === 3) {
    if (a && b && c) {
      domains.push([0, 0.3]);
      domains.push([0.35, 0.65]);
      domains.push([0.7, 1]);
    }
    if (a && b && d) {
      domains.push([0, 0.3]);
      domains.push([0.35, 0.65]);
      domains.push([0.7, 1]);
    }
    if (a && c && d) {
      domains.push([0, 0.3]);
      domains.push([0.35, 0.65]);
      domains.push([0.7, 1]);
    }
    if (b && c && d) {
      domains.push([0, 0.3]);
      domains.push([0.35, 0.65]);
      domains.push([0.7, 1]);
    }
  } else if (trueCount === 4) {
    domains.push([0, 0.23]);
    domains.push([0.26, 0.5]);
    domains.push([0.53, 0.77]);
    domains.push([0.8, 1]);
  }

  return domains;
};

export const generateUniqueId = () => `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

export enum SENSOR_FUSION_OPTIONS_TYPE {
  Trends = 'trends',
  VibrationSensors = 'vibrationSensors',
  OperationalSensors = 'operationalSensors',
}

export const isVibrationSensor = (item: any) => {
  return VIBRATION_SENSOR_TYPES_KEYWORDS.some((keyword) => item?.sensorType?.toLowerCase().includes(keyword));
};

export const isTemperaturePressureSensor = (item: any) => {
  return TEMPERATURE_SENSOR_TYPES_KEYWORDS.some((keyword) => item?.sensorType?.toLowerCase().includes(keyword));
};

export const isConditionSpeedCurrentSensor = (item: any) => {
  return CONDITION_SENSOR_TYPE_KEYWORDS.some((keyword) => item?.sensorType?.toLowerCase().includes(keyword));
};

export const isOilSensor = (item: any) => {
  return OIL_SENSOR_TYPE_KEYWORDS.some((keyword) => item?.sensorType?.toLowerCase().includes(keyword));
};

export const getTracesBySensorType = (traces: any, conditionFunc: (item: any) => boolean) => {
  return traces.filter(conditionFunc);
};

export const orderTraces = (traces: any) => {
  const isVibrationSensorsExists = traces.some((trace: any) => isVibrationSensor(trace));
  const isTemperatureSensorsExists = traces.some((trace: any) => isTemperaturePressureSensor(trace));
  const isConditionSensorsExists = traces.some((trace: any) => isConditionSpeedCurrentSensor(trace));
  const isOilSensorsExists = traces.some((trace: any) => isOilSensor(trace));

  const yAxes = ['y1', 'y2', 'y3', 'y4'];

  const categories = [
    isOilSensorsExists && 'oil',
    isConditionSensorsExists && 'condition',
    isTemperatureSensorsExists && 'temperature',
    isVibrationSensorsExists && 'vibration',
  ].filter(Boolean);

  const categoryToYAxis: Record<string, string> = {};
  categories.forEach((category, index) => {
    categoryToYAxis[category] = yAxes[index];
  });

  const orderedTraces = traces.map((item: any) => {
    if (isOilSensor(item)) {
      return { ...item, yaxis: categoryToYAxis['oil'] };
    }
    if (isConditionSpeedCurrentSensor(item)) {
      return { ...item, yaxis: categoryToYAxis['condition'] };
    }
    if (isTemperaturePressureSensor(item)) {
      return { ...item, yaxis: categoryToYAxis['temperature'] };
    }
    if (isVibrationSensor(item)) {
      return { ...item, yaxis: categoryToYAxis['vibration'] };
    }
    return item;
  });

  return orderedTraces;
};

export const mergeTraces = (dataRms: any, dataPi: any) => {
  let dataPiTraces;
  let dataRmsTraces;

  if (dataPi?.length) {
    dataPiTraces = [...dataPi.map((item: any) => item.measurements[0])];
  }

  if (dataRms?.length) {
    dataRmsTraces = [
      ...dataRms
        .map((item: any) => {
          return item.measurements.map((measurement: any) => ({
            ...measurement,
            trend: item.name,
          }));
        })
        .flat(),
    ];
  }

  return mergeArrays(dataPiTraces, dataRmsTraces)
    .map((item: any) => ({
      ...item,
      id: generateUniqueId(),
      selected: true,
    }))
    .filter((item: any) => item !== undefined);
};

export const trimMiddle = (string: string, maxLength: number) => {
  const halfLength = Math.floor((maxLength - 3) / 2);

  if (string.length <= maxLength) {
    return string;
  }

  return string.substring(0, halfLength) + '...' + string.substring(string.length - halfLength);
};

export const patchTracesByTrend = (trendOptions: any, patchedTraces: any) => {
  let result;

  if (trendOptions[0].selected && trendOptions[1].selected) {
    result = patchedTraces;
  } else if (!trendOptions[0].selected && trendOptions[1].selected) {
    result = patchedTraces.map((trace: any) => {
      if (trace.trend && trace.trend === trendOptions[0].name) {
        return {
          ...trace,
          selected: false,
        };
      } else {
        return trace;
      }
    });
  } else if (trendOptions[0].selected && !trendOptions[1].selected) {
    result = patchedTraces.map((trace: any) => {
      if (trace.trend && trace.trend === trendOptions[1].name) {
        return {
          ...trace,
          selected: false,
        };
      } else {
        return trace;
      }
    });
  } else if (!(trendOptions[0].selected && trendOptions[1].selected)) {
    result = patchedTraces.map((trace: any) => {
      if (trace.trend) {
        return {
          ...trace,
          selected: false,
        };
      } else {
        return trace;
      }
    });
  } else if (!(trendOptions[0].selected && !trendOptions[1].selected)) {
    result = patchedTraces.map((trace: any) => {
      if (trace.trend) {
        return {
          ...trace,
          selected: false,
        };
      } else {
        return trace;
      }
    });
  }

  return result;
};

export function findDifferences(array1: any, array2: any) {
  const map1 = new Map();
  array1.forEach((item: any) => {
    map1.set(item.id, item.value);
  });

  const result: any[] = [];

  array2.forEach((item: any) => {
    if (map1.has(item.id)) {
      const diff = item.value - map1.get(item.id);
      result.push({ id: item.id, value: diff });
    } else {
      return;
    }
  });

  return result;
}

export const getGrayBackgroundShape = (yAxisDomains: Array<any>): Partial<Shape> => {
  return {
    type: 'rect',
    xref: 'paper',
    yref: 'paper',
    x0: 0,
    y0: (() => {
      if (yAxisDomains.length === 2 || yAxisDomains.length === 3) {
        return yAxisDomains[1][0];
      } else {
        return 0;
      }
    })(),
    x1: 1,
    y1: (() => {
      if (yAxisDomains.length === 2 || yAxisDomains.length === 3) {
        return yAxisDomains[1][1];
      } else {
        return 0;
      }
    })(),
    fillcolor: 'rgba(2, 29, 61, 0.03)',
    line: {
      width: 0,
    },
  };
};

export const getSelectionLineShape = (selection: any, dash: Dash): Partial<Shape> => {
  return {
    type: 'line',
    x0: selection[0].x,
    x1: selection[0].x,
    y0: 0,
    y1: 1,
    xref: 'x',
    yref: 'paper',
    line: {
      color: 'rgba(2, 29, 61, 1)',
      width: 3,
      dash,
    },
  };
};

export const getIconByHealthStatus = (healthStatus: string) => {
  return `<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.3889 20C9.51392 20 6.88892 18.5 5.45142 16C4.01392 13.5312 4.01392 10.5 5.45142 8C6.88892 5.53125 9.51392 4 12.3889 4C15.2327 4 17.8577 5.53125 19.2952 8C20.7327 10.5 20.7327 13.5312 19.2952 16C17.8577 18.5 15.2327 20 12.3889 20Z" fill="${
    colors.health[healthStatus as keyof typeof colors.health]
  }"/><path d="M12.3889 16C10.7327 16 9.38892 14.6562 9.38892 13C9.38892 11.3438 12.3889 8 12.3889 8C12.3889 8 15.3889 11.3438 15.3889 13C15.3889 14.6562 14.0452 16 12.3889 16ZM10.8889 13.25V13H10.3889V13.25C10.3889 14.2188 11.1702 15 12.1389 15H12.3889V14.5H12.1389C11.4358 14.5 10.8889 13.9531 10.8889 13.25Z" fill="white"/>
             </svg>`;
};
