import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { setActiveSensorDataInnerTab, setSelectedOilReportMetadata } from '@/app/slices/applicationSlice';
import { SENSORS_DATA_TYPE } from '@/const';

export const useSelectedCoordinate = (coordinates: string[], component?: any) => {
  const dispatch = useDispatch();
  const selectedOilReportMetadata = useSelector((state: RootState) => state.application.selectedOilReportMetadata);
  const [selectedCoordinate, setSelectedCoordinate] = useState('');

  // Set initial selected coordinate based on metadata or the first available coordinate
  useEffect(() => {
    if (selectedOilReportMetadata) {
      setSelectedCoordinate((prev) =>
        prev !== selectedOilReportMetadata?.coord ? selectedOilReportMetadata?.coord : prev
      );
    } else {
      setSelectedCoordinate(coordinates?.[0] || '');
    }
  }, [selectedOilReportMetadata, coordinates]);

  // Reset selected coordinate when `component` changes
  useEffect(() => {
    if (component && coordinates.length > 1) {
      setSelectedCoordinate(coordinates[1]); // Change as needed
    }
  }, [component, coordinates]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      dispatch(setSelectedOilReportMetadata(null));
    };
  }, [dispatch]);

  // Reset selected coordinates if it no longer exists in available options
  useEffect(() => {
    if (selectedCoordinate && !coordinates.includes(selectedCoordinate)) {
      dispatch(setSelectedOilReportMetadata(null));
    }
  }, [coordinates, selectedCoordinate, dispatch]);

  return { selectedCoordinate, setSelectedCoordinate };
};
