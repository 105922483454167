import { BaseDialog } from '@/components/UI-kit/Dialog/BaseDialog';
import { BaseDrawer } from '@/components/UI-kit/Drawer/BaseDrawer';
import { BaseTooltip } from '@/components/UI-kit/Tooltip/BaseTooltip';
import { Button } from '@/components/ui/button';
import React, { ReactNode } from 'react';
import { DialogType, TooltipType, VariantType } from '@/types';
import { DrawerType } from '@/types/UI-Kit/drawer.types';
import { BaseUIKitWrapper } from '../_common/BaseUIKitWrapper';
import { cn } from '@/lib/utils';

export interface IconButtonProps {
  icon: any;
  onClick?: () => void;
  isActive?: boolean;
  tooltip?: TooltipType;
  label?: string;
  dialog?: DialogType;
  drawer?: DrawerType;
  href?: string;
  id?: string;
  classNames?: { icon?: string; button?: string };
  variant?: VariantType;
  showLabel?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

export const BaseIconButton = ({
  icon,
  onClick,
  isActive = false,
  tooltip,
  dialog,
  drawer,
  variant,
  label,
  classNames,
  showLabel,
  disabled,
}: IconButtonProps) => {
  const Icon = icon;

  return (
    <BaseUIKitWrapper tooltip={tooltip} drawer={drawer} dialog={dialog}>
      <Button
        disabled={disabled}
        variant={variant}
        size="icon"
        onClick={onClick}
        className={cn(
          ' min-h-11 min-w-11 ',
          ` ${isActive ? 'bg-gradient-to-br from-[#6667DD] to-[#12FFE2] rounded-full text-black' : ''}`,
          `${classNames?.button}`
        )}
      >
        <Icon className={cn('scale-[1.5]', `${classNames?.icon}`)} />
        {showLabel && <div className="whitespace-pre-line text-sm">{label}</div>}
      </Button>
    </BaseUIKitWrapper>
  );
};
