import React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  FormControl,
  FormLabel,
  Hide,
  Popover,
  PopoverBody,
  PopoverContent,
  Switch,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { DMArrowDownIcon, DMPreferencesIcon } from 'src/components/Icons';
import {
  activeAssetsCategory,
  dispatch,
  fullPullModeActivatedSelector,
  maintenanceChartVisible,
  RootState,
} from 'src/app/store';
import { useSelector } from 'react-redux';
import {
  setIsFullPullModeActivated,
  setIsMaintenanceChartVisible,
  setOilReport,
} from 'src/app/slices/applicationSlice';
import { MAINTENANCE_CHART_CONTEXT } from 'src/features/maintenance-data/maintenance-chart/MaintenanceChart';
import { ASSETS_CATEGORY } from 'src/const';

interface PreferencesPopoverProps extends ButtonProps {
  context: MAINTENANCE_CHART_CONTEXT;
}

export const PreferencesPopover = ({ context, ...rest }: PreferencesPopoverProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const activeAssetsCategoryId = useSelector(activeAssetsCategory);
  const isMaintenanceChartVisible = useSelector(maintenanceChartVisible);
  const isFullPullModeActivated = useSelector(fullPullModeActivatedSelector);
  const isOilReportEnabled = useSelector((state: RootState) => state.application.isOilReportEnabled);
  const contextKeyMap = {
    [MAINTENANCE_CHART_CONTEXT.Diagnostics]: 'onDiagnostics',
    [MAINTENANCE_CHART_CONTEXT.SensorFusion]: 'onSensorFusion',
    [MAINTENANCE_CHART_CONTEXT.SchemeSensors]: 'onSchemeSensors',
  };
  const key = contextKeyMap[context];

  const onMaintenanceChartSwitchChange = () => {
    dispatch(
      setIsMaintenanceChartVisible({
        ...isMaintenanceChartVisible,
        [key]: !isMaintenanceChartVisible[key],
      })
    );
  };

  const onFullPullModeSwitchChange = () => {
    dispatch(
      setIsFullPullModeActivated({
        ...isFullPullModeActivated,
        [key]: !isFullPullModeActivated[key],
      })
    );
  };

  const onOilReportSwitchChange = () => {
    console.log('onOilReportSwitchChange');
    dispatch(setOilReport(!isOilReportEnabled));
  };

  return (
    <Box position="relative">
      <Button
        leftIcon={<DMPreferencesIcon fontSize="1.5rem" />}
        rightIcon={<DMArrowDownIcon />}
        variant="naked"
        fontSize="0.875rem"
        size="sm"
        bgColor={isOpen ? 'rgba(112, 113, 243, 1)' : 'none'}
        color={isOpen ? 'white' : 'rgb(26, 32, 44)'}
        onClick={onToggle}
        mb={0}
        {...rest}
      >
        Preferences
      </Button>

      <Popover isOpen={isOpen} onClose={onClose}>
        <PopoverContent border="1px solid rgba(177, 185, 195, 1)" w={56} top={9}>
          <PopoverBody px={4} py={5}>
            <VStack w="full" alignItems="flex-start" spacing={4}>
              <FormControl display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                <FormLabel fontSize="0.875rem" mb={0}>
                  Oil Report
                </FormLabel>
                <Switch
                  colorScheme="DMButtonColorScheme"
                  isChecked={isOilReportEnabled}
                  onChange={onOilReportSwitchChange}
                />
              </FormControl>

              <FormControl display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                <FormLabel fontSize="0.875rem" mb={0}>
                  Maintenance Task
                </FormLabel>
                <Switch
                  colorScheme="DMButtonColorScheme"
                  isChecked={isMaintenanceChartVisible[key]}
                  onChange={onMaintenanceChartSwitchChange}
                  isDisabled={
                    (MAINTENANCE_CHART_CONTEXT.SensorFusion === context && isFullPullModeActivated.onSensorFusion) ||
                    (MAINTENANCE_CHART_CONTEXT.SchemeSensors === context && isFullPullModeActivated.onSchemeSensors)
                  }
                />
              </FormControl>

              {activeAssetsCategoryId === ASSETS_CATEGORY.Mobile ? (
                <FormControl display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                  <FormLabel fontSize="0.875rem" mb={0}>
                    Full Pull Mode
                  </FormLabel>
                  <Switch
                    colorScheme="DMButtonColorScheme"
                    isChecked={isFullPullModeActivated[key]}
                    onChange={onFullPullModeSwitchChange}
                  />
                </FormControl>
              ) : null}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
