import { locations } from '@/app/locations';
import { BaseTable } from '@/components/UI-kit';
import { useAssetsPreviewTable } from '@/NewUI/hooks/AssetsPreviewPage/AssetsOverview/useAssetsPreviewTable';
import { Asset } from '@/types/assets';
import React, { ReactNode } from 'react';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { AssetsPreviewSection } from '../../../_common/AssetsPreviewSection/AssetsPreviewSection';
import { SectionEmptyState } from '../../EmptyStates';
import { useQueryNavigation } from '@/NewUI/hooks';
import { ASSET_STUDIO, FILTER_PARAMS } from '@/types/_common/navigationTabs.enums';

interface AssetPreviewTableProps {
  data: Asset[];
  title: ReactNode | string;
}

export const AssetPreviewTable = ({ data, title }: AssetPreviewTableProps) => {
  const { navigateWithQuery } = useQueryNavigation();
  const { siteId } = useParams();
  const { tableStructure } = useAssetsPreviewTable();

  const onMachineItemClick = (data: Asset) => {
    navigateWithQuery(generatePath(locations.protected.sites.components, { siteId, machineId: data.asset_id }));
  };

  if (data.length === 0)
    return (
      <AssetsPreviewSection>
        <SectionEmptyState />
      </AssetsPreviewSection>
    );

  return (
    <BaseTable
      hideHeaders={true}
      mobileVariant="none"
      title={title}
      variant="rounded"
      data={data}
      columns={tableStructure}
      rowClickHandler={onMachineItemClick}
      fnClassNames={{
        table: {
          td: (el) => {
            return (
              el.not_monitored &&
              'bg-gray-100 border-4 border-white first-of-type:border-r-0 last-of-type:border-l-0 py-1 pl-5'
            );
          },
        },
      }}
      classNames={{
        card: {
          container: 'overflow-visible !h-fit',
          title: `${data.length === 0 ? 'pb-4' : ''}`,
        },
        table: {
          container: 'overflow-visible !h-fit',
          tr: 'hover:ring-2 hover:ring-primary-500',
        },
      }}
    />
  );
};
