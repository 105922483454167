import { createSlice } from '@reduxjs/toolkit';
import { processAssetsData } from './utils';
import { initialState as filterInitialState } from './filter-initial-state';
import { Menu } from './types/filter.types';

interface assetsPreviewState {
  selectedView: 'grid' | 'list';
  filteredItems: null | any; // @TODO: Replace `any` with the correct type
  filter: Menu[];
}

const initialState: assetsPreviewState = {
  selectedView: 'grid',
  ...filterInitialState,
};

const assetsPreviewSlice = createSlice({
  initialState,
  name: 'assetsPreview',
  reducers: {
    setSelectedView(state, action) {
      state.selectedView = action.payload;
    },
    setAssetsPreviewFilter(state, action) {
      state.filter = processAssetsData(action.payload);
    },
    setAssetsPreviewFilterItems(state, action) {
      state.filteredItems = action.payload;
    },
  },
});

export const AssetsPreviewSliceReducer = assetsPreviewSlice.reducer;
export const { setSelectedView, setAssetsPreviewFilter, setAssetsPreviewFilterItems } = assetsPreviewSlice.actions;
