import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import React from 'react';

interface AssetsFaultIndicatorProps {
  faultType: string | null;
  healthStatus: string;
  className?: string;
  version?: number;
  onClick?: (a: any) => void;
}

export const AssetsFaultIndicator = ({ faultType, healthStatus, onClick }: AssetsFaultIndicatorProps) => {
  return (
    <div onClick={onClick}>
      <Badge
        className={cn(
          `bg-${healthStatus} text-white py-[0.18rem] px-2.5 font-semibold whitespace-nowrap hover:ring-4 ring-neutral-50`,
          `px-3 text-xs`
        )}
      >
        {faultType}
      </Badge>
    </div>
  );
};
