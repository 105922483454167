import React, { ReactNode, useState } from 'react';
import {
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  SortingState,
} from '@tanstack/react-table';
import { BaseTableMobileCarousel } from './BaseTableMobile/BaseTableMobileCarousel';
import { BaseTableMobileCard } from './BaseTableMobile/BaseTableMobileCard';
import { DMChevronLeftIcon, DMChevronRightIcon } from '@/components/Icons';
import { BaseIconButton } from '../Button';
import { ArrowDown, ArrowUp } from 'lucide-react';
import { useSearchByHeader } from './useSearchByHeader';
import { usePagination } from './usePagination';
import { SearchByHeaderPanel } from './SearchByHeaderPanel';
import { cn } from '@/lib/utils';
import { BaseCard } from '../Cards/BaseCard';
import { useOverflow } from '@/NewUI/hooks/_common/useOverflow';

const tableRowStyle =
  'group whitespace-nowrap max-w-screen truncate hover:border-2 hover:hover:cursor-pointer hover:rounded-lg ';
const tableCellStyle = `text-sm font-medium first-of-type:text-left bg-white  group-hover:bg-inherit py-2 px-6 border-gray-400 h-8 first-of-type:w-[250px] max-w-fit min-w-max`;
const overflowBorder = 'first-of-type:border-y-0 first-of-type:border first-of-type:border-l-0 border-t-0 border-b-0';
const tableHeader =
  'bg-gray-200 sticky top-0 z-20 px-4 border-none first-of-type:text-left first-of-type:z-[22] text-sm font-normal align-bottom';

export interface BaseTableProps<T> {
  data: T[];
  columns: ColumnDef<T>[];
  rowClickHandler?: (row: any) => void;
  mobileVariant?: 'card' | 'carousel' | 'none';
  variant?: 'dense' | 'spaced' | 'rounded' | 'base';
  pageSize?: number;
  enableSorting?: boolean;
  enableSearch?: boolean;
  hideHeaders?: boolean;
  wrapHeaders?: boolean;
  title?: ReactNode | string;
  classNames?: {
    card?: { container?: string; content?: string; title?: string };
    table?: { td?: string; container?: string; header?: string; tr?: string; th?: string; table?: string };
    pagination?: { container?: string; text?: string; icons?: string };
  };
  fnClassNames?: {
    table?: {
      td?: (el: any) => string;
    };
  };
}

export const BaseTable = <T,>({
  data,
  columns,
  rowClickHandler,
  mobileVariant = 'card',
  pageSize,
  enableSorting = true,
  enableSearch = false,
  hideHeaders = false,
  wrapHeaders = true,
  variant = 'dense',
  title,
  classNames,
  fnClassNames,
}: BaseTableProps<T>) => {
  const search = useSearchByHeader(columns);
  const { pagination, setPagination } = usePagination(pageSize);
  const { isOverflowing, containerRef } = useOverflow();
  const [sorting, setSorting] = useState<SortingState>([]);

  // Initialize the table with typed data
  //@TODO: move to custom hooks(?) create a custom hook(s) that create the table(?)
  const table = useReactTable<T>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: search.setSearchAnnotation,
    globalFilterFn: search.filterFn,
    state: {
      pagination,
      sorting,
      globalFilter: search.searchAnnotation,
    },
  });

  return (
    <>
      <BaseCard
        variant="wrapper"
        title={title}
        classNames={{
          card: cn(
            `max-w-screen rounded-lg p-0 m-0 bg-gray-200 max-h-screen shadow-none ${
              mobileVariant === 'none' ? 'block' : 'hidden'
            } lg:block`,
            'rounded-xl',
            classNames?.card?.container
          ),
          content: cn('py-0 bg-inherit overflow-x-auto rounded-lg', classNames?.card?.content),
          title: cn('pb-0 pl-4 pt-3', classNames?.card?.title),
        }}
      >
        {enableSearch && <SearchByHeaderPanel {...search} />}
        <div
          //max-h-main-mobile - i've removed this from here - check if ok
          className={cn('overflow-x-auto p-0 flex items-start w-full', classNames?.table?.container)}
          ref={containerRef}
        >
          <table
            className={`table-auto border-separate w-full ${
              variant === 'dense' || variant === 'base' ? 'border-spacing-y-0.5 pb-1' : 'border-spacing-y-2'
            }`}
          >
            {!hideHeaders && (
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index) => (
                      <th
                        key={header.id}
                        className={cn(
                          header.index === 0 ? `left-0 z-21 ${tableHeader}` : `${tableHeader}`,
                          `${variant === 'base' && 'py-0.5'}`,
                          classNames?.table?.th
                        )}
                      >
                        {header.isPlaceholder ? null : enableSorting ? (
                          <div
                            className={cn(
                              `cursor-pointer select-none py-1 px-2 bg-gray-200 w-fit flex items-center gap-2 relative`,
                              `${wrapHeaders ? 'whitespace-nowrap' : 'text-center margin-auto'}`,
                              `${header.column.getIsSorted() ? 'bg-white rounded-l-lg' : ''}`
                            )}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            <div
                              className={`${
                                header.column.getIsSorted() ? 'visible pr-1' : 'hidden'
                              } absolute right-[-14px] bg-white h-full flex items-center rounded-r-lg`}
                            >
                              {{
                                asc: <ArrowUp size={'16px'} />, // Ascending icon
                                desc: <ArrowDown size={'16px'} />, // Descending icon
                              }[header.column.getIsSorted() as 'asc' | 'desc'] || null}
                            </div>
                          </div>
                        ) : (
                          flexRender(header.column.columnDef.header, header.getContext())
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
            )}
            <tbody className={cn('bg-white')}>
              {table.getRowModel().rows.map((row, rowNum) => {
                const isFirstRow = rowNum === 0;
                const isLastRow = rowNum === table.getRowModel().rows.length - 1;
                return (
                  <tr
                    className={cn(tableRowStyle, classNames?.table?.tr)}
                    key={row.id}
                    onClick={() => rowClickHandler && rowClickHandler(row.original)}
                  >
                    {row.getVisibleCells().map((cell, index) => {
                      const cellContent = flexRender(cell.column.columnDef.cell, cell.getContext());
                      // Apply highlighting to text nodes within JSX content
                      return (
                        <td
                          key={cell.id}
                          className={cn(
                            `${
                              index === 0
                                ? ` ${tableCellStyle} sticky left-0 z-10 ${isOverflowing ? overflowBorder : ''}`
                                : tableCellStyle
                            }`,
                            `${
                              variant === 'dense' &&
                              (table.getRowModel().rows.length - 1 === 0
                                ? 'first-of-type:rounded-l-xl last-of-type:rounded-r-xl'
                                : isFirstRow
                                ? 'first-of-type:rounded-tl-xl last-of-type:rounded-tr-xl'
                                : isLastRow && 'first-of-type:rounded-bl-xl last-of-type:rounded-br-xl')
                            }`,
                            `${variant === 'rounded' && 'first-of-type:rounded-l-xl last-of-type:rounded-r-xl'}`,
                            `${variant === 'base' && 'border-b-[0.5px] py-2'}`,
                            `${classNames?.table?.td}`,
                            `${fnClassNames?.table?.td && fnClassNames?.table?.td(row.original)}`
                          )}
                        >
                          {cellContent}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {pagination.pageSize < data?.length && (
          <div className={cn('flex items-center justify-center', classNames?.pagination?.container)}>
            <BaseIconButton
              icon={DMChevronLeftIcon}
              classNames={{ button: cn('text-primary', classNames?.pagination?.icons) }}
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            />
            <div className={classNames?.pagination?.text}>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount().toLocaleString()}
            </div>
            <BaseIconButton
              icon={DMChevronRightIcon}
              classNames={{ button: cn('text-primary', classNames?.pagination?.icons) }}
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            />
          </div>
        )}
      </BaseCard>

      {mobileVariant === 'carousel' ? (
        <BaseTableMobileCarousel table={table} rowClickHandler={rowClickHandler} />
      ) : (
        mobileVariant === 'card' && <BaseTableMobileCard table={table} rowClickHandler={rowClickHandler} />
      )}
    </>
  );
};
