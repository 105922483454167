import React, { useState } from 'react';
import { Box, Button, HStack, Text, SimpleGrid, Image } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { setSelectedScheme } from 'src/features/mobile-fleet-sensor-data/store/mobileFleetSensorData.slice';
import { useLocation, useNavigate } from 'react-router-dom';
import SubcomponentsLogo from 'src/assets/images/subcomponents-logo.svg';
import { HEALTH_STATUS } from 'src/const';
import { cloneDeep } from 'lodash';
import { CustomTooltip } from 'src/components/CustomTooltip';

const importOrder = [
  HEALTH_STATUS.CRITICAL,
  HEALTH_STATUS.ALARM,
  HEALTH_STATUS.MONITOR,
  HEALTH_STATUS.HEALTHY,
  HEALTH_STATUS.NOT_AVAILABLE,
];

const sortByObject = importOrder.reduce((obj: any, item, index) => {
  return {
    ...obj,
    [item]: index,
  };
}, {});

interface DiagnosticProps {
  diagnostic: any;
  idx: number;
  selectedComponent: any;
  setViewDataOptions: any;
}

const Diagnostic = ({ diagnostic, idx, selectedComponent, setViewDataOptions }: DiagnosticProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const onViewDataClick = (formulaId: string, faultType: string) => {
    const searchParams = new URLSearchParams(location.search);

    if (!selectedComponent) {
      setViewDataOptions({ component_id: diagnostic.component_id, formulaId, faultType });
    } else {
      dispatch(
        setSelectedScheme({
          id: formulaId,
          label: faultType,
        })
      );

      searchParams.set('tabIndex', '1');
      searchParams.set('formulaId', formulaId);
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      px="1rem"
      cursor="pointer"
      _hover={{ bg: '#f0f2f782' }}
      bg={(idx + 1) % 2 === 0 ? '#F0F2F7' : 'initial'}
      gap="1rem"
    >
      <Box display="flex" alignItems="center" gap="0.5rem" overflow="hidden">
        <Box
          w="0.5rem"
          h="0.5rem"
          borderRadius="full"
          bg={`health.${diagnostic.health_status.toLowerCase()}`}
          aspectRatio="1 / 1"
        />
        <CustomTooltip
          label={diagnostic.fault_type}
          placement="top"
          borderRadius="md"
          textStyles={{
            fontSize: '14px',
            fontWeight: 500,
          }}
        >
          {diagnostic.fault_type}
        </CustomTooltip>
      </Box>
      <Button
        variant="unstyled"
        fontSize="12px"
        fontWeight={600}
        color="#6667DD"
        flexShrink={0}
        onClick={() => onViewDataClick(diagnostic.formula_id, diagnostic.fault_type)}
      >
        <Text>View Data</Text>
      </Button>
    </Box>
  );
};

interface SubcomponentBoxProps {
  subcomponent: any;
  selectedComponent: any;
  setViewDataOptions: any;
}

const SubcomponentBox = ({ subcomponent, selectedComponent, setViewDataOptions }: SubcomponentBoxProps) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const toggleShowMore = () => setIsShowMore((prevState) => !prevState);
  const visibleDiagnostics = isShowMore ? subcomponent.diagnostics : subcomponent.diagnostics.slice(0, 3);
  return (
    <Box bg="#fff" p="1rem" borderRadius="8px">
      <HStack mb="0.5rem" justifyContent="space-between" gap="15px">
        <Box overflow="hidden">
          <HStack userSelect="none">
            <CustomTooltip
              label={subcomponent.subcomponent_ui_name || subcomponent.subcomponent_name}
              placement="top"
              borderRadius="md"
              textStyles={{
                fontSize: '14px',
                fontWeight: 600,
              }}
            >
              {subcomponent.subcomponent_ui_name || subcomponent.subcomponent_name}
            </CustomTooltip>
            <Text
              fontSize="12px"
              fontWeight={600}
              textTransform="capitalize"
              color={`health.${subcomponent.subcomponent_health_status.toLowerCase()}`}
              flexShrink={0}
              position="relative"
              top="0.5px"
            >
              {subcomponent.subcomponent_health_status === 'na'
                ? 'Not Monitored'
                : subcomponent.subcomponent_health_status}
            </Text>
          </HStack>
          {selectedComponent === null && (
            <CustomTooltip
              label={subcomponent.component_ui_name || subcomponent.component_name}
              placement="top"
              borderRadius="md"
              textStyles={{
                fontSize: '12px',
                fontWeight: 600,
                color: '#55687D',
                userSelect: 'none',
              }}
            >
              {subcomponent.component_ui_name || subcomponent.component_name}
            </CustomTooltip>
          )}
        </Box>
        <Button
          variant="unstyled"
          fontSize="14px"
          fontWeight={500}
          color="#B1B9C3"
          border="1px solid #B1B9C3"
          px="8px"
          height="32px"
          borderRadius="8px"
          cursor="default"
          flexShrink={0}
        >
          Expand
        </Button>
      </HStack>
      {visibleDiagnostics.map((diagnostic: any, idx: number) => (
        <Diagnostic
          diagnostic={diagnostic}
          key={diagnostic.diagnostic_id}
          idx={idx}
          selectedComponent={selectedComponent}
          setViewDataOptions={setViewDataOptions}
        />
      ))}
      {subcomponent.diagnostics.length > 3 && (
        <Button variant="unstyled" fontSize="14px" fontWeight={600} color="#6667DD" ml="1rem" onClick={toggleShowMore}>
          <Text>Show {isShowMore ? 'less' : 'more'}...</Text>
        </Button>
      )}
    </Box>
  );
};

const getSubcomponentsList = (diagnostics: any[]): any[] => {
  const subpomponentsList: any[] = [];
  const clonedDiagnostics = cloneDeep(diagnostics);

  clonedDiagnostics
    .sort((a, b) => a.fault_type.localeCompare(b.fault_type))
    .sort((a: any, b: any) => sortByObject[a.health_status] - sortByObject[b.health_status]);

  clonedDiagnostics.forEach((diagnostic: any) => {
    const subcomponent = subpomponentsList.find(
      (subcomponent: any) => subcomponent.subcomponent_id === diagnostic.subcomponent_id
    );
    if (subcomponent) {
      subcomponent.diagnostics.push(diagnostic);
    } else {
      subpomponentsList.push({
        subcomponent_id: diagnostic.subcomponent_id,
        subcomponent_name: diagnostic.subcomponent_name,
        subcomponent_ui_name: diagnostic.subcomponent_ui_name,
        subcomponent_health_status: diagnostic.subcomponent_health_status,
        component_id: diagnostic.component_id,
        component_name: diagnostic.component_name,
        component_ui_name: diagnostic.component_ui_name,
        diagnostics: [{ ...diagnostic }],
      });
    }
  });

  return subpomponentsList
    .sort((a, b) => a.subcomponent_ui_name.localeCompare(b.subcomponent_ui_name))
    .sort((a: any, b: any) => sortByObject[a.health_status] - sortByObject[b.health_status]);
};

interface SubcomponentsListProps {
  diagnostics: any[];
  selectedComponent: any;
  setViewDataOptions: any;
}

export const SubcomponentsList = ({ diagnostics, selectedComponent, setViewDataOptions }: SubcomponentsListProps) => {
  const subcomponents: any[] = getSubcomponentsList(diagnostics);
  return (
    <Box position="relative" flex="1" w="full">
      <Box
        display="flex"
        alignItems="center"
        gap="5px"
        p="1rem"
        pb="0.5rem"
        fontSize="14px"
        fontWeight={600}
        height="48px"
        bg="#E6E8EC"
        border="2px solid #E6E8EC"
        borderTopRadius="8px"
      >
        <Image src={SubcomponentsLogo} pointerEvents="none" userSelect="none" />
        <Text>
          {selectedComponent ? selectedComponent.component_ui_name || selectedComponent.component_name : ''} Parts
          Overview
        </Text>
      </Box>
      <Box
        position="absolute"
        p="0.5rem"
        w="full"
        maxH="calc(100% - 48px)"
        overflowY="auto"
        bg="#E6E8EC"
        border="2px solid #E6E8EC"
        borderBottomRadius="8px"
      >
        <SimpleGrid columns={3} spacing={2}>
          {subcomponents.map((subcomponent) => (
            <SubcomponentBox
              subcomponent={subcomponent}
              key={subcomponent.subcomponent_id}
              selectedComponent={selectedComponent}
              setViewDataOptions={setViewDataOptions}
            />
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};
