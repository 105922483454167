import { Box, HStack, Text, Flex, VStack } from '@chakra-ui/react';
import { format, subMonths } from 'date-fns';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyGetPotentialSavingsQuery } from 'src/app/api/businessInsightsApi';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/providers/AuthProvider';
import { selectCachedUserDataById } from 'src/app/queries';
import { useSelector } from 'react-redux';
import { MANAGEMENT_ROLE } from 'src/const';
import { ChevronDown, ChevronUp, TableIcon } from 'lucide-react';
import { Skeleton } from '@/components/ui/skeleton';
import { getOption } from '@/pages/machines/statistics/operational-gains/utils';
import { BIDefaults, BI } from '@/pages/machines/statistics/operational-gains/consts';
import { cn } from '@/lib/utils';
import { BaseButton } from '@/components/UI-kit/Button';
import { BaseDropdownFilterMenu } from '@/components/UI-kit/Dropdown';
import { MonthlyImpactTable } from './MonthlyImpactTable';

const MONTHLY_IMPACT_TITLE_HEIGHT = 48;
const MONTHLY_IMPACT_PADDING_BOTTOM = 10;
const MONTHLY_IMPACT_CHART_TITLE_HEIGHT = 40;
const DEFAULT_MONTHLY_IMPACT_CHART_HEIGHT = 190;

const chartTitles = {
  cost: 'Accumulated Monthly Cost Impact',
  downtime: 'Accumulated Monthly Downtime Impact',
};

function getCurrencySymbol(currencyCode: string) {
  try {
    return (0)
      .toLocaleString('en', {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();
  } catch (error) {
    console.error(`Invalid currency code: ${currencyCode}, using ${currencyCode} as fallback.`);
    return currencyCode;
  }
}

const MonthlyImpactChart = ({ period, dataType, data, currency, isLoading, chartHeight }: any) => {
  let filteredAccumulated = [];
  let filteredCurrent = [];

  if (period === BIDefaults.DEFAULT_PERIOD) {
    filteredAccumulated = Object.keys(data.potential_savings).map((key) => ({
      month: key.split('_')[1],
      year: key.split('_')[0],
      accumulated:
        dataType === BI.Cost
          ? data.potential_savings[key].accumulated.cost
          : data.potential_savings[key].accumulated.downtime,
    }));

    filteredCurrent = Object.keys(data.potential_savings).map((key) => ({
      month: key.split('_')[1],
      year: key.split('_')[0],
      current:
        dataType === BI.Cost ? data.potential_savings[key].current.cost : data.potential_savings[key].current.downtime,
    }));
  } else {
    filteredAccumulated = Object.keys(data.potential_savings)
      .filter((key) => key.startsWith(period))
      .map((key) => ({
        month: key.split('_')[1],
        year: key.split('_')[0],
        accumulated:
          dataType === BI.Cost
            ? data.potential_savings[key].accumulated.cost
            : data.potential_savings[key].accumulated.downtime,
      }));

    filteredCurrent = Object.keys(data.potential_savings)
      .filter((key) => key.startsWith(period))
      .map((key) => ({
        month: key.split('_')[1],
        year: key.split('_')[0],
        current:
          dataType === BI.Cost
            ? data.potential_savings[key].current.cost
            : data.potential_savings[key].current.downtime,
      }));
  }

  const getMonthName = (year: string, monthNumber: string) => {
    const date = new Date(`${year}-${monthNumber}-01`);
    return format(date, 'MMM yyyy');
  };

  return (
    <>
      {isLoading ? (
        <Skeleton className="h-[500px] w-full" />
      ) : (
        <>
          {chartHeight && (
            <ReactECharts
              style={{
                height: chartHeight - MONTHLY_IMPACT_CHART_TITLE_HEIGHT,
              }}
              echarts={echarts}
              option={getOption({
                dataType,
                current: filteredCurrent.map((item) => ({ ...item, monthName: getMonthName(item.year, item.month) })),
                accumulated: filteredAccumulated.map((item) => ({
                  ...item,
                  monthName: getMonthName(item.year, item.month),
                })),
                currency,
                gridHeight: chartHeight - MONTHLY_IMPACT_CHART_TITLE_HEIGHT - 40,
              })}
            />
          )}
        </>
      )}
    </>
  );
};

export const MonthlyImpactNew = ({
  showDetails = true,
  setIsMachinesFailuresTableVisible,
  isMachinesFailuresTableVisible,
  isBigResolution,
  monthlyImpactWrapperHeight,
  setTitle,
}: any) => {
  const { siteId } = useParams();
  const [fetchPotentialSaving, { data: potentialSavingData, isLoading }] = useLazyGetPotentialSavingsQuery({});
  const [yearOptions, setYearOptions] = useState<Array<string>>([]);
  const [selectedYear, setSelectedYear] = useState<string>(BIDefaults.DEFAULT_PERIOD);
  const [selectedDataType, setSelectedDataType] = useState<string>(BIDefaults.DEFAULT_DATA_TYPE);
  const { t } = useTranslation();
  const { user } = useAuth();
  const userData = useSelector((state) => selectCachedUserDataById(state, user!.uid));
  const [showImpactTable, setShowImpactTable] = useState(false);
  type Failure = {
    display_cost: boolean;
    currency: string;
  };

  const displayCost = useMemo(() => {
    return (
      potentialSavingData?.failures &&
      Object.values(potentialSavingData.failures).some((failures: any) =>
        (failures as Failure[]).some((failure: Failure) => failure.display_cost)
      )
    );
  }, [potentialSavingData]);

  const currency = useMemo(() => {
    const failure =
      potentialSavingData?.failures &&
      Object.values(potentialSavingData.failures)
        .flat()
        .find((failure: any) => failure.display_cost);
    return failure?.currency || 'USD';
  }, [potentialSavingData]);

  useEffect(() => {
    if (siteId) {
      fetchPotentialSaving({ site_id: siteId }, true);
    }
  }, [siteId]);

  useEffect(() => {
    if (potentialSavingData && potentialSavingData.potential_savings) {
      const years = Object.keys(potentialSavingData.potential_savings)
        .map((key) => key.split('_')[0])
        .filter((value, index, self) => self.indexOf(value) === index);
      setYearOptions(years);
    }
  }, [potentialSavingData]);

  const handleSelectChangePeriod = (selectedValue: any) => {
    setSelectedYear(selectedValue);
  };

  const handleSelectChangeDataType = (selectedValue: any) => {
    setSelectedDataType(selectedValue === 'cost' ? 'cost' : 'hours');
    setTitle(selectedValue === 'cost' ? 'cost' : 'downtime');
  };

  const isEmptyObject = (obj: any) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const filterDataForLastYear = (data: any) => {
    const currentDate = new Date();
    const oneYearAgo = subMonths(currentDate, 12); // 12 months including the current month

    const filteredData = Object.keys(data.potential_savings || {})
      .filter((key) => {
        const [year, month] = key.split('_');
        const date = new Date(Number(year), Number(month) - 1);
        return date >= oneYearAgo && date <= currentDate;
      })
      .reduce((obj: any, key: any) => {
        obj[key] = data.potential_savings[key];
        return obj;
      }, {});

    const filteredFailures = Object.keys(data.failures || {})
      .filter((key) => {
        const [year, month] = key.split('_');
        const date = new Date(Number(year), Number(month) - 1);
        return date >= oneYearAgo && date <= currentDate;
      })
      .reduce((obj: any, key: any) => {
        obj[key] = data.failures[key];
        return obj;
      }, {});

    return { potential_savings: filteredData, failures: filteredFailures };
  };

  const filterDataByYear = (data: any, year: string) => {
    const filteredData = Object.keys(data.potential_savings || {})
      .filter((key) => key.startsWith(year))
      .reduce((obj: any, key: any) => {
        obj[key] = data.potential_savings[key];
        return obj;
      }, {});

    const filteredFailures = Object.keys(data.failures || {})
      .filter((key) => key.startsWith(year))
      .reduce((obj: any, key: any) => {
        obj[key] = data.failures[key];
        return obj;
      }, {});

    return { potential_savings: filteredData, failures: filteredFailures };
  };

  const isDataAvailable =
    potentialSavingData &&
    !isEmptyObject(potentialSavingData.potential_savings) &&
    Object.keys(potentialSavingData.potential_savings).length > 0;

  const filteredData =
    selectedYear === BIDefaults.DEFAULT_PERIOD
      ? filterDataForLastYear(potentialSavingData || {})
      : filterDataByYear(potentialSavingData || {}, selectedYear);

  const showCostOption = displayCost || userData?.role === MANAGEMENT_ROLE.DM_Admin;

  const chartHeight = isBigResolution
    ? monthlyImpactWrapperHeight - MONTHLY_IMPACT_TITLE_HEIGHT - MONTHLY_IMPACT_PADDING_BOTTOM
    : DEFAULT_MONTHLY_IMPACT_CHART_HEIGHT;

  return (
    <>
      <div
        className={cn('absolute right-4 top-[0.3rem] flex items-center h-fit', !showDetails && 'mr-4 top-[1.62rem]')}
      >
        {showCostOption && (
          <BaseDropdownFilterMenu
            defaultSelected={{ 'Impact Type': [BIDefaults.DEFAULT_DATA_TYPE.toLowerCase()] }}
            multiSelect={false}
            closeOnSelect={true}
            filterLable="Impact Type"
            onFilterChange={(data) => {
              handleSelectChangeDataType(data['Impact Type'][0]);
            }}
            data={[
              {
                menuLabel: 'Impact Type',
                menuItems: ['cost', 'time'].map((el) => {
                  return { id: el, label: el };
                }),
              },
            ]}
          />
        )}
        {yearOptions.length > 0 && (
          <BaseDropdownFilterMenu
            defaultSelected={{ Period: [BIDefaults.DEFAULT_PERIOD] }}
            multiSelect={false}
            closeOnSelect={true}
            filterLable="Period Selection"
            onFilterChange={(data) => {
              handleSelectChangePeriod(data['Period'][0]);
            }}
            data={[
              {
                menuLabel: 'Period',
                menuItems: [BIDefaults.DEFAULT_PERIOD, ...yearOptions].map((el) => {
                  return { id: el, label: el };
                }),
              },
            ]}
          />
        )}
        {/* {showDetails && (
          <BaseButton
            className="font-medium h-7 px-2 mr-[-6px]"
            variant={'filled'}
            size={'xs'}
            dialog={{
              title: 'Machines and Failures',
              element: <MonthImpactDetailsModal />,
              className: 'lg:max-w-fit max-w-screen overflow-auto max-h-screen',
            }}
          >
            <TableIcon />
            <span className="hidden lg:block text-sm font-medium">Show Details</span>
          </BaseButton>
        )} */}
      </div>
      <VStack w="full" bg="white" borderRadius="md">
        <Box w="full">
          {isLoading ? (
            <Skeleton className={`h-[180px] my-0.5 mx-2`} />
          ) : isDataAvailable ? (
            <>
              <HStack px={4} py={2} justifyContent="space-between" alignItems="center"></HStack>
              <MonthlyImpactChart
                period={selectedYear}
                dataType={selectedDataType}
                data={filteredData}
                currency={getCurrencySymbol(currency)}
                isLoading={isLoading}
                chartHeight={chartHeight}
              />
            </>
          ) : (
            <Flex height={chartHeight} alignItems="center" justifyContent="center">
              <Text fontSize="0.875rem" color="gray.500">
                Insufficient data available to generate the chart
              </Text>
            </Flex>
          )}
        </Box>
      </VStack>
      {showDetails && (
        <div className={cn('flex flex-col gap-6 relative h-full w-full ', `${showImpactTable && 'pt-4'}`)}>
          {showImpactTable && <MonthlyImpactTable showCost={selectedDataType === 'cost'} currency={currency} />}
          <BaseButton
            className="font-medium h-7 px-2 mr-[-6px] absolute translate-y-[40%] z-[50]"
            variant={'filled'}
            rightIcon={!showImpactTable ? <ChevronDown /> : <ChevronUp />}
            size={'xs'}
            onClick={() => {
              setShowImpactTable((prev) => !prev);
            }}
          >
            {`${showImpactTable ? 'Hide' : 'Show'} Details`}
          </BaseButton>
        </div>
      )}
      {/* {filteredData && Object.keys(filteredData.failures).length > 0 && (
        <MachinesFailuresTable
          potentialSavingData={filteredData}
          dataType={selectedDataType}
          isLoading={isLoading}
          currency={currency}
          setIsMachinesFailuresTableVisible={setIsMachinesFailuresTableVisible}
          isMachinesFailuresTableVisible={isMachinesFailuresTableVisible}
        />
      )} */}
    </>
  );
};
