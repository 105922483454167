import React, { useEffect, useMemo, useRef, useState } from 'react';
import { changeTraceType, getSelectionLineShape, splitTraces } from 'src/features/mobile-fleet-sensor-data/utils';
import Plot from 'react-plotly.js';
import { Box, Collapse, HStack, VStack } from '@chakra-ui/react';
import { Legend } from 'src/features/maintenance-data/maintenance-chart/components/Legend';
import { MultipleSubplotsToolbar } from 'src/features/mobile-fleet-sensor-data/components/MultipleSubplotsModebar';
import { Layout, Shape } from 'plotly.js';
import { useSelector } from 'react-redux';
import {
  dispatch,
  fullPullModeActivatedSelector,
  maintenanceChartVisible,
  mobileFleetSensorFusionSelectionSelector,
  RootState,
} from 'src/app/store';
import { setIsMaintenanceChartVisible, setMobileFleetSensorFusionSelection } from 'src/app/slices/applicationSlice';
import {
  MAINTENANCE_CHART_CONTEXT,
  MaintenanceChart,
} from 'src/features/maintenance-data/maintenance-chart/MaintenanceChart';
import { format } from 'date-fns';
import { DIAGNOSTICS_REGULAR_DATE_FORMAT } from 'src/const';
import { mobileFleetSensorDataSelectedSchemeSelector } from 'src/features/mobile-fleet-sensor-data/store/mobileFleetSensorData.slice';
import { mobileFleetApi } from 'src/app/api/mobileFleetApi';

interface MobileFleetSensorFusionProps {
  selectedComponent: any;
  queryParams: any;
}

export const MobileFleetSensorFusion: React.FC<MobileFleetSensorFusionProps> = ({ selectedComponent, queryParams }) => {
  const boxRef = useRef(null);
  const [revision, setRevision] = useState<number>(0);
  const [layout, setLayout] = useState<Partial<Layout>>();
  const [xAxisMinMaintenance, setXAxisMinMaintenance] = useState<string>();
  const [xAxisMaxMaintenance, setXAxisMaxMaintenance] = useState<string>();
  const isFullPullModeActivated = useSelector(fullPullModeActivatedSelector);
  const isMaintenanceChartVisible = useSelector(maintenanceChartVisible);
  const mobileFleetSensorFusionSelection = useSelector(mobileFleetSensorFusionSelectionSelector);
  const selectedScheme = useSelector(mobileFleetSensorDataSelectedSchemeSelector);
  const mobileFleetSensorsData = useSelector(
    (state: RootState) => mobileFleetApi.endpoints.getMobileFleetSensors.select(queryParams)(state)?.data
  );
  const uniqueSensorGroups = new Set(mobileFleetSensorsData?.map((item: any) => item.sensor_group));
  const numSubplots = Math.min(uniqueSensorGroups.size, 3);
  const domainHeight = 1 / numSubplots;

  const traces = useMemo(() => {
    return mobileFleetSensorsData
      ? splitTraces(changeTraceType(mobileFleetSensorsData, isFullPullModeActivated.onSensorFusion), numSubplots)
      : [];
  }, [mobileFleetSensorsData, isFullPullModeActivated.onSensorFusion]);

  const initialLayout: any = {
    hovermode: 'x unified',
    hoversubplots: 'axis',
    margin: { t: 16, l: 48, r: 16, b: 16 },
    showlegend: false,
    xaxis: {
      autorange: true,
      showspikes: false,
      gridcolor: '#F1F1FE',
      gridwidth: 1,
      showgrid: true,
      zeroline: true,
      zerolinecolor: '#B1B9C3',
      zerolinewidth: 1,
    },
  };

  for (let i = 0; i < numSubplots; i++) {
    const startDomain = i * domainHeight;
    const endDomain = (i + 1) * domainHeight;

    initialLayout[`yaxis${i + 1}`] = {
      domain: [startDomain, endDomain],
      showgrid: true,
      zeroline: true,
      zerolinecolor: '#B1B9C3',
      zerolinewidth: 1,
    };
  }

  const handleClick = (eventData: any) => {
    const seen = new Set();
    const dataJSONString = JSON.stringify(eventData.points, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) return;
        seen.add(value);
      }
      return value;
    });
    const parsedData = JSON.parse(dataJSONString);
    const { button, shiftKey } = eventData.event;

    if (button === 0) {
      dispatch(
        setMobileFleetSensorFusionSelection({
          [shiftKey ? 'pin' : 'current']: parsedData,
        })
      );
    } else if (button === 2) {
      dispatch(
        setMobileFleetSensorFusionSelection({
          pin: parsedData,
        })
      );
    }
  };

  const onRelayout = (eventData: any) => {
    const xAxisRangeMin = eventData['xaxis.range[0]'];
    const xAxisRangeMax = eventData['xaxis.range[1]'];
    const xAxisAutoRange = eventData['xaxis.autorange'];

    if (xAxisRangeMin && xAxisRangeMax) {
      setXAxisMinMaintenance(format(new Date(xAxisRangeMin), DIAGNOSTICS_REGULAR_DATE_FORMAT));
      setXAxisMaxMaintenance(format(new Date(xAxisRangeMax), DIAGNOSTICS_REGULAR_DATE_FORMAT));
    }

    if (xAxisAutoRange) {
      setXAxisMinMaintenance(traces[0].x[0]);
      setXAxisMaxMaintenance(traces[0].x[traces[0].x.length - 1]);
    }
  };

  useEffect(() => {
    const { pin, current } = mobileFleetSensorFusionSelection;

    const shapes = [
      pin && getSelectionLineShape(pin, 'dash'),
      current && getSelectionLineShape(current, 'solid'),
    ].filter(Boolean) as Partial<Shape>[];

    setLayout((prevLayout) => ({
      ...prevLayout,
      shapes,
    }));
  }, [mobileFleetSensorFusionSelection.current, mobileFleetSensorFusionSelection.pin]);

  useEffect(() => {
    dispatch(
      setMobileFleetSensorFusionSelection({
        current: undefined,
        pin: undefined,
      })
    );
    setLayout({ ...initialLayout });

    if (traces.length) {
      if (traces[0].x.length) setXAxisMinMaintenance(traces[0].x[0]);
      setXAxisMaxMaintenance(traces[0].x[traces[0].x.length - 1]);
    }
  }, [traces]);

  useEffect(() => {
    setLayout({ ...initialLayout });
  }, [selectedScheme]);

  useEffect(() => {
    if (isFullPullModeActivated.onSensorFusion) {
      dispatch(
        setIsMaintenanceChartVisible({
          ...isMaintenanceChartVisible,
          onSensorFusion: false,
        })
      );

      setRevision((prevState) => prevState + 1);
    }

    setRevision((prevState) => prevState + 1);
  }, [isFullPullModeActivated.onSensorFusion]);

  const containerHeight = isMaintenanceChartVisible.onSensorFusion ? `calc(100vh - 370px)` : `calc(100vh - 305px)`;

  return (
    <Box>
      <Box height={containerHeight} ref={boxRef}>
        {layout ? (
          <Plot
            className="sensor-fusion-multiple-subplots"
            data={structuredClone(traces)}
            revision={revision}
            layout={layout}
            useResizeHandler
            style={{ width: '100%', height: '100%' }}
            config={{
              displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: ['lasso2d', 'select2d', 'autoScale2d'],
            }}
            onClick={handleClick}
            onRelayout={onRelayout}
          />
        ) : null}
      </Box>

      <Collapse in={isMaintenanceChartVisible.onSensorFusion} animateOpacity>
        <Box pt={2} pb={1} pl={9} pr={0}>
          <MaintenanceChart
            selectedComponent={selectedComponent}
            xAxisMin={xAxisMinMaintenance}
            xAxisMax={xAxisMaxMaintenance}
            context={MAINTENANCE_CHART_CONTEXT.SensorFusion}
            wrapperRef={boxRef}
            hideLegend
          />
        </Box>
      </Collapse>

      <HStack justifyContent="space-between" pl={12}>
        <Box>
          {isMaintenanceChartVisible.onSensorFusion ? (
            <Legend items={[{ color: '#01152B', label: 'Maintenance Task' }]} />
          ) : null}
        </Box>
        <MultipleSubplotsToolbar plotlyContainerClassName="sensor-fusion-multiple-subplots" />
      </HStack>
    </Box>
  );
};
