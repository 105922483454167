import React from 'react';
import { Box } from '@chakra-ui/react';
import { SettingsSidebar } from 'src/pages/settings/components/SettingsSidebar';
import { Outlet } from 'react-router-dom';
import Fallback from 'src/components/Fallback';
import { ErrorBoundary } from 'react-error-boundary';
import { Header } from '@/NewUI/layouts/Header/Header';
export const SettingsLayout = () => {
  return (
    <Box>
      <SettingsSidebar />
      <Header />
      <Box {...containerProps}>
        <ErrorBoundary FallbackComponent={Fallback}>
          <Outlet />
        </ErrorBoundary>
      </Box>
    </Box>
  );
};

const containerProps = {
  pl: '21.25rem',
  h: '100%',
};
