import { Card, CardContent, CardFooter, CardTitle } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { useMousedownScroll } from '@/NewUI/hooks/_common/useMousedownScroll';
import React, { forwardRef, ReactNode } from 'react';

export interface BaseCardProps {
  children: ReactNode;
  title?: ReactNode;
  footer?: ReactNode;
  variant?: 'wrapper' | 'base';
  classNames?: { card?: string; title?: string; footer?: string; content?: string };
  onClick?: () => any;
}

const cardStyleByVarinat = {
  base: {
    // card: 'max-w-screen p-2 max-h-screen rounded-none border-none relative min-h-fit',
    card: 'max-w-screen p-2 max-h-screen rounded-none border-none relative min-h-full',
    title: 'pt-0 pt-1 pb-2 pl-2 font-semibold text-sm capitalized flex items-center h-full',
    content: 'p-2',
    footer: '',
  },
  wrapper: {
    card: 'bg-gray-200 rounded-lg border-2 min-h-fit',
    content: 'bg-white ',
    title: '',
    footer: '',
  },
};

export const BaseCard = forwardRef(
  (
    { children, title, variant = 'base', footer, classNames, onClick }: BaseCardProps,
    ref: React.Ref<HTMLDivElement> // Specify the type of the ref (HTMLDivElement or the element you are referencing)
  ) => {
    return (
      <Card
        ref={ref}
        className={cn([`${cardStyleByVarinat.base.card}`, `${cardStyleByVarinat[variant].card}`, classNames?.card])}
        onClick={onClick}
      >
        {title && (
          <CardTitle
            className={cn([
              `${cardStyleByVarinat.base.title}`,
              `${cardStyleByVarinat[variant].title}`,
              classNames?.title,
            ])}
          >
            {title}
          </CardTitle>
        )}
        <CardContent
          className={cn([
            `${cardStyleByVarinat.base.content}`,
            `${cardStyleByVarinat[variant].content}`,
            classNames?.content,
          ])}
        >
          {children}
        </CardContent>
        {footer && (
          <CardFooter
            className={cn([
              `${cardStyleByVarinat.base.footer}`,
              `${cardStyleByVarinat[variant].footer}`,
              classNames?.footer,
            ])}
          >
            {footer}
          </CardFooter>
        )}
      </Card>
    );
  }
);

BaseCard.displayName = 'BaseCard';
