export const generateTableStructure = (reportsData: any) => {
  const tableStructure: any = [];

  if (!reportsData) return [];

  reportsData.forEach((report: any) => {
    report.data.forEach((nestedItem: any) => {
      // Check if the group already exists in the table structure
      let group = tableStructure.find((g: any) => g.id === nestedItem.group);
      if (!group) {
        group = {
          id: nestedItem.group,
          data: [],
        };
        tableStructure.push(group);
      }

      // Find the item in the group data
      // let item = group.data.find((i: any) => i.id === nestedItem.id);
      let item = group.data.find((i: any) => i.name === nestedItem.name);
      if (!item) {
        item = {
          id: nestedItem.name,
          name: nestedItem.name,
          limit: nestedItem.limit,
          reportsData: [],
        };
        group.data.push(item);
      }

      // Add the report meta to the reportsData array based on the date
      item.reportsData.push({
        date: report?.meta?.date,
        value: nestedItem.value,
        health_status: nestedItem.health_status,
      });
    });
  });

  return tableStructure;
};

export const oilReportData4 = [
  {
    meta: {
      date: '2025-02-03',
      health_status: 'critical',
      report_number: 123,
      oil_type: 'FUCHS UNISYN CLP 320',
      report_recommendations: 'Results within acceptable limits. Continue with regular maintenance and monitoring',
    },
    data: [
      { id: '1-11', name: 'Meter Reading', value: 3, group: 'Summary' },
      { id: '1-12', name: 'Oil Hrs', value: 5, group: 'Summary' },
      { id: '1-13', name: 'Top Up', value: 7, group: 'Summary' },
      { id: '1-14', name: 'Oil Change?', value: 'Yes', group: 'Summary' },
      { id: '1-1', name: 'Iron', limit: 10, value: 18, health_status: 'critical', group: 'Wear Metals' },
      { id: '1-2', name: 'Copper', limit: 300, value: 25, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-3', name: 'Lead', limit: 45, value: 9, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-4', name: 'Aluminum', limit: 10, value: 6, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-6', name: 'Water', value: 0.15, health_status: 'healthy', group: 'Contaminants' },
      { id: '1-7', name: 'Glycol', value: 0.25, health_status: 'alarm', group: 'Contaminants' },
      { id: '1-8', name: 'Coolant', value: 5, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-9', name: 'Viscosity', value: 130, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-10', name: 'Oxidation', value: 42, health_status: 'alarm', group: 'Oil Condition' },
    ],
  },
  // {
  //   meta: {
  //     date: '2025-02-01',
  //     health_status: 'alarm',
  //     report_number: 456,
  //     oil_type: 'FUCHS UNISYN CLP 320',
  //     report_recommendations: 'Results within acceptable limits. Continue with regular maintenance and monitoring',
  //   },
  //   data: [
  //     { id: '1-11', name: 'Meter Reading', value: 2, group: 'Summary' },
  //     { id: '1-12', name: 'Oil Hrs', value: 3, group: 'Summary' },
  //     { id: '1-13', name: 'Top Up', value: 5, group: 'Summary' },
  //     { id: '1-14', name: 'Oil Change?', value: 'Yes', group: 'Summary' },
  //     { id: '1-1', name: 'Iron', limit: 10, value: 20, health_status: 'healthy', group: 'Wear Metals' },
  //     { id: '1-2', name: 'Copper', limit: 20, value: 18, health_status: 'alarm', group: 'Wear Metals' },
  //     { id: '1-3', name: 'Lead', limit: 45, value: 5, health_status: 'healthy', group: 'Wear Metals' },
  //     { id: '1-4', name: 'Aluminum', limit: 10, value: 8, health_status: 'alarm', group: 'Wear Metals' },
  //     { id: '1-6', name: 'Water', value: 0.2, health_status: 'healthy', group: 'Contaminants' },
  //     { id: '1-7', name: 'Glycol', value: 0.3, health_status: 'alarm', group: 'Contaminants' },
  //     { id: '1-8', name: 'Coolant', value: 6, health_status: 'healthy', group: 'Oil Condition' },
  //     { id: '1-9', name: 'Viscosity', value: 140, health_status: 'healthy', group: 'Oil Condition' },
  //     { id: '1-10', name: 'Oxidation', value: 50, health_status: 'alarm', group: 'Oil Condition' },
  //   ],
  // },
  {
    meta: {
      date: '2025-02-04',
      health_status: 'alarm',
      report_number: 789,
      oil_type: 'FUCHS UNISYN CLP 320',
      report_recommendations: 'Results within acceptable limits. Continue with regular maintenance and monitoring',
    },
    data: [
      { id: '1-11', name: 'Meter Reading', value: 1, group: 'Summary' },
      { id: '1-12', name: 'Oil Hrs', value: 2, group: 'Summary' },
      { id: '1-13', name: 'Top Up', value: 4, group: 'Summary' },
      { id: '1-14', name: 'Oil Change?', value: 'Yes', group: 'Summary' },
      { id: '1-1', name: 'Iron', limit: 10, value: 25, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-2', name: 'Copper', limit: 20, value: 22, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-3', name: 'Lead', limit: 45, value: 4, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-4', name: 'Aluminum', limit: 10, value: 9, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-6', name: 'Water', value: 0.25, health_status: 'healthy', group: 'Contaminants' },
      { id: '1-7', name: 'Glycol', value: 0.35, health_status: 'alarm', group: 'Contaminants' },
      { id: '1-8', name: 'Coolant', value: 7, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-9', name: 'Viscosity', value: 150, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-10', name: 'Oxidation', value: 55, health_status: 'alarm', group: 'Oil Condition' },
    ],
  },
  {
    meta: {
      date: '2025-02-05',
      health_status: 'alarm',
      report_number: 91011,
      oil_type: 'FUCHS UNISYN CLP 320',
      report_recommendations: 'Results within acceptable limits. Continue with regular maintenance and monitoring',
    },
    data: [
      { id: '1-11', name: 'Meter Reading', value: 4, group: 'Summary' },
      { id: '1-12', name: 'Oil Hrs', value: 6, group: 'Summary' },
      { id: '1-13', name: 'Top Up', value: 8, group: 'Summary' },
      { id: '1-14', name: 'Oil Change?', value: 'Yes', group: 'Summary' },
      { id: '1-1', name: 'Iron', limit: 10, value: 30, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-2', name: 'Copper', limit: 20, value: 26, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-3', name: 'Lead', limit: 45, value: 6, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-4', name: 'Aluminum', limit: 10, value: 10, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-6', name: 'Water', value: 0.3, health_status: 'healthy', group: 'Contaminants' },
      { id: '1-7', name: 'Glycol', value: 0.4, health_status: 'alarm', group: 'Contaminants' },
      { id: '1-8', name: 'Coolant', value: 8, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-9', name: 'Viscosity', value: 160, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-10', name: 'Oxidation', value: 60, health_status: 'alarm', group: 'Oil Condition' },
    ],
  },
  {
    meta: {
      date: '2025-02-06',
      health_status: 'alarm',
      report_number: 91011,
      oil_type: 'FUCHS UNISYN CLP 320',
      report_recommendations: 'Results within acceptable limits. Continue with regular maintenance and monitoring',
    },
    data: [
      { id: '1-11', name: 'Meter Reading', value: 4, group: 'Summary' },
      { id: '1-12', name: 'Oil Hrs', value: 6, group: 'Summary' },
      { id: '1-13', name: 'Top Up', value: 8, group: 'Summary' },
      { id: '1-14', name: 'Oil Change?', value: 'Yes', group: 'Summary' },
      { id: '1-1', name: 'Iron', limit: 10, value: 30, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-2', name: 'Copper', limit: 20, value: 26, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-3', name: 'Lead', limit: 45, value: 6, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-4', name: 'Aluminum', limit: 10, value: 10, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-6', name: 'Water', value: 0.3, health_status: 'healthy', group: 'Contaminants' },
      { id: '1-7', name: 'Glycol', value: 0.4, health_status: 'alarm', group: 'Contaminants' },
      { id: '1-8', name: 'Coolant', value: 8, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-9', name: 'Viscosity', value: 160, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-10', name: 'Oxidation', value: 60, health_status: 'alarm', group: 'Oil Condition' },
    ],
  },
  {
    meta: {
      date: '2025-02-08',
      health_status: 'alarm',
      report_number: 91011,
      oil_type: 'FUCHS UNISYN CLP 320',
      report_recommendations: 'Results within acceptable limits. Continue with regular maintenance and monitoring',
    },
    data: [
      { id: '1-11', name: 'Meter Reading', value: 4, group: 'Summary' },
      { id: '1-12', name: 'Oil Hrs', value: 6, group: 'Summary' },
      { id: '1-13', name: 'Top Up', value: 8, group: 'Summary' },
      { id: '1-14', name: 'Oil Change?', value: 'Yes', group: 'Summary' },
      { id: '1-1', name: 'Iron', limit: 10, value: 30, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-2', name: 'Copper', limit: 20, value: 26, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-3', name: 'Lead', limit: 45, value: 6, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-4', name: 'Aluminum', limit: 10, value: 10, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-6', name: 'Water', value: 0.3, health_status: 'healthy', group: 'Contaminants' },
      { id: '1-7', name: 'Glycol', value: 0.4, health_status: 'alarm', group: 'Contaminants' },
      { id: '1-8', name: 'Coolant', value: 8, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-9', name: 'Viscosity', value: 160, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-10', name: 'Oxidation', value: 60, health_status: 'alarm', group: 'Oil Condition' },
    ],
  },
  {
    meta: {
      date: '2025-02-10',
      health_status: 'alarm',
      report_number: 91011,
      oil_type: 'FUCHS UNISYN CLP 320',
      report_recommendations: 'Results within acceptable limits. Continue with regular maintenance and monitoring',
    },
    data: [
      { id: '1-11', name: 'Meter Reading', value: 4, group: 'Summary' },
      { id: '1-12', name: 'Oil Hrs', value: 6, group: 'Summary' },
      { id: '1-13', name: 'Top Up', value: 8, group: 'Summary' },
      { id: '1-14', name: 'Oil Change?', value: 'Yes', group: 'Summary' },
      { id: '1-1', name: 'Iron', limit: 10, value: 30, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-2', name: 'Copper', limit: 20, value: 26, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-3', name: 'Lead', limit: 45, value: 6, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-4', name: 'Aluminum', limit: 10, value: 10, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-6', name: 'Water', value: 0.3, health_status: 'healthy', group: 'Contaminants' },
      { id: '1-7', name: 'Glycol', value: 0.4, health_status: 'alarm', group: 'Contaminants' },
      { id: '1-8', name: 'Coolant', value: 8, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-9', name: 'Viscosity', value: 160, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-10', name: 'Oxidation', value: 60, health_status: 'alarm', group: 'Oil Condition' },
    ],
  },
  {
    meta: {
      date: '2025-02-11',
      health_status: 'critical',
      report_number: 91011,
      oil_type: 'FUCHS UNISYN CLP 320',
      report_recommendations: 'Results within acceptable limits. Continue with regular maintenance and monitoring',
    },
    data: [
      { id: '1-11', name: 'Meter Reading', value: 4, group: 'Summary' },
      { id: '1-12', name: 'Oil Hrs', value: 6, group: 'Summary' },
      { id: '1-13', name: 'Top Up', value: 8, group: 'Summary' },
      { id: '1-14', name: 'Oil Change?', value: 'Yes', group: 'Summary' },
      { id: '1-1', name: 'Iron', limit: 10, value: 30, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-2', name: 'Copper', limit: 20, value: 26, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-3', name: 'Lead', limit: 45, value: 6, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-4', name: 'Aluminum', limit: 10, value: 10, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-6', name: 'Water', value: 0.3, health_status: 'healthy', group: 'Contaminants' },
      { id: '1-7', name: 'Glycol', value: 0.4, health_status: 'alarm', group: 'Contaminants' },
      { id: '1-8', name: 'Coolant', value: 8, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-9', name: 'Viscosity', value: 160, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-10', name: 'Oxidation', value: 60, health_status: 'alarm', group: 'Oil Condition' },
    ],
  },
  {
    meta: {
      date: '2025-02-12',
      health_status: 'alarm',
      report_number: 91011,
      oil_type: 'FUCHS UNISYN CLP 320',
      report_recommendations: 'Results within acceptable limits. Continue with regular maintenance and monitoring',
    },
    data: [
      { id: '1-11', name: 'Meter Reading', value: 4, group: 'Summary' },
      { id: '1-12', name: 'Oil Hrs', value: 6, group: 'Summary' },
      { id: '1-13', name: 'Top Up', value: 8, group: 'Summary' },
      { id: '1-14', name: 'Oil Change?', value: 'Yes', group: 'Summary' },
      { id: '1-1', name: 'Iron', limit: 10, value: 30, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-2', name: 'Copper', limit: 20, value: 26, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-3', name: 'Lead', limit: 45, value: 6, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-4', name: 'Aluminum', limit: 10, value: 10, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-6', name: 'Water', value: 0.3, health_status: 'healthy', group: 'Contaminants' },
      { id: '1-7', name: 'Glycol', value: 0.4, health_status: 'alarm', group: 'Contaminants' },
      { id: '1-8', name: 'Coolant', value: 8, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-9', name: 'Viscosity', value: 160, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-10', name: 'Oxidation', value: 60, health_status: 'alarm', group: 'Oil Condition' },
    ],
  },
  {
    meta: {
      date: '2025-02-13',
      health_status: 'alarm',
      report_number: 91011,
      oil_type: 'FUCHS UNISYN CLP 320',
      report_recommendations: 'Results within acceptable limits. Continue with regular maintenance and monitoring',
    },
    data: [
      { id: '1-11', name: 'Meter Reading', value: 4, group: 'Summary' },
      { id: '1-12', name: 'Oil Hrs', value: 6, group: 'Summary' },
      { id: '1-13', name: 'Top Up', value: 8, group: 'Summary' },
      { id: '1-14', name: 'Oil Change?', value: 'Yes', group: 'Summary' },
      { id: '1-1', name: 'Iron', limit: 10, value: 30, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-2', name: 'Copper', limit: 20, value: 26, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-3', name: 'Lead', limit: 45, value: 6, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-4', name: 'Aluminum', limit: 10, value: 10, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-6', name: 'Water', value: 0.3, health_status: 'healthy', group: 'Contaminants' },
      { id: '1-7', name: 'Glycol', value: 0.4, health_status: 'alarm', group: 'Contaminants' },
      { id: '1-8', name: 'Coolant', value: 8, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-9', name: 'Viscosity', value: 160, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-10', name: 'Oxidation', value: 60, health_status: 'alarm', group: 'Oil Condition' },
    ],
  },
  {
    meta: {
      date: '2025-02-14',
      health_status: 'alarm',
      report_number: 91011,
      oil_type: 'FUCHS UNISYN CLP 320',
      report_recommendations: 'Results within acceptable limits. Continue with regular maintenance and monitoring',
    },
    data: [
      { id: '1-11', name: 'Meter Reading', value: 4, group: 'Summary' },
      { id: '1-12', name: 'Oil Hrs', value: 6, group: 'Summary' },
      { id: '1-13', name: 'Top Up', value: 8, group: 'Summary' },
      { id: '1-14', name: 'Oil Change?', value: 'Yes', group: 'Summary' },
      { id: '1-1', name: 'Iron', limit: 10, value: 30, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-2', name: 'Copper', limit: 20, value: 26, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-3', name: 'Lead', limit: 45, value: 6, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-4', name: 'Aluminum', limit: 10, value: 10, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-6', name: 'Water', value: 0.3, health_status: 'healthy', group: 'Contaminants' },
      { id: '1-7', name: 'Glycol', value: 0.4, health_status: 'alarm', group: 'Contaminants' },
      { id: '1-8', name: 'Coolant', value: 8, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-9', name: 'Viscosity', value: 160, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-10', name: 'Oxidation', value: 60, health_status: 'alarm', group: 'Oil Condition' },
    ],
  },
  {
    meta: {
      date: '2025-02-15',
      health_status: 'alarm',
      report_number: 91011,
      oil_type: 'FUCHS UNISYN CLP 320',
      report_recommendations: 'Results within acceptable limits. Continue with regular maintenance and monitoring',
    },
    data: [
      { id: '1-11', name: 'Meter Reading', value: 4, group: 'Summary' },
      { id: '1-12', name: 'Oil Hrs', value: 6, group: 'Summary' },
      { id: '1-13', name: 'Top Up', value: 8, group: 'Summary' },
      { id: '1-14', name: 'Oil Change?', value: 'Yes', group: 'Summary' },
      { id: '1-1', name: 'Iron', limit: 10, value: 30, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-2', name: 'Copper', limit: 20, value: 26, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-3', name: 'Lead', limit: 45, value: 6, health_status: 'healthy', group: 'Wear Metals' },
      { id: '1-4', name: 'Aluminum', limit: 10, value: 10, health_status: 'alarm', group: 'Wear Metals' },
      { id: '1-6', name: 'Water', value: 0.3, health_status: 'healthy', group: 'Contaminants' },
      { id: '1-7', name: 'Glycol', value: 0.4, health_status: 'alarm', group: 'Contaminants' },
      { id: '1-8', name: 'Coolant', value: 8, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-9', name: 'Viscosity', value: 160, health_status: 'healthy', group: 'Oil Condition' },
      { id: '1-10', name: 'Oxidation', value: 60, health_status: 'alarm', group: 'Oil Condition' },
    ],
  },
  // {
  //   meta: {
  //     date: '2025-02-12',
  //     health_status: 'alarm',
  //     report_number: 91011,
  //     oil_type: 'FUCHS UNISYN CLP 320',
  //     report_recommendations: 'Results within acceptable limits. Continue with regular maintenance and monitoring',
  //   },
  //   data: [
  //     { id: '1-11', name: 'Meter Reading', value: 4, group: 'Summary' },
  //     { id: '1-12', name: 'Oil Hrs', value: 6, group: 'Summary' },
  //     { id: '1-13', name: 'Top Up', value: 8, group: 'Summary' },
  //     { id: '1-14', name: 'Oil Change?', value: 'Yes', group: 'Summary' },
  //     { id: '1-1', name: 'Iron', limit: 10, value: 30, health_status: 'healthy', group: 'Wear Metals' },
  //     { id: '1-2', name: 'Copper', limit: 20, value: 26, health_status: 'alarm', group: 'Wear Metals' },
  //     { id: '1-3', name: 'Lead', limit: 45, value: 6, health_status: 'healthy', group: 'Wear Metals' },
  //     { id: '1-4', name: 'Aluminum', limit: 10, value: 10, health_status: 'alarm', group: 'Wear Metals' },
  //     { id: '1-6', name: 'Water', value: 0.3, health_status: 'healthy', group: 'Contaminants' },
  //     { id: '1-7', name: 'Glycol', value: 0.4, health_status: 'alarm', group: 'Contaminants' },
  //     { id: '1-8', name: 'Coolant', value: 8, health_status: 'healthy', group: 'Oil Condition' },
  //     { id: '1-9', name: 'Viscosity', value: 160, health_status: 'healthy', group: 'Oil Condition' },
  //     { id: '1-10', name: 'Oxidation', value: 60, health_status: 'alarm', group: 'Oil Condition' },
  //   ],
  // },
  // {
  //   meta: {
  //     date: '2025-02-13',
  //     health_status: 'alarm',
  //     report_number: 91011,
  //     oil_type: 'FUCHS UNISYN CLP 320',
  //     report_recommendations: 'Results within acceptable limits. Continue with regular maintenance and monitoring',
  //   },
  //   data: [
  //     { id: '1-11', name: 'Meter Reading', value: 4, group: 'Summary' },
  //     { id: '1-12', name: 'Oil Hrs', value: 6, group: 'Summary' },
  //     { id: '1-13', name: 'Top Up', value: 8, group: 'Summary' },
  //     { id: '1-14', name: 'Oil Change?', value: 'Yes', group: 'Summary' },
  //     { id: '1-1', name: 'Iron', limit: 10, value: 30, health_status: 'healthy', group: 'Wear Metals' },
  //     { id: '1-2', name: 'Copper', limit: 20, value: 26, health_status: 'alarm', group: 'Wear Metals' },
  //     { id: '1-3', name: 'Lead', limit: 45, value: 6, health_status: 'healthy', group: 'Wear Metals' },
  //     { id: '1-4', name: 'Aluminum', limit: 10, value: 10, health_status: 'alarm', group: 'Wear Metals' },
  //     { id: '1-6', name: 'Water', value: 0.3, health_status: 'healthy', group: 'Contaminants' },
  //     { id: '1-7', name: 'Glycol', value: 0.4, health_status: 'alarm', group: 'Contaminants' },
  //     { id: '1-8', name: 'Coolant', value: 8, health_status: 'healthy', group: 'Oil Condition' },
  //     { id: '1-9', name: 'Viscosity', value: 160, health_status: 'healthy', group: 'Oil Condition' },
  //     { id: '1-10', name: 'Oxidation', value: 60, health_status: 'alarm', group: 'Oil Condition' },
  //   ],
  // },
  // {
  //   meta: {
  //     date: '2025-02-14',
  //     health_status: 'alarm',
  //     report_number: 91011,
  //     oil_type: 'FUCHS UNISYN CLP 320',
  //     report_recommendations: 'Results within acceptable limits. Continue with regular maintenance and monitoring',
  //   },
  //   data: [
  //     { id: '1-11', name: 'Meter Reading', value: 4, group: 'Summary' },
  //     { id: '1-12', name: 'Oil Hrs', value: 6, group: 'Summary' },
  //     { id: '1-13', name: 'Top Up', value: 8, group: 'Summary' },
  //     { id: '1-14', name: 'Oil Change?', value: 'Yes', group: 'Summary' },
  //     { id: '1-1', name: 'Iron', limit: 10, value: 30, health_status: 'healthy', group: 'Wear Metals' },
  //     { id: '1-2', name: 'Copper', limit: 20, value: 26, health_status: 'alarm', group: 'Wear Metals' },
  //     { id: '1-3', name: 'Lead', limit: 45, value: 6, health_status: 'healthy', group: 'Wear Metals' },
  //     { id: '1-4', name: 'Aluminum', limit: 10, value: 10, health_status: 'alarm', group: 'Wear Metals' },
  //     { id: '1-6', name: 'Water', value: 0.3, health_status: 'healthy', group: 'Contaminants' },
  //     { id: '1-7', name: 'Glycol', value: 0.4, health_status: 'alarm', group: 'Contaminants' },
  //     { id: '1-8', name: 'Coolant', value: 8, health_status: 'healthy', group: 'Oil Condition' },
  //     { id: '1-9', name: 'Viscosity', value: 160, health_status: 'healthy', group: 'Oil Condition' },
  //     { id: '1-10', name: 'Oxidation', value: 60, health_status: 'alarm', group: 'Oil Condition' },
  //   ],
  // },
  // {
  //   meta: {
  //     date: '2025-02-15',
  //     health_status: 'alarm',
  //     report_number: 91011,
  //     oil_type: 'FUCHS UNISYN CLP 320',
  //     report_recommendations: 'Results within acceptable limits. Continue with regular maintenance and monitoring',
  //   },
  //   data: [
  //     { id: '1-11', name: 'Meter Reading', value: 4, group: 'Summary' },
  //     { id: '1-12', name: 'Oil Hrs', value: 6, group: 'Summary' },
  //     { id: '1-13', name: 'Top Up', value: 8, group: 'Summary' },
  //     { id: '1-14', name: 'Oil Change?', value: 'Yes', group: 'Summary' },
  //     { id: '1-1', name: 'Iron', limit: 10, value: 30, health_status: 'healthy', group: 'Wear Metals' },
  //     { id: '1-2', name: 'Copper', limit: 20, value: 26, health_status: 'alarm', group: 'Wear Metals' },
  //     { id: '1-3', name: 'Lead', limit: 45, value: 6, health_status: 'healthy', group: 'Wear Metals' },
  //     { id: '1-4', name: 'Aluminum', limit: 10, value: 10, health_status: 'alarm', group: 'Wear Metals' },
  //     { id: '1-6', name: 'Water', value: 0.3, health_status: 'healthy', group: 'Contaminants' },
  //     { id: '1-7', name: 'Glycol', value: 0.4, health_status: 'alarm', group: 'Contaminants' },
  //     { id: '1-8', name: 'Coolant', value: 8, health_status: 'healthy', group: 'Oil Condition' },
  //     { id: '1-9', name: 'Viscosity', value: 160, health_status: 'healthy', group: 'Oil Condition' },
  //     { id: '1-10', name: 'Oxidation', value: 60, health_status: 'alarm', group: 'Oil Condition' },
  //   ],
  // },
  // {
  //   meta: {
  //     date: '2025-02-16',
  //     health_status: 'alarm',
  //     report_number: 91011,
  //     oil_type: 'FUCHS UNISYN CLP 320',
  //     report_recommendations: 'Results within acceptable limits. Continue with regular maintenance and monitoring',
  //   },
  //   data: [
  //     { id: '1-11', name: 'Meter Reading', value: 4, group: 'Summary' },
  //     { id: '1-12', name: 'Oil Hrs', value: 6, group: 'Summary' },
  //     { id: '1-13', name: 'Top Up', value: 8, group: 'Summary' },
  //     { id: '1-14', name: 'Oil Change?', value: 'Yes', group: 'Summary' },
  //     { id: '1-1', name: 'Iron', limit: 10, value: 30, health_status: 'healthy', group: 'Wear Metals' },
  //     { id: '1-2', name: 'Copper', limit: 20, value: 26, health_status: 'alarm', group: 'Wear Metals' },
  //     { id: '1-3', name: 'Lead', limit: 45, value: 6, health_status: 'healthy', group: 'Wear Metals' },
  //     { id: '1-4', name: 'Aluminum', limit: 10, value: 10, health_status: 'alarm', group: 'Wear Metals' },
  //     { id: '1-6', name: 'Water', value: 0.3, health_status: 'healthy', group: 'Contaminants' },
  //     { id: '1-7', name: 'Glycol', value: 0.4, health_status: 'alarm', group: 'Contaminants' },
  //     { id: '1-8', name: 'Coolant', value: 8, health_status: 'healthy', group: 'Oil Condition' },
  //     { id: '1-9', name: 'Viscosity', value: 160, health_status: 'healthy', group: 'Oil Condition' },
  //     { id: '1-10', name: 'Oxidation', value: 60, health_status: 'alarm', group: 'Oil Condition' },
  //   ],
  // },
];

// console.log('kololo', generateTableStructure(oilReportData4));

// export const oilReportStructure = [
//   {
//     id: 'Summary',
//     data: [
//       { id: '1-11', name: 'Meter Reading', reportsData: [] },
//       { id: '1-12', name: 'Oil Hrs', reportsData: [] },
//       { id: '1-13', name: 'Top Up', reportsData: [] },
//       { id: '1-14', name: 'Oil Change?', reportsData: [] },
//     ],
//   },
//   {
//     id: 'Wear Metals',
//     data: [
//       { id: '1-1', name: 'Iron', limit: 10, reportsData: [] },
//       { id: '1-2', name: 'Copper', limit: 300, reportsData: [] },
//       { id: '1-3', name: 'Lead', limit: 45, reportsData: [] },
//       { id: '1-4', name: 'Aluminum', limit: 10, reportsData: [] },
//     ],
//   },
//   {
//     id: 'Contaminants',
//     data: [
//       { id: '1-6', name: 'Water', reportsData: [] },
//       { id: '1-7', name: 'Glycol', reportsData: [] },
//     ],
//   },
//   {
//     id: 'Oil Condition',
//     data: [
//       { id: '1-8', name: 'Coolant', reportsData: [] },
//       { id: '1-9', name: 'Viscosity', reportsData: [] },
//       { id: '1-10', name: 'Oxidation', reportsData: [] },
//     ],
//   },
// ];
