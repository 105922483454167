import React from 'react';
import { ASSETS_TYPE, FILTER_PARAMS } from '@/types/_common/navigationTabs.enums';
import { Highlight } from '@/components/UI-kit/Highlight';
import { useSearchParams } from 'react-router-dom';
import { Asset } from '@/types/assets';
import { HEALTH_STATUS } from '@/const';
import { StatusTag } from '@/NewUI/components/_common/StatusTag';
import { AssetPreviewItemTitle, DataNotRecivedBadge } from '../../_common';
import { cn } from '@/lib/utils';
import { StatusChanged } from '../../_common/StatusChanged';

interface HeaderSectionProps {
  className: string;
  asset: Asset;
  assetsType: ASSETS_TYPE | undefined;
}

export function HeaderSection({ className, asset, assetsType }: HeaderSectionProps) {
  const [params] = useSearchParams();
  return (
    <div className={cn(className, `min-h-8`)}>
      <div>
        <AssetPreviewItemTitle
          title={
            <Highlight
              searchTerm={params.get(FILTER_PARAMS.GENERAL_SEARCH) || ''}
              text={`${asset.asset_ui_name} ${
                assetsType === ASSETS_TYPE.MOBILE ? ` - ${asset.asset_supplier} ${asset.asset_model}` : ''
              }`}
            />
          }
        />
        {/* <div className="h-6">{asset.health_status_changed && <StatusChanged />}</div> */}
      </div>
      <div className="h-full items-start mt-[-0.3rem]">{asset.not_monitored && <DataNotRecivedBadge />}</div>
    </div>
  );
}
