import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useAppFilters } from '../_common';

export const useAppNavigation = () => {
  const { pathname, search } = useLocation();
  const { siteId, id } = useParams();
  const [searchParams] = useSearchParams();
  const appFilters = useAppFilters();

  return { pathname, pathNameAsArray: pathname.split('/'), siteId, machineId: id, searchParams, appFilters };
};
