import React, { useRef, useEffect, useState } from 'react';
import { Box, HStack, VStack, Tooltip, Text, Image } from '@chakra-ui/react';
import { format } from 'date-fns';
import { HealthStatusIndicator } from 'src/components/StateIndicator';
import { Asset } from 'src/types/assets';
import { HEALTH_STATUS, REGULAR_DATE_FORMAT } from 'src/const';
import { MachineSensorsModal } from 'src/pages/machines/modals/MachineSensorsModal';
import evidencePlayIcon from 'src/assets/images/evidence-play-icon.svg';
import { FaultyComponentsList } from 'src/pages/machines/components/FaultyComponentsList';
import { getVisibleFaultyComponentsCount } from 'src/pages/machines/utils';
import { AssetFaultType } from 'src/pages/machines/components/AssetFaultType';

type MachineGridItemProps = {
  item: Asset;
  isRequireAttention?: boolean;
  siteId: string | undefined;
  onMachineItemClick: () => void;
  hasCamera: boolean;
  isNotMonitored: boolean;
};

export const MachineGridItem = ({
  item,
  isRequireAttention,
  siteId,
  onMachineItemClick,
  hasCamera,
  isNotMonitored,
}: MachineGridItemProps) => {
  const [visibleFaultyComponentsCount, setVisibleFaultyComponentsCount] = useState<number | null>(null);

  const faultyComponentsWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (faultyComponentsWrapper.current?.children?.length) {
      setVisibleFaultyComponentsCount(getVisibleFaultyComponentsCount(faultyComponentsWrapper.current));
    }
  }, []);

  return (
    <VStack
      bg="white"
      borderRadius="lg"
      boxShadow="sm"
      height="100%"
      p="0.75rem"
      spacing={2.5}
      cursor="pointer"
      _hover={{
        boxShadow: 'lg',
      }}
      onClick={onMachineItemClick}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" w="full">
        <Text fontSize="14px" fontWeight={600} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
          {item.asset_ui_name} {item.asset_category === 'mobile' && `- ${item.asset_supplier} ${item.asset_model}`}
        </Text>
        {isNotMonitored && (
          <Box
            color="white"
            fontWeight={600}
            borderRadius="8px"
            background="#01152B"
            textAlign="center"
            px={2}
            fontSize="10px"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            Not Monitored
          </Box>
        )}
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" w="full" gap="5px">
        <Box overflow="hidden">
          <Box display="flex" alignItems="center" gap={2}>
            <HealthStatusIndicator
              type={item.health_status}
              whiteSpace="nowrap"
            />
            <Text
              fontSize="12px"
              color="#55687D"
              fontWeight="500"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              Last verified: {format(new Date(item.latest_diagnostic_created_at), REGULAR_DATE_FORMAT)}
            </Text>
          </Box>

          <Tooltip label="Health status changed" borderRadius="md">
            <Text
              fontSize="10px"
              color="#01152B"
              fontWeight={600}
              whiteSpace="nowrap"
              pt={0.5}
              visibility={item.health_status_changed ? 'visible' : 'hidden'}
            >
              Status Change
            </Text>
          </Tooltip>
        </Box>

        <Box display="flex" gap={3} overflow="hidden" flexShrink={0}>
          {hasCamera && (
            <Tooltip label="Camera" borderRadius="md">
              <Image src={evidencePlayIcon} alt="Evidence Play Icon" />
            </Tooltip>
          )}
          <MachineSensorsModal
            sensors={item.sensors_health_status}
            title={item.asset_ui_name}
            siteId={siteId!}
            assetId={item.asset_id}
            assetCategory={item.asset_category}
            sensorsCountActive={item.sensors_count_active}
            sensorsCountTotal={item.sensors_count_total}
          />
        </Box>
      </Box>

      {isRequireAttention && (
        <>
          {item.faulty_components ? (
            <HStack
              alignItems="center"
              alignSelf="flex-start"
              gap="10px"
              overflow="hidden"
              minWidth="0px"
              flexShrink={1}
              ref={faultyComponentsWrapper}
              visibility={visibleFaultyComponentsCount !== null ? 'visible' : 'hidden'}
              w="full"
            >
              <FaultyComponentsList
                components={item.faulty_components}
                visibleFaultyComponentsCount={visibleFaultyComponentsCount}
              />
            </HStack>
          ) : !!item.most_severe_diagnostics?.[0].fault_type &&
            (item.health_status === HEALTH_STATUS.CRITICAL || item.health_status === HEALTH_STATUS.ALARM) ? (
            <Box w="full">
              <AssetFaultType
                faultType={item.most_severe_diagnostics[0].fault_type}
                variant={item.health_status === HEALTH_STATUS.CRITICAL ? 'critical' : 'alarm'}
                styles={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
              />
            </Box>
          ) : (
            <></>
          )}
        </>
      )}
    </VStack>
  );
};
