import { Box, HStack } from '@chakra-ui/react';
import React from 'react';

export const ComponentFusionTrendChartHead = ({
  selectedComponent,
  assetName,
  children,
}: {
  selectedComponent?: any;
  assetName?: string;
  children?: React.ReactNode;
}) => {
  const activeTitle = selectedComponent?.component_ui_name || selectedComponent?.component_name || assetName;

  return (
    <HStack pt={2} pb={3}>
      <Box pl={2} fontWeight={600} fontSize="14px">
        {`${activeTitle} Fusion Trend`}
      </Box>
      <Box>{children}</Box>
    </HStack>
  );
};
