import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { cn } from '@/lib/utils';
import { CHECKBOX_OVERWRITE } from './_overwrites/checkbox';

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    indeterminate?: boolean;
    strokeWidth?: number;
    children?: React.ReactNode;
  }
>(({ className, indeterminate = false, strokeWidth = 2, children, ...props }, ref) => (
  <div className="flex items-center gap-2">
    <CheckboxPrimitive.Root ref={ref} className={cn(CHECKBOX_OVERWRITE.classNames.Root, className)} {...props}>
      <CheckboxPrimitive.Indicator className={cn(CHECKBOX_OVERWRITE.classNames.Indicator)}>
        <CHECKBOX_OVERWRITE.elements.CheckmarkIcon strokeWidth={strokeWidth} indeterminate={indeterminate} />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
    {children && <span className="text-inherit h-full justify-self-center">{children}</span>}
  </div>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
