import React from 'react';
import { BaseTooltip } from '@/components';
import { DMCirclePlay, DMTriangleWarningIcon } from '@/components/Icons';
import { BaseButton } from '@/components/UI-kit/Button';
import { SENSORS_DATA_TYPE } from '@/const';
import { cn } from '@/lib/utils';
import { useParams } from 'react-router-dom';
import { useNavigateEvidence } from '@/NewUI/hooks/AssetsStudioPage/MainContent/VisualAI/useNavigateEvidence';

interface AssetActionsBarProps {
  variant?: 'table' | 'normal' | 'component-card';
  className?: string;
  asset: any;
  onClick?: () => void;
}
//@TODO: move to types
export interface Sensor {
  data_source: string;
  component_id: string;
  subcomponent: string;
  located_at: string;
  sensor_name: string;
  sensor_type: string;
  max_sensor_data_gap_hours: null;
  last_uptime: string;
  health_status: string;
}
//@TODO: make the compoentns generic & reuseable
export const AssetActionsBar = ({ variant, asset }: AssetActionsBarProps) => {
  const { health_status_changed, sensors_health_status, diagnostics } = asset;
  const { id } = useParams();

  const cameras: Sensor[] = sensors_health_status
    ? sensors_health_status?.filter((sensor: Sensor) => sensor.sensor_type.toLowerCase() === SENSORS_DATA_TYPE.Camera)
    : diagnostics
    ? diagnostics
        ?.map((el: any) => el.sources_info)
        .flat()
        .filter((el: any) => el?.source_type?.toLowerCase() === SENSORS_DATA_TYPE.Camera)
    : [];

  const hasCamera = cameras && cameras.length > 0;
  const firstCameraSensor = cameras?.[0];

  const { navigateEvidence } = useNavigateEvidence({
    id: firstCameraSensor?.component_id || asset.id,
    sourceType: SENSORS_DATA_TYPE?.Camera,
    healthStatus: firstCameraSensor?.health_status,
    //default date in case date is N/A
    diagnosticDate: diagnostics?.[0]?.diagnostic_created_at || '2024-08-04T00:00:00',
    assetId: asset.asset_id || id,
  });

  const handleNavigate = () => {
    navigateEvidence();
  };

  return (
    <div
      className={cn(
        'flex gap-0.5 items-center w-fit',
        `${variant === 'table' ? 'scale-[90%] pb-1 ml-[-0.5rem]' : 'pb-0.5'}`
      )}
    >
      {/* @TODO: replace false with real data once BE will support it */}
      {/* @TODO: change buttons to anchor for semantics */}
      {false && (
        <BaseTooltip content={'Missing Onboarding Data'}>
          <div className="pt-0.5">
            <DMTriangleWarningIcon boxSize={6} />
          </div>
        </BaseTooltip>
      )}
      {hasCamera && (
        <BaseTooltip content={'Visual AI'}>
          {/* <Link to={id || asset.asset_id + '/components?assetStudio=visual-ai'}> */}
          <BaseButton
            size="icon"
            variant="icon"
            icon={<DMCirclePlay boxSize={1} />}
            onClick={() => {
              handleNavigate();
            }}
          />
          {/* </Link> */}
        </BaseTooltip>
      )}
      {/* {health_status_changed && variant === 'component-card' && (
        <BaseTooltip content={'Status Changed'}>
          <div className="pt-0.5">
            <DMStatusChanged boxSize={6} />
          </div>
        </BaseTooltip>
      )} */}
    </div>
  );
};
