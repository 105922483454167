import { Badge } from '@/components/ui/badge';
import React from 'react';

interface DataNotRecivedBadgeProps {
  variant?: 'table' | 'normal';
}

export const DataNotRecivedBadge = ({ variant = 'normal' }: DataNotRecivedBadgeProps) => {
  const style =
    variant === 'normal'
      ? `bg-notMonitored text-white py-0.5 px-2 font-semibold text-[10px]`
      : `text-[9px] mb-0.5 bg-notMonitored text-white h-[1.1rem] pb-1`;
  return <Badge className={style}>N/A</Badge>;
};
