import React from 'react';
import {
  DMInfoCircleIcon,
  DMLogoutIcon,
  DMNotificationsIcon,
  DMSettingsIcon,
  DMSitesIcon,
  DMUserGuideIcon,
} from '@/components/Icons';
import { LegendContent } from '@/components/legend/components/LegendContent';
import { ComponentWithAs, IconProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

import pdfFile from 'src/assets/files/UserManualDataMindAIOct2024.pdf';
import Notifications from '@/components/Notifications';

export interface SidebarButton {
  id: string;
  icon: ComponentWithAs<'svg', IconProps>;
  tooltip?: { element: ReactNode | string; direction?: 'top' | 'bottom' | 'left' | 'right' };
  label: string;
  drawer?: { title?: string; element: ReactNode | string; direction?: 'top' | 'bottom' | 'left' | 'right' };
  dialog?: { title?: string; element: ReactNode | string; className?: string };
  onClick?: () => void;
  section: 'top' | 'middle' | 'bottom';
  href: string | null;
}

export const useSidebarElements = () => {
  const sidebarElements: SidebarButton[] = [
    {
      id: 'sites',
      icon: DMSitesIcon,
      tooltip: { element: 'Sites' },
      label: 'Sites',
      section: 'top',
      href: '/sites',
    },
    {
      id: 'info',
      icon: DMInfoCircleIcon,
      href: null,
      tooltip: { element: 'Health trend legend' },
      // tooltip: (
      //   <div className="max-w-[400px] flex-col justify-start items-start text-left">
      //     <>
      //       <h2 className="font-semibold py-2">Health trend legend</h2>
      //       <LegendContent />
      //     </>
      //   </div>
      // ),
      label: 'Health trend',
      // dialog: {
      //   title: 'Health trend legend',
      //   className: 'max-w-fit',
      //   element: (
      //     <div className="w-full max-w-[400px] flex-col justify-start items-start text-default text-left">
      //       <LegendContent />
      //     </div>
      //   ),
      // },
      section: 'top',
      drawer: {
        title: 'Health trend legend index',
        element: (
          <div className="max-w-[400px] px-4 ">
            <LegendContent />
          </div>
        ),
        direction: 'left',
      },
    },
    {
      id: 'alerts',
      icon: DMNotificationsIcon,
      href: null,
      tooltip: { element: 'Alerts' },
      label: 'Alerts',
      section: 'top',
      drawer: {
        title: 'Alerts',
        element: (
          <div className="min-w-screen">
            <Notifications defaultOpen={true} />
          </div>
        ),
      },
    },
    {
      id: 'user-guide',
      icon: DMUserGuideIcon,
      href: null,
      dialog: {
        title: 'User Guide',
        className: 'max-w-[1200px] min-w-fit px-1 md:px-4 py-4',
        element: (
          <iframe
            src={pdfFile}
            style={{
              width: '100%',
              height: '80vh',
            }}
          />
        ),
      },
      tooltip: { element: 'User guide' },
      label: 'User guide',
      section: 'top',
    },
    {
      id: 'settings',
      icon: DMSettingsIcon,
      href: '/settings',
      label: 'Settings',
      tooltip: { element: 'Settings' },
      section: 'top',
    },
    {
      id: 'logout',
      icon: DMLogoutIcon,
      href: '/login',
      tooltip: { element: 'Logout' },
      label: 'Logout',
      section: 'bottom',
    },
  ];

  return { sidebarElements };
};
