import { SidebarButtons } from '@/components/SideBar/SidebarButtons';
import React from 'react';

export default function MobileSidebar() {
  return (
    <aside className="grid grid-cols-3 gap-8 mb-10 px-8">
      <SidebarButtons section="top" className=" capitalize text-default xl:text-white" />
      <SidebarButtons section="bottom" className=" capitalize text-default  xl:text-white" />
    </aside>
  );
}
