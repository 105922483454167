import React, { useEffect, useState } from 'react';
import { Counter } from '../Counter/Counter';

interface MenuItem {
  id: string;
  label: string | React.ReactNode;
  counter?: number;
  className?: string;
  disabled?: boolean;
}

interface useUIKitFilterProps {
  multiSelect?: boolean;
  data: { menuLabel?: string; menuItems: MenuItem[] }[];
  onFilterChange?: (filters: any) => void;
  filterLable?: string;
  closeOnSelect?: boolean;
  type?: 'dropdown' | 'filter';
  defaultSelected?: Record<string, string[]>;
}

export const useUIKitFilter = ({
  data,
  defaultSelected,
  multiSelect,
  type,
  onFilterChange,
  filterLable,
}: useUIKitFilterProps) => {
  const [initialData, setInitialData] = useState(data);
  // "added"
  const [initialDefaultSelected, setInitialDefaultSelected] = useState<any>(defaultSelected);

  useEffect(() => {
    if (JSON.stringify(initialData) !== JSON.stringify(data)) setInitialData(data);
    // "added"
    if (JSON.stringify(initialDefaultSelected) !== JSON.stringify(defaultSelected))
      setInitialDefaultSelected(defaultSelected);
  }, [data, defaultSelected]);

  const initialSelectedGroups = React.useMemo(() => {
    if (defaultSelected) {
      return defaultSelected;
    }
    if (!multiSelect) return {};
    const selected: Record<string, string[]> = {};
    initialData?.forEach((group: any) => {
      selected[group?.menuLabel] = group?.menuItems?.filter((item: any) => !item.disabled).map((item: any) => item.id);
    });
    return selected;
  }, [initialData, initialDefaultSelected]);

  const [allItemsSelected, setAllItemsSelected] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<Record<string, string[]>>(initialSelectedGroups);

  // Initialize the default value only once
  useEffect(() => {
    // "removed"
    // multiSelect && setSelectedGroups(initialSelectedGroups);
    setSelectedGroups(initialSelectedGroups);
  }, [initialSelectedGroups]);

  const toggleItem = (groupLabel: string, item: MenuItem) => {
    if (item.disabled) return;

    setSelectedGroups((prev) => {
      if (multiSelect) {
        return {
          ...prev,
          [groupLabel]: prev[groupLabel]?.includes(item.id)
            ? prev[groupLabel].filter((i) => i !== item.id)
            : [...(prev[groupLabel] || []), item.id],
        };
      } else if (
        (type === 'dropdown' || !multiSelect) &&
        prev[groupLabel]?.includes(item.id) &&
        selectedGroups[groupLabel].length === 1
      ) {
        return prev;
      } else {
        return {
          ...prev,
          [groupLabel]: prev[groupLabel]?.includes(item.id) ? [] : [item.id],
        };
      }
    });
  };

  const toggleAll = (groupLabel: string, items: MenuItem[]) => {
    const enabledItems = items.filter((item) => !item.disabled).map((item) => item.id);
    setSelectedGroups((prev) => {
      const allSelected = enabledItems.every((item) => prev[groupLabel]?.includes(item));
      return {
        ...prev,
        [groupLabel]: allSelected ? [] : enabledItems,
      };
    });
  };

  const isSelected = (groupLabel: string, item: string) => selectedGroups[groupLabel]?.includes(item) || false;
  const areAllSelected = (groupLabel: string, items: MenuItem[]) =>
    items.filter((item) => !item.disabled).every((item) => selectedGroups[groupLabel]?.includes(item.id));
  const isAnySelected = (groupLabel: string, items: MenuItem[]) => {
    const selectedCount = items.filter((item) => isSelected(groupLabel, item.id)).length;
    return selectedCount > 0 && selectedCount < items.length;
  };
  // Utility function to check if all items in all groups are selected
  const areAllItemsSelected = () => {
    return data.every((group: any) => {
      const enabledItems = group.menuItems.filter((item: any) => !item.disabled).map((item: any) => item.id);
      return enabledItems.every((item: any) => selectedGroups[group.menuLabel]?.includes(item));
    });
  };

  useEffect(() => {
    if (onFilterChange) {
      onFilterChange(selectedGroups); // Call with the constructed filters object
    }
    setAllItemsSelected(areAllItemsSelected()); // Update all items selected state
  }, [selectedGroups, data]);

  // Determine the filter label based on the selection status
  const getFilterLabel = () => {
    if (allItemsSelected || Object.values(selectedGroups).flat().length === 0) {
      return allItemsSelected ? `${filterLable}` : 'No Selection';
    }

    const selectedItems = Object.values(selectedGroups).flat();
    const firstTwoItems = selectedItems.slice(0, 2);
    const remainingCount = selectedItems.length - firstTwoItems.length;

    return (
      <div className="capitalize flex items-center">
        {firstTwoItems.join(', ')}
        {remainingCount > 0 && <Counter value={remainingCount} prefix="+" className="ml-2" size="md" />}
      </div>
    );
  };

  const getFilterLabelCount = () => {
    const selectedItems = Object.values(selectedGroups).flat();
    return <Counter value={selectedItems?.length} prefix="+" size="md" />;
  };
  return {
    allItemsSelected,
    filterData: data,
    initialSelectedGroups,
    selectedGroups,
    toggleAll,
    isSelected,
    areAllSelected,
    isAnySelected,
    getFilterLabelCount,
    getFilterLabel,
    setSelectedGroups,
    toggleItem,
  };
};
