import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSidebarElements } from '@/NewUI/hooks/Sidebar/useSidebarElements';
import { useViewportWidth } from '@/NewUI/hooks/_common';
import { useAppNavigation } from '@/NewUI/hooks/Sidebar/useAppNavigation';
import { BaseIconButton } from '@/components/UI-kit';

interface SidebarButtonsProps {
  section: 'top' | 'middle' | 'bottom';
  className?: string;
}
export const SidebarButtons = ({ section, className }: SidebarButtonsProps) => {
  const navigate = useNavigate();
  const { pathname } = useAppNavigation();
  const { sidebarElements } = useSidebarElements();
  const buttons = sidebarElements.filter((sidebarElement) => sidebarElement.section === section);
  const { isTablet } = useViewportWidth();
  return (
    <>
      {buttons.map((button, index) => {
        const { id, icon, tooltip, dialog, drawer, href, onClick, label } = button;
        const Icon = icon;
        return !isTablet ? (
          <BaseIconButton
            key={id}
            icon={icon}
            tooltip={tooltip}
            dialog={dialog}
            drawer={drawer}
            onClick={
              href
                ? () => {
                    navigate(href);
                  }
                : onClick
            }
            isActive={!isTablet && pathname.includes(id)} //@TODO : replace with url(?)
          />
        ) : (
          <>
            <BaseIconButton
              key={id}
              icon={icon}
              tooltip={tooltip}
              label={label}
              dialog={dialog}
              drawer={drawer}
              showLabel={true}
              onClick={
                href
                  ? () => {
                      navigate(href);
                    }
                  : () => {
                      onClick && onClick();
                    }
              }
              classNames={{ button: `flex-col border-[1px] hover:cursor-pointer p-14  ${className}` }}
              isActive={!isTablet && section === 'top' && index === 0} //@TODO : replace with url(?)
            />
          </>
        );
      })}
    </>
  );
};
