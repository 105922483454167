import React from 'react';
import {
  Box,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { DMHeartPulseIcon } from 'src/components/Icons';
import { FormattedText } from 'src/components/FormattedText';
import { LegendContent } from './components/LegendContent';

export const LegendModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <VStack spacing="10px" cursor="pointer" onClick={onOpen} userSelect="none">
        <IconButton
          aria-label="Info"
          size="lg"
          background={isOpen ? '#12FFE2' : 'transparent'}
          color={isOpen ? '#021d3d' : '#fff'}
          borderRadius="16px"
          height="2rem"
          icon={<DMHeartPulseIcon />}
          variant="unstyled"
        />
        <Text fontSize="10px" lineHeight="14px" fontWeight={600}>
          Status
        </Text>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose} size="sm" scrollBehavior="outside" isCentered>
        <ModalOverlay />
        <ModalContent pb={4} borderRadius="lg">
          <ModalHeader fontWeight={400} fontSize="1rem" px={4}>
            <Box textStyle="subtitleBold">
              <FormattedText label="general.legend.title" />
            </Box>
          </ModalHeader>
          <ModalCloseButton top={3} right={3} />
          <ModalBody p={4}>
            <LegendContent />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
