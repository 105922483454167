import { Asset } from '@/types/assets';
import React, { useRef } from 'react';
import { AssetActionsBar, FaultyComponents, SensorsNewIndicator } from '../../_common';

interface FooterSectionProps {
  asset: Asset;
  classNames: {
    assetsFaultIndicator: string;
  };
  isRequireAttention?: boolean;
}

export function FooterSection({ asset, isRequireAttention }: FooterSectionProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  if (!isRequireAttention && asset?.faulty_components && asset?.faulty_components?.length > 0) return null;
  const faulty_components = asset.faulty_components;

  return (
    <div className={`flex justify-between items-center ${!asset.not_monitored || 'grayscale'}`} ref={containerRef}>
      <FaultyComponents faulty_components={faulty_components || []} asset_id={asset.asset_id} />
      <div className="flex items-center justify-end gap-2 h-full">
        <AssetActionsBar asset={asset} />
        <SensorsNewIndicator asset={asset} />
      </div>
    </div>
  );
}
