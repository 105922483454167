import React from 'react';
import { Badge } from '@chakra-ui/react';
import { NotificationAlert } from 'src/data/notifications';

export const NotificationsBadge = (props: { notifications: NotificationAlert[] }) => {
  return (
    <Badge
      borderRadius="full"
      bgColor="red"
      color="white"
      w="1.25rem"
      h="1.25rem"
      fontSize="0.75rem"
      position="absolute"
      left="1.5rem"
      top={0.8}
      border="2px solid #2f2f66"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {props.notifications?.length}
    </Badge>
  );
};
