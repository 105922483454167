import React from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar as NewSidebar } from '@/NewUI/layouts/Sidebar/Sidebar';
import { Sidebar as OriginalSidebar } from '@/components/Sidebar';
import { diagnosticApi } from '@/app/api/diagnosticApi';
import { userApi } from '@/app/api/userApi';
import { locations } from '@/app/locations';
import { firebaseUserRequestingSelector, mfaExpiredSelector } from '@/app/store';
import { ScreenSpinner } from '@/components/ScreenSpinner';
import { useAuth } from '@/providers/AuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';

const isNewSidebar = false;

export const AppWrapper = () => {
  const { user, signOut } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isFirebaseUserRequesting = useSelector(firebaseUserRequestingSelector);
  const isMfaExpired = useSelector(mfaExpiredSelector);

  if (isMfaExpired) {
    signOut().then(() => {
      navigate(locations.public.login);
      dispatch(diagnosticApi.util.resetApiState());
      dispatch(userApi.util.resetApiState());
      analytics.track('User logged out');
    });
  }

  if (isFirebaseUserRequesting) {
    return <ScreenSpinner />;
  }

  if (!user) {
    sessionStorage.setItem('redirectAfterLogin', location.pathname + location.search);
    return <Navigate to={locations.public.login} state={{ from: location }} replace />;
  }
  return (
    <>
      <div className="hidden xl:flex">{isNewSidebar ? <NewSidebar /> : <OriginalSidebar />}</div>
      <Outlet />
    </>
  );
};
