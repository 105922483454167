import { Box, Center, Hide, Spinner } from '@chakra-ui/react';
import React from 'react';
import { ComponentsSidebarContent } from 'src/pages/machines/components/components-sidebar/ComponentsSidebarContent';
import { ComponentsSidebarHead } from 'src/pages/machines/components/components-sidebar/ComponentsSidebarHead';
import { ShowAllComponentSidebarItem } from './ShowAllComponentSidebarItem';
import { ComponentsSidebarBottom } from './ComponentsSidebarBottom';
import { useSelector } from 'react-redux';
import { activeAssetsCategory, dispatch, schemesMenuActivatedSelector } from 'src/app/store';
import { SchemesMenu } from 'src/features/mobile-fleet-sensor-data/components/schemes-menu/SchemesMenu';
import { ASSETS_CATEGORY } from 'src/const';
import { setSchemesMenuActivated } from 'src/app/slices/applicationSlice';

export const ComponentsSidebar = (props: {
  isLoading?: boolean;
  asset?: any;
  selectedComponent?: any;
  onAllComponentsClick?: () => void;
  components: any;
  activeTabIndex: number;
  element: (component: any, index: number) => JSX.Element;
}) => {
  const { asset_ui_name: assetName } = props.asset || {};
  const isSchemesMenuActivated = useSelector(schemesMenuActivatedSelector);
  const activeAssetsCategoryId = useSelector(activeAssetsCategory);
  const isMobileAsset = activeAssetsCategoryId === ASSETS_CATEGORY.Mobile;
  const isSensorDataTabActive = props.activeTabIndex === 1;

  return (
    <Box>
      <Box
        h={{
          base: 0,
          xl: 'calc(100vh - 29.1rem)',
          '1.5xl': 'calc(100vh - 29.1rem)',
        }}
        borderRadius="lg"
        minW="19.75rem"
        w="19.75rem"
        bg="#E6E8EC"
        overflowY="auto"
        borderBottom="0.5rem solid #E6E8EC"
        borderTop="0.5rem solid #E6E8EC"
        display="flex"
        flexDirection="column"
        borderBottomRadius="xl"
        mb={4}
      >
        {isMobileAsset && isSensorDataTabActive && isSchemesMenuActivated ? (
          <>
            {!props.selectedComponent && (
              <Hide below="xl">
                <ComponentsSidebarHead components={props.components} assetName={assetName} />
              </Hide>
            )}

            {!props.selectedComponent && (
              <Box p={2}>
                <Hide below="xl">
                  <ShowAllComponentSidebarItem
                    onClick={props.onAllComponentsClick!}
                    component={props.components}
                    selectedComponent={props.selectedComponent}
                  />
                </Hide>
              </Box>
            )}
          </>
        ) : (
          <>
            <Hide below="xl">
              <ComponentsSidebarHead components={props.components} assetName={assetName} />
            </Hide>

            <Box p={2}>
              <Hide below="xl">
                <ShowAllComponentSidebarItem
                  onClick={props.onAllComponentsClick!}
                  component={props.components}
                  selectedComponent={props.selectedComponent}
                />
              </Hide>
            </Box>
          </>
        )}

        {props.isLoading ? (
          <Center pt={5}>
            <Spinner size="lg" />
          </Center>
        ) : isMobileAsset && isSensorDataTabActive && isSchemesMenuActivated ? (
          <>
            {!props.selectedComponent ? (
              <Box flex="1">
                <ComponentsSidebarContent components={props.components} element={props.element} />
              </Box>
            ) : null}
          </>
        ) : (
          <Box flex="1">
            <ComponentsSidebarContent components={props.components} element={props.element} />
          </Box>
        )}

        {isMobileAsset && isSensorDataTabActive && props.selectedComponent && isSchemesMenuActivated ? (
          <SchemesMenu
            onBackButtonClick={() => {
              dispatch(setSchemesMenuActivated(false));
            }}
            selectedComponent={props.selectedComponent}
          />
        ) : null}
      </Box>

      <Box maxWidth="19.75rem" overflowY="auto">
        <ComponentsSidebarBottom
          imageResourceId={props?.asset?.asset_image_resource_id}
          isPageLoading={props?.isLoading}
          assetName={assetName}
        />
      </Box>
    </Box>
  );
};
