import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover';
import { StatusTag } from '@/NewUI/components/_common/StatusTag';
import { ASSET_STUDIO } from '@/types/_common/navigationTabs.enums';

import React from 'react';

interface AssetsSelectorProps {
  selectedComponents: any;
  asset: any;
}
//@TODO: turn to dropdown
export const AssetsSelector = ({ selectedComponents, asset }: AssetsSelectorProps) => {
  return (
    <Popover>
      <PopoverTrigger className="flex items-center gap-2">
        {selectedComponents?.id !== ASSET_STUDIO.ALL_COMPONENTS
          ? selectedComponents?.data?.[0]?.component_name
          : selectedComponents?.id}
        {selectedComponents?.id !== ASSET_STUDIO.ALL_COMPONENTS && (
          <StatusTag status={selectedComponents?.data[0]?.health_status} />
        )}
      </PopoverTrigger>
      <PopoverContent></PopoverContent>
    </Popover>
  );
};
