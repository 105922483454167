import React from 'react';
import { AssetHealthStatusIndicator } from '../../_common/AssetHealthStatusIndicator';
import { SensorsData } from '../../_common/SensorsIndicator/SensorsData';
import { Asset } from '@/types/assets';
import { AssetLastVerified } from '../../_common/AssetLastVerified';
import { cn } from '@/lib/utils';
import { StatusChanged } from '../../_common/StatusChanged';

interface BodySectionProps {
  asset: Asset;
  isRequireAttention: boolean;
  classNames?: {
    assetsFaultIndicator?: string;
  };
}
const assetsFaultIndicatorStyle = 'flex items-center gap-3 overflow-hidden  whitespace-nowrap pt-2 w-full';

export function BodySection({ asset, classNames, isRequireAttention }: BodySectionProps) {
  return (
    <>
      <div className={cn('flex justify-between ')}>
        <div className={`pb-2 flex-1`}>
          <div
            className={cn(
              classNames?.assetsFaultIndicator || assetsFaultIndicatorStyle,
              `flex-col gap-1 pb-1 items-start`
            )}
          >
            <div className="flex justify-between w-full">
              <AssetHealthStatusIndicator
                healthStatus={asset.health_status}
                showReasons={isRequireAttention}
                reasons={asset.health_status_changed ? ['Status Change'] : undefined}
              />
            </div>
            <AssetLastVerified lastVerified={asset.latest_diagnostic_created_at} />
          </div>
        </div>
      </div>
    </>
  );
}
