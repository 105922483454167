import React from 'react';
import { HStack, Spinner } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { DMThumbDownIcon, DMThumbUpIcon } from 'src/components/Icons';
import { useUpdateFeedbackMutation } from 'src/app/api/diagnosticApi';
import { setUpdatedFailuresFeedbacks } from 'src/app/slices/applicationSlice';
import { updatedFailuresFeedbacksSelector } from 'src/app/store';

const enum FEEDBACK_STATE {
  Resolved = 'resolved',
  Unresolved = 'unresolved',
  NoFeedback = 'no_feedback',
}

interface FeedbackThumbsProps {
  feedbackType: string;
  feedback: string;
  description: string;
  diagnosticId: string;
}

export const FeedbackThumbs = ({ feedback, feedbackType, description, diagnosticId }: FeedbackThumbsProps) => {
  const dispatch = useDispatch();
  const updatedFailuresFeedbacks = useSelector(updatedFailuresFeedbacksSelector);
  const [updateFeedback, { isLoading: isUpdating }] = useUpdateFeedbackMutation();

  const onThumbClick = async (feedback: string) => {
    const response = await updateFeedback({
      diagnostic_id: diagnosticId,
      feedback_type: feedbackType,
      feedback,
      description,
    });

    if (response && !('error' in response)) {
      dispatch(
        setUpdatedFailuresFeedbacks({
          diagnosticId,
          feedbackType,
          description,
          feedback,
        })
      );
    }
  };

  const latestFeedback = updatedFailuresFeedbacks?.[diagnosticId]?.[feedbackType]?.[description] || feedback;

  return (
    <HStack justifyContent="flex-end">
      {isUpdating ? (
        <Spinner size="sm" />
      ) : (
        <>
          <DMThumbDownIcon
            fontSize="1.5rem"
            color={
              latestFeedback === FEEDBACK_STATE.Unresolved
                ? 'health.critical'
                : latestFeedback !== null
                ? 'rgba(2, 29, 61, 0.34)'
                : 'black'
            }
            onClick={() => {
              if (latestFeedback === FEEDBACK_STATE.Unresolved) {
                onThumbClick(FEEDBACK_STATE.NoFeedback);
              } else {
                onThumbClick(FEEDBACK_STATE.Unresolved);
              }
            }}
            cursor="pointer"
          />
          <DMThumbUpIcon
            fontSize="1.5rem"
            color={
              latestFeedback === FEEDBACK_STATE.Resolved
                ? 'health.healthy'
                : latestFeedback !== null
                ? 'rgba(2, 29, 61, 0.34)'
                : 'black'
            }
            onClick={() => {
              if (latestFeedback === FEEDBACK_STATE.Resolved) {
                onThumbClick(FEEDBACK_STATE.NoFeedback);
              } else {
                onThumbClick(FEEDBACK_STATE.Resolved);
              }
            }}
            cursor="pointer"
          />
        </>
      )}
    </HStack>
  );
};
