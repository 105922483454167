import { setAssetsPreviewFilter } from '@/app/slices/assetsPreviewSlice';
import { useLazyGetAssetsQuery } from '@/app/api/diagnosticApi';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ASSETS_TYPE } from '@/types/_common/navigationTabs.enums';
import { Asset } from '@/types/assets';
import { useQueryNavigation } from '../../_common';

export function useFetchAssets() {
  const { siteId } = useParams();
  const dispatch = useDispatch();
  const [fetchAssets, { data: assets, isLoading }] = useLazyGetAssetsQuery();
  const { currentParams } = useQueryNavigation();

  useEffect(() => {
    if (siteId) {
      fetchAssets(
        {
          site_id: siteId,
        },
        true
      ).unwrap();
    }
  }, [siteId]);

  useEffect(() => {
    if (assets) {
      const filterData = assets.filter((asset: Asset) => {
        if (currentParams && currentParams?.assetsType === ASSETS_TYPE.MOBILE) return asset.asset_category === 'mobile';
        else return asset.asset_category !== 'mobile';
      });
      dispatch(setAssetsPreviewFilter(filterData));
    }
  }, [assets, currentParams?.assetsType]);

  return { assets, isLoading };
}
