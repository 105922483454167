import { cn } from '@/lib/utils';
import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';

interface CommonFailures {
  fault_name: string;
  occurrences: number;
  average_alarm_days: number;
  average_critial_days: number;
}
// text-xs items-center flex justify-center justify-start h-8 gap-1
const headerStyle = 'text-xs';
const cellStyle = 'text-xs pr-4';

export const useCommonFailuresTable = () => {
  const assets_preview_table: ColumnDef<CommonFailures>[] = useMemo(() => {
    return [
      {
        accessorKey: 'fault_name',
        header: () => <div className={'text-xs h-full'}>Failure</div>,
        cell: (info) => {
          return <div className={cellStyle}>{info.row.original.fault_name}</div>;
        },
      },
      {
        accessorKey: 'occurrences',
        header: () => <div className={headerStyle}>Occurrences</div>,
        cell: (info) => {
          return <div className={cellStyle}>{info.row.original.occurrences}</div>;
        },
      },
      {
        accessorKey: 'average_critial_days',
        header: () => (
          <div className={headerStyle}>
            <span className="text-critical font-semibold text-xs">Critical</span> Avg. Time
          </div>
        ),
        cell: (info) => {
          return <div className={cellStyle}>{info.row.original.average_critial_days} Days</div>;
        },
      },
      {
        accessorKey: 'average_alarm_days',
        header: () => (
          <div className={headerStyle}>
            <span className="text-alarm font-semibold text-xs">Alarm</span> Avg. Time
          </div>
        ),
        cell: (info) => {
          return <div className={cellStyle}>{info.row.original.average_alarm_days} Days</div>;
        },
      },
    ];
  }, []);
  return { tableStructure: assets_preview_table };
};
