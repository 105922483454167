import { Highlight } from '@/components/UI-kit/Highlight/Highlight';
import { DMSensorsIcon } from '@/components/Icons';
import { REGULAR_DATE_FORMAT } from '@/const';
import { useAppFilters } from '@/NewUI/hooks/_common';
import { ASSETS_TYPE } from '@/types/_common/navigationTabs.enums';
import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import React, { useMemo } from 'react';

const headerTagStyle = 'text-sm px-2 py-0.5 rounded-full text-white font-medium text-xs whitespace-nowrap';

export interface SensorData {
  data_source?: string;
  last_uptime: string;
  sensor_name: string;
  sensor_type: string;
  health_status: string;
  located_at: string;
  component_id: string;
}

const healthTypeConvert = {
  online: 'text-healthy',
  offline: 'text-critical',
};

export const useSensorsTable = () => {
  const { assetsType } = useAppFilters();
  const sites_table_structure: ColumnDef<SensorData>[] = useMemo(
    () => [
      {
        accessorKey: 'data_source',
        header: 'Data Source',
        cell: (info) => info.getValue(),
        enableFiltering: true,
      },
      {
        accessorKey: 'sensor_name',
        header: 'Sensor Name',
        cell: (info) => info.getValue(),
        enableFiltering: true,
      },
      {
        accessorKey: 'health_status',
        header: 'Health Status',
        cell: (info) => {
          const content = assetsType === ASSETS_TYPE.FIXED ? info.row.original.health_status : 'Data Not Received';
          return (
            <div
              className={
                (content.toLowerCase() === 'online' || content.toLowerCase() === 'data received'
                  ? 'text-healthy'
                  : 'text-critical') +
                ' capitalize font-medium  rounded-full flex items-center gap-1 justify-center w-full'
              }
            >
              {/* <div className={info.row.original.health_status ==ß= 'online' ? 'animate-pulse-fast' : ''}>
            <DMSensorsIcon boxSize={6} />
          </div> */}
              {content}
            </div>
          );
        },
        enableFiltering: true,
      },
      {
        accessorKey: 'last_uptime',
        header: 'Last Report',
        cell: (info) => format(new Date(info.row.original.last_uptime), REGULAR_DATE_FORMAT),
      },
      {
        accessorKey: 'sensor_type',
        header: 'Type',
        cell: (info) => <div className="capitalize">{info.row.original.sensor_type}</div>,
        enableFiltering: true,
      },
      {
        accessorKey: 'located_at',
        header: 'Location',
        cell: (info) => <div className="capitalize">{info.row.original.located_at}</div>,
        enableFiltering: true,
      },
    ],
    []
  );
  return {
    tableStructure: sites_table_structure.filter((el: any) =>
      assetsType === ASSETS_TYPE.FIXED ? el.accessorKey !== 'data_source' : true
    ),
  };
};
