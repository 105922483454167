import { cn } from '@/lib/utils';
import { Check, Minus } from 'lucide-react';
import React, { ReactNode } from 'react';

interface Checkmarks {
  strokeWidth: number;
  indeterminate: boolean;
}

interface CheckmarkLabel {
  children: ReactNode;
}

const CheckmarkIcon = ({ strokeWidth, indeterminate }: Checkmarks) => {
  const Icon = indeterminate ? Minus : Check;
  return <Icon className={cn(CHECKBOX_OVERWRITE.classNames.Icon)} strokeWidth={strokeWidth} />;
};

const CheckmarkLabel = ({ children }: CheckmarkLabel) => {
  return children && <span className="text-inherit h-full justify-self-center">{children}</span>;
};

export const CHECKBOX_OVERWRITE = {
  classNames: {
    Root: 'peer h-4 w-4 shrink-0 rounded-sm border border-neutral-100 ring-offset-background focus-visible:outline-none focus-visible:ring-[1px] disabled:hover:ring-0 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:border-neutral-100 hover:ring-[1px] hover:ring-neutral-100 data-[state=checked]:border-primary-600 data-[state=checked]:disabled:border-neutral-100 data-[state=checked]:bg-primary-600 data-[state=checked]:disabled:bg-neutral-100 data-[state=checked]:hover:ring-primary-600 data-[state=checked]:text-white',
    Indicator: 'flex items-center justify-center text-current ',
    Icon: 'h-4 w-4 px-[2px] pb-[2px]',
  },
  elements: {
    CheckmarkIcon,
    CheckmarkLabel,
  },
};
