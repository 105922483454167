import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import React, { ReactNode } from 'react';

interface AssetPreviewItemTitleProps {
  title: ReactNode | string;
  subTitle?: ReactNode | string;
  className?: string;
}

export const AssetPreviewItemTitle = ({ title, subTitle, className }: AssetPreviewItemTitleProps) => {
  return (
    <div className={cn(`flex flex-col gap-0.5`, className)}>
      <div className="font-semibold text-sm">{title}</div>
      <div className="text-xs text-gray-600 font-medium">{subTitle}</div>
    </div>
  );
};
