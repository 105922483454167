import { createApi, retry } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithStatusCheck } from 'src/app/baseQueryWithStatusCheck';

const baseQuery = retry(baseQueryWithStatusCheck, { maxRetries: 2 });

export const mobileFleetApi = createApi({
  reducerPath: 'mobileFleetApi',
  baseQuery,
  tagTypes: ['MobileFleetSensors'],
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getMobileFleetSensors: builder.query<any, any>({
      query: ({
        site_id,
        asset_name,
        component_id,
        formulas_ids,
        from_date,
        to_date,
        full_pull,
        downsample_by,
        daily_max,
      }) => {
        const searchParams = new URLSearchParams();

        if (site_id) {
          searchParams.append('site_id', site_id);
        }
        if (asset_name) {
          searchParams.append('asset_name', asset_name);
        }
        if (component_id) {
          searchParams.append('component_id', component_id);
        }
        if (formulas_ids) {
          searchParams.append('formulas_ids', formulas_ids);
        }
        if (from_date) {
          searchParams.append('from_date', from_date);
        }
        if (to_date) {
          searchParams.append('to_date', to_date);
        }

        if (full_pull !== undefined) {
          searchParams.append('full_pull', full_pull);
        }

        if (downsample_by) {
          searchParams.append('downsample_by', downsample_by);
        }

        if (daily_max) {
          searchParams.append('daily_max', daily_max);
        }

        return {
          url: `sensors_data/component_formulas?${searchParams.toString()}`,
        };
      },
      providesTags: [{ type: 'MobileFleetSensors' }],
      transformResponse: (response: any) => {
        const { measurements } = response;

        return measurements
          .filter((entry: any) => entry.x.length)
          .map((entry: any, index: number) => {
            const color = `hsl(${(index * 360) / measurements.length}, 50%, 55%)`;

            return {
              ...entry,
              id: index,
              type: 'scatter',
              mode: 'lines',
              name: entry.name,
              xaxis: 'x',
              yaxis: `y${index + 1}`,
              line: {
                color,
              },
              bar: {
                color,
              },
              marker: {
                color,
              },
              hoverinfo: 'none',
              visible: true,
            };
          });
      },
    }),
  }),
});

export const { useLazyGetMobileFleetSensorsQuery } = mobileFleetApi;
