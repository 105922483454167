export const colors = {
  black: '#021d3d',
  health: {
    critical: '#CC0000',
    alarm: '#ff891b',
    monitor: '#f1bf42',
    healthy: '#11975D',
    notAvailable: '#01152B',
    na: '#01152B',
    offline: '#CC0000',
    online: '#11975D',
    data_not_received: '#CC0000',
    data_received: '#11975D',
  },
  DMTagBackground: 'rgba(112, 113, 243, 0.12)',
  DMWhite: '#fffefe',
  DMGray: '#f0f1f2',
  DMPrimaryColor: '#7071f3',
  DMRadioColorScheme: {
    500: '#7071f3',
  },
  DMCheckboxColorScheme: {
    500: '#7071f3',
  },
  DMButtonColorScheme: {
    50: '#F0F0FF',
    100: '#D0D1FF',
    200: '#B0B3FF',
    300: '#9094FF',
    400: '#7075FF',
    500: '#7071F3',
    600: '#5657C1',
    700: '#3C3D8F',
    800: '#22225D',
    900: '#08082B',
  },
};
