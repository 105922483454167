import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Text,
  useDisclosure,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { SIDEBAR_MENU_BUTTON_SIZE } from 'src/const';
import { DMNotificationsIcon, DMTrashIcon } from 'src/components/Icons';
import { AlertsList } from 'src/components/AlertsList';
import {
  useClearNotificationsMutation,
  useLazyGetNotificationsDMQuery,
  useLazyGetNotificationsQuery,
  useReadNotificationsMutation,
} from 'src/app/api/notificationApi';
import { NotificationsBadge } from 'src/components/NotificationsBadge';
import { theme } from 'src/theme';
import { NotificationAlert } from 'src/data/notifications';
import { useAuth } from 'src/providers/AuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import { setNotificationFeatureFlag } from 'src/app/slices/applicationSlice';
import { notificationsFeatureFlagSelector } from 'src/app/store';
import { NavLink } from 'react-router-dom';
import { useGetPreferencesQuery } from 'src/app/api/userApi';
import { Skeleton } from './ui/skeleton';

const Notifications = ({ defaultOpen = false }: { defaultOpen?: boolean }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: defaultOpen });
  const notificationsFeatureFlag = useSelector(notificationsFeatureFlagSelector);
  const [isNotMobileResolution] = useMediaQuery(`(min-width: ${theme.breakpoints.xl})`);
  const [fetchNotifications, { data: notifications, isLoading }] = useLazyGetNotificationsQuery({
    pollingInterval: 60000,
  });
  const [enabledNotifications, setEnabledNotifications] = useState([]);
  const [fetchDeliveryMethods, { data: deliveryMethods, isLoading: isLoadingDeliveryMethods }] =
    useLazyGetNotificationsDMQuery();
  const [clearNotifications, { isLoading: isClearing }] = useClearNotificationsMutation();
  const [readNotifications] = useReadNotificationsMutation();
  const { data: userPreferences } = useGetPreferencesQuery({});
  const [isDialogOpened, setDialogOpened] = useState<boolean>(false);

  const onAllNotificationsClear = async () => {
    await clearNotifications([...notificationsIds]);
    await fetchNotifications({});
  };

  useEffect(() => {
    if (userPreferences) {
      dispatch(setNotificationFeatureFlag(userPreferences?.notifications_enabled));
    }
  }, [userPreferences]);

  const notificationsIds = enabledNotifications?.map((item: NotificationAlert) => item.notification_id);
  const unreadNotifications = enabledNotifications?.filter((item: NotificationAlert) => !item.read);

  useEffect(() => {
    fetchDeliveryMethods({ uids: user?.uid });
    if (userPreferences?.notifications_enabled) {
      fetchNotifications({});
    }
  }, [isOpen, userPreferences]);

  const markAsRead = async () => {
    if (isOpen && notificationsIds?.length) {
      await readNotifications([...notificationsIds]);
      await fetchNotifications({});
    }
  };

  useEffect(() => {
    markAsRead();
  }, [isOpen, notifications]);

  useEffect(() => {
    if (deliveryMethods) {
      const enabledEventTypes = deliveryMethods
        .filter((item: any) => {
          const isDashboardEnabled = item?.delivery_methods.filter((deliveryMethod: any) => {
            return deliveryMethod.name.toLowerCase() === 'dashboard' && deliveryMethod.enabled;
          });

          return isDashboardEnabled?.length;
        })
        .map((item: any) => item?.event_type);

      const enabledNotificationsArray = notifications?.filter((item: NotificationAlert) =>
        enabledEventTypes.includes(item.event_type)
      );
      setEnabledNotifications(enabledNotificationsArray);
    }
  }, [isOpen, notifications, deliveryMethods]);

  return isLoading && isOpen ? (
    <Skeleton className="h-screen w-screen p-2" />
  ) : (
    <>
      <VStack spacing="4px" cursor="pointer" position="relative" onClick={onOpen}>
        <IconButton
          aria-label="Alerts"
          size="lg"
          background={isOpen ? '#12FFE2' : 'transparent'}
          color={isOpen ? '#021d3d' : '#fff'}
          borderRadius="16px"
          height="2rem"
          icon={<DMNotificationsIcon boxSize={SIDEBAR_MENU_BUTTON_SIZE} mb={0.5} />}
          variant="unstyled"
        />

        <Text fontSize="10px" lineHeight="14px" fontWeight={600}>
          Alerts
        </Text>

        {unreadNotifications?.length && notificationsFeatureFlag ? (
          <NotificationsBadge notifications={unreadNotifications} />
        ) : null}
      </VStack>

      <Drawer isOpen={isOpen} placement="left" size={isNotMobileResolution ? 'sm' : 'full'} onClose={onClose}>
        <DrawerOverlay ml={isNotMobileResolution ? '4.5rem' : 0} />
        <DrawerContent ml={isNotMobileResolution ? '4.5rem' : 0}>
          <DrawerCloseButton top={3} right={4} />
          <DrawerHeader>
            <Box fontWeight="bold" fontSize="1rem">
              Alerts
            </Box>
          </DrawerHeader>

          <DrawerBody pl={0} pr={0} pt={0}>
            {!isLoading && enabledNotifications ? (
              <VStack pb={8} justifyContent="space-between" h="100%" alignItems="flex-start">
                <AlertsList notifications={enabledNotifications} />

                {notificationsIds?.length ? (
                  <HStack
                    w="full"
                    justifyContent="center"
                    spacing={0}
                    h={24}
                    position="fixed"
                    bottom={0}
                    bgColor="white"
                    style={{ boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)' }}
                  >
                    {isDialogOpened ? (
                      <Box px={0} py={0} textAlign="center">
                        <Box fontSize="0.875rem" pb="0.4375rem">
                          Are you sure you want to delete all notifications?
                        </Box>
                        <HStack justifyContent="center">
                          <Button size="xs" onClick={() => setDialogOpened(false)}>
                            Cancel
                          </Button>
                          <Button
                            size="xs"
                            colorScheme="red"
                            onClick={async () => {
                              setDialogOpened(false);
                              await onAllNotificationsClear();
                            }}
                          >
                            Delete
                          </Button>
                        </HStack>
                      </Box>
                    ) : (
                      <Button
                        variant="outline"
                        colorScheme="red"
                        size="sm"
                        leftIcon={<DMTrashIcon fontSize={20} />}
                        fontWeight={500}
                        textTransform="uppercase"
                        onClick={() => {
                          setDialogOpened(true);
                        }}
                        isLoading={isClearing}
                      >
                        Clear all
                      </Button>
                    )}
                  </HStack>
                ) : null}
              </VStack>
            ) : null}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Notifications;
