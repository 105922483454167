import React, { ReactNode } from 'react';
import { cn } from '@/lib/utils';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';

// export type BaseDialogVariantsType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '80vw' | 'screen';

interface BaseDialogProps {
  children: ReactNode;
  content: ReactNode | string;
  title?: string;
  className?: string;
}

export const BaseDialog = ({ children, content, title, className }: BaseDialogProps) => {
  if (!content) return <>{children}</>;
  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className={cn(`${className}`, 'sm:mt-0 mt-[30px]')}>
        <DialogHeader>
          <DialogTitle className="py-2">{title}</DialogTitle>
          <DialogDescription>{content}</DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
