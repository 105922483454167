import { AssetSensorsHealthStatus } from 'src/types/assets';
import { SENSOR_TYPE } from 'src/const';
import { SourceInfo } from 'src/types/diagnostics';

export const getNotVibrationSensors = (sensor: AssetSensorsHealthStatus) =>
  sensor.sensor_type.toLowerCase() !== SENSOR_TYPE.Vibration &&
  sensor.sensor_type.toLowerCase() !== SENSOR_TYPE.Vibrations;

export const getCameraSource = (sources: Array<SourceInfo>) =>
  sources?.find((source: SourceInfo) => source.source_type?.toLowerCase() === SENSOR_TYPE.Camera);

export const getResourceId = (source: SourceInfo | undefined) => {
  return source ? (source.evidence_resource_id === null ? undefined : source.evidence_resource_id) : undefined;
};
