import { PositionEnum } from '@/types/_common';
import { HStack, VStack } from '@chakra-ui/react';

export const FLEX_CONTAINER = { horizontile: HStack, vertical: VStack };
export const POSITION_ABSOLUTE = {
  [PositionEnum.TOP_RIGHT]: { position: 'absolute', top: '0', right: '0', padding: '5' },
  [PositionEnum.TOP_LEFT]: { position: 'absolute', top: '0', left: '0', padding: '5' },
  [PositionEnum.TOP_CENTER]: { position: 'absolute', top: '0', left: '50%', padding: '5' },
  [PositionEnum.CENTER]: { position: 'absolute', bottom: '50%', left: '50%', padding: '5' },
  [PositionEnum.BOTTOM_RIGHT]: { position: 'absolute', bottom: '0', right: '0', padding: '5' },
  [PositionEnum.BOTTOM_LEFT]: { position: 'absolute', bottom: '0', left: '0', padding: '5' },
  [PositionEnum.BOTTOM_CENTER]: { position: 'absolute', bottom: '0', left: '50%', padding: '5' },
};
