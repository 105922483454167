import React, { useCallback } from 'react';
import { SitesData } from '@/NewUI/hooks/SitesPage/useSitesPageTables';
import { ColumnDef } from '@tanstack/react-table';
import { Skeleton } from '@/components/ui/skeleton';
import { BaseTable } from '@/components/UI-kit';
import { useQueryNavigation } from '@/NewUI/hooks/_common/useNavigateWithQuery';
import { ASSETS_TYPE, FILTER_PARAMS } from '@/types/_common/navigationTabs.enums';
import { ErrorBoundary } from 'react-error-boundary';
import Fallback from '@/components/Fallback';
import { Site } from '@/types/sites';

interface SitesTableProps {
  isLoading: boolean;
  data: any[];
  columns: ColumnDef<SitesData>[];
}

export const SitesTable = ({ isLoading, data, columns }: SitesTableProps) => {
  const { navigateWithQuery, currentParams } = useQueryNavigation();
  const handleNavigate = useCallback(
    (site: Site) => {
      let replaceParams = {};
      if (site?.fixed_assets === 0)
        replaceParams = {
          [FILTER_PARAMS.ASSETS_TYPE]: ASSETS_TYPE.MOBILE,
        };
      navigateWithQuery(`/sites/${site.site_id}/machines`, {
        excludeParams: ['search'],
        replaceParams,
      });
    },
    [data]
  );

  return isLoading ? (
    <Skeleton className="w-100 h-[40dvh] xl:h-[80vh] rounded-xl" />
  ) : (
    <ErrorBoundary FallbackComponent={Fallback}>
      <BaseTable
        variant="spaced"
        // enableSearch={true}
        enableSorting={false}
        mobileVariant="carousel"
        classNames={{
          table: {
            td: 'text-center  ',
            th: '[&>*]:w-full [&>*]:justify-center [&>div]:first-of-type:justify-start',
            tr: 'hover:ring-2 hover:ring-primary-500 hover:rounded-none ring-inset',
          },
        }}
        data={data || []}
        columns={columns}
        rowClickHandler={handleNavigate}
      />
    </ErrorBoundary>
  );
};
