import { isNotMonitored } from './../../../../pages/machines/utils';
import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppFilters } from '../../_common';
import { ASSETS_TYPE } from '@/types/_common/navigationTabs.enums';

const HEALTH_STATUS_SCORE = {
  critical: 5,
  alarm: 4,
  monitor: 3,
  healthy: 2,
  na: 1,
};

const sortAssetsByStatusCode = (assets: any[]) => {
  if (!assets || assets.length === 0) return [];
  return assets.sort((a, b) => {
    // Sort by health_status using the HEALTH_STATUS_SCORE
    if (a.health_status && b.health_status) {
      const tmpA = a.not_monitored ? 'na' : a.health_status;
      const tmpB = b.not_monitored ? 'na' : b.health_status;
      const statusDiff =
        HEALTH_STATUS_SCORE[tmpB.toLowerCase() as 'critical'] - HEALTH_STATUS_SCORE[tmpA.toLowerCase() as 'critical'];
      if (statusDiff !== 0) return statusDiff;
    }
    // If health_status is the same (=0), sort alphabetically
    return a.asset_ui_name.localeCompare(b.asset_ui_name);
    //@todo - add handler for na
  });
};

export function useAssetsData(assets: any[] | undefined) {
  const [searchParams] = useSearchParams();
  const { assetsType } = useAppFilters();

  const { required, notRequired } = useMemo(() => {
    if (!assets) return { requiredAttentionStatusesAssets: [], notRequiredAttentionStatusesAssets: [] };

    const searchValue = searchParams.get('search');
    const filteredAssets = searchValue
      ? assets?.filter((asset) => {
          return assetsType === ASSETS_TYPE.MOBILE && searchValue?.length > 0
            ? asset.asset_ui_name.toLowerCase().includes(searchValue.toLowerCase()) ||
                asset.asset_name.toLowerCase().includes(searchValue.toLowerCase())
            : asset.asset_ui_name.toLowerCase().includes(searchValue.toLowerCase());
        })
      : assets;

    const requiredAttentionStatuses = ['critical', 'alarm'];

    const requiredAttentionStatusesAssets = filteredAssets?.filter((el) =>
      requiredAttentionStatuses.includes(el.health_status)
    );

    const notRequiredAttentionStatusesAssets = filteredAssets?.filter(
      (el) => !requiredAttentionStatuses.includes(el.health_status)
    );

    const required = {
      fixed: sortAssetsByStatusCode(requiredAttentionStatusesAssets.filter((el) => el.asset_category !== 'mobile')),
      mobile: sortAssetsByStatusCode(requiredAttentionStatusesAssets.filter((el) => el.asset_category === 'mobile')),
    };

    const notRequired = {
      fixed: sortAssetsByStatusCode(notRequiredAttentionStatusesAssets.filter((el) => el.asset_category !== 'mobile')),
      mobile: sortAssetsByStatusCode(notRequiredAttentionStatusesAssets.filter((el) => el.asset_category === 'mobile')),
    };

    return {
      required,
      notRequired,
    };
  }, [assets, searchParams, assetsType]);

  return {
    required,
    notRequired,
  };
}
