import React from 'react';
import { Box, Fade, HStack, useBoolean } from '@chakra-ui/react';
import { DMComponentIcon } from 'src/components/Icons';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { ComponentsSidebar } from 'src/pages/machines/components/components-sidebar/ComponentsSidebar';
import { ComponentSidebarItem } from 'src/pages/machines/components/components-sidebar/ComponentSidebarItem';
import { ShowAllComponentSidebarItem } from './components-sidebar/ShowAllComponentSidebarItem';

export const ComponentsMobileMenu = (props: {
  selectedComponent?: any;
  onAllComponentsClick?: () => void;
  components: any;
  onComponentListItemClick: (selectedComponent: any) => void;
  activeTabIndex: number;
}) => {
  const [flag, setFlag] = useBoolean();

  return (
    <Box position="fixed" w="full" top="3.5rem" left={0} zIndex={4}>
      <HStack
        h={12}
        width="full"
        bgColor="#7071f3"
        px={4}
        justifyContent="space-between"
        color="white"
        fontWeight={500}
        onClick={setFlag.toggle}
        cursor="pointer"
      >
        <HStack>
          <DMComponentIcon fontSize="1.25rem" />
          <Box>Machine components</Box>
        </HStack>

        <HStack>
          <Box>{props.components?.length}</Box>
          {flag ? <ChevronUpIcon fontSize="1.5rem" /> : <ChevronDownIcon fontSize="1.5rem" />}
        </HStack>
      </HStack>

      {flag ? (
        <Fade in={flag} transition={{ enter: { delay: 0 }, exit: { delay: 0 } }}>
          <Box bgColor="white" height="100vh" pt={2}>
            <Box p={2}>
              <ShowAllComponentSidebarItem
                onClick={() => {
                  props.onAllComponentsClick!();
                  setFlag.off();
                }}
                component={props.components}
                selectedComponent={props.selectedComponent}
              />
            </Box>

            <ComponentsSidebar
              activeTabIndex={props.activeTabIndex}
              components={props.components}
              element={(component: any, index: number) => (
                <ComponentSidebarItem
                  key={index}
                  onClick={() => {
                    props.onComponentListItemClick(component);
                    setFlag.off();
                  }}
                  component={component}
                />
              )}
            />
          </Box>
        </Fade>
      ) : null}
    </Box>
  );
};
