import { Avatar, HStack, Tooltip, useMediaQuery, useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { NavLink } from 'react-router-dom';
import { useAuth } from 'src/providers/AuthProvider';
import { useSelector } from 'react-redux';
import { selectCachedUserDataById } from 'src/app/queries';
import { useLazyGetUsersQuery } from 'src/app/api/userApi';
import { START_PAGE_TOAST_ID, TOAST_STATUS } from 'src/const';
import { useTranslation } from 'react-i18next';
import { theme } from 'src/theme';

export const UserMenu = () => {
  const { user } = useAuth();
  const toast = useToast();
  const { t } = useTranslation();
  const userData = useSelector((state) => selectCachedUserDataById(state, user!.uid));
  const fullName = userData ? `${userData?.first_name} ${userData?.last_name}` : '';
  const [fetchUsers] = useLazyGetUsersQuery();
  const [isNotMobileResolution] = useMediaQuery(`(min-width: ${theme.breakpoints.xl})`);

  const onAvatarClick = (e: any) => {
    if (!isNotMobileResolution) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (user) {
      fetchUsers({ uids: user.uid })
        .unwrap()
        .catch((error) => {
          if (!toast.isActive(START_PAGE_TOAST_ID)) {
            toast({
              id: START_PAGE_TOAST_ID,
              status: TOAST_STATUS.Error,
              title: t('general.errors.title'),
              description: t('general.errors.communication'),
            });
          }
          Sentry.captureException(
            error?.data?.message || error?.error || error?.message || error?.originalError || error
          );
        });
    }
  }, [user]);

  return (
    <NavLink to="/settings" onClick={onAvatarClick}>
      <HStack>
        <Tooltip label={fullName} borderRadius="md" placement="right">
          <Avatar
            name={fullName}
            src={(userData && `data:image/png;base64,${userData?.photo}`) || undefined}
            size="md"
            fontWeight={600}
            outlineOffset="2px"
            outline="2px solid #6667DD"
          />
        </Tooltip>
      </HStack>
    </NavLink>
  );
};
