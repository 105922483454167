import React, { useEffect, useState } from 'react';
import { Accordion, AccordionItem, AccordionPanel, Box, Card, CardBody, HStack, VStack } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  mobileFleetSensorDataSelectedSchemeSelector,
  setSelectedScheme,
} from 'src/features/mobile-fleet-sensor-data/store/mobileFleetSensorData.slice';
import { transformData } from 'src/features/mobile-fleet-sensor-data/utils';
import { SchemesMenuSubItem } from 'src/features/mobile-fleet-sensor-data/components/schemes-menu/components/SchemesMenuSubItem';
import { SchemesMenuHeader } from 'src/features/mobile-fleet-sensor-data/components/schemes-menu/components/SchemesMenuHeader';
import { SchemesMenuItemHead } from 'src/features/mobile-fleet-sensor-data/components/schemes-menu/components/SchemesMenuItemHead';
import { useSearchParams } from 'react-router-dom';

interface SchemesMenuProps {
  onBackButtonClick?: () => void;
  selectedComponent: any;
}

export const SchemesMenu: React.FC<SchemesMenuProps> = ({ onBackButtonClick, selectedComponent }) => {
  const dispatch = useDispatch();
  const selectedScheme = useSelector(mobileFleetSensorDataSelectedSchemeSelector);
  const [data, setData] = useState<any>(transformData(selectedComponent, selectedScheme));
  const [searchParams] = useSearchParams();
  const formulaIdParam = searchParams.get('formulaId');

  const onAllComponentsSensorsClick = (selectedScheme: any) => {
    const updatedData = data.map((item: any) => ({
      ...item,
      schemes: item.schemes.map((scheme: any) => ({
        ...scheme,
        isSelected: false,
      })),
    }));

    dispatch(setSelectedScheme(undefined));
    setData(updatedData);
  };

  const onSchemesMenuSubItemClick = (selectedScheme: any) => {
    const updatedData = data.map((item: any) => ({
      ...item,
      schemes: item.schemes.map((scheme: any) => ({
        ...scheme,
        isSelected: scheme.id === selectedScheme.id,
      })),
    }));

    dispatch(
      setSelectedScheme({
        id: selectedScheme.id,
        label: selectedScheme.label,
      })
    );
    setData(updatedData);
  };

  useEffect(() => {
    const subcomponentsData = transformData(selectedComponent, selectedScheme);

    if (formulaIdParam) {
      const schemeWithFormulaId = subcomponentsData.flatMap((subcomponent: any) =>
        subcomponent?.schemes.filter((scheme: any) => scheme.id === formulaIdParam)
      );

      if (schemeWithFormulaId.length) {
        dispatch(
          setSelectedScheme({
            id: schemeWithFormulaId[0].id,
            label: schemeWithFormulaId[0].label,
          })
        );
      }

      return;
    }

    if (subcomponentsData?.length) {
      const mostSevereScheme = subcomponentsData[0].schemes[0];

      onSchemesMenuSubItemClick(mostSevereScheme);
      dispatch(
        setSelectedScheme({
          id: mostSevereScheme.id,
          label: mostSevereScheme.label,
        })
      );
    } else {
      dispatch(setSelectedScheme(undefined));
    }
  }, [selectedComponent]);

  return (
    <VStack
      alignItems="flex-start"
      bgColor="#E6E8EC"
      px={2}
      pb={0}
      borderBottomRadius="lg"
      w="100%"
      height="calc(100vh - 215px)"
      gap={0}
    >
      <SchemesMenuHeader onBackButtonClick={onBackButtonClick} selectedComponent={selectedComponent} />

      <Card w="full" boxShadow={0} h="full" borderTopRadius="0">
        <CardBody py={2} px={0} position="relative">
          <HStack
            h="3rem"
            p={4}
            fontSize="0.875rem"
            justifyContent="space-between"
            cursor="pointer"
            onClick={onAllComponentsSensorsClick}
          >
            <Box fontWeight={600}>All Component’s Sensors</Box>
            <Box>{!selectedScheme ? <CheckIcon fontSize="0.75rem" /> : null}</Box>
          </HStack>

          <Accordion defaultIndex={[0, 1, 2]} allowMultiple>
            {data.map((item: any, index: number) => {
              return (
                <AccordionItem border={0} key={index}>
                  <SchemesMenuItemHead
                    statusColor={item.statusColor}
                    statusLabel={item.statusLabel}
                    label={item.label}
                  />

                  <AccordionPanel p={0}>
                    <VStack w="full" alignItems="flex-start" spacing="0">
                      {item.schemes.map((item: any, index: number) => (
                        <SchemesMenuSubItem
                          key={index}
                          id={item.id}
                          status={item.status}
                          label={item.label}
                          isSelected={item.isSelected}
                          onSchemesMenuSubItemClick={onSchemesMenuSubItemClick}
                        />
                      ))}
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </CardBody>
      </Card>
    </VStack>
  );
};
