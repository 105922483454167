import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

export const SchemesMenuSubItem = (props: {
  id: string;
  status: string;
  label: string;
  isSelected: boolean;
  onSchemesMenuSubItemClick: (selectedScheme: any) => void;
}) => {
  const { id, status, label, isSelected, onSchemesMenuSubItemClick } = props;
  const onClick = () => onSchemesMenuSubItemClick({ id, label });

  return (
    <HStack h={12} px={4} w="full" cursor="pointer" onClick={onClick} spacing={2}>
      <HStack w="full">
        <Box minW={2} w={2} h={2} borderRadius="full" bgColor={`health.${status}`} />
        <Box
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '0.875rem',
            textTransform: 'capitalize',
            maxWidth: '13.75rem',
            fontWeight: isSelected ? 600 : undefined,
          }}
        >
          {label}
        </Box>
      </HStack>

      {isSelected ? <CheckIcon fontSize="0.75rem" color="#01152B" /> : null}
    </HStack>
  );
};
