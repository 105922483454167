import React from 'react';
import { Box } from '@chakra-ui/react';
import ReactECharts from 'echarts-for-react';
import { colors } from 'src/theme/foundations/colors';

export const MobileFleetFusionTrend: React.FC<{ data: any }> = ({ data }) => {
  const scatterData = data.diagnostics.x.map((date: any, index: any) => [date, data.diagnostics.y[index]]);

  type HealthStatus =
    | 'critical'
    | 'alarm'
    | 'monitor'
    | 'healthy'
    | 'notAvailable'
    | 'na'
    | 'offline'
    | 'online'
    | 'data_not_received'
    | 'data_received';

  const options = {
    animation: false,
    grid: { top: 8, right: 16, bottom: 8, left: 8 },
    xAxis: {
      type: 'time',
      nameLocation: 'middle',
      nameTextStyle: {
        fontSize: 14,
        padding: [10, 0, 0, 0],
      },
    },
    yAxis: {
      axisTick: false,
      axisLine: false,
      type: 'category',
    },
    series: [
      {
        type: 'scatter',
        data: scatterData,
        smooth: true,
        symbolSize: 12,
        zlevel: 2,
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 2,
          color: (params: any) => {
            const status: HealthStatus = params.data[1];
            return colors.health[status];
          },
        },
      },
      {
        type: 'line',
        smooth: false,
        data: scatterData,
        symbol: 'circle',
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 2,
        },
        symbolSize: 12,
        lineStyle: {
          color: 'rgba(177, 185, 195, 0.8)',
          width: 1,
        },
      },
    ],
  };

  return (
    <Box p={4} pl={12} pb={0} fontSize="0.9rem" fontWeight={700}>
      <Box textAlign="left" pl={0.5}>
        AI Fusion Trend
      </Box>
      <Box borderBottom="1px solid #B1B9C3">
        <ReactECharts option={options} style={{ height: 80 }} />
      </Box>
    </Box>
  );
};
