import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
import NoResultsFoundIcon from 'src/assets/images/no-results-found.svg';

export const NoResultsFound = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    position="absolute"
    top={0}
    bottom={0}
    left={0}
    right={0}
    margin="auto"
    color="#01152B"
    fontWeight={600}
  >
    <Image src={NoResultsFoundIcon} marginBottom="15px" />
    <Text>No matches were found.</Text>
    <Text>Please refine your search and try again.</Text>
  </Box>
);
