import React, { ReactNode, useState } from 'react';
import { BaseCard } from './BaseCard';
import { cn } from '@/lib/utils';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

interface GridCardsProps<T> {
  title?: string;
  elements: {
    element: ReactNode;
    title: string;
    classNames: { card?: string; content?: string };
    order: number;
  }[];
  classNames?: { card?: string; content?: string };
  enableDrag?: boolean;
}

export function GridCards<T>({
  title,
  elements: initialElements,
  classNames,
  enableDrag,
  ...props
}: GridCardsProps<T>) {
  const [elements, setElements] = useState(initialElements);
  const [isDragEnabled, setIsDragEnabled] = useState(enableDrag ?? true); // use enableDrag to determine initial drag state

  const onDragEnd = (result: DropResult) => {
    if (!isDragEnabled || !result.destination) {
      return;
    }

    const items = Array.from(elements);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setElements(items);
  };

  return (
    <BaseCard
      variant="wrapper"
      classNames={{
        content: cn('bg-gray-200 p-0', classNames?.content),
        card: cn('h-full', classNames?.card),
      }}
      title={title}
    >
      {isDragEnabled ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="grid-cards">
            {(provided, snapshot) => (
              <div
                className={cn('grid grid-cols-12 gap-2', snapshot.isDraggingOver ? 'bg-blue-100' : '')}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {elements.map((el, index) => (
                  <Draggable key={index} draggableId={`card-${index}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={cn(
                          el.classNames.card,
                          snapshot.isDragging ? 'z-10 shadow-lg' : '',
                          'transition-all duration-200 ease-in-out'
                        )}
                      >
                        <BaseCard
                          classNames={{
                            card: cn(
                              'rounded-lg cursor-move',
                              el.classNames.card,
                              snapshot.isDragging ? 'opacity-75 scale-105' : ''
                            ),
                            content: cn(el.classNames.content),
                          }}
                        >
                          {el.element}
                        </BaseCard>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="grid grid-cols-12 gap-2">
          {elements.map((el, index) => (
            <BaseCard
              classNames={{
                card: cn('rounded-lg', 'cols-span-12', el.classNames?.card),
                content: cn(el.classNames?.content),
              }}
              key={index}
            >
              {el.element}
            </BaseCard>
          ))}
        </div>
      )}
    </BaseCard>
  );
}
