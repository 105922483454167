import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { Asset } from 'src/types/assets';
import { AssetSensorsHealthStatus } from 'src/data/sensors';
import { DiagnosticsRequestsQueryParams } from 'src/types/diagnostics';
import { baseQueryWithStatusCheck } from 'src/app/baseQueryWithStatusCheck';

const baseQuery = retry(baseQueryWithStatusCheck, { maxRetries: 2 });

export const diagnosticApi = createApi({
  reducerPath: 'diagnosticApi',
  baseQuery,
  tagTypes: ['Sites', 'Assets', 'OrganizationSummary', 'Diagnostics', 'Sensors', 'Feedback', 'Resource'],
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getSites: builder.query<Array<any>, any>({
      query: () => {
        return { url: `diagnostic/sites_preview` };
      },
      providesTags: (result = []) => [
        ...result.map(({ site_id }) => ({ type: 'Sites', site_id } as const)),
        { type: 'Sites' as const, id: 'LIST' },
      ],
      // transformResponse: (response: any) => {
      //   return response.map((item: any) => {
      //     if (item?.all) {
      //       return item;
      //     } else {
      //       if (item.site_id === 'york') {
      //         return {
      //           site_id: item.site_id,
      //           site_name: item.site_name,
      //           all: {
      //             latest_diagnostic_created_at: item.latest_diagnostic_created_at,
      //             assets_health_state: {
      //               ...item.assets_health_state,
      //               critical: item.assets_health_state.critical + 1,
      //             },
      //             total:
      //               Number(Object.values(item.assets_health_state).reduce((sum: any, value: any) => sum + value, 0)) +
      //               1,
      //           },
      //           fixed: {
      //             latest_diagnostic_created_at: item.latest_diagnostic_created_at,
      //             assets_health_state: item.assets_health_state,
      //             total: Object.values(item.assets_health_state).reduce((sum: any, value: any) => sum + value, 0),
      //           },
      //           mobile: {
      //             latest_diagnostic_created_at: item.latest_diagnostic_created_at,
      //             assets_health_state: {
      //               critical: 1,
      //               alarm: 0,
      //               healthy: 0,
      //               monitor: 0,
      //               na: 0,
      //             },
      //             total: 1,
      //           },
      //         };
      //       } else {
      //         return {
      //           site_id: item.site_id,
      //           site_name: item.site_name,
      //           all: {
      //             latest_diagnostic_created_at: item.latest_diagnostic_created_at,
      //             assets_health_state: item.assets_health_state,
      //             total: Object.values(item.assets_health_state).reduce((sum: any, value: any) => sum + value, 0),
      //           },
      //           fixed: {
      //             latest_diagnostic_created_at: item.latest_diagnostic_created_at,
      //             assets_health_state: item.assets_health_state,
      //             total: Object.values(item.assets_health_state).reduce((sum: any, value: any) => sum + value, 0),
      //           },
      //         };
      //       }
      //     }
      //   });
      // },
    }),

    getAssets: builder.query<Array<Asset>, any>({
      query: ({ site_id }) => ({
        url: `diagnostic/assets_preview?site_id=${site_id}`,
      }),
      providesTags: (result = []) => [
        ...result.map(({ asset_id }) => ({ type: 'Assets', asset_id } as const)),
        { type: 'Assets' as const, id: 'LIST' },
      ],
      transformResponse: (response: any, meta: any) => {
        const updatedResponse = response.map((asset: any) => {
          if (asset.asset_category === 'mobile') {
            return {
              ...asset,
              show_diagnostics_day: 21,
            };
          } else {
            return {
              ...asset,
              show_diagnostics_day: 183,
            };
          }
        });

        return updatedResponse;
      },
    }),

    getResource: builder.query<any, any>({
      query: ({ resource_id }) => ({
        url: `diagnostic/resource?resource_id=${resource_id}`,
      }),
      providesTags: () => [{ type: 'Resource' }],
    }),

    getSensors: builder.query<AssetSensorsHealthStatus, void>({
      query: () => ({ url: 'sensors_health_status' }),
      providesTags: () => [{ type: 'Sensors' }],
    }),

    getDiagnostics: builder.query<any, DiagnosticsRequestsQueryParams>({
      query: ({ site_id, assets_ids, from_date, to_date, asset_category }) => {
        const searchParams = new URLSearchParams();

        if (site_id) {
          searchParams.append('site_id', site_id);
        }

        if (assets_ids) {
          if (asset_category === 'mobile') {
            searchParams.append('asset_id', assets_ids);
          } else {
            searchParams.append('assets_ids', assets_ids);
          }
        }

        if (from_date) {
          searchParams.append('from_date', from_date);
        }

        if (to_date) {
          searchParams.append('to_date', to_date);
        }

        if (asset_category === 'mobile') {
          return {
            url: `diagnostic/mf_diagnostics?${searchParams.toString()}`,
          };
        } else {
          return {
            url: `diagnostic/diagnostics?${searchParams.toString()}`,
          };
        }
      },
      transformResponse: (response: any, meta: any) => {
        const transformedResponse = Object.entries(response).sort((a, b) => {
          const dateA = Date.parse(a[0]);
          const dateB = Date.parse(b[0]);

          return dateA - dateB;
        });

        // transformedResponse.forEach((entry: any) => {
        //   entry[1].assets[0].components.forEach((component: any) => {
        //     component.unit_ui_name = !component.unit_ui_name ? 'General Component' : !component.unit_ui_name;
        //   });
        // });

        return transformedResponse;
      },
      providesTags: [{ type: 'Diagnostics' }],
    }),

    getDiagnosticsByFormula: builder.query<any, any>({
      query: ({ site_id, asset_id, formula_id, from_date, to_date }) => {
        const searchParams = new URLSearchParams();

        if (site_id) {
          searchParams.append('site_id', site_id);
        }

        if (asset_id) {
          searchParams.append('asset_id', asset_id);
        }

        if (formula_id) {
          searchParams.append('formula_id', formula_id);
        }

        if (from_date) {
          searchParams.append('from_date', from_date);
        }

        if (to_date) {
          searchParams.append('to_date', to_date);
        }

        return {
          url: `diagnostic/mf_diagnostics_by_formula?${searchParams.toString()}`,
        };
      },
      providesTags: [{ type: 'Diagnostics' }],
    }),

    updateFeedback: builder.mutation<any, any>({
      query({ diagnostic_id, feedback_type, feedback, description }) {
        const searchParams = new URLSearchParams();
        if (diagnostic_id) {
          searchParams.append('diagnostic_id', diagnostic_id);
        }
        if (feedback_type) {
          searchParams.append('feedback_type', feedback_type);
        }
        if (feedback) {
          searchParams.append('feedback', feedback);
        }
        if (description) {
          searchParams.append('description', description);
        }

        return {
          url: `diagnostic/update_feedback?${searchParams.toString()}`,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useGetSitesQuery,
  useGetAssetsQuery,
  useLazyGetSitesQuery,
  useLazyGetAssetsQuery,
  useLazyGetDiagnosticsQuery,
  useUpdateFeedbackMutation,
  useLazyGetResourceQuery,
  useLazyGetDiagnosticsByFormulaQuery,
} = diagnosticApi;
