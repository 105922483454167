import React from 'react';
import { Site } from '@/types/sites';

export const useSitesData = (sites: Site[] | undefined, assetsType: 'all' | 'fixed' | 'mobile') => {
  if (!sites) return { tmpSites: [], chartData: { metaData: { total: 0 }, data: [] } };
  const tmpSites = sites.map((el) => el.assets_categories) || [];

  const chartData = {
    metaData: {
      total: tmpSites
        .map((el) => el?.[assetsType]?.total_assets || 0)
        .reduce((partialSum: number, a: number) => partialSum + a, 0),
    },
    data: [
      {
        label: 'critical',
        value: tmpSites
          .map((el) => el[assetsType]?.assets_health_statuses_counts?.critical || 0)
          .reduce((partialSum: number, a: number) => partialSum + a, 0),
        color: 'text-critical',
        fill: 'hsl(var(--bg-critical))',
      },
      {
        label: 'alarm',
        value: tmpSites
          .map((el) => el[assetsType]?.assets_health_statuses_counts?.alarm || 0)
          .reduce((partialSum: number, a: number) => partialSum + a, 0),
        color: 'text-alarm',
        fill: 'hsl(var(--bg-alarm)',
      },
      {
        label: 'monitor',
        value: tmpSites
          .map((el) => el[assetsType]?.assets_health_statuses_counts?.monitor || 0)
          .reduce((partialSum: number, a: number) => partialSum + a, 0),
        color: 'text-monitor',
        fill: 'hsl(var(--bg-monitor))',
      },
      {
        label: 'healthy',
        value: tmpSites
          .map((el) => el[assetsType]?.assets_health_statuses_counts?.healthy || 0)
          .reduce((partialSum: number, a: number) => partialSum + a, 0),
        color: 'text-healthy',
        fill: 'hsl(var(--bg-healthy))',
      },
      {
        label: 'N/A',
        value: tmpSites
          .map((el) => el[assetsType]?.assets_health_statuses_counts?.na || 0)
          .reduce((partialSum: number, a: number) => partialSum + a, 0),
        color: 'text-notMonitored',
        fill: 'hsl(var(--bg-not-monitored))',
      },
    ],
  };

  const indexData = [
    {
      id: 'fixed',
      label: 'fixed assets',
      value: tmpSites
        .map((el) => el?.fixed?.total_assets || 0)
        .reduce((partialSum: number, a: number) => partialSum + a, 0),
    },
    {
      id: 'mobile',
      label: 'mobile fleet assets',
      value: tmpSites
        .map((el) => el.mobile?.total_assets || 0)
        .reduce((partialSum: number, a: number) => partialSum + a, 0),
    },
  ];

  const sitesIndexData = {
    data: chartData.data,
    metaData: assetsType === 'all' ? indexData : indexData.filter((el) => el.id === assetsType),
  };

  const data = sites
    ? sites.map((el: any) => {
        return {
          site_id: el.site_id,
          site_name: el.site_name,
          latitude: el.latitude,
          longitude: el.longitude,
          all: el?.assets_categories?.all,
          fixed: el?.assets_categories?.fixed,
          mobile: el?.assets_categories?.mobile,
          fixed_assets: el?.assets_categories?.fixed?.total_assets || 0,
          mobile_assets: el?.assets_categories?.mobile?.total_assets || 0,
        };
      })
    : null;

  return {
    data,
    chartData,
    sitesIndexData,
  };
};
