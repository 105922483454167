import React, { useEffect, useState } from 'react';
import { Check, ChevronDown } from 'lucide-react';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu';
import { DMFiltersIcon } from '@/components/Icons';
import { BaseButton } from '../Button/BaseButton';
import { cn } from '@/lib/utils';
import { SizeType, VariantType } from '@/types';
import { useUIKitFilter } from '../_hooks/useUIKitFilter';
import { Checkbox } from '@/components/ui/checkbox';

interface MenuItem {
  id: string;
  label: string | React.ReactNode;
  counter?: number;
  className?: string;
  disabled?: boolean;
  hidden?: boolean;
}

interface DropdownMenuCheckboxesProps<T> {
  multiSelect?: boolean;
  showCounters?: boolean;
  menuTrigger?: React.ReactNode;
  selectAllLabel?: string | React.ReactNode;
  checkmarkPlacement?: 'left' | 'right';
  data: { menuLabel?: string; menuItems: MenuItem[] }[];
  filterLable?: string;
  closeOnSelect?: boolean;
  type?: 'dropdown' | 'filter';
  orientation?: 'horizontal' | 'vertical';
  variant?: VariantType;
  size?: SizeType;
  disabled?: boolean;
  showAll?: boolean;
  defaultSelected?: Record<string, string[]>;
  menuItemRender?: (item: MenuItem, isSelected: boolean, counter?: number) => React.ReactNode;
  menuItemCounterRender?: (count: number) => React.ReactNode;
  onFilterChange?: (filters: any) => void;
  menuLabelRender?: (label: string) => React.ReactNode;
  renderSelectedCheckmark?: (isSelected: boolean, semiSelected?: boolean) => React.ReactNode;
}

export const BaseDropdownFilterMenu = <T,>({
  multiSelect = true,
  showCounters = false,
  menuTrigger,
  selectAllLabel = 'show all',
  checkmarkPlacement = 'left',
  closeOnSelect = false,
  filterLable = 'All Items',
  showAll = true,
  data,
  type = 'dropdown',
  variant,
  orientation = 'vertical',
  size = 'xs',
  defaultSelected,
  disabled = false,
  onFilterChange,
  menuItemRender,
  menuLabelRender,
  menuItemCounterRender,
  renderSelectedCheckmark,
}: //{ 'Label': ['item1', 'items2]}
DropdownMenuCheckboxesProps<T>) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const {
    filterData,
    allItemsSelected,
    initialSelectedGroups,
    getFilterLabel,
    getFilterLabelCount,
    toggleAll,
    areAllSelected,
    isAnySelected,
    setSelectedGroups,
    isSelected,
    toggleItem,
  } = useUIKitFilter({
    data,
    defaultSelected,
    multiSelect,
    type,
    onFilterChange,
    filterLable,
  });
  const hideUnselected = renderSelectedCheckmark || multiSelect;
  const renderCheckmark = (isSelected: boolean, isSemiSelection?: boolean, isDisabled?: boolean) =>
    renderSelectedCheckmark ? (
      renderSelectedCheckmark(isSelected)
    ) : multiSelect ? (
      isSemiSelection ? (
        <Checkbox indeterminate={!isSelected} checked={isSemiSelection} />
      ) : (
        <Checkbox checked={isSelected || isDisabled} />
      )
    ) : (
      <Check className="h-4 w-4 text-primary" />
    );
  return (
    <DropdownMenu onOpenChange={setMenuOpen} open={menuOpen} modal={true}>
      <DropdownMenuTrigger
        asChild
        className={cn('px-4 rounded-lg data-[state=open]:bg-primary-600 data-[state=open]:text-white self-center')}
      >
        <Button
          variant={variant ? variant : !allItemsSelected && type === 'filter' ? 'filled' : 'ghost'}
          size={size}
          disabled={disabled}
        >
          {menuTrigger || (
            <>
              {type === 'filter' && <DMFiltersIcon boxSize={6} />}
              {/* {<span className="hidden sm:block ">{getFilterLabel()}</span>} */}
              {<span className="hidden sm:block ">{type !== 'filter' ? getFilterLabel() : 'Filter'}</span>}
              {/* {
                <span className="block sm:hidden text-sm">
                  {type === 'filter' ? getFilterLabelCount() : getFilterLabel()}
                </span>
              } */}
              {type === 'dropdown' && (
                <span className={`${menuOpen && 'rotate-[180deg]'}`}>
                  <ChevronDown />
                </span>
              )}
            </>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={cn(
          'w-fit py-2 max-h-[70vh] xl:max-h-[80vh] overflow-y-auto',
          `${orientation === 'horizontal' && 'flex gap-6'}`
        )}
      >
        {filterData.map((group, groupIndex) => {
          const totalCounter = group?.menuItems?.reduce((sum, item) => sum + (item.counter || 0), 0);

          return (
            <div key={group.menuLabel || groupIndex}>
              {menuLabelRender ? (
                menuLabelRender(group.menuLabel!)
              ) : (
                <DropdownMenuLabel className="text-gray-500">{group.menuLabel}</DropdownMenuLabel>
              )}
              {multiSelect && showAll && (
                <DropdownMenuItem
                  onClick={(e) => {
                    if (!closeOnSelect) {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                    toggleAll(group.menuLabel!, group.menuItems);
                  }}
                  className="flex justify-between items-center w-full font-medium hover:cursor-pointer"
                >
                  {checkmarkPlacement === 'left' && (
                    <div
                      className={`pr-1.5 ${
                        areAllSelected(group.menuLabel!, group.menuItems) || hideUnselected
                          ? 'opacity-100'
                          : 'opacity-0'
                      }`}
                    >
                      {renderCheckmark(
                        areAllSelected(group.menuLabel!, group.menuItems),
                        isAnySelected(group.menuLabel!, group.menuItems)
                      )}
                    </div>
                  )}
                  <div className="flex justify-between items-center w-full capitalize">
                    <span className="text-sm">{selectAllLabel}</span>
                    {showCounters &&
                      (menuItemCounterRender ? menuItemCounterRender(totalCounter) : `( ${totalCounter} )`)}
                  </div>
                  {checkmarkPlacement === 'right' && (
                    <div
                      className={`pl-1.5 ${
                        areAllSelected(group.menuLabel!, group.menuItems) || hideUnselected
                          ? 'opacity-100'
                          : 'opacity-0'
                      }`}
                    >
                      {renderCheckmark(
                        areAllSelected(group.menuLabel!, group.menuItems),
                        isAnySelected(group.menuLabel!, group.menuItems)
                      )}
                    </div>
                  )}
                </DropdownMenuItem>
              )}
              {group.menuItems.map(
                (item: MenuItem, index) =>
                  !item.hidden && (
                    <DropdownMenuItem
                      disabled={item.disabled}
                      key={item.id || index}
                      onClick={(e) => {
                        //Prevents default closing behavior
                        if (!closeOnSelect) {
                          e.preventDefault();
                          e.stopPropagation();
                        }
                        toggleItem(group.menuLabel!, item);
                      }}
                      className="flex justify-between items-center w-full"
                    >
                      {checkmarkPlacement === 'left' && (
                        <div
                          className={`pr-1.5 ${
                            isSelected(group.menuLabel!, item.id) || item.disabled || hideUnselected
                              ? 'opacity-100'
                              : 'opacity-0'
                          }`}
                        >
                          {renderCheckmark(
                            isSelected(group.menuLabel!, item.id),
                            false,
                            item.disabled && isAnySelected(group.menuLabel!, group.menuItems)
                          )}
                        </div>
                      )}
                      <div className="flex justify-between items-center w-full capitalize font-medium gap-4">
                        {menuItemRender ? menuItemRender(item, isSelected(group.menuLabel!, item.id)) : item.label}
                        {showCounters &&
                          item.counter !== undefined &&
                          (menuItemCounterRender ? menuItemCounterRender(item.counter) : `( ${item.counter} )`)}
                      </div>
                      {checkmarkPlacement === 'right' && (
                        <div
                          className={`pl-1.5 ${
                            isSelected(group.menuLabel!, item.id) || (hideUnselected && !item.disabled)
                              ? 'opacity-100'
                              : 'opacity-0'
                          }`}
                        >
                          {renderCheckmark(
                            isSelected(group.menuLabel!, item.id),
                            false,
                            item.disabled && isAnySelected(group.menuLabel!, group.menuItems)
                          )}
                        </div>
                      )}
                    </DropdownMenuItem>
                  )
              )}
              {groupIndex !== filterData.length - 1 && <DropdownMenuSeparator />}
            </div>
          );
        })}
        {menuOpen && !closeOnSelect && (
          <div className="flex gap-2 min-w-full justify-end px-2 py-1 pt-4">
            <BaseButton
              variant="ghost"
              size={'sm'}
              className="px-2 h-7"
              onClick={() => setSelectedGroups(initialSelectedGroups)}
            >
              Reset
            </BaseButton>
            <BaseButton
              size={'sm'}
              variant="filled"
              className="px-2 h-7"
              onClick={() => setMenuOpen(false)} // Close the menu on click
            >
              Done
            </BaseButton>
          </div>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
