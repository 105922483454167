import React from 'react';

const cleanPercentage = (percentage: number) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

interface CircleProps {
  color: string;
  pct?: number;
  size: number;
}

const Circle = ({ color, pct = 0, size }: CircleProps) => {
  const r = size / 2.5;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / -100;
  return (
    <circle
      r={r}
      cx={size / 2}
      cy={size / 2}
      fill="transparent"
      stroke={color}
      strokeWidth={size / 5}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      className="duration-700 ease-in-out"
    ></circle>
  );
};

interface ProgressCircleProps {
  circleColor: string;
  fillColor: string;
  percentage: number;
  size: number;
}

export const ProgressCircle = ({ circleColor, fillColor, percentage, size }: ProgressCircleProps) => {
  const pct = cleanPercentage(percentage);
  return (
    <svg width={size} height={size}>
      <g transform={`rotate(-90 ${size / 2 + ' ' + size / 2})`}>
        <Circle color={circleColor} size={size} />
        <Circle color={fillColor} pct={pct} size={size} />
      </g>
    </svg>
  );
};
