import React from 'react';
import { Box, ComponentWithAs, HStack, Icon, IconProps, Tooltip } from '@chakra-ui/react';
import {
  DMConveyorBeltIcon,
  DMFixedAssetsIcon,
  DMGridIcon,
  DMMobileAssetsIcon,
  DMTruckIcon,
} from 'src/components/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { activeAssetsCategory, disabledAssetsCategories } from 'src/app/store';
import { setActiveAssetsCategory } from 'src/app/slices/applicationSlice';
import { ASSETS_CATEGORY } from 'src/const';

interface Mode {
  id: string;
  name: string;
  tooltip: string;
  icon: ComponentWithAs<'svg', IconProps>;
}

const modes: Mode[] = [
  { id: ASSETS_CATEGORY.All, name: 'All Assets', tooltip: 'All Assets', icon: DMGridIcon },
  { id: ASSETS_CATEGORY.Mobile, name: 'Mobile', tooltip: 'Mobile Fleet', icon: DMMobileAssetsIcon },
  { id: ASSETS_CATEGORY.Fixed, name: 'Fixed', tooltip: 'Fixed Assets', icon: DMFixedAssetsIcon },
];

interface SwitcherButtonProps {
  mode: Mode;
  isActive: boolean;
  onClick: () => void;
  isDisabled: boolean;
}

const SwitcherButton: React.FC<SwitcherButtonProps> = ({ mode, isActive, onClick, isDisabled }) => {
  const activeStyles = {
    bg: 'white',
    color: '#01152B',
  };

  const inactiveStyles = {
    bg: 'transparent',
    color: '#01152B',
    _hover: { bg: '#E6E8EC' },
  };

  const disabledStyles = {
    opacity: 0.3,
    cursor: 'default',
  };

  return (
    <HStack
      as="button"
      px={2}
      borderRadius="full"
      spacing={1}
      align="center"
      whiteSpace="nowrap"
      onClick={onClick}
      {...(isActive ? activeStyles : inactiveStyles)}
      {...(isDisabled && disabledStyles)}
    >
      <Box px={1} py={'3px'}>
        {isActive || isDisabled ? (
          <Icon as={mode.icon} fontSize="1.55rem" pb="1.2px" />
        ) : (
          <Tooltip label={mode.tooltip} borderRadius="md">
            <Icon as={mode.icon} fontSize="1.55rem" pb="1.2px" />
          </Tooltip>
        )}
      </Box>
      {isActive && (
        <Box fontSize="0.76rem" fontWeight="500" pb={'0.5px'}>
          {mode.name}
        </Box>
      )}
    </HStack>
  );
};

export const Switcher: React.FC = () => {
  const dispatch = useDispatch();
  const activeAssetsCategoryId = useSelector(activeAssetsCategory);
  const disabledAssetsCategoriesList = useSelector(disabledAssetsCategories);

  const onSwitcherButtonClick = (value: string) => dispatch(setActiveAssetsCategory(value));

  return (
    <HStack justify="space-between" align="center" bg="#E6E8EC" p={1.5} spacing={0.2} borderRadius="full">
      {modes.map((mode: Mode) => {
        const isDisabled = disabledAssetsCategoriesList[mode.id];
        return (
          <SwitcherButton
            key={mode.id}
            mode={mode}
            isActive={activeAssetsCategoryId === mode.id}
            onClick={() => !isDisabled && onSwitcherButtonClick(mode.id)}
            isDisabled={isDisabled}
          />
        );
      })}
    </HStack>
  );
};
