import { BaseTable } from '@/components/UI-kit';
import { useMachineAndFailuresTableData } from '@/NewUI/hooks/AssetsPreviewPage';
import useFetchPotentialSavings from '@/NewUI/hooks/AssetsPreviewPage/BusinessInsights/useFetchPotentialSavings';
import React from 'react';

interface MonthlyImpactTableProps {
  showCost?: boolean;
  currency?: string;
}

export const MonthlyImpactTable = ({ showCost = false, currency = 'USD' }: MonthlyImpactTableProps) => {
  const { failures, isLoading } = useFetchPotentialSavings();
  const { tableStructure } = useMachineAndFailuresTableData(showCost, currency);

  return (
    <BaseTable
      data={failures}
      mobileVariant="card"
      variant="base"
      columns={tableStructure}
      classNames={{
        card: { container: 'bg-white' },
        table: {
          container: 'max-h-[450px] sm:max-h-[30vh] px-2',
          th: '[&>*]:w-full [&>div:first-of-type]:justify-start [&>div:nth-of-type(2)]:justify-start [&>div:nth-of-type(3)]:justify-start  [&>*]:justify-center ',
        },
      }}
    />
  );
};
