import { getAssetById } from './../queries';
import { createApi, retry } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithStatusCheck } from 'src/app/baseQueryWithStatusCheck';
const baseQuery = retry(baseQueryWithStatusCheck, { maxRetries: 2 });

const getSearchParams = ({
  site_id,
  asset_id,
  asset_name,
  component_id,
  component_name,
  from_date,
  to_date,
}: Record<string, string | undefined>) => {
  const searchParams = new URLSearchParams();
  if (site_id) {
    searchParams.append('site_id', site_id);
  }
  if (asset_id) {
    searchParams.append('asset_id', asset_id);
  }
  if (asset_name) {
    searchParams.append('asset_name', asset_name);
  }
  if (component_id) {
    searchParams.append('component_id', component_id);
  }
  if (component_name) {
    searchParams.append('component_name', component_name);
  }
  if (from_date) {
    searchParams.append('from_date', from_date);
  }
  if (to_date) {
    searchParams.append('to_date', to_date);
  }
  return searchParams;
};

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery,
  tagTypes: ['oilReportRecap', 'oilReport'],
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getOilReportRecap: builder.query<any, any>({
      query: ({ site_id, asset_name, component_id, from_date, to_date }) => {
        const searchParams = getSearchParams({ site_id, asset_name, component_id, from_date, to_date });
        return { url: `oil_report/recap?${searchParams.toString()}` };
      },
      providesTags: [{ type: 'oilReportRecap' }],
      // transformResponse: (response: any) => {
      //   return {
      //     'Coord 1': [
      //       {
      //         meta: {
      //           date: '2024-12-26',
      //           health_status: 'monitor',
      //           report_number: '1914750',
      //           oil_type: 'FUCHS UNISYN CLP 320',
      //           report_recommendations:
      //             'Results within acceptable limits. Continue with regular maintenance and monitoring.',
      //         },
      //       },
      //       {
      //         meta: {
      //           date: '2024-12-24',
      //           health_status: 'critical',
      //           report_number: '1914750',
      //           oil_type: 'FUCHS UNISYN CLP 320',
      //           report_recommendations:
      //             'Results within acceptable limits. Continue with regular maintenance and monitoring.',
      //         },
      //       },
      //       {
      //         meta: {
      //           date: '2025-01-01',
      //           health_status: 'critical',
      //           report_number: '1914750',
      //           oil_type: 'FUCHS UNISYN CLP 320',
      //           report_recommendations:
      //             'Results within acceptable limits. Continue with regular maintenance and monitoring.',
      //         },
      //       },
      //     ],
      //     'Coord 2': [
      //       {
      //         meta: {
      //           date: '2025-01-01',
      //           health_status: 'monitor',
      //           report_number: '1914750',
      //           oil_type: 'FUCHS UNISYN CLP 320',
      //           report_recommendations:
      //             'Results within acceptable limits. Continue with regular maintenance and monitoring.',
      //         },
      //       },
      //       {
      //         meta: {
      //           date: '2025-01-14',
      //           health_status: 'critical',
      //           report_number: '1914750',
      //           oil_type: 'FUCHS UNISYN CLP 320',
      //           report_recommendations:
      //             'Results within acceptable limits. Continue with regular maintenance and monitoring.',
      //         },
      //       },
      //     ],
      //     'Coord 3': [
      //       {
      //         meta: {
      //           date: '2025-01-07',
      //           health_status: 'healthy',
      //           report_number: '1914750',
      //           oil_type: 'FUCHS UNISYN CLP 320',
      //           report_recommendations:
      //             'Results within acceptable limits. Continue with regular maintenance and monitoring.',
      //         },
      //       },
      //       {
      //         meta: {
      //           date: '2025-01-08',
      //           health_status: 'healthy',
      //           report_number: '1914750',
      //           oil_type: 'FUCHS UNISYN CLP 320',
      //           report_recommendations:
      //             'Results within acceptable limits. Continue with regular maintenance and monitoring.',
      //         },
      //       },
      //       {
      //         meta: {
      //           date: '2025-01-09',
      //           health_status: 'healthy',
      //           report_number: '1914750',
      //           oil_type: 'FUCHS UNISYN CLP 320',
      //           report_recommendations:
      //             'Results within acceptable limits. Continue with regular maintenance and monitoring.',
      //         },
      //       },
      //       {
      //         meta: {
      //           date: '2025-01-10',
      //           health_status: 'healthy',
      //           report_number: '1914750',
      //           oil_type: 'FUCHS UNISYN CLP 320',
      //           report_recommendations:
      //             'Results within acceptable limits. Continue with regular maintenance and monitoring.',
      //         },
      //       },
      //       {
      //         meta: {
      //           date: '2025-01-15',
      //           health_status: 'healthy',
      //           report_number: '1914750',
      //           oil_type: 'FUCHS UNISYN CLP 320',
      //           report_recommendations:
      //             'Results within acceptable limits. Continue with regular maintenance and monitoring.',
      //         },
      //       },
      //       {
      //         meta: {
      //           date: '2025-01-20',
      //           health_status: 'healthy',
      //           report_number: '1914750',
      //           oil_type: 'FUCHS UNISYN CLP 320',
      //           report_recommendations:
      //             'Results within acceptable limits. Continue with regular maintenance and monitoring.',
      //         },
      //       },
      //       {
      //         meta: {
      //           date: '2025-01-30',
      //           health_status: 'healthy',
      //           report_number: '1914750',
      //           oil_type: 'FUCHS UNISYN CLP 320',
      //           report_recommendations:
      //             'Results within acceptable limits. Continue with regular maintenance and monitoring.',
      //         },
      //       },
      //       {
      //         meta: {
      //           date: '2025-02-07',
      //           health_status: 'healthy',
      //           report_number: '1914750',
      //           oil_type: 'FUCHS UNISYN CLP 320',
      //           report_recommendations:
      //             'Results within acceptable limits. Continue with regular maintenance and monitoring.',
      //         },
      //       },
      //       {
      //         meta: {
      //           date: '2025-02-05',
      //           health_status: 'critical',
      //           report_number: '1914750',
      //           oil_type: 'FUCHS UNISYN CLP 320',
      //           report_recommendations:
      //             'Results within acceptable limits. Continue with regular maintenance and monitoring.',
      //         },
      //       },
      //     ],
      //     'Coord 4': [
      //       {
      //         meta: {
      //           date: '2024-12-21',
      //           health_status: 'healthy',
      //           report_number: '1914750',
      //           oil_type: 'FUCHS UNISYN CLP 320',
      //           report_recommendations:
      //             'Results within acceptable limits. Continue with regular maintenance and monitoring.',
      //         },
      //       },
      //       {
      //         meta: {
      //           date: '2025-01-12',
      //           health_status: 'healthy',
      //           report_number: '1914750',
      //           oil_type: 'FUCHS UNISYN CLP 320',
      //           report_recommendations:
      //             'Results within acceptable limits. Continue with regular maintenance and monitoring.',
      //         },
      //       },
      //       {
      //         meta: {
      //           date: '2025-01-15',
      //           health_status: 'critical',
      //           report_number: '1914750',
      //           oil_type: 'FUCHS UNISYN CLP 320',
      //           report_recommendations:
      //             'Results within acceptable limits. Continue with regular maintenance and monitoring.',
      //         },
      //       },
      //     ],
      //   };
      // },
    }),
    getFullOilReport: builder.query<any, any>({
      query: ({ site_id, asset_name, component_id, from_date, to_date }) => {
        const searchParams = getSearchParams({ site_id, asset_name, component_id, from_date, to_date });
        return { url: `oil_report/full_report?${searchParams.toString()}` };
      },
      providesTags: [{ type: 'oilReport' }],
    }),
  }),
});

export const { useLazyGetFullOilReportQuery, useLazyGetOilReportRecapQuery } = reportsApi;
