import React, { useEffect, useMemo, useRef, useState } from 'react';
import Plot from 'react-plotly.js';
import { Config, Layout, Shape } from 'plotly.js';
import { Box, Collapse, HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import {
  mobileFleetSensorDataSelectedSchemeSelector,
  setSelectedScheme,
} from 'src/features/mobile-fleet-sensor-data/store/mobileFleetSensorData.slice';
import {
  setIsMaintenanceChartVisible,
  setMobileFleetSchemeSensorsCurrentSelection,
  setMobileFleetSchemeSensorsPinSelection,
} from 'src/app/slices/applicationSlice';
import {
  dateRangePickerSelector,
  dispatch,
  fullPullModeActivatedSelector,
  maintenanceChartVisible,
  mobileFleetSchemeSensorsCurrentSelectionSelector,
  mobileFleetSchemeSensorsPinSelectionSelector,
  RootState,
} from 'src/app/store';
import { MultipleSubplotsToolbar } from 'src/features/mobile-fleet-sensor-data/components/MultipleSubplotsModebar';
import { changeTraceType, getInitialLayout, getSelectionLineShape } from 'src/features/mobile-fleet-sensor-data/utils';
import {
  MAINTENANCE_CHART_CONTEXT,
  MaintenanceChart,
} from 'src/features/maintenance-data/maintenance-chart/MaintenanceChart';
import { Legend } from 'src/features/maintenance-data/maintenance-chart/components/Legend';
import { format } from 'date-fns';
import { DIAGNOSTICS_REGULAR_DATE_FORMAT } from 'src/const';
import { mobileFleetApi } from 'src/app/api/mobileFleetApi';
import useSize from '@react-hook/size';
import { trace } from '@sentry/react';

interface MultipleSubplotsProps {
  selectedComponent: any;
  queryParams: any;
}

export const MultipleSubplots: React.FC<MultipleSubplotsProps> = ({ selectedComponent, queryParams }) => {
  const boxRef = useRef(null);
  const [, plotContainerHeight] = useSize(boxRef);
  const [revision, setRevision] = useState<number>(0);
  const [layout, setLayout] = useState<Partial<Layout>>();
  const [timelineLayout, setTimelineLayout] = useState<Partial<Layout>>();
  const dateRangePickerDate = useSelector(dateRangePickerSelector);
  const isFullPullModeActivated = useSelector(fullPullModeActivatedSelector);
  const selectedScheme = useSelector(mobileFleetSensorDataSelectedSchemeSelector);
  const mobileFleetSchemeSensorsCurrentSelection = useSelector(mobileFleetSchemeSensorsCurrentSelectionSelector);
  const mobileFleetSchemeSensorsPinSelection = useSelector(mobileFleetSchemeSensorsPinSelectionSelector);
  const isMaintenanceChartVisible = useSelector(maintenanceChartVisible);
  const mobileFleetSensorsData = useSelector(
    (state: RootState) => mobileFleetApi.endpoints.getMobileFleetSensors.select(queryParams)(state)?.data
  );
  const traces = useMemo(() => {
    return mobileFleetSensorsData
      ? changeTraceType(mobileFleetSensorsData, isFullPullModeActivated.onSchemeSensors)
      : [];
  }, [mobileFleetSensorsData, isFullPullModeActivated.onSchemeSensors]);
  const [xAxisMinMaintenance, setXAxisMinMaintenance] = useState<string>(traces?.length ? traces[0].x[0] : undefined);
  const [xAxisMaxMaintenance, setXAxisMaxMaintenance] = useState<string>(
    traces?.length ? traces[0].x[traces[0].x.length - 1] : undefined
  );
  const numSubplots: number = traces?.filter((item: any) => item.visible).length || 1;

  useEffect(() => {
    dispatch(setMobileFleetSchemeSensorsCurrentSelection(undefined));
    dispatch(setMobileFleetSchemeSensorsPinSelection(undefined));
  }, [selectedScheme]);

  const timelineData = useMemo(() => {
    if (traces.length) {
      return [
        {
          x: traces[0].x,
          y: traces[0].y,
          mode: 'markers',
          marker: { opacity: 0 },
        },
      ];
    } else {
      return [];
    }
  }, [traces]);

  const initialTimelineLayout: Partial<Layout> | any = useMemo(() => {
    return {
      xaxis: { showgrid: false, autorange: true },
      yaxis: { visible: false },
      margin: { t: 0, l: 48, r: 16, b: 36 },
      height: 20,
    };
  }, []);

  const handleClick = (eventData: any) => {
    const seen = new Set();
    const dataJSONString = JSON.stringify(eventData.points, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) return;
        seen.add(value);
      }
      return value;
    });
    const parsedData = JSON.parse(dataJSONString);
    const { button, shiftKey } = eventData.event;

    if (button === 0) {
      dispatch(
        shiftKey
          ? setMobileFleetSchemeSensorsPinSelection(parsedData)
          : setMobileFleetSchemeSensorsCurrentSelection(parsedData)
      );
    } else if (button === 2) {
      dispatch(setMobileFleetSchemeSensorsPinSelection(parsedData));
    }
  };

  useEffect(() => {
    const shapes = [
      mobileFleetSchemeSensorsPinSelection?.length &&
        getSelectionLineShape(mobileFleetSchemeSensorsPinSelection, 'dash'),
      mobileFleetSchemeSensorsCurrentSelection?.length &&
        getSelectionLineShape(mobileFleetSchemeSensorsCurrentSelection, 'solid'),
    ].filter(Boolean) as Partial<Shape>[];

    setLayout((prevLayout) => ({
      ...prevLayout,
      shapes: [...(prevLayout?.shapes?.filter((item: any) => item.shapeCategory === 'threshold') || []), ...shapes],
    }));
  }, [mobileFleetSchemeSensorsCurrentSelection, mobileFleetSchemeSensorsPinSelection]);

  useEffect(() => {
    const xRange = traces?.length ? [traces[0].x[0], traces[0].x[traces[0].x.length - 1]] : [];
    const initialLayout = getInitialLayout(numSubplots, plotContainerHeight, xRange);

    dispatch(setMobileFleetSchemeSensorsCurrentSelection(undefined));
    dispatch(setMobileFleetSchemeSensorsPinSelection(undefined));

    for (let i = 1; i <= numSubplots; i++) {
      const spacing = 0.05;
      const domainHeight = (1 - spacing * (numSubplots - 1)) / numSubplots;
      const domainStart = (i - 1) * (domainHeight + spacing);
      const domainEnd = domainStart + domainHeight;

      initialLayout[`yaxis${i}`] = {
        anchor: 'x',
        domain: [domainStart, domainEnd],
        showgrid: true,
        zeroline: true,
        zerolinecolor: '#B1B9C3',
        zerolinewidth: 1,
      };

      const visibleTraces = traces?.filter((item: any) => item.visible);

      if (traces?.length) {
        initialLayout.annotations.push({
          x: 0.0,
          y: domainEnd,
          text: `${visibleTraces[i - 1].name} [${visibleTraces[i - 1].y_units}]`,
          showarrow: false,
          xref: 'paper',
          yref: 'paper',
          xanchor: 'left',
          yanchor: 'bottom',
          font: { size: 14, weight: 600, color: 'black' },
        });

        if (traces[i - 1].ui_threshold) {
          initialLayout.annotations.push({
            x: 0.87,
            y: domainEnd,
            text: `Threshold: ${visibleTraces[i - 1].ui_threshold}`,
            showarrow: false,
            xref: 'paper',
            yref: 'paper',
            xanchor: 'left',
            yanchor: 'bottom',
            font: { size: 14, weight: 400, color: '#CC0000' },
          });
        }

        if (traces[i - 1].ui_threshold) {
          initialLayout.shapes.push({
            type: 'line',
            xref: 'x',
            yref: `y${i}`,
            x0: traces[0].x[0],
            x1: traces[0].x[traces[0].x.length - 1],
            y0: traces[i - 1].ui_threshold,
            y1: traces[i - 1].ui_threshold,
            line: {
              color: 'red',
              width: 1,
              dash: '#CC0000',
            },
            shapeCategory: 'threshold',
          });
        }
      }
    }

    setRevision(revision + 1);
    setLayout(initialLayout);
    setTimelineLayout(initialTimelineLayout);

    if (traces?.length) {
      setXAxisMinMaintenance(traces[0].x[0]);
      setXAxisMaxMaintenance(traces[0].x[traces[0].x.length - 1]);
    }
  }, [traces, numSubplots, plotContainerHeight, selectedScheme, dateRangePickerDate.to, dateRangePickerDate.from]);

  const onMainPlotRelayout = (eventData: any) => {
    const xAxisRangeMin = eventData['xaxis.range[0]'];
    const xAxisRangeMax = eventData['xaxis.range[1]'];
    const xAxisAutoRange = eventData['xaxis.autorange'];

    if (xAxisRangeMin && xAxisRangeMax) {
      setTimelineLayout({
        ...initialTimelineLayout,
        xaxis: {
          ...initialTimelineLayout?.xaxis,
          autorange: false,
          range: [xAxisRangeMin, xAxisRangeMax],
        },
      });

      setXAxisMinMaintenance(format(new Date(xAxisRangeMin), DIAGNOSTICS_REGULAR_DATE_FORMAT));
      setXAxisMaxMaintenance(format(new Date(xAxisRangeMax), DIAGNOSTICS_REGULAR_DATE_FORMAT));

      return;
    }

    if (xAxisAutoRange) {
      setTimelineLayout({
        ...initialTimelineLayout,
        xaxis: {
          ...initialTimelineLayout?.xaxis,
          autorange: true,
        },
      });

      setXAxisMinMaintenance(traces[0].x[0]);
      setXAxisMaxMaintenance(traces[0].x[traces[0].x.length - 1]);

      return;
    }
  };

  const onTimelinePlotRelayout = (eventData: any) => {
    const xAxisRangeMin = eventData['xaxis.range[0]'];
    const xAxisRangeMax = eventData['xaxis.range[1]'];
    const xAxisAutoRange = eventData['xaxis.autorange'];

    if (xAxisRangeMin && xAxisRangeMax) {
      setLayout({
        ...layout,
        xaxis: {
          ...layout?.xaxis,
          autorange: false,
          range: [xAxisRangeMin, xAxisRangeMax],
        },
      });

      setXAxisMinMaintenance(format(new Date(xAxisRangeMin), DIAGNOSTICS_REGULAR_DATE_FORMAT));
      setXAxisMaxMaintenance(format(new Date(xAxisRangeMax), DIAGNOSTICS_REGULAR_DATE_FORMAT));

      return;
    }

    if (xAxisAutoRange) {
      setLayout({
        ...layout,
        xaxis: {
          ...layout?.xaxis,
          autorange: true,
        },
      });

      setXAxisMinMaintenance(traces[0].x[0]);
      setXAxisMaxMaintenance(traces[0].x[traces[0].x.length - 1]);

      return;
    }
  };

  useEffect(() => {
    if (isFullPullModeActivated.onSensorFusion) {
      dispatch(
        setIsMaintenanceChartVisible({
          ...isMaintenanceChartVisible,
          onSchemeSensors: false,
        })
      );
    }

    setRevision((prevState) => prevState + 1);
  }, [isFullPullModeActivated.onSchemeSensors]);

  const config: Partial<Config> = useMemo(
    () => ({
      displayModeBar: true,
      displaylogo: false,
      modeBarButtonsToRemove: ['lasso2d', 'select2d', 'autoScale2d'],
    }),
    []
  );

  const containerHeight = isMaintenanceChartVisible.onSchemeSensors ? `calc(100vh - 512px)` : `calc(100vh - 450px)`;

  return (
    <Box>
      <Box height={containerHeight} overflowY="auto" mb={1} ref={boxRef}>
        {layout && traces ? (
          <Plot
            className="schemes-multiple-subplots"
            data={structuredClone(traces)
              ?.filter((item: any) => item.visible)
              ?.map((item: any, index: number) => {
                return {
                  ...item,
                  yaxis: `y${index + 1}`,
                };
              })}
            layout={layout}
            useResizeHandler
            style={{ width: '100%', height: 'auto' }}
            revision={revision}
            config={config}
            onClick={handleClick}
            onRelayout={onMainPlotRelayout}
          />
        ) : null}
      </Box>

      <Box w="full">
        {timelineLayout && timelineData ? (
          <Plot
            data={timelineData}
            useResizeHandler
            layout={timelineLayout}
            style={{ width: '100%' }}
            config={{
              displayModeBar: false,
            }}
            onRelayout={onTimelinePlotRelayout}
          />
        ) : null}
      </Box>

      <Collapse in={isMaintenanceChartVisible.onSchemeSensors} animateOpacity>
        <Box pt={2} pb={1} pl={9} pr={0}>
          <MaintenanceChart
            selectedComponent={selectedComponent}
            xAxisMin={xAxisMinMaintenance}
            xAxisMax={xAxisMaxMaintenance}
            context={MAINTENANCE_CHART_CONTEXT.SensorFusion}
            wrapperRef={boxRef}
            hideLegend
          />
        </Box>
      </Collapse>

      <HStack justifyContent="space-between" pl={12}>
        <Box>
          {isMaintenanceChartVisible.onSchemeSensors ? (
            <Legend items={[{ color: '#01152B', label: 'Maintenance Task' }]} />
          ) : null}
        </Box>
        <MultipleSubplotsToolbar plotlyContainerClassName="schemes-multiple-subplots" />
      </HStack>
    </Box>
  );
};
