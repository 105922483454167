import React from 'react';
import {
  Box,
  VStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { DMHeartPulseIcon } from 'src/components/Icons';
import { FormattedText } from 'src/components/FormattedText';
import { analytics } from 'src/analytics';
import { LegendContent } from './components/LegendContent';

export const LegendPopover = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const onIconButtonClick = () => analytics.track('Legend popover opened', {});

  return (
    <Popover placement="right" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <VStack spacing="4px" cursor="pointer" onClick={onIconButtonClick} userSelect="none">
          <IconButton
            aria-label="Info"
            size="lg"
            background={isOpen ? '#12FFE2' : 'transparent'}
            color={isOpen ? '#021d3d' : '#fff'}
            borderRadius="16px"
            height="2rem"
            icon={<DMHeartPulseIcon />}
            variant="unstyled"
          />
          <Text fontSize="10px" lineHeight="14px" fontWeight={600}>
            Status
          </Text>
        </VStack>
      </PopoverTrigger>

      <PopoverContent w="458px" color="#000" bottom={4} left={3} top={16}>
        <PopoverCloseButton top={6} right={6} />
        <PopoverBody p={6}>
          <Box textStyle="subtitleBold" mb={6} textTransform="capitalize">
            <FormattedText label="general.legend.title" />
          </Box>

          <LegendContent />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
