import React, { useEffect, useMemo } from 'react';
import { Skeleton } from '@/components/ui/skeleton';
import { setSelectedView, applyAssetsFilter } from '@/app/slices/assetsPreviewSlice';
import { RootState } from '@/app/store';
import { useDispatch, useSelector } from 'react-redux';
import { ASSETS_TYPE } from '@/types/_common/navigationTabs.enums';
import { AllSectionEmptyState } from './EmptyStates';
import { GridView, ListView } from './AssetPreviewViews';
import { useFetchAssets, useAssetsData, useViewportWidth, useAppFilters } from '@/NewUI/hooks';

export const AssetsOverview = () => {
  const dispatch = useDispatch();
  const { selectedView, filteredItems } = useSelector((state: RootState) => state.assetsPreview);
  const { isMobile } = useViewportWidth();
  const { isLoading, assets } = useFetchAssets();
  const { assetsType } = useAppFilters();

  const { required, notRequired } = useAssetsData(assets);

  const [requireAttention, notRequireAttention] = useMemo(() => {
    const applyFilter = (data: any) =>
      assetsType === ASSETS_TYPE.MOBILE
        ? applyAssetsFilter(data?.mobile || [], filteredItems, {
            isMobile: true,
          })
        : applyAssetsFilter(data?.fixed || [], filteredItems);

    return [applyFilter(required), applyFilter(notRequired)];
  }, [assetsType, filteredItems, required, notRequired, assets]);

  useEffect(() => {
    dispatch(setSelectedView('grid'));
  }, [isMobile]);

  const isEmpty = useMemo(
    () => requireAttention.length === 0 && notRequireAttention.length == 0,
    [isLoading, requireAttention, notRequireAttention]
  );

  return (
    <div className="flex-col gap-4 h-full flex">
      {isLoading ? (
        <>
          <Skeleton className="h-[calc(50vh-90px)]" />
          <Skeleton className="h-[calc(50vh-90px)]" />
        </>
      ) : (
        <>
          {isEmpty ? (
            <AllSectionEmptyState />
          ) : (
            {
              grid: GridView,
              list: ListView,
            }[selectedView]?.({
              requireAttention,
              notRequireAttention,
            })
          )}
        </>
      )}
    </div>
  );
};
