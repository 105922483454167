import React from 'react';
import { Layout } from '../layouts';
import { UserSettings } from '../components/UserSettings - WIP/UserSettings';
import { BaseTabs } from '@/components/UI-kit';
// WIP
const settingsTabs = [
  { id: 'account-settings', title: 'Account Settings', element: <UserSettings /> },
  { id: 'user-managment', title: 'User Managment', element: <div>User Managment</div> },
  { id: 'preferences', title: 'Preferences', element: <div>Prefrences</div> },
  { id: 'application-preferences', title: 'Application Preferences', element: <div>Application Prefs</div> },
];

export const SettingsPage = () => {
  return (
    <Layout>
      <div className="flex flex-col md:px-8 px-2 md:py-4 py-6 ">
        <BaseTabs tabs={settingsTabs} defaultValue={settingsTabs[0].id} />
      </div>
    </Layout>
  );
};
