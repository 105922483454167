import { cn } from '@/lib/utils';
import React, { useEffect, useState } from 'react';
import { AssetsFaultIndicator } from '../AssetsFaultIndicator';
import { useViewportWidth } from '@/NewUI/hooks/_common';
import { ExceedingComponentsPopover } from './ExceedingComponentsPopover';
import { FILTER_PARAMS } from '@/types/_common/navigationTabs.enums';
import { useNavigate } from 'react-router-dom';

interface FaultyComponentsProps {
  faulty_components: any[];
  asset_id: string;
}

export const FaultyComponents = ({ faulty_components, asset_id }: FaultyComponentsProps) => {
  const faulty_componentsTMP = faulty_components;
  const { isMobile, isTablet } = useViewportWidth();
  const navigate = useNavigate();
  const [maxItems, setMaxItem] = useState(isTablet ? (isMobile ? 1 : 2) : 2);
  useEffect(() => {
    setMaxItem(isTablet ? (isMobile ? 1 : 2) : 2);
  }, [isMobile, isTablet]);
  const shownItems = faulty_componentsTMP?.slice(0, maxItems);
  const exceededItems = faulty_componentsTMP?.slice(maxItems);

  return (
    <div className={cn(`flex gap-1`)}>
      {shownItems?.map(
        (el, index) =>
          el.component_ui_name && (
            <AssetsFaultIndicator
              onClick={(e: any) => {
                e.stopPropagation();
                const params = new URLSearchParams();
                params.append(FILTER_PARAMS.SELECTED_COMPONENT, el.component_id);
                navigate(`${asset_id}/components?${params.toString()}`);
              }}
              key={index}
              healthStatus={el.health_status}
              faultType={el.component_ui_name}
            />
          )
      )}
      {exceededItems.length > 0 && <ExceedingComponentsPopover items={exceededItems} />}
    </div>
  );
};
