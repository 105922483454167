import React, { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { ceil } from 'lodash';
import { format } from 'date-fns';
import { SHORT_YEAR_DATE_FORMAT } from '@/const';
import { cn } from '@/lib/utils';
import { Switch } from '@/components/ui/switch';
import { StatusTag } from '@/NewUI/components/_common/StatusTag';

const headerTagStyle = 'text-sm px-2 py-0.5 rounded-full text-white font-medium text-xs whitespace-nowrap';

export interface MachinesAndFailures {
  fault_name: string;
  asset_ui_name: string;
  asset_type: string;
  component_ui_name: string;
  critical_days: number;
  alarm_days: number;
  start_date: string;
  end_date: string;
  last_health_status: string;
  downtime: number;
  currency: string;
  display_cost: boolean;
  savings: number;
}

export const useMachineAndFailuresTableData = (showCost: boolean, currency?: string) => {
  const machines_and_failures_table_structure: ColumnDef<MachinesAndFailures>[] = useMemo(
    () => [
      {
        accessorKey: 'asset_ui_name',
        header: 'Machine',
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'component_ui_name',
        header: 'Component',
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'fault_name',
        header: 'Failure',
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'start_date',
        header: () => <div className="w-full flex justify-center items-center">Affecting Date</div>,
        cell: (info) => {
          return (
            <div className="w-full flex justify-center items-center text-sm">
              {info.cell.getValue()
                ? (format(new Date(info.cell.getValue() as any), SHORT_YEAR_DATE_FORMAT) as '')
                : ''}
            </div>
          );
        },
      },
      {
        accessorKey: 'end_date',
        header: () => <div className="w-full flex justify-center items-center">End Date</div>,
        cell: (info) => {
          return (
            <div className="w-full flex justify-center items-center text-sm">
              {info.cell.getValue()
                ? (format(new Date(info.cell.getValue() as any), SHORT_YEAR_DATE_FORMAT) as '')
                : ''}
            </div>
          );
        },
      },
      {
        accessorKey: 'last_health_status',
        header: () => <div className="w-full flex justify-center items-center">Health Status</div>,
        cell: (info) => {
          return (
            <div className="w-full flex justify-center items-center text-sm">
              <StatusTag status={info.cell.getValue() as 'critical'} />
            </div>
          );
        },
      },
      {
        accessorKey: 'downtime',
        header: () => <div className="w-full flex justify-center items-center">Estimated DownTime</div>,
        cell: (info) => {
          return (
            <div className={cn('w-full flex justify-center items-center text-sm')}>
              {!info.cell.getValue() ? (
                <div className={`${!info.cell.getValue() && 'text-critical px-2 text-sm font-semibold'}`}>
                  {'<<< No Data >>>'}
                </div>
              ) : (
                ((info.cell.getValue() + ' hours') as '')
              )}{' '}
            </div>
          );
        },
      },
      {
        accessorKey: 'savings',
        header: () => <div className="w-full flex justify-center items-center">Potential Saving</div>,
        cell: (info) => {
          const formattedCurrency = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(info.cell.getValue() as 1);
          return <div className="w-full flex justify-center items-center text-sm">{formattedCurrency}</div>;
        },
      },
      // {
      //   accessorKey: 'include-row',
      //   header: () => <div className="w-full flex justify-center items-center">Included</div>,
      //   cell: (info) => {
      //     return (
      //       <div className="flex justify-center items-center">
      //         <Switch />
      //       </div>
      //     );
      //   },
      // },
    ],
    [currency, showCost]
  );

  return {
    tableStructure: showCost
      ? machines_and_failures_table_structure
      : machines_and_failures_table_structure.filter((el: any) => el.accessorKey !== 'savings'),
  };
};
