import { VStack, Button } from '@chakra-ui/react';
import React, { useState } from 'react';
import { DMArrowDownIcon, DMArrowRightIcon } from 'src/components/Icons';

interface ComponentSidebarUnit {
  unitData: any;
  element: (component: any, index: number) => JSX.Element;
  isOpenedByDefault?: boolean;
}

export const ComponentSidebarUnit = ({ unitData, element, isOpenedByDefault }: ComponentSidebarUnit) => {
  const [isOpened, setIsOpened] = useState(!!isOpenedByDefault);

  const toggleOpen = () => setIsOpened((prevVal) => !prevVal);

  return (
    <>
      <Button
        px={4}
        py="30px"
        variant="naked"
        bgColor="#fff"
        borderRadius="lg"
        rightIcon={isOpened ? <DMArrowDownIcon fontSize="1.5rem" /> : <DMArrowRightIcon fontSize="1.5rem" />}
        justifyContent="space-between"
        onClick={toggleOpen}
        zIndex={1}
      >
        {unitData.unit_ui_name}
      </Button>
      {isOpened && (
        <VStack gap="0.5rem" alignItems="stretch" paddingLeft="1.5rem">
          {unitData.components.map(element)}
        </VStack>
      )}
    </>
  );
};
