import { HEALTH_STATUS } from '@/const';
import React from 'react';
import { cn } from '@/lib/utils';
import { Badge } from '@/components/ui/badge';
import { BaseTooltip } from '@/components';
import { FaultReasons } from './FaultReasons';

interface AssetHealthStatusIndicatorProps {
  healthStatus: HEALTH_STATUS;
  isVertical?: boolean;
  showReasons?: boolean;
  counterOnly?: boolean;
  isMonitored?: boolean;
  reasons?: string[];
  size?: 'md' | 'sm';
}

export const AssetHealthStatusIndicator = ({
  healthStatus,
  isVertical = false,
  reasons,
  size,
  counterOnly,
  showReasons,
}: AssetHealthStatusIndicatorProps) => {
  return (
    <div className={cn(`flex`, ` ${isVertical ? 'flex-col justify-center items-start gap-1' : 'items-center gap-2 '}`)}>
      <div className={`text-${healthStatus} text-xs font-semibold capitalize`}>
        {healthStatus === 'na' ? 'N/A' : healthStatus}
      </div>
      <FaultReasons reasons={reasons} counterOnly={counterOnly} showReasons={showReasons} size={size} />
    </div>
  );
};
