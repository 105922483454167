import React, { useState, useMemo, useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { ChevronUp, ChevronDown } from 'lucide-react';
import { Asset } from '@/types/assets';
import { format } from 'date-fns';
import { cn } from '@/lib/utils';

const headersClassname = 'whitespace-nowrap flex flex-col gap-2 bg-white p-6 min-w-[14rem] hover:cursor-pointer ';
const headerClassname = 'h-5 flex items-center';

export const useOilReportTable = (oilReportData: any = [], rawData: any = [], selectedOilReportMetadata: any) => {
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const [selectedItem, setSelectedItem] = useState('');

  useEffect(() => {
    const latestDate = selectedOilReportMetadata?.date || rawData?.[rawData.length - 1]?.meta.date;

    // Prevent unnecessary updates to selectedItem
    setSelectedItem((prev) => (prev !== latestDate ? latestDate : prev));

    // Ensure expandedRows is only set once (if it's empty)
    if (expandedRows.size === 0 && oilReportData.length > 0) {
      setExpandedRows(new Set(oilReportData.map((el: any) => el.id)));
    }
  }, [rawData, selectedOilReportMetadata, oilReportData]);

  const handleHeaderClick = (date: string) => {
    setSelectedItem((prev) => (prev !== date ? date : prev));
  };

  const renderNestedRowCell = (nestedRow: any, column: any, index: number) => {
    const item = nestedRow.reportsData.find((el: any) => el.date === column.accessorKey);
    return (
      <div
        className={`text-center h-12 flex items-center w-full ${
          index === 0 ? 'justify-start min-w-[400px]' : `justify-center text-${item?.health_status}`
        } ${column.isSelected ? 'bg-primary-50' : ''}`}
      >
        {index === 0 ? (
          <div className="flex items-center justify-between w-full pl-4 pr-10 h-full bg-white">
            <div>{nestedRow.name}</div>
            <div className="text-center w-12 flex flex-col relative h-full items-center justify-center">
              <div>{nestedRow?.limit}</div>
              {nestedRow?.limit && (
                <div className="text-gray-500 text-[0.5rem] capitalize absolute bottom-[-0.3px]">limit</div>
              )}
            </div>
          </div>
        ) : (
          item?.value
        )}
      </div>
    );
  };

  const renderTriggerRow = (row: any) => row.original.name;

  const columns: ColumnDef<Asset>[] = useMemo(
    () => [
      {
        accessorKey: '1',
        header: () => (
          <div className={`${headersClassname} rounded-l-xl min-w-[420px]`}>
            <div className={headerClassname}>Report Sample Date</div>
            <div className={headerClassname}>Report Number</div>
            <div className={headerClassname}>Report Status</div>
          </div>
        ),
      },
      {
        id: 'expander',
        header: '',
        cell: ({ row }: any) => (
          <div
            className="flex items-center justify-between hover:cursor-pointer bg-white rounded-l-xl h-12 px-4"
            onClick={() =>
              setExpandedRows((prev) => {
                const newSet = new Set(prev);
                newSet.has(row.original.id) ? newSet.delete(row.original.id) : newSet.add(row.original.id);
                return newSet;
              })
            }
          >
            <div className="flex items-center justify-start w-full h-full gap-2 px-2 ">
              {expandedRows.has(row.original.id) ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
              <span className="pl-4">{row.original.id}</span>
            </div>
          </div>
        ),
      },
      ...rawData.map((el: any, index: number) => {
        const { date, health_status, report_number } = el.meta;
        return {
          accessorKey: date,
          id: date,
          isSelected: selectedItem === date,
          header: () => (
            <div
              className={cn(
                `${headersClassname}`,
                `${selectedItem === date ? 'bg-primary-50' : ''} ${index === rawData.length - 1 ? 'rounded-r-xl' : ''}`
              )}
              onClick={() => handleHeaderClick(date)}
            >
              <div className={cn('font-semibold flex gap-2 items-center', headerClassname)}>
                {date && format(new Date(date), 'dd MMM yy')}
              </div>
              <div className={headerClassname}>{Number(report_number).toLocaleString()}</div>
              <div className={`${headerClassname} capitalize text-sm text-${health_status}`}>{health_status}</div>
            </div>
          ),
          cell: ({ row }: any) => row.origin,
        };
      }),
    ],
    [expandedRows, selectedItem, rawData]
  );

  return {
    columns,
    expandedRows,
    setExpandedRows,
    renderNestedRowCell,
    renderTriggerRow,
    selectedItem,
  };
};
