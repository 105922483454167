import { dateRangePickerSelector } from 'src/app/store';
import { createSlice } from '@reduxjs/toolkit';
import { format, sub } from 'date-fns';
import { DIAGNOSTICS_REGULAR_DATE_FORMAT, SENSORS_DATA_TYPE } from 'src/const';

const DEFAULT_DATE_PICKER_DATES = {
  from: format(sub(new Date(), { months: 2 }), DIAGNOSTICS_REGULAR_DATE_FORMAT),
  to: format(new Date(), DIAGNOSTICS_REGULAR_DATE_FORMAT),
};

const initialState: {
  isFirebaseRequesting: boolean;
  searchValue: string;
  uiVersion?: number;
  notificationsFeatureFlag: boolean;
  isMfaExpired: boolean;
  isMaintenanceChartVisible: Record<string, boolean>;
  isFullPullModeActivated: Record<string, boolean>;
  activeAssetsCategory: string;
  disabledAssetsCategories: Record<string, boolean>;
  mobileFleetSchemeSensorsCurrentSelection: any;
  mobileFleetSchemeSensorsPinSelection: any;
  mobileFleetSensorFusionSelection: {
    current: any;
    pin: any;
  };
  mobileFleetSensorTraces: any;
  dateRangePickerDate: Record<string, string>;
  isSchemesMenuActivated: boolean;
  selectedOilReportMetadata: any;
  activeSensorDataInnerTab: string;
  isOilReportEnabled: boolean;
  updatedFailuresFeedbacks: Record<string, any> | null;
} = {
  isFirebaseRequesting: true,
  searchValue: '',
  uiVersion: 1,
  notificationsFeatureFlag: false,
  isMfaExpired: false,
  isMaintenanceChartVisible: {
    onDiagnostics: true,
    onSensorFusion: true,
    onSchemeSensors: true,
  },
  isFullPullModeActivated: {
    onSensorFusion: false,
    onSchemeSensors: false,
  },
  activeAssetsCategory: 'all',
  disabledAssetsCategories: {
    all: false,
    mobile: false,
    fixed: false,
  },
  mobileFleetSchemeSensorsCurrentSelection: null,
  mobileFleetSchemeSensorsPinSelection: null,
  mobileFleetSensorFusionSelection: {
    current: null,
    pin: null,
  },
  mobileFleetSensorTraces: null,
  dateRangePickerDate: DEFAULT_DATE_PICKER_DATES,
  isSchemesMenuActivated: false,
  selectedOilReportMetadata: null,
  activeSensorDataInnerTab: SENSORS_DATA_TYPE.PI,
  isOilReportEnabled: true,
  updatedFailuresFeedbacks: null,
};

export const appSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    resetAppState: () => initialState,
    setIsFirebaseRequesting: (state, action) => {
      state.isFirebaseRequesting = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setNotificationFeatureFlag: (state, action) => {
      state.notificationsFeatureFlag = action.payload;
    },
    setMfaExpired: (state, action) => {
      state.isMfaExpired = action.payload;
    },
    setIsMaintenanceChartVisible: (state, action) => {
      state.isMaintenanceChartVisible = {
        ...state.isMaintenanceChartVisible,
        ...action.payload,
      };
    },
    setActiveAssetsCategory: (state, action) => {
      state.activeAssetsCategory = action.payload;
    },
    setDisabledAssetsCategories: (state, action) => {
      state.disabledAssetsCategories = {
        ...state.disabledAssetsCategories,
        ...action.payload,
      };
    },
    setIsFullPullModeActivated: (state, action) => {
      state.isFullPullModeActivated = {
        ...state.isFullPullModeActivated,
        ...action.payload,
      };
    },
    setMobileFleetSchemeSensorsCurrentSelection: (state, action) => {
      state.mobileFleetSchemeSensorsCurrentSelection = action.payload;
    },
    setMobileFleetSchemeSensorsPinSelection: (state, action) => {
      state.mobileFleetSchemeSensorsPinSelection = action.payload;
    },
    setMobileFleetSensorFusionSelection: (state, action) => {
      state.mobileFleetSensorFusionSelection = {
        ...state.mobileFleetSensorFusionSelection,
        ...action.payload,
      };
    },
    setMobileFleetSensorTraces: (state, action) => {
      state.mobileFleetSensorTraces = action.payload;
    },
    setDateRangePickerDate: (state, action) => {
      state.dateRangePickerDate = action.payload;
    },
    resetDateRangePicker: (state) => {
      state.dateRangePickerDate = DEFAULT_DATE_PICKER_DATES;
    },
    setUIVersion: (state, action) => {
      state.uiVersion = action.payload;
    },
    setSchemesMenuActivated: (state, action) => {
      state.isSchemesMenuActivated = action.payload;
    },
    setSelectedOilReportMetadata: (state, action) => {
      state.selectedOilReportMetadata = action.payload;
    },
    setActiveSensorDataInnerTab: (state, action) => {
      state.activeSensorDataInnerTab = action.payload;
    },
    setOilReport: (state, action) => {
      state.isOilReportEnabled = action.payload;
    },
    setUpdatedFailuresFeedbacks: (state, action) => {
      const { diagnosticId, feedbackType, description, feedback } = action.payload;
      const prevState = state.updatedFailuresFeedbacks;
      state.updatedFailuresFeedbacks = {
        ...prevState,
        [diagnosticId]: {
          ...prevState?.[diagnosticId],
          [feedbackType]: {
            ...prevState?.[diagnosticId]?.[feedbackType],
            [description]: feedback,
          },
        },
      };
    },
  },
});

export const {
  resetAppState,
  setIsFirebaseRequesting,
  setSearchValue,
  setNotificationFeatureFlag,
  setMfaExpired,
  setIsMaintenanceChartVisible,
  setActiveAssetsCategory,
  setDisabledAssetsCategories,
  setIsFullPullModeActivated,
  setMobileFleetSchemeSensorsCurrentSelection,
  setMobileFleetSchemeSensorsPinSelection,
  setMobileFleetSensorFusionSelection,
  setMobileFleetSensorTraces,
  setDateRangePickerDate,
  setUIVersion,
  setSchemesMenuActivated,
  setSelectedOilReportMetadata,
  setActiveSensorDataInnerTab,
  setOilReport,
  setUpdatedFailuresFeedbacks,
  resetDateRangePicker,
} = appSlice.actions;
