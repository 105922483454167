import { DMNewSensorsIcon, DMChevronRightIcon, DMSensorsWarningIcon } from '@/components/Icons';
import { BaseButton } from '@/components/UI-kit/Button/BaseButton';
import { AssetSensorsHealthStatus } from '@/types/assets';
import React from 'react';
import { SensorModal } from '../../../../_common';
import { useAppFilters } from '@/NewUI/hooks/_common';
import { ASSETS_TYPE } from '@/types/_common/navigationTabs.enums';
import RoundProgressIcon from '@/NewUI/components/_common/RoundProgressIcon';

interface SensorsIndicatorProps {
  sensors: AssetSensorsHealthStatus[];
  assetName: string;
  activeCount?: number;
  totalCount?: number;
}

export const SensorsIndicator = ({ sensors, assetName, activeCount, totalCount }: SensorsIndicatorProps) => {
  const { assetsType } = useAppFilters();
  const isFixed = assetsType === ASSETS_TYPE.FIXED;
  const isMixed = Array.from(new Set(sensors.map((el) => el.health_status))).length > 1;
  return (
    <BaseButton
      id="sensors-btn"
      className="px-1 gap-0"
      leftIcon={
        isFixed ? (
          <div className="text-healthy animate-pulse-fast scale-150 flex items-center">
            {isMixed ? <DMSensorsWarningIcon /> : <DMNewSensorsIcon />}
          </div>
        ) : (
          activeCount !== undefined &&
          totalCount &&
          totalCount > 0 && (
            <span className="p-0 flex gap-2 items-center text-healthy h-full scale-125">
              <RoundProgressIcon value={(100 * activeCount) / totalCount} size={16} strokeWidth={3} />
            </span>
          )
        )
      }
      dialog={{
        title: assetName,
        element: <SensorModal sensors={sensors} />,
        className: 'max-w-screen md:max-w-fit md:min-w-[1200px] max-h-screen overflow-auto md:overflow-hidden',
      }}
    >
      {isFixed ? (
        <span className="text-[#6667DD] font-semibold text-sm">{`${sensors?.length} Sensors`} </span>
      ) : (
        activeCount !== undefined &&
        totalCount &&
        totalCount > 0 && (
          <span className="text-primary-500 font-semibold text-sm whitespace-nowrap">{`${activeCount} / ${totalCount} Data Received`}</span>
        )
      )}
    </BaseButton>
  );
};
