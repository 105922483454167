import { Box, Divider, HStack } from '@chakra-ui/react';
import { DMComponentIcon } from 'src/components/Icons';
import React from 'react';

export const ComponentsSidebarHead = (props: { components: any; assetName?: string }) => {
  const componentsLength = Array.isArray(props.components)
    ? props.components.filter((component) => Object.keys(component).length > 1).length
    : 0;

  return (
    <HStack
      justifyContent="space-between"
      px={6}
      pt={3}
      pb={1}
      fontWeight={600}
      position="sticky"
      zIndex={2}
      top={0}
      bgColor="#E6E8EC"
    >
      <Box>{props.assetName}</Box>
    </HStack>
  );
};
