import React from 'react';
import { useLazyGetCommonFailuresStatisticsQuery } from '@/app/api/statisticsApi';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function useFetchCommonFailures() {
  const { siteId } = useParams();
  const [fetchCommonFailuresData, { data: rawCommonFailuresData, isLoading }] = useLazyGetCommonFailuresStatisticsQuery(
    {}
  );

  useEffect(() => {
    if (siteId) {
      fetchCommonFailuresData({ site_id: siteId });
    }
  }, [siteId]);

  return {
    rawCommonFailuresData,
    isLoading,
  };
}
