import React, { useEffect, useState } from 'react';
import { Box, Card, CardBody, HStack, VStack } from '@chakra-ui/react';
import { MobileFleetSensorDataHeader } from 'src/features/mobile-fleet-sensor-data/components/MobileFleetSensorDataHeader';
import { MultipleSubplots } from 'src/features/mobile-fleet-sensor-data/components/MultipleSubplots';
import { SensorFusionLegend } from 'src/features/sensor-fusion/SensorFusionLegend';
import { useSelector } from 'react-redux';
import { mobileFleetSensorDataSelectedSchemeSelector } from 'src/features/mobile-fleet-sensor-data/store/mobileFleetSensorData.slice';
import {
  dateRangePickerSelector,
  dispatch,
  fullPullModeActivatedSelector,
  mobileFleetSchemeSensorsCurrentSelectionSelector,
  mobileFleetSchemeSensorsPinSelectionSelector,
  mobileFleetSensorFusionSelectionSelector,
} from 'src/app/store';
import {
  setMobileFleetSchemeSensorsPinSelection,
  setMobileFleetSensorFusionSelection,
} from 'src/app/slices/applicationSlice';
import { MobileFleetSensorFusion } from 'src/features/mobile-fleet-sensor-data/components/MobileFleetSensorFusion';
import { useLazyGetMobileFleetSensorsQuery } from 'src/app/api/mobileFleetApi';
import { Loading } from 'src/components/Loading';
import { useParams } from 'react-router-dom';
import { getAssetById } from 'src/app/queries';
import { format } from 'date-fns';
import { DIAGNOSTICS_FROM_DATE_FORMAT, DIAGNOSTICS_TO_DATE_FORMAT } from 'src/const';
import { MobileFleetFusionTrend } from 'src/features/mobile-fleet-sensor-data/components/MobileFleetFusionTrend';
import { useLazyGetDiagnosticsByFormulaQuery } from 'src/app/api/diagnosticApi';
import { MAINTENANCE_CHART_CONTEXT } from 'src/features/maintenance-data/maintenance-chart/MaintenanceChart';

function extractFormulaIds(data: any) {
  return data.subcomponents.flatMap((subcomponent: any) =>
    subcomponent.diagnostics.map((diagnostic: any) => diagnostic.formula_id)
  );
}

interface MobileFleetSensorDataProps {
  selectedComponent: any;
  revision: number;
}

export const MobileFleetSensorData: React.FC<MobileFleetSensorDataProps> = ({ selectedComponent, revision }) => {
  const { siteId, machineId } = useParams<string>();
  const { asset } = getAssetById(siteId, machineId);
  const { asset_name: assetName } = asset || {};
  const isFullPullModeActivated = useSelector(fullPullModeActivatedSelector);
  const dateRangePickerDate = useSelector(dateRangePickerSelector);
  const selectedScheme = useSelector(mobileFleetSensorDataSelectedSchemeSelector);
  const { id: selectedSchemeId } = selectedScheme || {};
  const mobileFleetSchemeSensorsCurrentSelection = useSelector(mobileFleetSchemeSensorsCurrentSelectionSelector);
  const mobileFleetSchemeSensorsPinSelection = useSelector(mobileFleetSchemeSensorsPinSelectionSelector);
  const mobileFleetSensorFusionSelection = useSelector(mobileFleetSensorFusionSelectionSelector);
  const [fetchMobileFleetSensors, { data, isFetching, isLoading }] = useLazyGetMobileFleetSensorsQuery();
  const [fetchMobileFleetFusionTrend, { data: fusionTrendData }] = useLazyGetDiagnosticsByFormulaQuery();
  const formulaIds = extractFormulaIds(selectedComponent);
  const isNoData = data && data.every((item: any) => item.x.length === 0);
  const [queryParams, setQueryParams] = useState<Record<string, any>>();

  const onContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.preventDefault();
  const onUnpinSelection = () => {
    dispatch(setMobileFleetSchemeSensorsPinSelection(undefined));
    dispatch(
      setMobileFleetSensorFusionSelection({
        ...mobileFleetSensorFusionSelection,
        pin: undefined,
      })
    );
  };

  useEffect(() => {
    if (selectedComponent) {
      const isFullPull = !selectedScheme
        ? isFullPullModeActivated.onSensorFusion
        : isFullPullModeActivated.onSchemeSensors;
      const params = {
        site_id: siteId,
        asset_name: assetName,
        component_id: selectedComponent.component_id,
        formulas_ids: !selectedScheme ? formulaIds.toString() : selectedScheme.id,
        from_date: format(new Date(dateRangePickerDate.from), DIAGNOSTICS_FROM_DATE_FORMAT),
        to_date: format(new Date(dateRangePickerDate.to), DIAGNOSTICS_TO_DATE_FORMAT),
        full_pull: isFullPull,
        downsample_by: !isFullPull ? 10 : undefined,
        daily_max: isFullPull,
      };

      setQueryParams(params);

      fetchMobileFleetSensors(params, true);
    }
  }, [
    selectedComponent,
    selectedSchemeId,
    dateRangePickerDate.from,
    dateRangePickerDate.to,
    isFullPullModeActivated.onSensorFusion,
    isFullPullModeActivated.onSchemeSensors,
  ]);

  useEffect(() => {
    if (selectedScheme) {
      fetchMobileFleetFusionTrend(
        {
          site_id: siteId,
          asset_id: machineId,
          formula_id: selectedScheme.id,
          from_date: format(new Date(dateRangePickerDate.from), DIAGNOSTICS_FROM_DATE_FORMAT),
          to_date: format(new Date(dateRangePickerDate.to), DIAGNOSTICS_TO_DATE_FORMAT),
        },
        true
      );
    }
  }, [selectedScheme, dateRangePickerDate.from, dateRangePickerDate.to]);

  return selectedScheme ? (
    <HStack w="full" spacing={4} alignItems="flex-start">
      <Box bgColor="#E6E8EC" w="full" p={2} pt={0} borderRadius="lg" height="calc(100vh - 208px)">
        <MobileFleetSensorDataHeader
          scheme={selectedScheme}
          queryParams={queryParams}
          context={MAINTENANCE_CHART_CONTEXT.SchemeSensors}
        />

        {isFetching || isLoading ? (
          <Loading />
        ) : (
          <Card w="full" boxShadow={0}>
            <CardBody p={0} onContextMenu={onContextMenu}>
              {fusionTrendData ? <MobileFleetFusionTrend data={fusionTrendData} /> : null}

              {data?.length || !isNoData ? (
                <MultipleSubplots selectedComponent={selectedComponent} queryParams={queryParams} />
              ) : (
                <VStack height="calc(100vh - 398px)" alignItems="center" justifyContent="center">
                  <Box fontWeight={600} fontSize="0.875rem">
                    Sensors data currently unavailable
                  </Box>
                </VStack>
              )}
            </CardBody>
          </Card>
        )}
      </Box>

      <SensorFusionLegend
        currentSelection={mobileFleetSchemeSensorsCurrentSelection}
        pinSelection={mobileFleetSchemeSensorsPinSelection}
        onUnpinSelection={onUnpinSelection}
      />
    </HStack>
  ) : (
    <HStack w="full" spacing={4} alignItems="flex-start">
      <Box bgColor="#E6E8EC" w="full" p={2} pt={0} borderRadius="lg" height="calc(100vh - 208px)">
        <MobileFleetSensorDataHeader
          scheme={selectedScheme}
          queryParams={queryParams}
          context={MAINTENANCE_CHART_CONTEXT.SensorFusion}
        />

        {isFetching || isLoading ? (
          <Loading />
        ) : (
          <Card w="full" boxShadow={0}>
            <CardBody p={0} onContextMenu={onContextMenu}>
              {data?.length || !isNoData ? (
                <MobileFleetSensorFusion selectedComponent={selectedComponent} queryParams={queryParams} />
              ) : (
                <VStack height="calc(100vh - 265px)" alignItems="center" justifyContent="center">
                  <Box fontWeight={600} fontSize="0.875rem">
                    Sensors data currently unavailable
                  </Box>
                </VStack>
              )}
            </CardBody>
          </Card>
        )}
      </Box>

      <SensorFusionLegend
        currentSelection={mobileFleetSensorFusionSelection.current}
        pinSelection={mobileFleetSensorFusionSelection.pin}
        onUnpinSelection={onUnpinSelection}
      />
    </HStack>
  );
};
