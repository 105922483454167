import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DIAGNOSTICS_DATE_FORMAT } from 'src/const';
import { format } from 'date-fns';
import { useLazyGetFullOilReportQuery, useLazyGetOilReportRecapQuery } from '@/app/api/reportsApi';
import { getAssetById } from '@/app/queries';
import { useSelector } from 'react-redux';
import { dateRangePickerSelector } from '@/app/store';

export const useFetchReports = ({ asset_name, component_id }: any) => {
  const { from, to } = useSelector(dateRangePickerSelector);

  const [
    fetchOilReportRecap,
    { data: oilReportRecap, isLoading: isLoadingOilReportRecap, isFetching: isFetchingOilReportRecap },
  ] = useLazyGetOilReportRecapQuery();

  const [fetchFullOilReport, { data: oilReport, isFetching: isLoadingOilReport, isFetching: isFetchingOilReport }] =
    useLazyGetFullOilReportQuery();

  const { siteId, machineId, componentId } = useParams();

  const getOilReportRecap = useCallback(() => {
    const fromDateISO = format(new Date(from), DIAGNOSTICS_DATE_FORMAT);
    const toDateISO = format(new Date(to), DIAGNOSTICS_DATE_FORMAT);
    fetchOilReportRecap(
      {
        site_id: siteId,
        asset_name,
        component_id,
        from_date: fromDateISO,
        to_date: toDateISO,
      },
      true
    ).unwrap();
  }, [componentId, from, to]);

  const getFullOilReport = useCallback(() => {
    const fromDateISO = format(new Date(from), DIAGNOSTICS_DATE_FORMAT);
    const toDateISO = format(new Date(to), DIAGNOSTICS_DATE_FORMAT);
    fetchFullOilReport(
      {
        site_id: siteId,
        asset_name,
        component_id,
        from_date: fromDateISO,
        to_date: toDateISO,
      },
      true
    ).unwrap();
  }, [componentId, from, to]);

  useEffect(() => {
    getOilReportRecap();
    getFullOilReport();
  }, [componentId, from, to]);

  return {
    oilReport,
    oilReportRecap,
    isLoadingOilReport,
    isLoadingOilReportRecap,
    isFetchingOilReport,
    isFetchingOilReportRecap,
    getFullOilReport,
    getOilReportRecap,
  };
};
