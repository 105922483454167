export enum ASSETS_TYPE {
  MOBILE = 'mobile-assets',
  FIXED = 'fixed-assets',
  ALL = 'all-assets',
}

export enum ASSETS_PREVIEW {
  MAP = 'map',
  BI = 'business-insights',
  ASSETS = 'assets',
  MAINTENANCE_PLANING = 'maintenance-planning',
}

export enum ASSET_STUDIO {
  DIAGNOSTICS = 'diagnostics',
  SENSOR_DATA = 'sensors-data',
  VISUAL_AI = 'visual-ai',
  MAINTENANCE_DATA = 'maintenance-data',
  ALL_COMPONENTS = 'all-components',
  REPORTS = 'reports',
}

export enum FILTER_PARAMS {
  ASSETS_PREVIEW = 'assetsPreview',
  ASSETS_TYPE = 'assetsType',
  ASSET_STUDIO = 'assetStudio',
  GENERAL_SEARCH = 'search',
  SELECTED_COMPONENT = 'selectedComponent',
  TAB = 'tab',
}
