export const BUTTONS_OVERWRITES = {
  classNames: {
    base: 'inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none  disabled:pointer-events-none disabled:opacity-50  [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0',
    variants: {
      default: '',
      filled:
        'bg-primary-600 text-white hover:bg-white hover:bg-primary-500 disabled:bg-neutral-100 active:bg-primary-600 disabled:opacity-100',
      outline: 'text-default bg-white ring-[1px] hover:ring-primary-500 active:ring-2 ring-neutral-100 ',
      ghost:
        'text-default hover:bg-gray-200 disabled:text-disabled disabled:cursor-not-allowed active:bg-gray-300 active:text-default',
      icon: 'text-default hover:bg-neutral-25 disabled:text-disabled disabled:cursor-not-allowed active:bg-primary-600 active:text-white ',
      tab: 'text-accent inline-flex items-center justify-center whitespace-nowrap bg-white  px-5 py-3 text-sm font-medium  transition-all focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 data-[state=active]:border-accent data-[state=active]:bg-accent data-[state=active]:text-accent-foreground data-[state=active]:font-medium',
      datepicker: 'flex justify-between w-full border-b-2 rounded-none border-b-neutral-100 !pt-6 !pb-4',
    },
    size: {
      default: 'h-10 px-4 py-2',
      xs: 'h-8 rounded-md px-1',
      sm: 'h-9 rounded-md px-3',
      lg: 'h-11 rounded-md px-8',
      icon: 'h-8 w-8 [&_svg]:scale-[1.5]',
    },
  },
};
