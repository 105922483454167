import { createApi, retry } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithStatusCheck } from 'src/app/baseQueryWithStatusCheck';

const baseQuery = retry(baseQueryWithStatusCheck, { maxRetries: 2 });

export const mapApi = createApi({
  reducerPath: 'mapApi',
  baseQuery,
  tagTypes: ['mapHeatmapData', 'mapSpeedData'],
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getHeatMapData: builder.query<any, any>({
      query: ({ site_id = 'york' }) => {
        const searchParams = new URLSearchParams();
        if (site_id) {
          searchParams.append('site_id', site_id);
        }
        // return { url: `sensors_data/mobile?site_id=york&filename=heatmapData.json` };
        return [];
      },
      providesTags: [{ type: 'mapHeatmapData' }],
    }),
    getSpeedMapData: builder.query<any, any>({
      query: () => {
        return [];
        // return { url: `sensors_data/mobile?site_id=york&filename=pathPathData.json` }
      },
      providesTags: [{ type: 'mapSpeedData' }],
    }),
  }),
});

export const { useLazyGetHeatMapDataQuery, useLazyGetSpeedMapDataQuery } = mapApi;
