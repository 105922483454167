import React, { ReactNode, useEffect, useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useViewportWidth } from '@/NewUI/hooks/_common';
import { useAppFilters } from '@/NewUI/hooks/_common/useAppFilters';
import { cn } from '@/lib/utils';

export type TabType = {
  id: string;
  title: string;
  element?: any;
  icon?: ReactNode;
  disabled?: boolean;
  tooltip?: string;
};

interface BaseTabsProps {
  tabs: TabType[];
  defaultValue: string;
  updateUrl?: boolean;
  hideTabTriggers?: boolean;
  onClick?: () => void;
  paramAlias?: 'tab' | 'assetsType' | 'assetsPreview' | 'assetStudio';
  renderFixedElement?: () => ReactNode;
  renderRightPane?: () => ReactNode;
}

export const BaseTabs = ({
  tabs,
  defaultValue,
  updateUrl = true,
  onClick,
  paramAlias = 'tab',
  hideTabTriggers = true,
  renderFixedElement,
  renderRightPane,
}: BaseTabsProps) => {
  const { isTablet } = useViewportWidth();
  const fitlers = useAppFilters();
  const [selectedTab, setSelectedTab] = useState();
  // State to keep track of the current tab
  useEffect(() => {
    fitlers.setFilters({ [paramAlias]: defaultValue });
  }, [defaultValue]);

  // Update the tab in URL params when a new tab is clicked
  const handleTabClick = (tabId: string) => {
    fitlers.setFilters({ [paramAlias]: tabId });
    onClick && onClick();
  };

  return (
    <Tabs
      defaultValue={fitlers[paramAlias] || defaultValue}
      value={fitlers[paramAlias] || defaultValue}
      className="w-full h-full bg-transparent "
    >
      <div className="flex min-w-full justify-between items-center">
        <TabsList
          className={`z-20 ${
            isTablet ? 'fixed top-header w-screen left-0 rounded-none border-2 py-1 px-1 h-100' : 'h-100 my-3'
          }`}
        >
          {tabs.map((tab: TabType) => (
            <TabsTrigger
              data-testid={'page_tabs_' + tab.title.toLowerCase().replace(' ', '_')}
              disabled={tab.disabled}
              className={cn(
                `z-20 hover:bg-neutral-25 disabled:opacity-100`,
                `${
                  isTablet
                    ? hideTabTriggers
                      ? 'hidden'
                      : 'px-2 w-screen border-none text-xs sm:text-sm first-of-type:rounded-none last-of-type:rounded-none whitespace-pre-wrap h-100'
                    : hideTabTriggers && 'hidden'
                }`,
                `${tab.disabled ? (isTablet ? 'hidden' : 'text-disabled cursor-not-allowed') : ''}`
              )}
              key={tab.id + '_tabs_trigger'}
              value={tab.id}
              onClick={() => handleTabClick(tab.id)}
            >
              {tab.title}
            </TabsTrigger>
          ))}
        </TabsList>
        <div id="tab-line-right-pane" className="w-full">
          {renderRightPane ? renderRightPane() : ''}
        </div>
      </div>

      {renderFixedElement
        ? renderFixedElement()
        : tabs.map((tab: TabType) => (
            <TabsContent
              key={tab.id + '_tabs_content'}
              value={tab.id}
              className={`overflow-auto max-h-main-mobile ${hideTabTriggers ? '' : 'pb-6'}`}
            >
              {tab.element}
            </TabsContent>
          ))}
    </Tabs>
  );
};
