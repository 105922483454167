import React from 'react';
import { MAP_CONTROLLERS_DEFAULT_LAYOUT } from '../site-map-config/config';
import { MapDataLayerSelector, MapZoomButtons, MapIndex } from '.';
import { MapLineIndex } from './MapIndex/MapLineIndex';

//@TODO: switch to area-based sections (components will be mapped to areas) so use of containers can be applied.
const {
  zoomController: { layout: ZoomControllersLayout, position: zoomControllersPosition },
  layer: { layout: LayerControllerLayout, position: layerControllerPosition },
  mapIndex: { layout: MapIndexLayout, position: mapIndexPosition },
  MapLineIndex: { layout: MapLineIndexLayout, position: mapLineIndexPosition },
} = MAP_CONTROLLERS_DEFAULT_LAYOUT;

export const MapControllers = () => {
  return (
    <>
      <ZoomControllersLayout sx={{ ...zoomControllersPosition }}>
        <MapZoomButtons />
      </ZoomControllersLayout>
      {/* <LayerControllerLayout sx={{ ...layerControllerPosition }}>
        <MapDataLayerSelector />
      </LayerControllerLayout>
      <MapIndexLayout sx={{ ...mapIndexPosition }}>
        <MapIndex />
      </MapIndexLayout>
      <MapLineIndexLayout sx={{ ...mapLineIndexPosition }}>
        <MapLineIndex />
      </MapLineIndexLayout> */}
    </>
  );
};
