import { useLazyGetCommonFailuresStatisticsQuery } from '@/app/api/statisticsApi';
import { getFormattedDateRange } from '@/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

interface CommonFailureAggregated {
  fault_name: string;
  occurrences: number;
  total_alarm_days: number;
  total_critical_days: number;
}

interface CommonFailures {
  fault_name: string;
  occurrences: number;
  average_alarm_days: number;
  average_critial_days: number;
}

const DAYS_TO_PERIOD = {
  '365': '1 Year',
  '180': '180 Days',
  '90': '90 Days',
  '60': '60 Days',
};

const processData = (data: Record<string, any[]>, selectedItems: string[]) => {
  if (!data) return;
  return Object.fromEntries(
    Object.entries(data).map(([key, records]) => {
      // Aggregate data for each `fault_name`
      const aggregated = Object.values(
        records
          ?.filter((el) => selectedItems.includes(el.asset_ui_name))
          .reduce((acc, curr) => {
            const { fault_name, critical_days, alarm_days } = curr;

            if (!acc[fault_name]) {
              acc[fault_name] = {
                fault_name,
                occurrences: 0,
                total_alarm_days: 0,
                total_critical_days: 0,
              };
            }

            acc[fault_name].occurrences += 1;
            acc[fault_name].total_alarm_days += alarm_days;
            acc[fault_name].total_critical_days += critical_days;

            return acc;
          }, {} as Record<string, CommonFailureAggregated>)
      ).map(
        (group: any): CommonFailures => ({
          fault_name: group.fault_name,
          occurrences: group.occurrences,
          average_alarm_days: Math.round(group.total_alarm_days / group.occurrences),
          average_critial_days: Math.round(group.total_critical_days / group.occurrences),
        })
      );

      return [key, aggregated];
    })
  );
};

export const useCommonFailuresData = (rawCommonFailuresData: any) => {
  const initialTimeRange = Object?.keys(rawCommonFailuresData || {})[3] || '365';
  const [selectedRange, setSelectedRange] = useState(initialTimeRange);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [formattedDateRange, setFormattedDateRange] = useState(getFormattedDateRange(initialTimeRange));
  const [selectedAssets, setSelectedAssets] = useState([]);

  const handleSelectChange = (selectedValue: any) => {
    if (!selectedValue) return;
    if (isNaN(selectedValue))
      selectedValue = periodFilters?.find((el) => el.id === selectedValue.Period[0])?.value as '365';
    const numberOfDays = parseInt(selectedValue, 10);
    const formattedDate = getFormattedDateRange(numberOfDays);
    setSelectedRange(selectedValue);
    setFormattedDateRange(formattedDate);
  };

  const assetsFilters = useMemo(
    () =>
      Array.from(new Set(rawCommonFailuresData?.[selectedRange]?.map((el: any) => el.asset_ui_name))).map((el) => {
        return { id: el, label: el };
      }) as { id: string; label: string }[],
    [selectedRange, rawCommonFailuresData]
  );
  const periodFilters = useMemo(
    () =>
      rawCommonFailuresData
        ? Object?.keys(rawCommonFailuresData)?.map((el) => {
            return { id: DAYS_TO_PERIOD[el as '365'], label: DAYS_TO_PERIOD[el as '365'], value: el.toString() };
          })
        : null,
    [selectedRange, rawCommonFailuresData]
  );

  const proccesedFailuresData = useMemo(() => {
    if (rawCommonFailuresData) return processData(rawCommonFailuresData, selectedItems);
  }, [rawCommonFailuresData, selectedItems]);

  return {
    commonFailures: proccesedFailuresData,
    rawData: rawCommonFailuresData,
    selectedRange,
    setSelectedRange,
    handleSelectChange,
    setSelectedItems,
    formattedDateRange,
    selectedAssets,
    setSelectedAssets,
    assetsFilters,
    periodFilters,
  };
};
