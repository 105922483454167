import React, { useEffect } from 'react';
import { Box, Hide, HStack, IconButton, Show, Text, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { DMSearchIcon } from 'src/components/Icons';
import { useSticky } from 'src/hooks/useSticky';
import { useMatch, useSearchParams } from 'react-router-dom';
import { locations } from 'src/app/locations';
import { ToolbarSearch } from 'src/components/ToolbarSearch';
import { Asset } from 'src/types/assets';
import { Site } from 'src/types/sites';
import SummaryDrawer from 'src/components/SummaryDrawer';
import { SearchInputMobile } from 'src/components/SearchInputMobile';
import { theme } from 'src/theme';
import { FilterMenu } from 'src/components/FilterMenu';
import { LocationBreadcrumbs } from 'src/components/LocationBreadcrumbs';
import { Switcher } from 'src/components/switcher/Switcher';

type ToolbarProps = {
  siteId?: string;
  siteName?: string;
  sites?: Site[];
  machineName?: string;
  machineId?: string;
  isTopFixed?: boolean;
  machines?: Asset[];
  latestDiagnosticDate?: string;
  assetHealthStatus?: string;
  asset?: Asset;
  amountByStatus?: Record<string, number>;
  selectedStatuses?: Record<string, boolean>;
  setSelectedStatuses?: (state: Record<string, boolean>) => void;
  totalAssetsSensors?: Record<string, number>;
  selectedSensors?: string;
  setSelectedSensors?: (state: string) => void;
  machineSensorModal?: React.ReactNode;
  tabIndex?: number;
  amountByStatusChanged?: Record<string, number>;
  selectedStatusChanged?: Record<string, boolean>;
  setSelectedStatusChanged?: (state: Record<string, boolean>) => void;
  selectedComponent?: any;
  setSelectedComponent?: any;
  components?: any;
  setComponents?: any;
  componentId?: any;
  amountByCamera?: any;
  hideSensors?: boolean;
  hideSwitcher?: boolean;
};

export const Toolbar = ({
  siteName = 'Sites',
  sites,
  machineName,
  machineId,
  machines = [],
  latestDiagnosticDate,
  assetHealthStatus,
  isTopFixed = false,
  amountByStatus,
  selectedStatuses,
  setSelectedStatuses,
  totalAssetsSensors,
  setSelectedSensors,
  machineSensorModal,
  tabIndex,
  amountByStatusChanged,
  selectedStatusChanged,
  setSelectedStatusChanged,
  selectedComponent,
  setSelectedComponent,
  components,
  setComponents,
  componentId,
  amountByCamera,
  hideSensors,
  hideSwitcher,
}: ToolbarProps) => {
  const [sticky] = useSticky();
  const matchSitesLocation = useMatch(locations.protected.sites.index);
  const matchMachinesLocation = useMatch(locations.protected.sites.machines);
  const matchFailuresLocation = useMatch(locations.protected.sites.failures);
  const matchComponentsLocation = useMatch(locations.protected.sites.components);
  const matchSpecificComponentLocation = useMatch(locations.protected.sites.specificComponent);
  const isFailureComponentsLocationActivated =
    matchFailuresLocation || matchComponentsLocation || matchSpecificComponentLocation;
  const matchAccountSettingsPage = useMatch(locations.protected.settings.account);
  const matchUserManagementSettingsPage = useMatch(locations.protected.settings.userManagement);
  const matchCreateUserSettingsPage = useMatch(locations.protected.settings.createUser);
  const matchUserSettingsPage = useMatch(locations.protected.settings.user);
  const matchPreferencesSettingsPage = useMatch(locations.protected.settings.preferences);
  const matchApplicationPreferencesSettingsPage = useMatch(locations.protected.settings.applicationPreferences);
  const matchSettingsPage =
    matchAccountSettingsPage ||
    matchUserManagementSettingsPage ||
    matchCreateUserSettingsPage ||
    matchUserSettingsPage ||
    matchPreferencesSettingsPage ||
    matchApplicationPreferencesSettingsPage;
  const { isOpen: isSearchOpen, onOpen: onSearchOpen, onClose: onSearchClose } = useDisclosure();
  const { isOpen: isSearchMobileOpen, onOpen: onSearchMobileOpen, onClose: onSearchMobileClose } = useDisclosure();
  const [isNotMobileResolution] = useMediaQuery(`(min-width: ${theme.breakpoints.xl})`);
  const [searchParams] = useSearchParams();

  const style = {
    ...toolbarBaseStyle,
    ...(isTopFixed ? toolbarFixedStyle : sticky ? toolbarStickyStyle : null),
  };
  const getComponentSensorTabIndex = searchParams.get('tabIndex');

  useEffect(() => {
    // Verifies that the search is closed when the user
    // navigates to the user management settings page
    if (matchUserManagementSettingsPage && isSearchOpen) {
      onSearchClose();
    }
  }, [matchUserManagementSettingsPage]);

  return (
    <HStack
      h="3.5rem"
      w="full"
      justifyContent="space-between"
      pl={{ base: isTopFixed ? 4 : 0, xl: isTopFixed ? 0 : 0 }}
      pr={{ base: isTopFixed ? 4 : 0, xl: isTopFixed ? 0 : 0 }}
      css={{ ...style }}
    >
      <Box display="flex" gap="20px">
        <LocationBreadcrumbs
          matchSitesLocation={matchSitesLocation}
          notMobileResolution={isNotMobileResolution}
          matchFailuresLocation={matchFailuresLocation}
          siteName={siteName}
          failureComponentsLocationActivated={isFailureComponentsLocationActivated}
          machineId={machineId}
          current={machineName}
          machines={machines}
          assetHealthStatus={assetHealthStatus}
          latestDiagnosticDate={latestDiagnosticDate}
          machineSensorModal={machineSensorModal}
          tabIndex={tabIndex}
          matchSettingsPage={matchSettingsPage}
        />
      </Box>

      <HStack spacing={0} justifyContent="flex-end">
        {selectedStatuses &&
        setSelectedStatuses &&
        setSelectedSensors &&
        selectedStatusChanged &&
        setSelectedStatusChanged ? (
          <Box mr={2}>
            {/* <FilterMenu
              assetsAmountByStatus={amountByStatus}
              selectedStatuses={selectedStatuses}
              setSelectedStatuses={setSelectedStatuses}
              setSelectedSensors={setSelectedSensors}
              isDiagnosticsLocation={isFailureComponentsLocationActivated}
              totalAssetsSensors={totalAssetsSensors}
              assetsAmountByStatusChanged={amountByStatusChanged}
              selectedStatusChanged={selectedStatusChanged}
              setSelectedStatusChanged={setSelectedStatusChanged}
              getComponentSensorTabIndex={getComponentSensorTabIndex}
              matchSpecificComponentLocation={matchSpecificComponentLocation}
              selectedComponent={selectedComponent}
              setSelectedComponent={setSelectedComponent}
              components={components}
              setComponents={setComponents}
              componentId={componentId}
              assetsAmountByCamera={amountByCamera}
              hideSensors={hideSensors}
              tabIndex={tabIndex}
            /> */}
          </Box>
        ) : null}
        <Hide below="xl">
          {!isSearchOpen && !matchUserManagementSettingsPage && !isFailureComponentsLocationActivated ? (
            <>
              {matchMachinesLocation && tabIndex !== 0 ? null : (
                <HStack gap={0} cursor="pointer" onClick={onSearchOpen}>
                  <IconButton variant="naked" aria-label="search open" icon={<DMSearchIcon fontSize="1.5rem" />} />
                  <Text fontSize="14px" fontWeight={500}>
                    Search
                  </Text>
                </HStack>
              )}
            </>
          ) : null}

          {isSearchOpen ? (
            <ToolbarSearch isTopFixed={isTopFixed} sticky={sticky} onSearchClose={onSearchClose} />
          ) : null}
        </Hide>

        <Show below="xl">
          <HStack>
            {matchSitesLocation || (matchMachinesLocation && tabIndex === 0) || matchFailuresLocation ? (
              <IconButton
                variant="naked"
                aria-label="search open"
                icon={<DMSearchIcon fontSize="1.5rem" />}
                onClick={onSearchMobileOpen}
              />
            ) : null}
            {matchSitesLocation ? <SummaryDrawer sites={sites} /> : null}
          </HStack>
        </Show>
        {!hideSwitcher && <Switcher />}
      </HStack>

      {isSearchMobileOpen ? <SearchInputMobile onSearchMobileClose={onSearchMobileClose} /> : null}
    </HStack>
  );
};

const toolbarBaseStyle = css`
  width: 100%;
  background-color: transparent;
`;

const toolbarFixedStyle = css`
  background-color: #fff;
`;

const toolbarStickyStyle = css`
  width: 100%;
  background-color: #fff;
`;
