import React, { ReactNode, useEffect } from 'react';
import { useAppFilters } from '@/NewUI/hooks/_common/useAppFilters';
import { Tabs, TabsList, TabsTrigger } from '@radix-ui/react-tabs';
import { TabType } from '.';
import { cn } from '@/lib/utils';
import { BaseUIKitWrapper } from '../_common/BaseUIKitWrapper';

interface ToggleTabsProps {
  tabs: TabType[];
  defaultValue?: string;
  updateUrl?: boolean;
  onClick?: () => void;
  paramAlias?: 'tab' | 'assetsType';
}

export const ToggleTabs = ({ tabs, defaultValue, updateUrl = true, onClick, paramAlias = 'tab' }: ToggleTabsProps) => {
  const fitlers = useAppFilters();
  // State to keep track of the current tab
  useEffect(() => {
    if (!fitlers[paramAlias]) {
      fitlers.setFilters({ [paramAlias]: defaultValue });
    }
  }, []);

  // Update the tab in URL params when a new tab is clicked
  const handleTabClick = (tabId: string) => {
    fitlers.setFilters({ [paramAlias]: tabId });
    onClick && onClick();
  };

  return (
    <Tabs
      defaultValue={fitlers[paramAlias] || defaultValue}
      value={fitlers[paramAlias] || defaultValue}
      className="w-fit h-full"
    >
      <TabsList className="flex rounded-full p-1.5 bg-[#E6E8EC] w-fit items-center ">
        {tabs.map((tab: TabType) => (
          <BaseUIKitWrapper
            tooltip={{ element: tab?.tooltip || tab.title, direction: 'bottom' }}
            key={tab.id + '_tabs_trigger'}
          >
            <TabsTrigger
              disabled={tab.disabled}
              value={tab.id}
              data-testid={'header_tab_' + tab.id}
              onClick={() => handleTabClick(tab.id)}
              className="data-[state=active]:bg-white flex items-center gap-1 bg-transparent rounded-full data-[state=active]:text-default px-2 whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <span
                className={cn(
                  'hover:bg-white hover:data-[state=active]:bg-white w-8 h-8 justify-center flex items-center rounded-full',
                  `${tab.disabled && 'hover:bg-[#E6E8EC] text-disabled opacity-80'}`,
                  `${tab.id === fitlers[paramAlias] && 'hover:bg-white'}`
                )}
              >
                {tab.icon}
              </span>
              <span className={`${tab.id !== fitlers[paramAlias] && 'hidden'} text-xs font-medium`}>{tab.title}</span>
            </TabsTrigger>
          </BaseUIKitWrapper>
        ))}
      </TabsList>
    </Tabs>
  );
};
