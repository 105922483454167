import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { Box, Container, Hide, HStack } from '@chakra-ui/react';
import { Sidebar as OriginalSidebar } from 'src/components/Sidebar';
import { locations } from 'src/app/locations';
import { useAuth } from 'src/providers/AuthProvider';
import { firebaseUserRequestingSelector, mfaExpiredSelector } from 'src/app/store';
import { ScreenSpinner } from 'src/components/ScreenSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import Fallback from 'src/components/Fallback';
import { diagnosticApi } from 'src/app/api/diagnosticApi';
import { userApi } from 'src/app/api/userApi';
import { analytics } from 'src/analytics';
import { Sidebar as NewSidebar } from '@/NewUI/layouts/Sidebar/Sidebar';

export const AppLayout = () => {
  const [userMustChangePassword, setUserMustChangePassword] = useState<boolean | null>(null);
  const { user, signOut } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isFirebaseUserRequesting = useSelector(firebaseUserRequestingSelector);
  const isMfaExpired = useSelector(mfaExpiredSelector);

  useEffect(() => {
    if (localStorage.getItem('userMustChangePassword')) {
      setUserMustChangePassword(true);
    } else {
      setUserMustChangePassword(false);
    }
  }, []);

  useEffect(() => {
    if (userMustChangePassword) {
      navigate(locations.public.change);
    }
  }, [userMustChangePassword]);

  if (isMfaExpired) {
    signOut().then(() => {
      navigate(locations.public.login);
      dispatch(diagnosticApi.util.resetApiState());
      dispatch(userApi.util.resetApiState());
      analytics.track('User logged out');
    });
  }

  if (isFirebaseUserRequesting) {
    return <ScreenSpinner />;
  }

  if (!user && userMustChangePassword === false) {
    sessionStorage.setItem('redirectAfterLogin', location.pathname + '?' + location.search);
    return <Navigate to={locations.public.login} state={{ from: location }} replace />;
  }

  const isNewSidebar = false;

  return (
    <Container css={containerStyle}>
      <HStack w="full" spacing={0}>
        <Hide below="xl">{isNewSidebar ? <NewSidebar /> : <OriginalSidebar />}</Hide>
        <Box w="full" h="100vh">
          <Box pl={{ base: 4, xl: '4.5rem' }} css={outletBoxStyle}>
            <ErrorBoundary FallbackComponent={Fallback}>
              <Outlet />
            </ErrorBoundary>
          </Box>
        </Box>
      </HStack>
    </Container>
  );
};

const containerStyle = css`
  width: 100%;
  min-width: 100%;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
`;

const outletBoxStyle = css`
  width: 100%;
`;
