import { selectCachedUserDataById } from '@/app/queries';
import { MANAGEMENT_ROLE } from '@/const';
import { useAuth } from '@/providers/AuthProvider';
import React from 'react';
import { useSelector } from 'react-redux';

export const useIsDMAdmin = () => {
  const { user } = useAuth();
  const userData = useSelector((state) => selectCachedUserDataById(state, user!.uid));
  const isDMAdmin = [MANAGEMENT_ROLE.DM_Admin].includes(userData?.role);
  return { isDMAdmin };
};
