import React from 'react';
import { AssetsPreviewSection } from '../../_common';
import { AssetsPreviewItems } from '../AssetsPreviewItems';
import { SectionEmptyState } from '../EmptyStates';

interface GridViewProps {
  requireAttention: any[];
  notRequireAttention: any[];
}
export const GridView = ({ requireAttention, notRequireAttention }: GridViewProps) => {
  return (
    <>
      {[{ items: requireAttention, isRequiredAttention: true }, { items: notRequireAttention }].map(
        ({ items, isRequiredAttention = false }, index) => (
          <AssetsPreviewSection key={index} isRequiredAttention={isRequiredAttention} count={items?.length}>
            {items.length === 0 ? (
              <SectionEmptyState />
            ) : (
              <AssetsPreviewItems isRequiredAttention={isRequiredAttention} items={items} />
            )}
          </AssetsPreviewSection>
        )
      )}
    </>
  );
};
