import React from 'react';
import { Box, HStack, Text, Divider } from '@chakra-ui/react';

interface SummaryTotalAssetsProps {
  totalFixedAssets?: number;
  totalMobileAssets?: number;
}

export const SummaryTotalAssets = ({ totalFixedAssets, totalMobileAssets }: SummaryTotalAssetsProps) => {
  return (
    <HStack justifyContent="center" alignItems="center" spacing={7} pb={4} pt={4}>
      <Box textAlign="center" pr={2}>
        <Text fontSize="12px" fontWeight={500} lineHeight="1.2" pb={2}>
          Fixed <br /> Assets
        </Text>
        <Text fontSize="16px" fontWeight={600}>
          {totalFixedAssets}
        </Text>
      </Box>
      <Divider orientation="vertical" height="3.5rem" borderColor="#B1B9C3" />
      <Box textAlign="center" pl={2}>
        <Text fontSize="12px" fontWeight={500} lineHeight="1.2" pb={2}>
          Mobile Fleet <br /> Assets
        </Text>
        <Text fontSize="16px" fontWeight={600}>
          {totalMobileAssets}
        </Text>
      </Box>
    </HStack>
  );
};
