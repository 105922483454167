import React from 'react';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/components/ui/drawer';
import { ReactNode } from 'react';
import { ButtonProps } from '@/components/ui/button';
import { Separator } from '@radix-ui/react-separator';
import DrawerSwipeBtn from './DrawerSwipeBtn';
import { useViewportWidth } from '@/NewUI/hooks/_common';
import { DMCloseThinIcon } from '@/components/Icons';

interface BaseDrawerProps {
  children: ReactNode;
  content?: ReactNode | string;
  customCloseTrigger?: ReactNode;
  title?: string;
  direction?: 'top' | 'left' | 'right' | 'bottom';
  className?: string;
  isMobile?: boolean;
}

export const BaseDrawer = ({
  children,
  customCloseTrigger,
  content,
  title,
  direction = 'left',
  className,
}: BaseDrawerProps) => {
  if (!content) return <>{children}</>;
  const { isMobile } = useViewportWidth();
  return (
    <Drawer direction={direction}>
      <DrawerTrigger>{children}</DrawerTrigger>
      <DrawerContent className={`${isMobile && direction === 'bottom' ? 'pt-10' : 'h-full'} ${className} `}>
        <DrawerHeader>
          {isMobile && direction === 'bottom' ? (
            <DrawerSwipeBtn variant="top" />
          ) : (
            <DrawerClose className="w-100 flex justify-end h-2">
              <DMCloseThinIcon boxSize={6} />
            </DrawerClose>
          )}

          <DrawerTitle>{title}</DrawerTitle>
          {/* <DrawerDescription>{title}</DrawerDescription> */}
        </DrawerHeader>
        {content}
        {/* <DrawerFooter></DrawerFooter> */}
      </DrawerContent>
    </Drawer>
  );
};
