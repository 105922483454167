'use client';

import * as React from 'react';
import { Label, Pie, PieChart } from 'recharts';

import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { cn } from '@/lib/utils';

export const description = 'A donut chart with text';

const chartConfig = {} satisfies ChartConfig;

export interface BasePieChartProps {
  chartData: { data: any[]; metaData: any };
  chartTopIndex?: ReactNode;
  chartBottomIndex?: ReactNode;
  orientation?: 'vertical' | 'horizontile';
  classNames?: { chartContainer: string };
}

export const BasePieChart = ({
  chartData,
  chartTopIndex,
  chartBottomIndex,
  orientation = 'vertical',
  classNames,
}: BasePieChartProps) => {
  const { data, metaData } = chartData;
  const containerRef = useRef<HTMLDivElement>(null);
  const [innerRadius, setInnerRadius] = useState(0);
  const [outerRadius, setOuterRadius] = useState(0);

  // Handls Dynamic Radius
  useEffect(() => {
    const updateRadius = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        const height = containerRef.current.offsetHeight;
        setInnerRadius(Math.min(width, height) * 0.35);
        setOuterRadius(Math.min(width, height) * 0.35 + 10);
      }
    };
    updateRadius();
    window.addEventListener('resize', updateRadius);
    return () => window.removeEventListener('resize', updateRadius);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="p-1 flex items-center justify-center">{orientation === 'horizontile' && chartTopIndex}</div>
      <div className="flex items-center justify-around gap-2 h-full bg-white">
        <ChartContainer
          ref={containerRef}
          config={chartConfig}
          className={cn('mx-auto aspect-square max-h-[250px] capitalize w-full z-[0]', classNames?.chartContainer)}
        >
          <PieChart>
            <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
            <Pie
              data={data}
              dataKey="value"
              nameKey="label"
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              paddingAngle={4}
            >
              {metaData && (
                <Label
                  content={({ viewBox }) => {
                    if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                      return (
                        <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle" dominantBaseline="middle">
                          <tspan x={viewBox.cx} y={(viewBox.cy || 0) - 24} className="font-medium text-sm text-primary">
                            Total
                          </tspan>
                          <tspan x={viewBox.cx} y={(viewBox.cy || 0) + 10} className="text-3xl font-semibold ">
                            {metaData.total?.toLocaleString()}
                          </tspan>
                        </text>
                      );
                    }
                  }}
                />
              )}
            </Pie>
          </PieChart>
        </ChartContainer>
        {orientation === 'horizontile' && <div className="w-full">{chartBottomIndex}</div>}
      </div>
      <div className="flex-col gap-4 text-sm bg-white text-primary">
        {orientation === 'vertical' && chartTopIndex}
        {orientation === 'vertical' && chartBottomIndex}
      </div>
    </div>
  );
};
