import React from 'react';

export interface PieChartIndexProps {
  chartIndexData: {
    data: { label: string; value: number; color: string }[];
    metaData: { label: string; value: number }[];
  };
  renderLabel: (el: any, data?: any) => React.ReactNode;
}

export const PieChartTopIndex = ({ chartIndexData, renderLabel }: PieChartIndexProps) => {
  const { data, metaData } = chartIndexData;
  return (
    <div>
      {metaData && (
        <div className="flex gap-4 h-full w-full items-center justify-center pt-2 sm:py-2">
          {metaData.map((el, index) => (
            <React.Fragment key={el.label}>{renderLabel && renderLabel(el, { metaData, index })}</React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
