import { dispatch, RootState } from 'src/app/store';
import { FilterFn } from '@tanstack/react-table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchValue } from '@/app/slices/applicationSlice';

// Field-specific or global filter function
const annotatedOrGlobalFilterFn: FilterFn<any> = (row, columnId, filterValue) => {
  if (!filterValue.includes(':')) {
    // Global search logic: check all row cells
    return row.getAllCells().some((cell) => String(cell.getValue()).toLowerCase().includes(filterValue.toLowerCase()));
  }

  // Field-specific search logic
  const [field, value] = filterValue.split(':').map((s: string) => s.trim());
  if (!field || !value) return true;
  const cellValue = row.getValue(field);
  return String(cellValue).toLowerCase().includes(value.toLowerCase());
};

export const useSearchByHeader = (columns: any) => {
  const [searchAnnotation, setSearchAnnotation] = useState<string>('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const { searchValue } = useSelector((state: RootState) => state.application);

  const fieldMap = useMemo(() => {
    return columns
      .filter((el: any) => el.enableFiltering)
      .reduce((acc: any, col: any) => {
        if (typeof col.accessorKey === 'string' && typeof col.header === 'string') {
          acc[col.header] = col.accessorKey;
        }
        return acc;
      }, {} as Record<string, string>);
  }, [columns]);
  const headerNames = useMemo(() => Object.keys(fieldMap), [fieldMap]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchAnnotation(value);

    // Provide suggestions only for field-specific input
    if (value.includes(':')) {
      const [field] = value.split(':');
      dispatch(setSearchValue(value.split(':')[1]));
      const matchingFields = headerNames.filter((name) => name.toLowerCase().startsWith(field.toLowerCase()));
      setSuggestions(matchingFields);
    } else {
      dispatch(setSearchValue(value.split(':')[1]));
      setSuggestions([]); // No suggestions for global search
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setSearchAnnotation(`${fieldMap[suggestion]}:`);
    setSuggestions([]);
    searchInputRef.current?.focus();
  };

  const handleClearInput = () => {
    setSuggestions([]);
    setSearchAnnotation('');
    searchInputRef.current?.focus();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchInputRef.current && !searchInputRef.current.contains(event.target as Node)) {
        setSuggestions([]);
        setSearchValue('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return {
    filterFn: annotatedOrGlobalFilterFn,
    headerNames,
    searchAnnotation,
    columns,
    suggestions,
    handleSuggestionClick,
    handleInputChange,
    setSearchAnnotation,
    handleClearInput,
    searchInputRef,
    searchValue,
  };
};
