import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { REGULAR_DATE_FORMAT } from '@/const';
import { format, isValid } from 'date-fns';
import { Highlight } from '@/components/UI-kit/Highlight/Highlight';
import { Badge } from '@/components/ui/badge';

const headerTagStyle = 'sm:text-xs text-white font-medium text-xs w-full flex flex items-center justify-center';
const headerStyle = 'font-medium text-sm ';

interface DiagnosticsMetaData {
  alarm: number;
  critical: number;
  healthy: number;
  monitor: number;
  na: number;
}

export interface SitesData {
  site_name: string;
  site_id: string;
  latest_diagnostic_date: string;
  assets_health_statuses_counts: DiagnosticsMetaData;
  fixed_assets: number;
  mobile_assets: number;
}

const sites_table_structure: ColumnDef<SitesData>[] = [
  {
    accessorKey: 'site_name',
    header: 'Site Name',
    cell: (info) => {
      const latestDiagnosticDate = info.row.original.latest_diagnostic_date;
      const date = new Date(latestDiagnosticDate);
      return (
        <div className="flex flex-col gap-1 sm:gap-0">
          <span className="lg:font-medium font-semibold text-xl lg:text-lg">
            {<Highlight text={info.row.original.site_name as string} />}
          </span>
          <span className="text-gray-500 text-xs font-medium lg:font-base ">
            {'Last recorded: ' + (isValid(date) ? format(date, REGULAR_DATE_FORMAT) : 'No data')}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'fixed_assets',
    header: () => <div className={`${headerStyle} text-inherit`}>Fixed Assets</div>,
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: 'mobile_assets',
    header: () => <div className={`${headerStyle} text-inherit`}>Mobile Fleet</div>,
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: 'assets_health_statuses_counts.critical',
    header: () => (
      <div className={`${headerTagStyle}`}>
        <Badge className=" bg-critical px-2 text-xs">Critical</Badge>
      </div>
    ),
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: 'assets_health_statuses_counts.alarm',
    header: () => (
      <div className={`${headerTagStyle}`}>
        <Badge className=" bg-alarm px-2 text-xs">Alarm</Badge>
      </div>
    ),
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: 'assets_health_statuses_counts.monitor',
    header: () => (
      <div className={`${headerTagStyle}`}>
        <Badge className=" bg-monitor px-2 text-xs text-accent">Monitor</Badge>
      </div>
    ),
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: 'assets_health_statuses_counts.healthy',
    header: () => (
      <div className={`${headerTagStyle}`}>
        <Badge className=" bg-healthy px-2 text-xs">Healthy</Badge>
      </div>
    ),
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: 'assets_health_statuses_counts.na',
    header: () => (
      <div className={`${headerTagStyle}`}>
        <Badge className=" bg-notMonitored px-2 text-xs whitespace-nowrap">N/A</Badge>
      </div>
    ),
    cell: (info) => info.getValue(),
  },
];

export const useSitesPageTables = () => {
  return {
    columns: sites_table_structure,
  };
};
