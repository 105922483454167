import { useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

interface NavigateWithQueryOptions {
  excludeParams?: string[];
  replaceParams?: Record<string, string | null>;
  addParams?: Record<string, string>;
  allowAddNewParams?: boolean; // Defaults to true
}

export const useQueryNavigation = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Get current query parameters as an object
  const currentParams = useMemo(() => {
    const paramMap: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      paramMap[key] = value;
    });
    return paramMap;
  }, [searchParams]);

  // Main navigation and query update function
  const navigateWithQuery = (path: string, options: NavigateWithQueryOptions = {}) => {
    const { excludeParams = [], replaceParams = {}, addParams = {}, allowAddNewParams = true } = options;

    // Remove excluded parameters
    excludeParams.forEach((param) => {
      searchParams.delete(param);
    });

    // Replace existing parameters or optionally add new ones
    Object.entries(replaceParams).forEach(([key, value]) => {
      if (searchParams.has(key) || allowAddNewParams) {
        if (value === null) {
          searchParams.delete(key);
        } else {
          searchParams.set(key, value);
        }
      }
    });

    // Add new parameters if allowed
    if (allowAddNewParams) {
      Object.entries(addParams).forEach(([key, value]) => {
        searchParams.set(key, value);
      });
    }

    // Update the URL with the new search parameters
    setSearchParams(searchParams);

    // Navigate to the new path with updated query
    navigate({
      pathname: path,
      search: searchParams.toString(),
    });
  };

  // Return the navigation function and current parameters
  return { currentParams, navigateWithQuery, setSearchParams, pathname };
};
