import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { DotIndicator } from '@/NewUI/components/_common/DotIndcator';
import { Popover, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover';
import React from 'react';

interface ExceedingComponentsPopoverProps {
  items: any[];
}

export const ExceedingComponentsPopover = ({ items }: ExceedingComponentsPopoverProps) => {
  return (
    <Popover>
      <PopoverTrigger onClick={(e) => e.stopPropagation()}>
        <Badge className={`bg-${items[0].health_status} text-white py-1 px-2.5 font-semibold text-xs `}>
          +{items.length}
        </Badge>
      </PopoverTrigger>
      <PopoverContent
        side="top"
        className="bg-white z-[1000] rounded-xl flex flex-col justify-start border-[1px] border-gray-300"
      >
        {items.map((el, index) => (
          <div
            key={el.component_id + el.component_name}
            onClick={() => {
              console.log(el.component_id);
            }}
            className={cn(
              `flex gap-2 items-center justify-start pl-4 pr-6 py-1.5 hover:cursor-pointer hover:bg-gray-100`,
              // `${index % 2 == 0 ? 'bg-gray-200' : ''}`, in case we need strips
              `${index === 0 ? 'rounded-t-xl' : ''}`,
              `${index === items?.length - 1 ? 'rounded-b-xl' : ''}`
            )}
          >
            <DotIndicator status={el.health_status as 'critical'} />
            <div className="font-normal">{el.component_ui_name}</div>
          </div>
        ))}
      </PopoverContent>
    </Popover>
  );
};
