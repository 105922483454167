import { useLazyGetMachineStatusStatisticsQuery } from '@/app/api/statisticsApi';
import { BasePieChart } from '@/components/UI-kit';
import { Skeleton } from '@/components/ui/skeleton';
import { PieChartBottomIndex } from '@/NewUI/components/_common/PieChartBottomIndex';
import { useHealthStatusPieChart } from '@/NewUI/hooks/AssetsPreviewPage/BusinessInsights/useHealthStatusPieChart';
import { countMachineStatusData } from '@/pages/machines/charts/machine-current-status-chart/utils';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const SiteHealthPieChart = () => {
  const { siteId } = useParams();
  const [fetchMachineStatusStatistics, { data: machineStatusData, isLoading }] = useLazyGetMachineStatusStatisticsQuery(
    {}
  );
  const [data, setData] = useState<any>([]);
  const [status, setStatus] = useState<any>(null);
  const [currentDate, setCurrentDate] = useState<string>();

  useEffect(() => {
    fetchMachineStatusStatistics({ site_id: siteId });
  }, [siteId]);

  useEffect(() => {
    if (machineStatusData?.length) {
      const latest = machineStatusData[machineStatusData.length - 1][1];
      const [data, status] = countMachineStatusData(latest as Array<Record<string, string>>);
      const latestDate = machineStatusData[machineStatusData.length - 1][0] as string;

      setCurrentDate(latestDate);
      setData(data);
      setStatus(status);
    }
  }, [machineStatusData]);

  const { chartData, chartIndexData } = useHealthStatusPieChart(data);

  return isLoading ? (
    <Skeleton className="h-[240px]" />
  ) : (
    <div>
      <div>
        <div className="font-semibold text-sm">Current Status</div>
        <div className="text-xs text-gray-500">
          {currentDate && format(new Date(currentDate as string), 'MMMM dd, yyyy')}
        </div>
      </div>
      <BasePieChart
        orientation="horizontile"
        classNames={{ chartContainer: 'max-h-[200px]' }}
        chartData={chartData}
        chartBottomIndex={chartIndexData && <PieChartBottomIndex chartIndexData={chartIndexData} />}
      />
    </div>
  );
};
