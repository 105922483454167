import React from 'react';
import { Box, Tooltip } from '@chakra-ui/react';
import { AssetFaultType } from 'src/pages/machines/components/AssetFaultType';
import { FaultyComponent } from 'src/types/assets';

const MoreFaultyComponentsBox = ({ components }: { components: FaultyComponent[] }) => {
  const label = components.reduce(
    (prevValue, currentValue, currentIndex, array) =>
      prevValue + currentValue.component_ui_name + (currentIndex !== array.length - 1 ? ', ' : ''),
    ''
  );
  return (
    <Tooltip label={label} borderRadius="md" placement="bottom">
      <Box px="12px" py="8px" fontSize="14px" fontWeight="600" borderRadius="8px" bg="#01152B" color="#12FFE2">
        +{components.length}
      </Box>
    </Tooltip>
  );
};

type FaultyComponentsListProps = {
  components: FaultyComponent[];
  visibleFaultyComponentsCount: number | null;
};

export const FaultyComponentsList = ({ components, visibleFaultyComponentsCount }: FaultyComponentsListProps) => {
  const componentsToRender =
    visibleFaultyComponentsCount === null ? components : components.slice(0, visibleFaultyComponentsCount);
  const additionalComponents =
    visibleFaultyComponentsCount === null ? [] : components.slice(visibleFaultyComponentsCount);
  return (
    <>
      {componentsToRender.map((component: any, idx: number) => (
        <AssetFaultType
          faultType={component.component_ui_name}
          variant={component.health_status.toLowerCase()}
          key={idx}
        />
      ))}
      {visibleFaultyComponentsCount !== null &&
        !!componentsToRender.length &&
        componentsToRender.length < components.length && <MoreFaultyComponentsBox components={additionalComponents} />}
    </>
  );
};
