import React, { ReactNode, useEffect, useState } from 'react';
import BaseInput, { ButtonVariant } from './BaseInput';
import { ComponentWithAs, IconProps } from '@chakra-ui/react';

interface AutocompleteInputProps {
  options?: string[];
  placeholder?: string;
  onChange?: (val: string) => void;
  onClose?: () => void;
  ref?: React.RefObject<HTMLInputElement>;
  value?: string;
  leftButton?: {
    element: ReactNode | ComponentWithAs<'svg', IconProps>;
    onClick?: () => void;
    variant?: ButtonVariant;
  };
  rightButton?: {
    element: ReactNode | ComponentWithAs<'svg', IconProps>;
    onClick?: () => void;
    variant?: ButtonVariant;
  };
}

export const AutocompleteInput = React.forwardRef<HTMLInputElement, AutocompleteInputProps>(
  ({ options = [], onChange, onClose, placeholder = '', value, rightButton, leftButton }, ref) => {
    const [inputValue, setInputValue] = useState(value);
    const [suggestions, setSuggestions] = useState<string[]>([]);

    useEffect(() => {
      if (value !== undefined && value !== inputValue) {
        setInputValue(value);
      }
    }, [value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setInputValue(value);
      onChange && onChange(value);
      if (value) {
        const filteredSuggestions = options.filter((option) => option.toLowerCase().includes(value.toLowerCase()));
        setSuggestions(filteredSuggestions);
      } else {
        setSuggestions([]);
      }
    };

    const handleSelect = (suggestion: string) => {
      setInputValue(suggestion);
      onChange && onChange(suggestion);
      setSuggestions([]);
    };

    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'ArrowDown') {
        setHighlightedIndex((prevIndex) => (prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0));
      } else if (e.key === 'ArrowUp') {
        setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1));
      } else if (e.key === 'Enter' && highlightedIndex >= 0) {
        if (suggestions[highlightedIndex] !== undefined) handleSelect(suggestions[highlightedIndex]);
      } else if (e.key === 'Escape') {
        onClose && onClose();
      }
    };

    return (
      <div style={{ position: 'relative', width: '200px' }}>
        <BaseInput
          ref={ref}
          type="text"
          value={inputValue}
          onChange={handleChange}
          rightButton={rightButton}
          leftButton={leftButton}
          onKeyDown={handleKeyDown}
        />
        {/* @TODO: switch to tailwind */}
        {suggestions.length > 0 && (
          <ul
            style={{
              position: 'absolute',
              top: '100%',
              left: 0,
              width: '100%',
              backgroundColor: 'white',
              border: '1px solid #ccc',
              listStyleType: 'none',
              padding: '0',
              margin: '0',
              zIndex: 9999999,
            }}
          >
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSelect(suggestion)}
                className={`px-2 py-1 hover:bg-primary-500 hover:text-white ${
                  highlightedIndex === index ? 'bg-primary-500 text-white' : ''
                }`}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
);
AutocompleteInput.displayName = 'AutocompleteInput';
export default AutocompleteInput;
