import { Separator } from '@radix-ui/react-separator';
import React from 'react';

interface DrawerSwipeBtn {
  variant?: 'top' | 'bottom';
}

export default function DrawerSwipeBtn({ variant = 'bottom' }: DrawerSwipeBtn) {
  const position = variant === 'top' ? 'top-1' : 'bottom-0';
  return (
    <div className={`fixed ${position} w-full justify-center p-2 flex bg-white left-0`}>
      <Separator className="h-2 bg-gray-200 w-[200px] rounded-xl" />
    </div>
  );
}
