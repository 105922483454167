import React, { ReactNode } from 'react';
import { AssetsPreviewCard } from './AssetsPreviewCard';

interface AssetsPreviewSectionProps {
  isRequiredAttention?: boolean;
  children: ReactNode;
  count?: number;
}

export const AssetsPreviewSection = ({
  isRequiredAttention = false,
  children,
  count = 0,
}: AssetsPreviewSectionProps) => {
  return (
    <AssetsPreviewCard title={`${count} Assets That ${isRequiredAttention ? '' : "Don't"} Require Attention`}>
      {children}
    </AssetsPreviewCard>
  );
};
