import { dependencies } from 'echarts'; // Assuming dependencies are coming from echarts
import React, { useEffect, useRef, useState } from 'react';

export const useOverflow = (externalDependencies?: any[] | any) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  // Handle the external dependencies in the effect
  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        setIsOverflowing(containerRef.current.scrollWidth > containerRef.current.clientWidth);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [externalDependencies]); // External dependencies will trigger the effect

  return { isOverflowing, containerRef };
};
