import {
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { DMCalendarInfo } from 'src/components/Icons';
import DatePicker from 'react-datepicker';
import React from 'react';
import { DIAGNOSTICS_REGULAR_DATE_FORMAT, REGULAR_DATE_FORMAT } from 'src/const';
import { format, sub, add, isAfter } from 'date-fns';
import { dateRangePickerSelector, dispatch } from 'src/app/store';
import { setDateRangePickerDate } from 'src/app/slices/applicationSlice';
import { useSelector } from 'react-redux';

interface DateRangeCalendarPopoverProps {
  selectedDate: string;
  minDate?: string;
  onCloseChartDatePickerPopover: () => void;
  isDisabled?: boolean;
}

export const DateRangeCalendarPopover = ({
  selectedDate,
  minDate,
  onCloseChartDatePickerPopover,
  isDisabled,
}: DateRangeCalendarPopoverProps) => {
  const dateRangePickerDate = useSelector(dateRangePickerSelector);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const formatWeekDay = (dayName: string) => dayName.substring(0, 1);

  const handleDateChange = (date: Date) => {
    if (minDate) {
      const todayDate = new Date();
      const newToDate = add(date, { months: 2 });

      dispatch(
        setDateRangePickerDate({
          from: format(date, DIAGNOSTICS_REGULAR_DATE_FORMAT),
          to: isAfter(newToDate, todayDate)
            ? format(todayDate, DIAGNOSTICS_REGULAR_DATE_FORMAT)
            : format(newToDate, DIAGNOSTICS_REGULAR_DATE_FORMAT),
        })
      );
    } else {
      dispatch(
        setDateRangePickerDate({
          ...dateRangePickerDate,
          to: format(date, DIAGNOSTICS_REGULAR_DATE_FORMAT),
        })
      );
    }

    onClose();
    onCloseChartDatePickerPopover();
  };

  const onTriggerClick = (e: any) => {
    e.stopPropagation();
    onToggle();
  };

  const onDateChange = (e: any) => e.stopPropagation();

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement="left-end">
      <InputGroup size="sm">
        <PopoverTrigger>
          <Input
            sx={{
              ':focus-visible': {
                borderColor: 'rgba(112, 113, 243, 1)',
              },
            }}
            pl={3}
            fontWeight={500}
            isReadOnly
            cursor="pointer"
            variant="flushed"
            w="full"
            size="sm"
            onClick={onTriggerClick}
            onChange={onDateChange}
            value={format(new Date(selectedDate), REGULAR_DATE_FORMAT)}
            disabled={!!isDisabled}
          />
        </PopoverTrigger>
        <InputRightElement pr={3}>
          <DMCalendarInfo fontSize="1.5rem" />
        </InputRightElement>
      </InputGroup>

      <PopoverContent w="full" borderColor="rgba(177, 185, 195, 1)">
        <PopoverArrow />
        <PopoverBody p={0} w="full">
          <DatePicker
            inline
            // minDate={minDate ? sub(new Date(), { years: 1 }) : undefined}
            minDate={new Date('1.1.23')}
            maxDate={new Date()}
            selected={new Date(selectedDate)}
            formatWeekDay={formatWeekDay}
            onChange={handleDateChange}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
