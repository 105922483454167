import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebounce } from './useDebounce';
import { ASSET_STUDIO, ASSETS_PREVIEW, ASSETS_TYPE, FILTER_PARAMS } from '@/types/_common/navigationTabs.enums';
import { useQueryNavigation } from './useNavigateWithQuery';
const assetsTypeWhitelist = [ASSETS_TYPE.FIXED, ASSETS_TYPE.MOBILE];

export function useAppFilters() {
  const { currentParams, setSearchParams, pathname } = useQueryNavigation();
  const { siteId } = useParams();
  const { assetsType, assetsPreview, tab, search, assetStudio, selectedComponent } = currentParams;
  const [localSearch, setLocalSearch] = useState<string | null | undefined>(search);
  const debouncedSearch = useDebounce(localSearch);
  const setFilters = useCallback(
    (filters: Partial<Record<FILTER_PARAMS, string | null>>) => {
      setSearchParams((params) => {
        Object.entries(filters).forEach(([key, value]) => {
          if (value === undefined || value === null) {
            params.delete(key);
          } else {
            params.set(key, value);
          }
        });
        // Handle edge cases for `assetsType` and `assetsPreview`
        if (filters.assetsPreview && !assetsTypeWhitelist.includes(assetsType as ASSETS_TYPE)) {
          params.set(FILTER_PARAMS.ASSETS_TYPE, ASSETS_TYPE.FIXED);
        }
        return params;
      });
    },
    [setSearchParams, assetsTypeWhitelist]
  );
  // Update search filter when debounced search value changes
  useEffect(() => {
    if (debouncedSearch !== undefined) {
      setFilters({ [FILTER_PARAMS.GENERAL_SEARCH]: debouncedSearch });
    }
  }, [debouncedSearch]);

  // Initialize default filter values based on the current route
  useEffect(() => {
    if (pathname.includes('sites')) {
      if (!assetsType) {
        setFilters({
          [FILTER_PARAMS.ASSETS_TYPE]: siteId ? ASSETS_TYPE.FIXED : ASSETS_TYPE.ALL,
        });
      }
      if (siteId && !assetsPreview) {
        setFilters({ [FILTER_PARAMS.ASSETS_PREVIEW]: ASSETS_PREVIEW.ASSETS });
      }
      if (siteId && assetsPreview && !assetStudio && selectedComponent) {
        setFilters({ [FILTER_PARAMS.ASSET_STUDIO]: ASSET_STUDIO.DIAGNOSTICS });
      }
      if (assetStudio && !selectedComponent)
        setFilters({ [FILTER_PARAMS.SELECTED_COMPONENT]: ASSET_STUDIO.ALL_COMPONENTS });
    }
  }, [pathname, siteId, assetsType, assetsPreview, assetStudio, selectedComponent, setFilters]);

  return {
    tab,
    assetsPreview,
    assetsType,
    assetStudio,
    search: debouncedSearch,
    selectedComponent,
    setLocalSearch,
    setFilters,
  };
}
