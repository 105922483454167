import React, { useMemo } from 'react';
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import {
  AssetHealthStatusIndicator,
  AssetActionsBar,
  SensorsNewIndicator,
  Sensor,
} from '@/NewUI/components/AssetsPreviewPage';
import { useAppFilters, useQueryNavigation } from '@/NewUI/hooks/_common';
import { Separator } from '@radix-ui/react-separator';
import { ChevronDown } from 'lucide-react';
import { useState } from 'react';
import { Asset } from '@/NewUI/types/_common/asset.types';
import { ASSET_STUDIO, FILTER_PARAMS } from '@/types/_common/navigationTabs.enums';
import { useParams } from 'react-router-dom';
import { SENSORS_DATA_TYPE } from '@/const';

interface AssetsDropListProps {
  asset: Asset;
  assets: Asset[];
  siteId: string;
}
// @TODO: make the dropdown a reusable component
// @TODO: same item as the component card(?)
export const AssetsDropdown = ({ asset, assets, siteId }: AssetsDropListProps) => {
  const { asset_ui_name, asset_supplier, asset_model } = asset || {};
  const { assetStudio, selectedComponent } = useAppFilters();
  const { id } = useParams();
  const { navigateWithQuery } = useQueryNavigation();
  const [isOpen, setIsOpen] = useState<boolean>();
  // filter non-camera assets when in visual ai
  const filteredAssets = useMemo(() => {
    if (assetStudio === ASSET_STUDIO.VISUAL_AI && selectedComponent) {
      return assets?.filter((asset: any) => {
        const { sensors_health_status, diagnostics } = asset;
        return sensors_health_status
          ? sensors_health_status?.some(
              (sensor: Sensor) => sensor.sensor_type.toLowerCase() === SENSORS_DATA_TYPE.Camera
            )
          : diagnostics
          ? diagnostics
              ?.map((el: any) => el.sources_info)
              .flat()
              .some((el: any) => el?.source_type?.toLowerCase() === SENSORS_DATA_TYPE.Camera)
          : [];
      });
    } else return assets;
  }, [assets, assetStudio]);

  return (
    <>
      <Popover
        onOpenChange={(open) => {
          setIsOpen(open);
        }}
      >
        <PopoverTrigger
          className={cn(
            'flex items-center gap-2 ml-[-0.5rem] px-2 py-1',
            `${isOpen ? 'bg-primary-600 text-white rounded-lg' : ''}`
          )}
        >
          {`${asset_ui_name} ${asset_supplier ? ' - ' + asset_supplier + ' ' + asset_model : ''}`}
          {/* <StatusTag
            status={asset?.health_status}
            suffix={
              asset?.latest_diagnostic_created_at && format(new Date(asset?.latest_diagnostic_created_at), 'dd.MM.yyyy')
            }
          /> */}
          <ChevronDown size={'16px'} className={`${isOpen ? 'rotate-[180deg]' : ''}`} />
        </PopoverTrigger>
        <PopoverContent className="p-0 max-h-[500px] overflow-y-auto">
          {filteredAssets?.map((el: Asset) => (
            <React.Fragment key={el.asset_id}>
              <div
                className={cn(
                  'flex justify-between p-3 py-2.5 hover:bg-neutral-25 hover:cursor-pointer border-b-2 border-transparent',
                  `${asset?.not_monitored ? 'bg-neutral-25 ring-2 ring-inset ring-white' : ''}`,
                  `${el?.asset_id === id ? 'border-b-2 border-primary-600 ' : ''}`
                )}
                onClick={() =>
                  navigateWithQuery(`/sites/${siteId}/machines/${el?.asset_id}/components`, {
                    replaceParams: { [FILTER_PARAMS.SELECTED_COMPONENT]: ASSET_STUDIO.ALL_COMPONENTS },
                  })
                }
              >
                <div
                  className={cn(
                    ' flex flex-col gap-1',
                    `${asset?.asset_id === el?.asset_id ? 'font-medium border-primary-600' : ''}`
                  )}
                >
                  <div>{`${el.asset_ui_name} ${
                    el.asset_supplier ? ' - ' + el.asset_supplier + ' ' + el.asset_model : ''
                  }`}</div>
                  <AssetHealthStatusIndicator
                    healthStatus={el?.health_status}
                    counterOnly={true}
                    showReasons={true}
                    isMonitored={el?.not_monitored}
                    size={'sm'}
                    reasons={el?.health_status_changed ? ['Status Change'] : []}
                  />
                </div>
                <AssetActionsBar
                  asset={el}
                  variant="component-card"
                  onClick={() => {
                    navigateWithQuery(`/sites/${siteId}/machines/${el?.asset_id}/components`);
                  }}
                />
              </div>
              <Separator className="bg-neutral-100 h-[1px]" />
            </React.Fragment>
          ))}
        </PopoverContent>
      </Popover>
      <SensorsNewIndicator asset={asset} />
    </>
  );
};
