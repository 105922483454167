import React from 'react';
import { Box } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { NavigationMenu } from 'src/components/NavigationMenu';

export const Sidebar = () => {
  return (
    <Box css={style}>
      <NavigationMenu />
    </Box>
  );
};

const style = css`
  width: 72px;
  margin-top: 56px;
  height: calc(100% - 56px);
  position: fixed;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  color: #fffefe;
  z-index: 50;
  background-color: #01152b;
`;
