import React, { ReactNode } from 'react';
import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useNavigate } from 'react-router-dom';

export type Breadcrumb = {
  id?: string;
  label: string;
  href?: string;
  element?: ReactNode;
  onClick?: () => void;
};

interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
  start?: number;
  end?: number;
  className?: string;
}

const breadcrumbItemStyle = 'h-100 flex items-center gap-1 text-base capitalize';
const breadcrumbListstyle = 'capitalize font-medium text-default group w-full';

const renderBreadcrumbElement = (breadcrumbEl: Breadcrumb, showSeperator?: boolean, isMenu?: boolean) => {
  const { href, label, element } = breadcrumbEl;
  const navigate = useNavigate();
  const content = breadcrumbEl.href ? (
    <BreadcrumbLink
      href={href}
      className={`${breadcrumbItemStyle} ${href ? 'hover:underline underline-offset-3' : ''}`}
      onClick={(e) => {
        e.preventDefault();
        href && navigate(href);
      }}
    >
      {element || label}
    </BreadcrumbLink>
  ) : (
    <div className={breadcrumbItemStyle}>{element || label}</div>
  );
  return (
    <>
      {isMenu ? <DropdownMenuItem>{content}</DropdownMenuItem> : <BreadcrumbItem>{content}</BreadcrumbItem>}
      {showSeperator && <BreadcrumbSeparator className="last-of-type:hidden" />}
    </>
  );
};

const renderBreadCrumbsElements = (elements: Breadcrumb[]) =>
  elements.map((breadcrumbEl: Breadcrumb, index: number) => (
    <React.Fragment key={index}>{renderBreadcrumbElement(breadcrumbEl, true)}</React.Fragment>
  ));

const renderBreadCrumbsMenuElements = (elements: Breadcrumb[]) => {
  return (
    <>
      <BreadcrumbItem>
        <DropdownMenu>
          <DropdownMenuTrigger className="flex items-center gap-1">
            <BreadcrumbEllipsis className="h-4 w-4" />
            <span className="sr-only">Toggle menu</span>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            {elements.map((breadcrumbEl: Breadcrumb, index: number) => (
              <React.Fragment key={index}>{renderBreadcrumbElement(breadcrumbEl, false, true)}</React.Fragment>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </BreadcrumbItem>
      <BreadcrumbSeparator />
    </>
  );
};

export function BaseBreadcrumb({ breadcrumbs, start = 2, end = 2, className }: BreadcrumbsProps) {
  const startElements = breadcrumbs.slice(0, start);
  const middleElements = breadcrumbs.slice(start, breadcrumbs.length - end);
  const endElements = breadcrumbs.slice(startElements.length + middleElements.length);

  return (
    <Breadcrumb className={className}>
      <BreadcrumbList className={breadcrumbListstyle}>
        {startElements.length > 0 && renderBreadCrumbsElements(startElements)}
        {middleElements.length > 0 && renderBreadCrumbsMenuElements(middleElements)}
        {endElements.length > 0 && renderBreadCrumbsElements(endElements)}
      </BreadcrumbList>
    </Breadcrumb>
  );
}
