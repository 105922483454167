import { CollapsableTable } from '@/components/UI-kit/Tables/Collpsable';
import React, { useEffect, useMemo, useState } from 'react';
import { useOilReportTable } from '../../../../hooks/AssetsStudioPage/MainContent/ReportsData/useOilReportTable';
import { generateTableStructure } from '@/NewUI/components/AssetsPreviewPage/_common/MockData/oil-report-data';
import { Skeleton } from '@/components/ui/skeleton';
import { format } from 'date-fns';
import { DMInfoCircleIcon, DMOilCaneIcon, DMReportIcon } from '@/components/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveSensorDataInnerTab, setSelectedOilReportMetadata } from '@/app/slices/applicationSlice';
import { RootState } from '@/app/store';
import { SENSORS_DATA_TYPE } from '@/const';
import { cn } from '@/lib/utils';
import { useSelectedCoordinate } from '@/NewUI/hooks';

const iconClassName = 'w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center';

interface OilReportTableProps {
  fromDate?: any;
  toDate?: any;
  oilReportData?: any;
  isLoading: boolean;
  clickedCoordinate?: string;
  component?: any;
}
// @TODO: replace solution for component selection
// @TODO: replace useEffects

const OilReportTableEmptyState = () => {
  return (
    <div className="h-[calc(100vh-28rem)] w-full flex items-center justify-center flex-col gap-6">
      <div className="font-semibold">No Oil Reports Found</div>
      <div>Oil analysis enhances machine health insights - share your reports, and we’ll add them!</div>
    </div>
  );
};

export const OilReportTable = ({ oilReportData, isLoading = true, component }: OilReportTableProps) => {
  const selectedOilReportMetadata = useSelector((state: RootState) => state.application.selectedOilReportMetadata);
  const coordinates = useMemo(() => (oilReportData ? Object.keys(oilReportData) : []), [oilReportData]);
  const { selectedCoordinate, setSelectedCoordinate } = useSelectedCoordinate(coordinates, component);
  const oilReport = useMemo(
    () => (coordinates.length > 0 ? oilReportData[selectedCoordinate] : []),
    [selectedCoordinate, oilReportData, coordinates, isLoading]
  );
  const reportStructure = useMemo(() => generateTableStructure(oilReport), [oilReport]);
  const { columns, expandedRows, setExpandedRows, renderNestedRowCell, selectedItem } = useOilReportTable(
    reportStructure,
    oilReport,
    selectedOilReportMetadata
  );

  const selectedEl = selectedItem
    ? oilReport?.find((el: any) => el.meta.date === selectedItem)?.meta
    : selectedOilReportMetadata;

  if (!isLoading && (!oilReport || oilReport.length < 1)) return <OilReportTableEmptyState />;
  return (
    <div className="flex gap-2 h-[calc(100vh-10.3rem)] overflow-y-hidden w-full">
      {/* <FilterSideMenu items={maintenanceData} onFilterChange={handleFilterChange} title={<div>Placeholder</div>} /> */}
      {isLoading ? (
        <Skeleton className="w-full min-h-[calc(100vh-13.5rem)] h-[calc(100vh-13.5rem)]" />
      ) : (
        <div className="flex flex-col w-100 h-100 gap-2 overflow-x-auto overflow-hidden">
          {coordinates && coordinates.length > 1 && (
            <div className="flex w-full gap-4 py-1">
              {coordinates?.map((el: any) => (
                <div
                  key={el}
                  onClick={() => setSelectedCoordinate(el)}
                  className={cn(
                    'border-b-2 border-transparent px-2 cursor-pointer',
                    selectedCoordinate === el ? 'font-semibold border-primary-600' : ''
                  )}
                >
                  {el}
                </div>
              ))}
            </div>
          )}
          <div className="flex gap-6 items-center sticky left-0">
            <div className="flex items-center gap-2">
              <div className={iconClassName}>
                <DMReportIcon boxSize={6} />
              </div>
              <div className="">
                <div className="text-xs">Selected Report</div>
                <div className="font-semibold text-xs">
                  {`${selectedEl?.date && format(new Date(selectedEl?.date), 'dd MMM yy')},
              Report Number ${selectedEl?.report_number}`}
                </div>
              </div>
            </div>

            <div className="flex items-center gap-2">
              <div className={iconClassName}>
                <DMOilCaneIcon boxSize={6} />
              </div>
              <div className="">
                <div className="text-xs">Oil Type</div>
                <div className="font-semibold text-xs">{`${selectedEl?.oil_type}`}</div>
              </div>
            </div>

            <div className="flex items-center gap-2">
              <div className={iconClassName}>
                <DMInfoCircleIcon boxSize={6} />
              </div>
              <div className="">
                <div className="text-xs">Report Recommendations</div>
                <div className="font-semibold text-xs">{`${selectedEl?.report_recommendations}`}</div>
              </div>
            </div>
          </div>
          <CollapsableTable
            scrollToColumnId={selectedItem}
            mainData={reportStructure}
            renderNestedRowCell={renderNestedRowCell}
            nestedDataKey={'data'}
            mainColumns={columns}
            expandedRows={expandedRows}
            setExpandedRows={setExpandedRows}
          />
        </div>
      )}
    </div>
  );
};
